import React, { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { PRIVATE_PATH, VALID_SMS_CONTACT_METHODS, VALID_SMS_PHONE_TYPES } from '../../constants';

import ConfirmModal from '../../components/modals/BaseModal/ConfirmModal';
import Select from '../../components/form/Select';
import ToggleSlider from '../../components/form/ToggleSlider';

import './AccountManagement.scss';

const MODALS = { disable: 'DISABLE_MODAL', enable: 'ENABLE_MODAL' };
const validContactMethods = new Set(Object.keys(VALID_SMS_CONTACT_METHODS));
const validPhoneTypes = new Set(Object.keys(VALID_SMS_PHONE_TYPES));

const NotificationsSection = ({
	facilityOptions,
	saveChanges,
	setUserData,
	userData,
	setAllowEditContactInformation
}) => {
	const [allowEdit, setAllowEdit] = useState(false);
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		setAllowEdit(
			userData.smsOptIn !== false &&
				userData.phoneNumber &&
				validPhoneTypes.has(userData.contactPhoneType) &&
				validContactMethods.has(userData.contactMethodType)
		);
	}, [userData]);

	const toggleSMS = useCallback(() => {
		setShowModal(userData.smsEnabled ? MODALS.disable : MODALS.enable);
		setUserData((_userData) => ({ ..._userData, smsEnabled: !_userData.smsEnabled }));
	}, [userData, setUserData]);

	const dismissToggle = useCallback(() => {
		setShowModal(false);
		setUserData((_userData) => ({ ..._userData, smsEnabled: !_userData.smsEnabled }));
	}, [setUserData]);

	const confirmToggle = useCallback(() => {
		setShowModal(false);
		saveChanges();
	}, [saveChanges]);

	const formattedPhoneNumber =
		userData.sourceNumberFacilityContacts?.replace(/^\+1(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3') || 'N/A';

	return (
		<>
			<div className="notifications-section">
				<div className="account-management__form-wrapper">
					<Select
						className="select"
						disabled
						label={
							<>
								Facility <span className="input-label__comment">(Changes will only apply to the chosen facility.)</span>
							</>
						}
						name="facilityId"
						options={facilityOptions}
						value={userData.facilityId}
					/>

					<div className="with-divider">
						<ToggleSlider
							checked={userData.smsEnabled}
							disabled={!allowEdit}
							onSlide={toggleSMS}
							label="Enable SMS notifications"
						/>

						<div className="account-management__input-description">
							{userData.smsOptIn === false ? (
								<>You have previously opted out of these messages. To resume, text “YES” to {formattedPhoneNumber}.</>
							) : (
								!allowEdit && (
									<>
										A mobile phone number and a contact method that includes either “Text” or “Any” are required to
										enable SMS notifications.
										<br />
										<NavLink to={PRIVATE_PATH.CONTACT_INFORMATION} onClick={() => setAllowEditContactInformation(true)}>
											Edit contact information
										</NavLink>
									</>
								)
							)}
						</div>
					</div>
				</div>
			</div>
			{showModal === MODALS.disable && (
				<ConfirmModal
					className="notifications-section--disable-modal"
					onHide={dismissToggle}
					onPrimaryAction={confirmToggle}
					onSecondaryAction={dismissToggle}
					primaryActionLabel="Yes, disable"
					secondaryActionLabel="Cancel"
					title="Disable SMS notifications?"
				>
					Are you sure you want to disable SMS notifications for this facility? You will continue to receive text
					messages if they are enabled at another facility.
				</ConfirmModal>
			)}
			{showModal === MODALS.enable && (
				<ConfirmModal
					className="notifications-section--enable-modal"
					onHide={dismissToggle}
					onPrimaryAction={confirmToggle}
					onSecondaryAction={dismissToggle}
					primaryActionLabel="Yes, enable"
					secondaryActionLabel="Cancel"
					title="Enable SMS notifications?"
				>
					<p>
						By enabling SMS notification you agree to receive text messages about shift changes at this facility, such
						as call outs and replacements.
					</p>
					<p>
						The frequency of these messages may vary based on activity at your location. We will send you a confirmation
						message once you have enabled this setting.
					</p>
					<p>
						Message & data rates may apply. Reply STOP to opt-out or HELP for help to any message in the campaign.
						ShiftMed
						<a href="https://www.shiftmed.com/terms/" target="_blank" rel="noopener noreferrer">
							{' '}
							Terms & Conditions
						</a>{' '}
						and{' '}
						<a href="https://www.shiftmed.com/privacy/" target="_blank" rel="noopener noreferrer">
							Privacy Policy
						</a>
						.
					</p>
				</ConfirmModal>
			)}
		</>
	);
};

export default NotificationsSection;
