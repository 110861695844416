import React, { memo } from 'react';

import Actions from './Actions';

const RenderDataRow = ({ header, row, extraClassName }) => {
	const key = `data-${header.field}-${row.id}`;
	const action = header.action ? () => header.action(row.id) : null;
	const content = header.format ? header.format(row) : row[header.field];

	return header.field === 'actions' ? (
		<Actions key={key} rowId={row.id} edit={header.edit} remove={header.remove} className={extraClassName} />
	) : (
		<td onClick={action} key={key} aria-hidden="true" className={extraClassName}>
			{content}
		</td>
	);
};

export default memo(RenderDataRow);
