import React, { useState } from 'react';
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import classNames from 'classnames';
import ProTip from '../proTip/ProTip';

import BonusInfoIcon from '../../assets/images/bonus-info-icon.svg';
import GuaranteedIcon from '../../assets/images/guaranteed-icon.svg';
import TrashIcon from '../../assets/images/trash-icon.svg';

import './BonusModal.css';

/* eslint-disable react/jsx-props-no-spreading */

const DEFAULT_BONUSES = [25, 50, 75, 100];
const RANGE = [5, 500]; // We need to get this range from first and last row of homecare.facility_payable_bonus_lookup;

const BonusModal = (props) => {
	const [bonusAmount, setBonusAmount] = useState(props.shift.facilityPayableBonus || '');
	const [showFirstStep, setShowFirstStep] = useState(true);
	const [inputError, setInputError] = useState(null);
	const [eventCode, setEventCode] = useState(props.type);

	const handleInputChange = (e, customValue) => {
		setInputError(null);
		const value = customValue || e.target.value;
		setBonusAmount(value || '');
	};

	const handleInputBlur = (e) => {
		if (e.target.value) {
			const _amount = Math.round(e.target.value);

			if (_amount < RANGE[0]) {
				setInputError(`Minimum of $${RANGE[0]}`);
			} else if (_amount > RANGE[1]) {
				setInputError(`Maximum of $${RANGE[1]}`);
			} else if (!(_amount % 5 === 0)) {
				setInputError('$5 increments only');
			}
			setBonusAmount(_amount);
		}
	};

	const goToConfirmationStep = (type) => {
		setShowFirstStep(false);
		setEventCode(type || props.type);
	};

	const handleNextEvent = () => {
		if (props.showLoadingSpinner || inputError || !bonusAmount) return;
		if (showFirstStep) {
			goToConfirmationStep();
		} else {
			props.onConfirm(eventCode !== 'REMOVE' ? bonusAmount : 0, `${eventCode}_BONUS`);
		}
	};

	const tooltipPlacement = window.innerWidth < 1280 ? 'bottom' : 'right';

	/* eslint-disable jsx-a11y/label-has-associated-control */
	return (
		<Modal
			size={props.size || 'ms'}
			show
			aria-labelledby="contained-modal-title-vcenter"
			centered
			scrollable={!!props.scrollable}
			className="bonus-modal"
			onHide={props.onClose}
		>
			{showFirstStep && (
				<Modal.Body className="text-center">
					<div className="modal-title bonus">
						<span className="text-capitalize">{props.type.toLowerCase()}</span> a Bonus
					</div>
					<ProTip />
					<div className="modal-input">
						<label htmlFor="bonusAmount">Enter Bonus: </label>
						$
						<input
							id="bonusAmount"
							type="number"
							className={classNames('bonus-amount', { 'has-error': inputError })}
							placeholder={bonusAmount || 65}
							value={bonusAmount}
							onBlur={handleInputBlur}
							onChange={handleInputChange}
						/>
						<OverlayTrigger
							placement={tooltipPlacement}
							overlay={
								<Tooltip id="bonusInformation" className="bonus-tooltip">
									Bonuses can be any increment of $5. From ${RANGE[0]} to ${RANGE[1]}.
								</Tooltip>
							}
						>
							<img src={BonusInfoIcon} alt="Information icon" />
						</OverlayTrigger>
					</div>
					<p className={classNames('bonus-input-error', { show: inputError })}>{inputError}</p>
					<div className="modal-bonus-pills">
						<Pills bonusAmount={bonusAmount} handleInputChange={handleInputChange} />
					</div>
					{props.type === 'EDIT' && (
						<Button className="remove-bonus-button" onClick={() => goToConfirmationStep('REMOVE')}>
							<img className="remove-bonus-icon" src={TrashIcon} alt="remove bonus icon" />
							REMOVE
						</Button>
					)}
					<ShiftDetails {...props} />
				</Modal.Body>
			)}
			{!showFirstStep && <ConfirmationStep eventCode={eventCode} bonusAmount={bonusAmount} {...props} />}
			<Modal.Footer>
				{!showFirstStep && (
					<p className="bonus-go-back" onClick={() => setShowFirstStep(true)} aria-hidden="true">
						<span className="font-weight-bold">&#60;</span> GO BACK
					</p>
				)}
				<Button onClick={props.onClose} variant="secondary">
					{props.cancelLabel || 'CANCEL'}
				</Button>
				<Button
					onClick={handleNextEvent}
					variant="primary"
					className={classNames({ disabled: props.showLoadingSpinner || inputError || !bonusAmount })}
				>
					{showFirstStep ? `${props.type} BONUS` : 'CONFIRM'}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default BonusModal;

const ConfirmationStep = (props) => {
	const getTitle = () => {
		if (props.eventCode === 'REMOVE') return 'Remove Bonus';
		return `Confirm ${props.type === 'EDIT' ? 'Edit to Bonus' : 'Bonus'}`;
	};

	return (
		<Modal.Body className="text-center">
			<div className="modal-title">{getTitle()}</div>
			<div>
				{props.eventCode === 'REMOVE' && (
					<div>
						<p>{`Are you sure you want to remove this $${props.shift.facilityPayableBonus} bonus from all the open shifts?`}</p>
					</div>
				)}
				{props.eventCode !== 'REMOVE' && (
					<div>
						<p>{`Please confirm the ${
							props.type === 'EDIT' ? 'edit of ' : ''
						} bonus before adding it to the open shifts, that are detailed below.`}</p>
						<p>
							Bonus: <b>${props.bonusAmount}</b>
						</p>
					</div>
				)}
			</div>
			<ShiftDetails {...props} />
		</Modal.Body>
	);
};

const ShiftDetails = (props) => {
	const calculateOpenShifts = () => {
		if (props.shift.slots) {
			return props.shift.slots.filter((slot) => !slot.caregiver_id).length;
		}
		return 0;
	};
	const openShifts = calculateOpenShifts();
	return (
		<div className="modal-shift-info">
			<p className="modal-shift-info-title">Shift details:</p>
			<ul className="modal-shift-info-list">
				<li className="modal-shift-info-resource-type">
					Shift Type: <span className="ml-2">&nbsp;{props.shift.resourceType} </span>
					{props.shift.shift_guaranteed && (
						<span className="guaranteed-badge-span">
							<img className="guaranteed-icon" src={GuaranteedIcon} alt="Guaranteed shift" />
							Guaranteed
						</span>
					)}
				</li>
				<li>
					Time: <span>{`${props.shift.start_time} - ${props.shift.end_time}`}</span>
				</li>
				<li>
					Date: <span>{`${props.shift.shift_date} - ${moment.utc(props.shift.raw_start_time).format('dddd')}`}</span>
				</li>
				<li>
					Bonus applies to:{' '}
					<span>
						{openShifts} Open Shift{openShifts !== 1 ? 's' : ''}
					</span>
				</li>
			</ul>
		</div>
	);
};

const Pills = (props) => {
	return DEFAULT_BONUSES.map((bonus) => {
		return (
			<Button
				key={bonus}
				className={classNames('modal-bonus-pill', { selected: bonus === props.bonusAmount })}
				onClick={() => props.handleInputChange(null, bonus)}
			>
				{`$ ${bonus}`}
			</Button>
		);
	});
};
