import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Select from '../../../components/form/Select';
import InfoIcon from '../../../assets/images/icons8-info.svg';
import { showNotification } from '../../../redux/notification';
import xhr from '../../../utils/xhr';
import './SignatureRule.scss';

const SIGNATURE_OPTIONS = [
	{ label: 'Signature Required', value: 1 },
	{ label: 'Opt-Out of Signature', value: 2 }
];

const getSelectValue = (selectedValue) => {
	if (selectedValue === null) return null; // Distinguish from false in case no option was selected yet.
	return selectedValue ? 1 : 2;
};

const SignatureRule = ({ setShowLoadingSpinner, activeFacility }) => {
	const dispatch = useDispatch();
	const [pageHasBeenEdited, setPageHasBeenEdited] = useState(false);

	const [signatureClockInSelected, setSignatureClockInSelected] = useState(
		getSelectValue(activeFacility.checkin_signature_required)
	);
	const [signatureSentHomeSelected, setSignatureSentHomeSelected] = useState(
		getSelectValue(activeFacility.sent_home_early_signature_required)
	);

	const isInvalidToSave = () => {
		// Check if values have changed
		return signatureClockInSelected === null || signatureSentHomeSelected === null;
	};

	const getSignatureRules = useCallback(async () => {
		try {
			const { data: settingsFetched } = await xhr.request('GET', `/facility/${activeFacility.id}/signature-rule`);
			setSignatureClockInSelected(getSelectValue(settingsFetched.checkin_signature_required));
			setSignatureSentHomeSelected(getSelectValue(settingsFetched.sent_home_early_signature_required));
		} catch (error) {
			const message =
				error.response.data &&
				error.response.data.errors &&
				error.response.data.errors[0] &&
				error.response.data.errors[0].message;
			dispatch(showNotification('error', message || `Failed to get facility's settings.`));
		} finally {
			setShowLoadingSpinner(false);
		}
	}, [activeFacility.id, dispatch, setShowLoadingSpinner]);

	useEffect(() => {
		getSignatureRules();
	}, [getSignatureRules]);

	const onSave = async () => {
		if (isInvalidToSave()) {
			dispatch(
				showNotification(
					'error',
					'You must select a signature requirement for Clock-in and Sent home early in order to save.'
				)
			);
			return;
		}
		if (!pageHasBeenEdited) return;
		setShowLoadingSpinner(true);
		try {
			const updateSignatureRuleResponse = await xhr.request('PUT', `/facility/${activeFacility.id}/signature-rule`, {
				checkinSignatureRequired: signatureClockInSelected === 1,
				sentHomeEarlySignatureRequired: signatureSentHomeSelected === 1
			});
			if (updateSignatureRuleResponse.data) {
				dispatch(showNotification('success', 'Signature requirements updated successfully.'));
				setPageHasBeenEdited(false); // Prevent hitting BE if no new changes are applied
			}
		} catch (error) {
			const message =
				error.response.data &&
				error.response.data.errors &&
				error.response.data.errors[0] &&
				error.response.data.errors[0].message;
			dispatch(showNotification('error', message || `Failed to update facility's signature requirements.`));
		} finally {
			setShowLoadingSpinner(false);
		}
	};

	return (
		<div className="signature-rule">
			<div className="select-signature-section">
				<div className="signature-select clock-in">
					<p>Clock In</p>
					<Select
						handleChange={(option) => {
							setPageHasBeenEdited(true);
							setSignatureClockInSelected(option.clockIn);
						}}
						value={signatureClockInSelected}
						options={SIGNATURE_OPTIONS}
						name="clockIn"
					/>
				</div>
				<div className="clock-out">
					<p>
						Clock Out
						<OverlayTrigger
							trigger={['hover', 'click']}
							key="clock-out-tooltip"
							placement="bottom"
							overlay={
								<Tooltip id="tooltip-signature" show>
									Signature is always required for clock out.
								</Tooltip>
							}
						>
							<img alt="info-icon" className="info-clock-out" src={InfoIcon} />
						</OverlayTrigger>
					</p>
					<div className="signature-required-box">
						<p>Signature Required</p>
					</div>
				</div>
				<div className="signature-select sent-home-early">
					<p>Sent Home Early</p>
					<Select
						handleChange={(option) => {
							setPageHasBeenEdited(true);
							setSignatureSentHomeSelected(option.sentHomeEarly);
						}}
						className="signature-select"
						value={signatureSentHomeSelected}
						options={SIGNATURE_OPTIONS}
						name="sentHomeEarly"
					/>
				</div>
			</div>
			<button onClick={onSave} type="button" className={classNames('save-changes', { active: pageHasBeenEdited })}>
				SAVE CHANGES
			</button>
		</div>
	);
};

export default withRouter(SignatureRule);
