import React, { memo } from 'react';
import { useHistory } from 'react-router';
import useMediaQuery, { DESKTOP_WIDTH_QUERY } from '../hooks/useMediaQuery';
import ViewOnComputerModal from '../modals/ViewOnComputerModal';

const WithOnlyOnDesktop = ({ children }) => {
	const matches = useMediaQuery(DESKTOP_WIDTH_QUERY);
	const history = useHistory();

	const handleCloseViewOnComputerModal = () => history.push('/private/shifts');

	return matches ? children : <ViewOnComputerModal onCancel={handleCloseViewOnComputerModal} />;
};

export default memo(WithOnlyOnDesktop);
