import React from 'react';
import classNames from 'classnames';
import './ToggleSlider.scss';

const ToggleSlider = ({ checked, label, disabled, customClass, onSlide }) => {
	const handleSlide = () => {
		if (!disabled && onSlide) {
			onSlide(!checked);
		}
	};

	return (
		<div
			className={classNames('toggle-container', customClass)}
			role="button"
			tabIndex={0}
			onClick={handleSlide}
			onKeyDown={(e) => {
				if (e.key === 'Enter' || e.key === ' ') {
					handleSlide();
				}
			}}
		>
			<div className="input-label">{label}</div>

			<div
				className={classNames('toggle-slider', {
					'toggle-slider--disabled': disabled,
					'toggle-slider--checked': checked && !disabled
				})}
			>
				<div className={classNames('toggle-circle', { 'toggle-circle--checked': checked })} />
			</div>
		</div>
	);
};

export default ToggleSlider;
