import React, { memo, useState, useEffect, useCallback, useMemo } from 'react';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { user } from '../../redux/user';
import Select from '../../components/form/Select';
import removeIcon from '../../assets/images/remove-icon.svg';
import Utils from '../../utils/utils';

const SLOT_LIMIT = 20;

const ShiftRequired = (props) => {
	const { timezone, permissions } = useSelector(user);

	const [shiftStructureOptions, setShiftStructureOptions] = useState([]);
	const [structureIsOrientation, setStructureIsOrientation] = useState(false);

	const { shiftStructureId, numberOfProviders, resourceType, isGuaranteed, id } = props.shift;
	const { availableShiftStructures, date, errors } = props;
	const dateSelected = moment.tz(date, timezone);
	const today = moment.tz(timezone);
	const dateSelectedIsToday = dateSelected.isSame(today, 'day');

	const hasGuaranteePermission = useMemo(() => Utils.isAuthorizedExact('PORTAL.GUARANTEE', permissions), [permissions]);

	/* returns unique resource types values for dropdown selection */
	const getResourceTypeOptions = useCallback(() => {
		const added = {};
		const uniqueResourceTypes = [];

		availableShiftStructures.forEach((shiftStructure) => {
			if (!added[shiftStructure.resource_type]) {
				added[shiftStructure.resource_type] = true;
				uniqueResourceTypes.push({
					value: shiftStructure.resource_type,
					label: shiftStructure.resource_type
				});
			}
		});

		return uniqueResourceTypes;
	}, [availableShiftStructures]);

	/* we need to get the new available shift options in the second dropdown every time either the resource type changes
	 * or the available shift structures are changing
	 * */
	useEffect(() => {
		const structures = availableShiftStructures
			.filter((structure) => {
				/* If day selected in step one is current date, discard those shifts structures that start in the past. */
				const structureStartTime = moment.tz(structure.from_time, 'HH:mm:ss', true, timezone);
				const shiftAlreadyStarted = dateSelectedIsToday && today.isAfter(structureStartTime, 'minutes');
				return structure.resource_type === resourceType && !shiftAlreadyStarted;
			})
			.map((structure) => {
				const fromTime = moment.utc(structure.from_time, 'HH:mm:ss').format('h:mm A');
				const toTime = moment.utc(structure.to_time, 'HH:mm:ss').format('h:mm A');
				return {
					value: structure.id,
					label: structure.is_orientation ? `Orientation ${fromTime} - ${toTime}` : `${fromTime} - ${toTime}`
				};
			});
		setShiftStructureOptions(structures);
	}, [resourceType, availableShiftStructures, timezone]); // eslint-disable-line react-hooks/exhaustive-deps

	const updateShift = (patchObject) => {
		props.updateShift(props.shiftGroupIndex, props.shiftIndex, patchObject, id);
	};

	const handleShiftStructureChange = (value) => {
		const _shiftStructure = availableShiftStructures.find(
			(structure) => value.structure === structure.id
			// eslint-disable-next-line camelcase
		);
		const _shiftStructureIsOrientation = _shiftStructure ? _shiftStructure.is_orientation : null;
		setStructureIsOrientation(_shiftStructureIsOrientation);
		updateShift({
			shiftStructureId: value.structure,
			isGuaranteed: _shiftStructureIsOrientation ? false : isGuaranteed
		});
	};

	/* every time the resource type changes, we need to reset the current shift shiftStructureId */
	const handleResourceTypeChange = (value) => {
		updateShift({
			resourceType: value.type,
			shiftStructureId: null
		});
	};

	const removeShift = () => {
		props.removeShift(props.shiftGroupIndex, props.shiftIndex, id);
	};

	const handleProvidersChange = (e) => {
		let value = null;

		if (Number.isInteger(parseInt(e.target.value, 10))) {
			value = parseInt(e.target.value, 10);
		}

		updateShift({ numberOfProviders: value });
	};

	const handleIsGuaranteed = () => updateShift({ isGuaranteed: !isGuaranteed });

	const handleBlur = (e) => {
		const parsedValue = parseInt(e.target.value, 10);

		if (Number.isInteger(parsedValue) && parsedValue > SLOT_LIMIT) {
			updateShift({ numberOfProviders: SLOT_LIMIT });
		} else if (!Number.isInteger(parsedValue)) {
			updateShift({ numberOfProviders: null });
		}
	};

	return (
		<div>
			<div className="shift-options">
				<div className="shift-selector">
					<Select
						label="Shift Type"
						classNamePrefix="select-value"
						name="type"
						value={resourceType}
						hasError={errors.resourceType}
						placeholder="Select type..."
						handleChange={handleResourceTypeChange}
						options={getResourceTypeOptions()}
					/>
					{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
					<label className={classNames('required-label', { show: errors.resourceType })}>Required</label>
				</div>
				<div className="shift-selector">
					<Select
						label="Shift"
						classNamePrefix="select-value"
						name="structure"
						placeholder="Select shift..."
						value={shiftStructureId}
						hasError={errors.shiftStructureId}
						handleChange={handleShiftStructureChange}
						options={shiftStructureOptions}
					/>
					{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
					<label className={classNames('required-label', { show: errors.shiftStructureId })}>Required</label>
				</div>
				<div className="shift-selector provider-selector">
					<p className="provider-label">No of Providers</p>
					<input
						className={classNames('provider-input', { 'has-error': errors.numberOfProviders })}
						value={numberOfProviders || ''}
						onChange={handleProvidersChange}
						onBlur={handleBlur}
					/>
					{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
					<label className={classNames('required-label', { show: errors.numberOfProviders })}>Required</label>
				</div>
				<div className="shift-selector bonus-selector">
					<div className="bonus-remove">
						<div className="bonus">
							{!props.isFlex && (
								/* eslint-disable-next-line jsx-a11y/label-has-associated-control */
								<label className="guaranteed-shift">
									<input
										type="checkbox"
										disabled={structureIsOrientation || !hasGuaranteePermission}
										value={isGuaranteed}
										checked={isGuaranteed}
										onChange={handleIsGuaranteed}
									/>
									Guaranteed Shift
								</label>
							)}
							{props.allowRemove && (
								/* TODO fix eslint - transform to button */
								// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
								<div className="remove-section" onClick={removeShift}>
									<img className="remove-img" src={removeIcon} alt="remove" />
									<p className="remove-text">Remove</p>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default memo(ShiftRequired);
