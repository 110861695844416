import api from './api';

const professionalsApi = api.injectEndpoints({
	endpoints: (build) => ({
		professionals: build.query({
			query: () => 'professionals'
		}),
		flexWorkers: build.query({
			query: () => 'flex/internal-staff'
		})
	})
});

export const { useProfessionalsQuery, useFlexWorkersQuery } = professionalsApi;

export default professionalsApi;
