import React, { memo, useState } from 'react';
import { UNIT_ASSIGNMENT_MODALS } from '../../../constants/UnitAssignment';

import BaseModal from '../../../components/modals/BaseModal/BaseModal';

import './EditArrivedUnitModal.scss';

const EditAlreadyArrivedModal = ({ onCancel, setShowModal, slot }) => {
	const [disableButtons, setDisableButtons] = useState(false);

	const onConfirm = () => {
		setDisableButtons(true);
		setShowModal(UNIT_ASSIGNMENT_MODALS.edit);
	};

	return (
		<BaseModal
			className="already-arrived-modal"
			confirmModal
			title="This Professional has already arrived to their shift."
			primaryActionLabel="Yes, continue"
			onPrimaryAction={onConfirm}
			primaryActionDisabled={disableButtons}
			secondaryActionLabel="Cancel"
			onSecondaryAction={onCancel}
			secondaryActionDisabled={disableButtons}
			onHide={onCancel}
		>
			Do you want to update their unit assignment: <b>{slot.unit_name}</b> to something else? This will not add a second
			work location for this shift.
		</BaseModal>
	);
};

export default memo(EditAlreadyArrivedModal);
