import React from 'react';
import Icon from './Icon';

/**
 * AccountManagementIcon component displays an icon representing account management.
 *
 * @component
 * @param {Object} props - The component properties.
 * @param {number} [props.width=24] - Width of the icon.
 * @param {number} [props.height=25] - Height of the icon.
 * @param {string} [props.color='#000000'] - Color of the icon.
 * @param {string} [props.className] - Additional CSS classes for styling.
 * @returns {JSX.Element} AccountManagementIcon component.
 */
const AccountManagementIcon = React.memo(({ width = 24, height = 24, color = '#121315', className }) => {
	return (
		<Icon
			width={width}
			height={height}
			viewBox="0 0 24 24"
			color={color}
			className={className}
			path="M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.9 3.89 3 5 3ZM19 5H5V19H19V5ZM12 12C10.35 12 9 10.65 9 9C9 7.35 10.35 6 12 6C13.65 6 15 7.35 15 9C15 10.65 13.65 12 12 12ZM11 9C11 8.45 11.45 8 12 8C12.55 8 13 8.45 13 9C13 9.55 12.55 10 12 10C11.45 10 11 9.55 11 9ZM6 18V16.47C6 13.97 9.97 12.89 12 12.89C14.03 12.89 18 13.97 18 16.47V18H6ZM12 14.88C13.31 14.88 15 15.44 15.69 16H8.31C8.99 15.44 10.69 14.88 12 14.88Z"
		/>
	);
});

export default AccountManagementIcon;
