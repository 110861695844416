import React, { memo } from 'react';
import classNames from 'classnames';

import './MenuItemIconLabel.scss';

const MenuItemIconLabel = ({ icon, label, redNumber, showRedCircle, labelClass }) => {
	return (
		<div className="main-menu-item" role="button" aria-hidden="true">
			{icon}
			<span className={classNames('main-menu-label', labelClass)}>{label}</span>
			{showRedCircle && <span className="circle-red-number">{redNumber}</span>}
		</div>
	);
};

export default memo(MenuItemIconLabel);
