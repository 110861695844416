import React from 'react';
import classNames from 'classnames';
import './Avatar.scss';

/**
 * UserAvatar component displays a user's avatar with initials.
 *
 * @component
 * @param {Object} props - The component properties.
 * @param {string} [props.className] - Additional CSS classes for styling.
 * @param {string} props.initials - Initials to be displayed in the avatar.
 * @returns {JSX.Element} UserAvatar component.
 */
const UserAvatar = ({ className, initials }) => {
	return <div className={classNames(className, 'avatar user-avatar')}>{initials}</div>;
};

export default UserAvatar;
