import React, { useState, useEffect, memo } from 'react';
import moment from 'moment-timezone';
import { Modal, Button } from 'react-bootstrap';
import classNames from 'classnames';
import './CaregiverActionModals.css';
import InviteCaregiver from '../../../features/inviteCaregiver/InviteCaregiver';
import useInviteCaregiver from '../../../hooks/useInviteCaregiver';

const InviteCaregiverModal = (props) => {
	const {
		showCancel = true,
		size,
		onCancel = () => {},
		backdropClassName,
		title,
		customModalBodyClass,
		cancelLabel,
		className,
		scrollable,
		closeButton = true,
		reverseButtons = false,
		onSendInvitation,
		shift,
		shiftId,
		isRecurring,
		onShiftIdChange = () => {},
		onRecurringStatusChange = () => {}
	} = props;

	const [selectedCaregivers, setSelectedCaregivers] = useState([]);
	const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
	const [openSlots, setOpenSlots] = useState(shift.slots.length);

	const { bulkCreateInvite } = useInviteCaregiver(setShowLoadingSpinner, onSendInvitation);
	const { end_time: endTime, start_time: startTime, raw_start_time: rawStartDate, resourceType, slots } = shift;

	const specialtyId = slots[0].specialty_id;
	const formatDate = moment.utc(rawStartDate).format('dddd, MMMM D, YYYY');
	const shiftDate = moment.utc(rawStartDate).format('YYYY-MM-DD');

	const cancelButton = (
		<Button onClick={onCancel} variant={reverseButtons ? 'primary' : 'secondary'} disabled={showLoadingSpinner}>
			{cancelLabel || 'GO BACK'}
		</Button>
	);

	const addCaregiver = (caregiver) => {
		const found = selectedCaregivers.find((c) => c.caregiver_id === caregiver.caregiver_id);
		if (!found) {
			setSelectedCaregivers([...selectedCaregivers, ...[caregiver]]);
		}
	};

	const removeCaregiver = (caregiver) => {
		const newSelectedCaregiver = selectedCaregivers.filter((c) => c !== caregiver);
		setSelectedCaregivers(newSelectedCaregiver);
	};

	const sendInvitation = async () => {
		const successMessage = `You have successfully sent ${selectedCaregivers.length} invites for the ${resourceType} - ${formatDate}, ${startTime} - ${endTime} shift.`;
		const errorMessage = 'Invites failed to send, please try again';
		const bulkResponse = await bulkCreateInvite(
			selectedCaregivers,
			shiftId,
			isRecurring,
			shiftDate,
			successMessage,
			errorMessage
		);

		if (bulkResponse?.isRecurring) {
			onShiftIdChange(bulkResponse.shiftId);
			onRecurringStatusChange(false);
		}
	};

	const checkSlots = () => {
		const slotsAvailable = slots.filter((slot) => slot.caregiver_id == null).length;
		setOpenSlots(slotsAvailable);
	};

	useEffect(() => {
		checkSlots();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shift]);
	return (
		<Modal
			size={size || 'ms'}
			show
			className={classNames('invite-caregiver-modal', 'no-gutters', className)}
			onHide={onCancel}
			backdropClassName={backdropClassName}
			scrollable={!!scrollable}
		>
			<Modal.Header closeButton={closeButton}>
				<Modal.Title id="invite-caregiver-modal-title">
					<b>{title}</b> - <span>{formatDate}</span>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className={customModalBodyClass}>
				<InviteCaregiver
					specialtyId={specialtyId}
					startTime={startTime}
					endTime={endTime}
					resourceType={resourceType}
					dateTime={formatDate}
					shiftDate={shiftDate}
					openShift={openSlots}
					removeCaregiver={removeCaregiver}
					addCaregiver={addCaregiver}
					selectedCaregivers={selectedCaregivers}
					shiftId={shiftId}
					isRecurring={isRecurring}
					showLoadingSpinner={showLoadingSpinner}
				/>
			</Modal.Body>
			<Modal.Footer>
				{showCancel && !reverseButtons && cancelButton}
				<Button
					onClick={sendInvitation}
					id="send-invite-button"
					variant={reverseButtons ? 'secondary' : 'primary'}
					disabled={selectedCaregivers.length === 0 || showLoadingSpinner}
					className="no-gutters"
				>
					Send Invites
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default memo(InviteCaregiverModal);
