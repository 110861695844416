import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { loadShiftStructures, shiftStructures } from '../redux/feed';

/**
 * Custom React hook for managing shift slots.
 *
 * @module useShiftSlots
 * @returns {Object} - An object containing the following properties:
 *   @property {Function} getCaregiversNamesByShiftSlots - A function that takes an array of slots and sets the state of `assignedCaregiversByShiftSlots` with caregivers' information.
 *   @property {Array} assignedCaregiversByShiftSlots - An array of caregivers assigned to shift slots.
 *   @property {Array} feedShiftStructures - An array of shift structures from the Redux store.
 */
const useShiftSlots = () => {
	const dispatch = useDispatch();
	const feedShiftStructures = useSelector(shiftStructures, shallowEqual);
	const [assignedCaregiversByShiftSlots, setAssignedCaregiversByShiftSlots] = useState([]);

	const getCaregiversNamesByShiftSlots = (slots) => {
		const caregiverIds = new Set();
		const _caregivers = [];
		slots.forEach((slot) => {
			// eslint-disable-next-line camelcase
			if (slot?.caregiver?.highest_resource_type && !caregiverIds.has(slot.caregiver_id)) {
				caregiverIds.add(slot.caregiver_id);
				_caregivers.push({
					caregiverId: slot.caregiver_id,
					caregiverName: slot.caregiver_name,
					certificationType: slot.caregiver.highest_resource_type,
					caregiverStats: slot.caregiver.caregiverStats
				});
			}
		});

		setAssignedCaregiversByShiftSlots(_caregivers);
	};

	useEffect(() => {
		dispatch(loadShiftStructures());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		getCaregiversNamesByShiftSlots,
		assignedCaregiversByShiftSlots,
		feedShiftStructures
	};
};

export default useShiftSlots;
