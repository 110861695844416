import React, { memo } from 'react';
import classNames from 'classnames';
import GuaranteedIcon from '../../../components/icons/GuaranteedIcon';

const GuaranteedBadge = ({ isGuaranteed, isMobile }) => {
	return (
		<div className={classNames('guaranteed-checkbox-span', { 'not-guaranteed': !isGuaranteed })}>
			<GuaranteedIcon width={12} height={14} color={!isGuaranteed ? '#808080' : undefined} />
			{!isGuaranteed && isMobile ? 'Not guaranteed' : 'Guaranteed'}
		</div>
	);
};

export default memo(GuaranteedBadge);
