import { useState, useEffect } from 'react';

function useElementHeight(ref) {
	const [height, setHeight] = useState(0);

	useEffect(() => {
		if (ref.current) {
			const handleResize = () => {
				setHeight(ref.current.offsetHeight);
			};

			handleResize();

			window.addEventListener('resize', handleResize);

			// Always return a cleanup function
			return () => window.removeEventListener('resize', handleResize);
		}

		// Return a cleanup function if ref.current is not available
		return () => {};
	}, [ref]);

	return height;
}

export default useElementHeight;
