import React, { memo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';

import useMediaQuery, { MOBILE_WIDTH_QUERY } from '../hooks/useMediaQuery';

import './BaseBadge.scss';

const BaseBadge = ({ tooltipHtml, badgeCopy, icon, className }) => {
	const isMobile = useMediaQuery(MOBILE_WIDTH_QUERY);

	return tooltipHtml ? (
		<OverlayTrigger
			placement={isMobile ? 'bottom' : 'right'}
			trigger={['hover', 'focus']}
			className="base-badge--tooltip"
			overlay={
				<Tooltip delay={{ show: 150, hide: 400 }} style={{ fontSize: '1.4rem' }}>
					<div>{tooltipHtml}</div>
				</Tooltip>
			}
		>
			<div className={classNames(className, 'base-badge')}>
				{icon}
				<div className="badge-label">{badgeCopy}</div>
			</div>
		</OverlayTrigger>
	) : (
		<div className={classNames(className, 'base-badge')}>
			{icon}
			<div className="badge-label">{badgeCopy}</div>
		</div>
	);
};

export default memo(BaseBadge);
