import React, { memo, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import Pagination from './Pagination';
import RenderDataRow from './RenderDataRow';

import './DataTable.scss';

const DataTable = ({ headers, data, extraClassName, PageSize = 5, emptyDataMessage = 'No info.' }) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [tableData, setTableData] = useState([]);

	const setCurrentTableData = useCallback(() => {
		const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		setTableData(data.slice(firstPageIndex, lastPageIndex));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, data]);

	useEffect(() => {
		setCurrentTableData();
	}, [data, setCurrentTableData]);

	return (
		<div className="data-table-container">
			<table className={classNames('data-table', { [extraClassName]: extraClassName })}>
				<thead className="header-section">
					<tr>
						{headers.length > 0 &&
							headers.map((header) => {
								return header.field === 'actions' ? (
									<th className="small-title" key="actions">
										Actions
									</th>
								) : (
									<th className={header.size} key={header.field}>
										{header.title}
									</th>
								);
							})}
					</tr>
				</thead>
				<tbody className="body-section">
					<tr className="separator" />
					{tableData.length > 0 ? (
						tableData.map((row) => {
							return (
								<tr key={`row-${row.id}`}>
									{headers.map((header) => (
										<RenderDataRow
											header={header}
											key={header.title}
											row={row}
											extraClassName={header.extraClassName}
										/>
									))}
								</tr>
							);
						})
					) : (
						<tr>
							<td colSpan={headers.length > 0 && headers.length}>{emptyDataMessage}</td>
						</tr>
					)}
				</tbody>
			</table>
			{data.length > PageSize && (
				<Pagination
					className="pagination-bar"
					currentPage={currentPage}
					totalCount={data.length}
					pageSize={PageSize}
					onPageChange={(page) => setCurrentPage(page)}
				/>
			)}
		</div>
	);
};

export default memo(DataTable);
