import React, { memo, useEffect, useState } from 'react';
import moment from 'moment';

import BaseModal from '../../../components/modals/BaseModal/BaseModal';

import { WarningIcon } from '../../../components/icons';

const UnassignAlreadyArrivedModal = ({ onCancel, onEditAssignment, setSelectedUnit, selectedUnit, slot }) => {
	const [disableButtons, setDisableButtons] = useState(false);
	const [unassignUnit, setUnassignUnit] = useState(false);

	const onConfirm = () => {
		setDisableButtons(true);
		setSelectedUnit(null);
		setUnassignUnit(true);
	};

	useEffect(() => {
		if (unassignUnit && onEditAssignment && !selectedUnit) {
			onEditAssignment();
		}
	}, [unassignUnit, selectedUnit, onEditAssignment]);

	return (
		<BaseModal
			confirmModal
			title="This Professional has already arrived to their shift."
			titleIcon={<WarningIcon width="100" height="100" />}
			primaryActionLabel="Remove Unit"
			onPrimaryAction={onConfirm}
			primaryActionDisabled={disableButtons}
			secondaryActionLabel="Cancel"
			onSecondaryAction={onCancel}
			secondaryActionDisabled={disableButtons}
			onHide={onCancel}
		>
			Are you sure you want to remove the <b>{slot.unit_name}</b> unit from this shift{' '}
			<b>
				({moment.utc(slot.raw_start_time).format('dddd, MMMM Do, YYYY')},{' '}
				{moment.utc(slot.raw_start_time).format('hh:mm A')} – {moment.utc(slot.raw_end_time).format('hh:mm A')},{' '}
				{slot.resource_type})
			</b>
			?
		</BaseModal>
	);
};

export default memo(UnassignAlreadyArrivedModal);
