import React, { useState } from 'react';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { Button, Modal } from 'react-bootstrap';
import useSearchDebounce from '../../../hooks/useSearchDebounce';
import xhr from '../../../utils/xhr';
import ModalShiftDetails from './ModalShiftDetails';
import CaregiverSearchControl from './CaregiverSearchControl';
import LoadingSpinner from '../../spinner/LoadingSpinner';

const AssignCaregiverModal = ({ onCancel, onAssign, shift }) => {
	const [searchValue, setSearchValue] = useState('');
	const [selectedCaregivers, setSelectedCaregivers] = useState([]);
	const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
	const [searchLoading, setSearchLoading] = useState(false);
	const [searchCaregivers, setSearchCaregivers] = useState([]);
	const [searchError, setSearchError] = useState(false);
	const { end_time: endTime, start_time: startTime, resourceType } = shift;
	const date = moment.utc(shift.raw_start_time).format('dddd, MMMM D, YYYY');
	const openSlots = shift.slots.filter((slot) => slot.caregiver_id === null).length;

	const addSelectedCaregiver = (caregiver) => {
		setSearchValue('');
		setSearchCaregivers([]);
		const found = selectedCaregivers.find((c) => c.caregiver_id === caregiver.caregiver_id);
		if (!found) {
			setSelectedCaregivers([...selectedCaregivers, ...[caregiver]]);
		}
	};

	const handleResults = (results = []) => {
		setSearchCaregivers(results);
	};

	const searchParams = { shiftId: shift.id };

	const { searchTermChange } = useSearchDebounce(
		'/flex/caregivers/find',
		searchValue,
		searchParams,
		setSearchValue,
		setSearchLoading,
		setSearchError,
		handleResults,
		3
	);

	const assignCaregiver = async () => {
		setShowLoadingSpinner(true);
		const [caregiver] = selectedCaregivers;
		try {
			await xhr.request('POST', '/flex/caregivers/assign', {
				caregiverId: caregiver.caregiver_id,
				shiftId: shift.id
			});
			onAssign('success', 'Professional successfully assigned to shift');
		} catch (error) {
			const isValidationError = error.response?.data?.validationError;
			let message = 'There was a problem assigning the professional to the shift';
			if (isValidationError) {
				const [validationError] = error.response.data.errors;
				message = validationError.message;
			}
			onAssign('error', message);
		} finally {
			setShowLoadingSpinner(false);
		}
	};

	const removeSelectedProfessional = (e, caregiver) => {
		e.preventDefault();
		const newSelectedCaregiver = selectedCaregivers.filter((c) => c !== caregiver);
		setSelectedCaregivers(newSelectedCaregiver);
	};

	return (
		<Modal
			size="xl"
			show
			className={classNames('assign-caregiver-modal', 'no-gutters')}
			onHide={onCancel}
			backdropClassName="assign-caregiver-backdrop"
			scrollable
		>
			<Modal.Header closeButton>
				<Modal.Title id="assign-caregiver-modal-title">
					<b>Assign {resourceType}</b> - <span>{date}</span>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<ModalShiftDetails
					dateTime={date}
					resourceType={resourceType}
					startTime={startTime}
					endTime={endTime}
					openShift={openSlots}
				/>
				<br />
				<br />
				<CaregiverSearchControl
					addSelectedCaregiver={addSelectedCaregiver}
					errorSearch={searchError}
					searchDisabled={selectedCaregivers.length > 0}
					searchValue={searchValue}
					searchTermChange={searchTermChange}
					selectedCaregivers={selectedCaregivers}
					removeSelectedProfessional={removeSelectedProfessional}
					loading={searchLoading}
					searchedCaregivers={searchCaregivers}
					searchSectionTitle="Search and Select Professional"
					selectedSectionTitle="Selected Professional"
					copy="Search for an internal staff professional with this license type to assign to this shift."
				/>
				{showLoadingSpinner && <LoadingSpinner key="loading-invites" />}
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={onCancel} variant="secondary" disabled={showLoadingSpinner}>
					Cancel
				</Button>
				<Button
					onClick={assignCaregiver}
					id="send-invite-button"
					variant="primary"
					disabled={selectedCaregivers.length === 0 || showLoadingSpinner}
					className="no-gutters"
				>
					Assign Shift
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default AssignCaregiverModal;
