const isDevEnv = () => process.env.NODE_ENV === 'development';

const getOrigin = () => {
   if (!window.location.origin) {
       return `${window.location.protocol  }//${  window.location.hostname  }${window.location.port ? `:${  window.location.port}`: ''}`;
   } 
    return window.location.origin;
}

const config = {
    HOST_NAME: isDevEnv() ? 'https://qa-portal.shiftmed.com/' : getOrigin(),
    API_URL: '/api',
    IS_IE: !!window.document.documentMode // Will be true for every IE version between 5 and 11.
};

export default config;
