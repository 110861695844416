import React from 'react';
import Icon from './Icon';

/**
 * WarningIcon component displays an icon representing a logout action.
 *
 * @component
 * @param {Object} props - The component properties.
 * @param {number} [props.width=24] - Width of the icon.
 * @param {number} [props.height=24] - Height of the icon.
 * @param {string} [props.color='#A11010'] - Color of the icon.
 * @param {string} [props.className] - Additional CSS classes for styling.
 * @returns {JSX.Element} WarningIcon component.
 */
const WarningIcon = ({ width = 24, height = 24, color = '#A11010', className }) => {
	return (
		<Icon
			width={width}
			height={height}
			viewBox="0 0 100 100"
			color={color}
			className={className}
			path="M95.8337 89.5834L50.0003 10.4167L4.16699 89.5834H95.8337ZM45.8337 77.0834V68.75H54.167V77.0834H45.8337ZM45.8337 60.4167H54.167V43.75H45.8337V60.4167Z"
		/>
	);
};

export default WarningIcon;
