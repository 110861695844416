/* eslint-disable */

import React, { memo } from 'react';

import config from '../../../config';
import { CAREGIVER_ROLE } from '../../../constants';

import TierBadge from '../../../components/badges/TierBadge';
import BaseBadge from '../../../components/badges/BaseBadge';
import IconWithTooltip from '../../../components/icons/IconWithTooltip';
import { CalendarIcon, DownloadIcon, PhoneIcon } from '../../../components/icons';

const ShiftSlotDisplay = (props) => {
	let {
		slot: {
			caregiver_name: caregiverName,
			is_oriented_worker: isOrientedWorker,
			is_returning_worker: isReturningWorker,
			replaced_caregiver_name: replacedCaregiverName,
			caregiver_phone_number: caregiverPhoneNumber,
			block_schedule_id: blockScheduleId,
			attachment_id: attachmentId,
			slot_claim_role: slotClaimRole,
			slot_id: slotId,
			caregiver = {}
		} = {}
	} = props;

	const hasAttachment = !!attachmentId;
	const { isCaregiverInvited = false } = caregiver;
	const isExternalWorker = slotClaimRole?.code === CAREGIVER_ROLE.EXTERNAL;

	const showCaregiverCredentials = () =>
		window.open(`${config.HOST_NAME}${config.API_URL}/slot/${slotId}/credential-packet`, '_blank');

	const WorkerTypeBadges = () => {
		const badges = [];
		if (isExternalWorker) badges.push('EXTERNAL WORKER');
		if (isOrientedWorker) badges.push('ORIENTED WORKER');
		if (isReturningWorker && !isOrientedWorker) badges.push('RETURNING WORKER');
		if (blockScheduleId) badges.push('BLOCK SCHEDULED');

		return (
			<div
				className="d-flex justify-content-sm-center align-items-md-center flex-column-sm flex-wrap"
				key={`caregiver-badges-${slotId}`}
			>
				{badges.map((badge) => (
					<BaseBadge key={`worker-badge-${badge}`} badgeCopy={badge} className="worker-type-badge" />
				))}
			</div>
		);
	};

	return (
		<div className="replaced-caregiver-container" key={`oriented-badge-${slotId}`}>
			{replacedCaregiverName && (
				<div className="replaced-caregiver" key={`replaced-caregiver-${slotId}`}>
					{replacedCaregiverName}
				</div>
			)}
			<div className="replaced-caregiver-flex">
				<div className="current-caregiver" key="current-caregiver">
					<div className="d-flex justify-content-sm-center">
						{caregiverName ? (
							<>
								{replacedCaregiverName && (
									<img className="mr-2" src="/static/media/replace-arrow.95b782e4.svg" alt="replaced-caregiver-arrow" />
								)}
								{isCaregiverInvited && <IconWithTooltip tooltipText="Invited Professional" icon={<CalendarIcon />} />}
								{caregiverName}
								<TierBadge caregiver={caregiver} caregiverName={caregiverName} />
								{caregiverPhoneNumber && (
									<IconWithTooltip
										tooltipText={caregiverPhoneNumber}
										icon={<PhoneIcon alt="Phone" color="#7200a0" />}
									/>
								)}
								{hasAttachment && (
									<IconWithTooltip
										tooltipText="Click to open credentials"
										icon={<DownloadIcon />}
										onClick={showCaregiverCredentials}
									/>
								)}
							</>
						) : (
							<>
								{replacedCaregiverName && (
									<img className="mr-2" src="/static/media/replace-arrow.95b782e4.svg" alt="replaced-caregiver-arrow" />
								)}
								<div className="looking-caregiver-badge" key="caregiver-looking-badge">
									Looking For Professional
								</div>
							</>
						)}
					</div>
					<WorkerTypeBadges />
				</div>
			</div>
		</div>
	);
};

export default memo(ShiftSlotDisplay);
