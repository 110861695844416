import React, { forwardRef } from 'react';
import useMediaQuery, { MOBILE_WIDTH_QUERY } from '../../../components/hooks/useMediaQuery';
import ShiftSlotDesktop from './ShiftSlotDesktop';
import ShiftSlotMobile from './ShiftSlotMobile';

const ShiftSlot = forwardRef(
	(
		{
			slot,
			shift,
			units,
			user,
			isFixedMarkup,
			getRowColor,
			updateSlotArrivalStatus,
			onConfirm,
			onPrepareCancel,
			handleEditBonusModalButton,
			handleAddBonusModalButton,
			preventGuaranteeOrBonusUpdates,
			onGuaranteedCheckboxChange,
			setShowUnitAssignmentModal,
			setUnitAssignmentSlot
		},
		ref
	) => {
		// eslint-disable-next-line camelcase
		const getUnitName = (_id) => units.find((u) => u.id === _id)?.unit_name;

		const isMobile = useMediaQuery(MOBILE_WIDTH_QUERY);
		return isMobile ? (
			<ShiftSlotMobile
				slot={slot}
				shift={shift}
				units={units}
				user={user}
				isFixedMarkup={isFixedMarkup}
				getRowColor={getRowColor}
				updateSlotArrivalStatus={updateSlotArrivalStatus}
				onConfirm={onConfirm}
				onPrepareCancel={onPrepareCancel}
				handleEditBonusModalButton={handleEditBonusModalButton}
				handleAddBonusModalButton={handleAddBonusModalButton}
				preventGuaranteeOrBonusUpdates={preventGuaranteeOrBonusUpdates}
				onGuaranteedCheckboxChange={onGuaranteedCheckboxChange}
				setShowUnitAssignmentModal={setShowUnitAssignmentModal}
				setUnitAssignmentSlot={setUnitAssignmentSlot}
				getUnitName={getUnitName}
				ref={ref}
			/>
		) : (
			<ShiftSlotDesktop
				slot={slot}
				shift={shift}
				units={units}
				user={user}
				isFixedMarkup={isFixedMarkup}
				getRowColor={getRowColor}
				updateSlotArrivalStatus={updateSlotArrivalStatus}
				onConfirm={onConfirm}
				onPrepareCancel={onPrepareCancel}
				handleEditBonusModalButton={handleEditBonusModalButton}
				handleAddBonusModalButton={handleAddBonusModalButton}
				preventGuaranteeOrBonusUpdates={preventGuaranteeOrBonusUpdates}
				onGuaranteedCheckboxChange={onGuaranteedCheckboxChange}
				setShowUnitAssignmentModal={setShowUnitAssignmentModal}
				setUnitAssignmentSlot={setUnitAssignmentSlot}
				getUnitName={getUnitName}
				ref={ref}
			/>
		);
	}
);

export default ShiftSlot;
