import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import classNames from 'classnames';
import LoadingSpinner from '../spinner/LoadingSpinner';
import './ConfirmationModal.css';

const ConfirmationModal = (props) => {
	const {
		showCancel = true,
		size,
		onCancel,
		backdropClassName,
		title,
		customModalBodyClass,
		showLoadingSpinner,
		children,
		cancelLabel,
		onConfirm,
		confirmLabel,
		className,
		scrollable,
		closeButton = true,
		reverseButtons = false
	} = props;
	const cancelButton = (
		<Button onClick={onCancel} variant={reverseButtons ? 'primary' : 'secondary'}>
			{cancelLabel || 'GO BACK'}
		</Button>
	);
	return (
		<Modal
			size={size || 'ms'}
			show
			aria-labelledby="contained-modal-title-vcenter"
			centered
			scrollable={!!scrollable}
			className={classNames('confirmation-modal', className)}
			onHide={onCancel}
			backdropClassName={backdropClassName}
		>
			<Modal.Header closeButton={closeButton}>
				<Modal.Title id="contained-modal-title-vcenter">{title || 'Confirm'}</Modal.Title>
			</Modal.Header>
			<Modal.Body className={customModalBodyClass}>
				{!showLoadingSpinner ? (
					children
				) : (
					<div className="spinner-wrapper">
						<LoadingSpinner key="loading-spinner" />
					</div>
				)}
			</Modal.Body>
			<Modal.Footer>
				{showCancel && !reverseButtons && cancelButton}
				<Button onClick={onConfirm} variant={reverseButtons ? 'secondary' : 'primary'} disabled={showLoadingSpinner}>
					{confirmLabel || 'CONFIRM'}
				</Button>
				{showCancel && reverseButtons && cancelButton}
			</Modal.Footer>
		</Modal>
	);
};

export default ConfirmationModal;
