/* eslint-disable import/no-cycle */
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import notificationReducer from './notification';
import errorReducer from './error';
import pendingConfirmNotificationReducer from './pendingConfirmNotification';
import userReducer from './user';
import feedReducer from './feed';
import pastShiftsReducer from './pastShifts';
import settingsReducer from './settings';
import navigateReducer from './navigate';
import api from './api';

const store = configureStore({
	reducer: {
		api: api.reducer,
		notification: notificationReducer,
		error: errorReducer,
		user: userReducer,
		pendingConfirmNotification: pendingConfirmNotificationReducer,
		feed: feedReducer,
		pastShifts: pastShiftsReducer,
		settings: settingsReducer,
		navigate: navigateReducer
	},
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware)
});

setupListeners(store.dispatch);

export default store;
