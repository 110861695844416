import React from 'react';
import Icon from './Icon';

/**
 * CaretIcon component displays a caret icon with customizable direction.
 *
 * @component
 * @param {Object} props - The component properties.
 * @param {string} [props.pointTo='right'] - Direction of the caret ('left', 'up', 'right', 'down').
 * @param {number} [props.width=24] - Width of the caret icon.
 * @param {number} [props.height=25] - Height of the caret icon.
 * @param {string} [props.color] - Color of the caret icon.
 * @param {string} [props.className] - Additional CSS classes for styling.
 * @returns {JSX.Element} CaretIcon component.
 */
const CaretIcon = ({ width = 24, height = 25, color, className, pointTo = 'right' }) => {
	const rotations = {
		left: 90,
		up: 180,
		right: 270,
		default: 0
	};

	const dimensions = {
		left: '8 16',
		right: '8 16',
		default: '16 8'
	};

	const rotation = rotations[pointTo] || rotations.default;
	const dimension = dimensions[pointTo] || dimensions.default;

	return (
		<Icon
			width={width}
			height={height}
			color={color}
			viewBox={`0 0 ${dimension}`}
			className={className}
			style={{ transform: `rotate(${rotation}deg)` }}
			path="M11.9725 0.445312L7 5.40698L2.0275 0.445312L0.5 1.97281L7 8.47281L13.5 1.97281L11.9725 0.445312Z"
		/>
	);
};

export default CaretIcon;
