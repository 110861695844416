import React from 'react';

const GuaranteedIcon = ({ width = 24, height = 24, className, color = '#00A698', fillColor = '#FFFFFF' }) => {
	return (
		<svg
			width={width}
			height={height}
			className={className}
			viewBox="0 0 14 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.2605 11.1847C12.8186 12.3502 12.1503 13.3636 11.274 14.1965C10.2764 15.1445 8.97022 15.8978 7.39159 16.4352C7.33978 16.4528 7.28572 16.4672 7.23141 16.4778C7.15967 16.4916 7.08667 16.499 7.0143 16.5H7.00013C6.92299 16.5 6.84547 16.4924 6.76859 16.4778C6.71427 16.4672 6.66097 16.4528 6.60942 16.4355C5.0289 15.899 3.72106 15.1461 2.72253 14.1981C1.84579 13.3652 1.17764 12.3524 0.736383 11.1869C-0.0659796 9.06775 -0.0203239 6.73327 0.0164265 4.85729L0.0170536 4.82849C0.0244539 4.67358 0.0292201 4.51086 0.0318541 4.33117C0.0452749 3.44897 0.765983 2.71911 1.67258 2.66991C3.56277 2.56725 5.02501 1.96728 6.2744 0.782096L6.28531 0.77233C6.49277 0.58715 6.75416 0.496329 7.0143 0.500114C7.26515 0.503287 7.51501 0.593986 7.71506 0.77233L7.72573 0.782096C8.97536 1.96728 10.4376 2.56725 12.3278 2.66991C13.2344 2.71911 13.9551 3.44897 13.9685 4.33117C13.9712 4.51208 13.9759 4.67455 13.9833 4.82849L13.9837 4.84069C14.0203 6.72021 14.0657 9.05932 13.2605 11.1847Z"
				fill={color || 'currentColor'}
			/>
			<path
				d="M11.1034 8.26459C11.1034 10.4654 9.27026 12.2568 7.01444 12.2646H7C4.73752 12.2646 2.89648 10.4701 2.89648 8.26459C2.89648 6.05915 4.73752 4.26465 7 4.26465H7.01444C9.27026 4.27249 11.1034 6.06392 11.1034 8.26459Z"
				fill={fillColor}
			/>
			<path
				d="M9.02101 7.93352L7.04935 9.82804L6.62331 10.2374C6.52266 10.334 6.39066 10.3823 6.25878 10.3823C6.12678 10.3823 5.9949 10.334 5.89412 10.2374L4.97812 9.35692C4.77683 9.16353 4.77683 8.85027 4.97812 8.65675C5.17914 8.46335 5.50561 8.46335 5.7069 8.65675L6.25878 9.18696L8.29222 7.23335C8.49352 7.03983 8.81998 7.03983 9.02101 7.23335C9.2223 7.42674 9.2223 7.74039 9.02101 7.93352Z"
				fill={color || 'currentColor'}
			/>
		</svg>
	);
};

export default GuaranteedIcon;
