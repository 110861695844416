import React from 'react';
import Icon from './Icon';

const CalendarIcon = ({ width = 25, height = 24, color, className }) => {
	return (
		<Icon
			width={width}
			height={height}
			color={color}
			className={className}
			viewBox="0 0 25 24"
			fill="none"
			path="M18 4H19C20.1 4 21 4.9 21 6V20C21 21.1 20.1 22 19 22H5C3.89 22 3 21.1 3 20L3.01 6C3.01 4.9 3.89 4 5 4H6V2H8V4H16V2H18V4ZM5 10V20H19V10H5ZM19 8H5V6H19V8ZM12 13H17V18H12V13Z"
		/>
	);
};

export default CalendarIcon;
