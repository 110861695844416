import React, { useMemo } from 'react';
import classNames from 'classnames';
import ArrowLeft from '../../assets/images/complete-arrow-left.svg';
import ArrowRight from '../../assets/images/complete-arrow-right.svg';

import './Pagination.scss';

const LEFT_DOTS = 'dots-left';
const RIGHT_DOTS = 'dots-right';

const Pagination = ({ totalCount, currentPage, pageSize, onPageChange, siblingCount = 1 }) => {
	const range = (start, end) => {
		const length = end - start + 1;
		return Array.from({ length }, (_, idx) => idx + start);
	};

	const paginationRange = useMemo(() => {
		const totalPageCount = Math.ceil(totalCount / pageSize);
		const paginationSizeRange = 5;
		const totalPageNumbers = siblingCount + paginationSizeRange;

		if (totalPageNumbers >= totalPageCount) {
			return range(1, totalPageCount);
		}

		const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
		const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

		const shouldShowLeftDots = leftSiblingIndex > 2;
		const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

		const firstPageIndex = 1;
		const lastPageIndex = totalPageCount;

		if (!shouldShowLeftDots && shouldShowRightDots) {
			const leftItemCount = 3 + 2 * siblingCount;
			const leftRange = range(1, leftItemCount);
			return [...leftRange, RIGHT_DOTS, totalPageCount];
		}

		if (shouldShowLeftDots && !shouldShowRightDots) {
			const rightItemCount = 3 + 2 * siblingCount;
			const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
			return [firstPageIndex, LEFT_DOTS, ...rightRange];
		}

		if (shouldShowLeftDots && shouldShowRightDots) {
			const middleRange = range(leftSiblingIndex, rightSiblingIndex);
			return [firstPageIndex, LEFT_DOTS, ...middleRange, RIGHT_DOTS, lastPageIndex];
		}

		return paginationRange;
	}, [totalCount, pageSize, siblingCount, currentPage]);

	const lastPage = paginationRange[paginationRange.length - 1];

	const onNext = () => {
		if (currentPage !== lastPage) {
			onPageChange(currentPage + 1);
		}
	};

	const onPrevious = () => {
		if (currentPage !== 1) {
			onPageChange(currentPage - 1);
		}
	};

	return (
		<>
			<ul className="pagination-container">
				<li
					key="pagination-previous-arrow"
					aria-hidden="true"
					className={classNames('pagination-item', {
						disabled: currentPage === 1
					})}
					onClick={onPrevious}
				>
					<img aria-hidden src={ArrowLeft} alt="Previous" />
					Previous
				</li>
				{paginationRange.map((pageNumber) => {
					if (pageNumber === LEFT_DOTS || pageNumber === RIGHT_DOTS) {
						return (
							<li key={pageNumber === LEFT_DOTS ? LEFT_DOTS : RIGHT_DOTS} className="pagination-item dots">
								&#8230;
							</li>
						);
					}

					return (
						<li
							key={pageNumber}
							aria-hidden="true"
							className={classNames('pagination-item', {
								selected: pageNumber === currentPage
							})}
							onClick={() => onPageChange(pageNumber)}
						>
							{pageNumber}
						</li>
					);
				})}
				<li
					key="pagination-next-arrow"
					aria-hidden="true"
					className={classNames('pagination-item', {
						disabled: currentPage === lastPage
					})}
					onClick={onNext}
				>
					Next
					<img aria-hidden src={ArrowRight} alt="Next" />
				</li>
			</ul>
		</>
	);
};

export default Pagination;
