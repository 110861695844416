import React from 'react';
import Icon from './Icon';

/**
 * CheckIcon component displays an icon representing check mark.
 *
 * @component
 * @param {Object} props - The component properties.
 * @param {number} [props.width=16] - Width of the icon.
 * @param {number} [props.height=16] - Height of the icon.
 * @param {string} [props.color='#000000'] - Color of the icon.
 * @param {string} [props.className] - Additional CSS classes for styling.
 * @returns {JSX.Element} AccountManagementIcon component.
 */
const Compass = React.memo(({ width = 16, height = 16, color, className }) => {
	return (
		<Icon
			width={width}
			height={height}
			viewBox="0 0 32 32"
			color={color}
			className={className}
			path="M16.5 29v0c-6.904 0-12.5-5.596-12.5-12.5s5.596-12.5 12.5-12.5c6.904 0 12.5 5.596 12.5 12.5s-5.596 12.5-12.5 12.5zM16.5 28c6.351 0 11.5-5.149 11.5-11.5s-5.149-11.5-11.5-11.5c-6.351 0-11.5 5.149-11.5 11.5s5.149 11.5 11.5 11.5v0zM18.268 18.268c-1.768 1.768-8.485 4.95-8.485 4.95s3.182-6.718 4.95-8.485c1.768-1.768 8.485-4.95 8.485-4.95s-3.182 6.717-4.95 8.485v0zM16.5 6c-0.276 0-0.5 0.215-0.5 0.49v3.019c0 0.271 0.232 0.49 0.5 0.49 0.276 0 0.5-0.215 0.5-0.49v-3.019c0-0.271-0.232-0.49-0.5-0.49v0zM27.043 16.543c0-0.276-0.215-0.5-0.49-0.5h-3.019c-0.271 0-0.49 0.232-0.49 0.5 0 0.276 0.215 0.5 0.49 0.5h3.019c0.271 0 0.49-0.232 0.49-0.5v0zM16.5 27.087c0.276 0 0.5-0.215 0.5-0.49v-3.019c0-0.271-0.232-0.49-0.5-0.49-0.276 0-0.5 0.215-0.5 0.49v3.019c0 0.271 0.232 0.49 0.5 0.49v0zM5.957 16.543c0 0.276 0.215 0.5 0.49 0.5h3.019c0.271 0 0.49-0.232 0.49-0.5 0-0.276-0.215-0.5-0.49-0.5h-3.019c-0.271 0-0.49 0.232-0.49 0.5v0zM15.425 15.5c-1.061 1.061-3.465 5.586-3.465 5.586s4.526-2.404 5.586-3.465l-2.121-2.121z"
		/>
	);
});

export default Compass;
