import React, { useState } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

import { showNotification } from '../../redux/notification';
import xhr from '../../utils/xhr';
import LoadingSpinner from '../../components/spinner/LoadingSpinner';
import backgroundImage from '../../assets/images/login-background.png';
import logoIcon from '../../assets/images/portal-logo.svg';
import passwordIcon from '../../assets/images/lock.svg';

import './Login.css';

// TODO fix regex to not use useless escapes
// eslint-disable-next-line no-useless-escape
const passwordRegex = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[=\-±!@#$%^&*()_+§{}:"|<>?~\[\];\'\\\,.\/`']).{8,})/;

const NewPassword = (props) => {
	const dispatch = useDispatch();

	const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
	const [password, setPassword] = useState('');
	const [retypePassword, setRetypePassword] = useState('');
	const [passwordNotStrongEnough, setPasswordNotStrongEnough] = useState(false);
	const [passwordDoesNotMatch, setPasswordDoesNotMatch] = useState(false);
	const [passwordInputType, setPasswordInputType] = useState('password');

	const togglePasswordInputType = () => {
		setPasswordInputType(passwordInputType === 'password' ? 'text' : 'password');
	};

	let passwordInput = null;
	let retypeInput = null;

	const create = async () => {
		if (!passwordRegex.test(password)) {
			passwordInput.focus();
			setPasswordNotStrongEnough(true);
		} else if (password !== retypePassword) {
			retypeInput.focus();
			setPasswordDoesNotMatch(true);
		} else {
			setShowLoadingSpinner(true);
			const { verificationToken } = props.match.params;

			try {
				await xhr.request('POST', `/user/reset-password`, {
					password,
					retypePassword,
					verificationToken
				});
				props.history.replace('/login');
			} catch (error) {
				if (error.response.data && error.response.data.errors && error.response.data.errors.length) {
					dispatch(showNotification('error', error.response.data.errors[0].message));
				} else {
					dispatch(showNotification('error', 'Failed to change password.'));
				}
				setShowLoadingSpinner(false);
			}
		}
	};

	const tooltipPlacement = window.innerWidth < 1280 ? 'top' : 'right';

	return (
		<div className="login-screen">
			<img src={backgroundImage} className="login-background d-none d-md-block" alt="" />
			<div className="login-form-container flex-one">
				<div className="login-form">
					{/* TODO fix eslint - transform to button */}
					{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
					<img src={logoIcon} alt="" className="logo" onClick={() => props.history.replace('/')} />
					<div className="subtitle">Create new password</div>

					<div className="input-container">
						<div className="input-icon">
							<img src={passwordIcon} placeholder="Email" alt="" />
						</div>
						<OverlayTrigger
							trigger="focus"
							placement={tooltipPlacement}
							overlay={
								<Popover className="password-popover">
									<Popover.Content>
										<strong>Your password must contain:</strong>
										<ul>
											<li>At least one upper case letter</li>
											<li>At least one lower case letter</li>
											<li>At least one digit</li>
											<li>At least one special character</li>
											<li>Min 8 characters long</li>
										</ul>
									</Popover.Content>
								</Popover>
							}
						>
							<div className="input-password-wrapper">
								<input
									type={passwordInputType}
									placeholder="Password"
									minLength={8}
									ref={(input) => {
										passwordInput = input;
									}}
									className={classNames('flex-one password-strength form-control', {
										'is-error': passwordNotStrongEnough
									})}
									onKeyUp={(e) => e.keyCode === 13 && create()}
									onChange={(e) =>
										setPassword(e.target.value) || setPasswordNotStrongEnough(!passwordRegex.test(e.target.value))
									}
								/>
								{/* TODO fix eslint - transform to button */}
								{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/no-static-element-interactions */}
								<span onClick={togglePasswordInputType} className="password-show-hide-toggle">
									{passwordInputType === 'password' ? 'Show' : 'Hide'}
								</span>
							</div>
						</OverlayTrigger>
					</div>
					<div className="input-container">
						<div className="input-icon">
							<img src={passwordIcon} placeholder="Password" alt="" />
						</div>
						<OverlayTrigger
							trigger="focus"
							placement={tooltipPlacement}
							overlay={
								<Popover className="password-popover">
									<Popover.Content>
										<strong>Make sure passwords match</strong>
									</Popover.Content>
								</Popover>
							}
						>
							<div className="input-password-wrapper">
								<input
									type={passwordInputType}
									placeholder="Confirm Password"
									minLength={8}
									ref={(input) => {
										retypeInput = input;
									}}
									className={classNames('flex-one password-strength form-control', {
										'is-error': passwordDoesNotMatch
									})}
									onKeyUp={(e) => e.keyCode === 13 && create()}
									onChange={(e) =>
										setRetypePassword(e.target.value) || setPasswordDoesNotMatch(password !== e.target.value)
									}
								/>
								{/* TODO fix eslint - transform to button */}
								{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions,jsx-a11y/no-static-element-interactions */}
								<span onClick={togglePasswordInputType} className="password-show-hide-toggle">
									{passwordInputType === 'password' ? 'Show' : 'Hide'}
								</span>
							</div>
						</OverlayTrigger>
					</div>

					<Button size="lg" block onClick={create}>
						CREATE NEW PASSWORD
					</Button>
				</div>
			</div>

			{showLoadingSpinner && <LoadingSpinner />}
		</div>
	);
};

export default withRouter(NewPassword);
