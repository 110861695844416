import React, { memo } from 'react';
import BulbIcon from '../../assets/images/lightbulb-icon.svg';
import './ProTip.css';

const ProTip = () => {
	return (
		<div className="pro-tip-box">
			<p className="pro-tip-text">
				<img src={BulbIcon} alt="lightbulb-icon" />
				Pro Tip: <span>Shifts with bonuses historically result in a 95% show rate.</span>
			</p>
		</div>
	);
};

export default memo(ProTip);
