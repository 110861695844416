import React, { Fragment, useMemo } from 'react';
import './RecurringShiftsTable.scss';
import moment from 'moment';
import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import tooltipIcon from '../../../assets/images/tooltip-icon.svg';
import tradeMarkIcon from '../../../assets/images/trade-mark.svg';
import Checkbox from '../../form/Checkbox';
import { user } from '../../../redux/user';
import Utils from '../../../utils/utils';

const RecurringShiftsTable = (props) => {
	const { permissions } = useSelector(user);

	const { shiftStructures, currentDay, recurringShiftsPayload, tooltipPlacement, setRecurringShiftsPayload } = props;

	const hasGuaranteePermission = useMemo(() => Utils.isAuthorizedExact('PORTAL.GUARANTEE', permissions), [permissions]);

	const getCurrentValue = (structureId) => {
		const structurePayload = recurringShiftsPayload[currentDay.fullName].find(
			(structure) => structure.structureId === structureId
		);

		if (structurePayload) {
			return String(structurePayload.slots);
		}

		return 0;
	};

	const getRecurringShiftId = (structureId) => {
		const structurePayload = recurringShiftsPayload[currentDay.fullName].find(
			(structure) => structure.structureId === structureId
		);

		if (structurePayload) {
			return String(structurePayload.recurringShiftId);
		}

		return null;
	};

	const getGuaranteedStatus = (structureId) => {
		const structurePayload = recurringShiftsPayload[currentDay.fullName].find(
			(structure) => structure.structureId === structureId
		);

		return structurePayload && structurePayload.isGuaranteed && structurePayload.slots > 0;
	};

	return (
		<div className="reccuring-shifts-grid">
			<div className="w-100 row reccuring-shifts-grid-head">
				<div className="col-3 title-font reccuring-shifts-grid-head-font centered-v-h-position">Shift Type</div>
				<div className="col-3 title-font reccuring-shifts-grid-head-font centered-v-h-position">Time</div>
				<div className="col-2 centered-v-h-position">
					<div className="title-font reccuring-shifts-grid-head-font margin-right-6">Shifts</div>
					<div className="right-top-corner">
						<OverlayTrigger
							placement={tooltipPlacement}
							overlay={
								<Tooltip className="aditionalInformation info-tooltip">
									This is the total number of nurses &amp; aides that you are respectively requesting for each shift
									time.
								</Tooltip>
							}
						>
							<img src={tooltipIcon} alt="tooltip-icon" className="tooltip-icon-small" />
						</OverlayTrigger>
					</div>
				</div>
				<div className="col-2 centered-v-h-position">
					<div className="title-font centered-v-h-position reccuring-shifts-grid-font">Guaranteed Shifts</div>
					<div className="table-head-centered-icons">
						<div className="right-top-corner margin-right-4">
							<img src={tradeMarkIcon} alt="trade-mark-icon" className="trade-mark-icon" />
						</div>
						<div className="right-top-corner">
							<OverlayTrigger
								placement="right"
								overlay={
									<Tooltip className="aditionalInformation info-tooltip">
										<p>Guaranteed Shifts are picked up with 20% higher claim rates &amp; have higher show rates.</p>
										<p>
											NOTE: If this shift is claimed, you are committing to pay for the professional, even if you choose
											to cancel them later.
										</p>
									</Tooltip>
								}
							>
								<img src={tooltipIcon} alt="tooltip-icon" className="tooltip-icon-small" />
							</OverlayTrigger>
						</div>
					</div>
				</div>
				<div className="col-2" />
			</div>
			<div className="reccuring-shifts-grid-body">
				{shiftStructures.map((shiftObj, ind) => (
					<Fragment key={`fragment-${shiftObj.resource_type}-${shiftObj.from_time}-${shiftObj.to_time}`}>
						{ind - 1 >= 0 && shiftStructures[ind - 1].resource_type !== shiftObj.resource_type && <hr />}
						<div
							className="w-100 row no-gutters"
							key={`${shiftObj.resource_type}-${shiftObj.from_time}-${shiftObj.to_time}`}
						>
							<div className="col-3 reccuring-shifts-grid-font common-font centered-v-h-position">
								{((ind - 1 >= 0 && shiftStructures[ind - 1].resource_type !== shiftObj.resource_type) || ind === 0) &&
									shiftObj.resource_type}
							</div>
							<div className="col-3 centered-v-h-position common-font time-font">
								{`${moment.utc(shiftObj.from_time, 'HH:mm:ss').format('h:mm A')} - ${moment
									.utc(shiftObj.to_time, 'HH:mm:ss')
									.format('h:mm A')}`}
							</div>
							<div className="col-2 centered-v-h-position">
								<input
									type="text"
									className={classNames({ 'greyout-input': Number(getCurrentValue(shiftObj.id)) === 0 }, 'textInput')}
									value={getCurrentValue(shiftObj.id)}
									onChange={(e) => {
										const value = getCurrentValue(shiftObj.id);
										const isZero = !value || value === '0';
										const amount = isZero ? e.target.value.replace(/[0]/g, '') : e.target.value;
										setRecurringShiftsPayload({
											type: 'input',
											structureId: shiftObj.id,
											amount,
											currentDay,
											recurringShiftId: getRecurringShiftId(shiftObj.id)
										});
									}}
								/>
							</div>
							<div className="col-2 centered-v-h-position">
								<Checkbox
									className="custom-shifts-checkbox"
									handleChange={(newState) =>
										setRecurringShiftsPayload({
											type: 'checkbox',
											structureId: shiftObj.id,
											newState,
											currentDay,
											recurringShiftId: getRecurringShiftId(shiftObj.id)
										})
									}
									disabled={
										(parseInt(getCurrentValue(shiftObj.id), 10) || 0) === 0 ||
										shiftObj.is_orientation ||
										!hasGuaranteePermission
									}
									checked={getGuaranteedStatus(shiftObj.id)}
								/>
							</div>
							<div className="col-2 centered-v-h-position">
								{shiftObj.is_orientation && <span className="orientation-badge">Orientation</span>}
							</div>
						</div>
					</Fragment>
				))}
			</div>
			<hr />
		</div>
	);
};

export default RecurringShiftsTable;
