/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
	name: 'settings',
	initialState: {
		isEditingFacilityInfo: false,
		showPrompt: false
	},
	reducers: {
		setIsEditingFacilityInfo: (state, action) => {
			state.isEditingFacilityInfo = action.payload;
		},
		setShowPrompt: (state, action) => {
			state.showPrompt = action.payload;
		}
	}
});

export const { setIsEditingFacilityInfo, setShowPrompt } = slice.actions;

export const isEditingFacilityInfo = (state) => state.settings.isEditingFacilityInfo;
export const showFacilityInfoPrompt = (state) => state.settings.showPrompt;

export default slice.reducer;
