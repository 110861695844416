import React, { useState } from 'react';
import classNames from 'classnames';
import useMediaQuery, { MOBILE_WIDTH_QUERY } from '../../../components/hooks/useMediaQuery';
import arrow from '../../../assets/images/arrow-right-black.svg';
import { FilterBurgerIcon } from '../../../components/icons';
import './FiltersContainer.scss';

/**
 * FiltersContainer component for managing and displaying filters.
 *
 * @component
 * @param {object} props - The component props.
 * @param {number} props.filterCount - The count of active filters.
 * @param {Function} props.handleClearFilters - Function to clear filters.
 * @param {React.ReactNode} props.filterCol - JSX for filtering by columns.
 * @param {React.ReactNode} props.filterSearchCaregiver - JSX for searching caregivers.
 * @returns {JSX.Element} The rendered component.
 */
const FiltersContainer = ({ filterCount, handleClearFilters, filterCol, filterSearchCaregiver }) => {
	const [isOpen, setIsOpen] = useState(false);
	const isMobile = useMediaQuery(MOBILE_WIDTH_QUERY);

	const ClearButton = (
		<button type="button" className="clear-button" onClick={handleClearFilters}>
			Clear Filters
		</button>
	);

	const InformationSection = (
		<div className="information-section" onClick={() => setIsOpen(!isOpen)} aria-hidden="true">
			<FilterBurgerIcon />
			<span className="subtitle">Filters</span>
			{filterCount > 0 && <span className="filter-count">{filterCount}</span>}
			<button className={classNames('toggle-button', { active: isOpen })} type="button">
				<img src={arrow} alt="toggle-filter" />
			</button>
		</div>
	);

	/**
	 * JSX for the desktop view of filters.
	 *
	 * @type {JSX.Element}
	 */
	const DesktopView = (
		<>
			<div className="clear-section">
				<span className="subtitle">Filter by:</span> {filterCount > 0 && ClearButton}
			</div>
			<div className="row no-gutters">
				{filterCol}
				<div className="col-xl-4 col-md-4">{filterSearchCaregiver}</div>
			</div>
		</>
	);

	/**
	 * JSX for the mobile view of filters.
	 *
	 * @type {JSX.Element}
	 */
	const MobileView = (
		<div className="row no-gutters flex-column">
			<div className="row no-gutters">
				<div className="col-xl-4 col-md-4">{filterSearchCaregiver}</div>
			</div>
			<div className="row no-gutters">
				<div className="clear-section">
					<div className="clear-button-section">{filterCount > 0 && ClearButton}</div>
					{InformationSection}
				</div>
			</div>
			{isOpen && <div className="row no-gutters">{filterCol}</div>}
		</div>
	);

	return <div className="filter-shift-container">{isMobile ? MobileView : DesktopView}</div>;
};

export default FiltersContainer;
