import React from 'react';
import classNames from 'classnames';
import { Button, Modal, CloseButton } from 'react-bootstrap';
import './NotificationPopupModal.scss';
import WarningTriangle from '../../../assets/images/warning-triangle.svg';

const NotificationPopupModal = (props) => {
	const {
		customChildComponent,
		title,
		bodyText,
		size = 'md',
		confirmLabel = 'OK',
		confirmReverseColor = false,
		cancelLabel = 'CLOSE',
		onClose,
		onConfirm,
		light = false,
		backdrop = true,
		warning = false
	} = props;

	const cancelButton = (
		<Button
			onClick={onClose}
			variant={`outline-${confirmReverseColor ? 'light' : 'primary'}`}
			className={`common-font custom-button ${confirmReverseColor ? 'confirm' : 'close'}-button`}
		>
			{cancelLabel}
		</Button>
	);

	const confirmButton = (
		<Button
			onClick={onConfirm}
			variant={`outline-${confirmReverseColor ? 'primary' : 'light'}`}
			className={`common-font custom-button ${confirmReverseColor ? 'close' : 'confirm'}-button`}
		>
			{confirmLabel}
		</Button>
	);

	return (
		<Modal
			size={size}
			key="notification-popup-modal-key"
			show
			aria-labelledby="contained-modal-title-vcenter"
			centered
			scrollable={false}
			className="notification-popup-modal"
			onHide={onClose}
			backdropClassName="notification-popup-modal-backdrop"
			backdrop={backdrop}
		>
			{light && <CloseButton onClick={onClose} />}
			{warning && <img className="notification-popup-modal-image" src={WarningTriangle} alt="Warning badge" />}
			<div className="notification-popup-modal-title">
				<div className="common-font title-font">{title}</div>
			</div>
			<div className="common-font notification-popup-modal-body">
				{/* eslint-disable-next-line react/no-danger */}
				{customChildComponent || (
					<div
						className={classNames({ 'body-text': !light, 'body-text-light': light })}
						dangerouslySetInnerHTML={{ __html: bodyText }}
					/>
				)}
			</div>
			{!light && (
				<div className="notification-popup-modal-footer">
					<div className="buttons-block">
						{cancelButton}
						<div className="mx-3" />
						{confirmButton}
					</div>
				</div>
			)}
		</Modal>
	);
};

export default NotificationPopupModal;
