/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { withRouter, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectedFacility } from '../redux/user';
import { FEATURE, PERMISSION, PRIVATE_PATH } from '../constants';
import Utils from '../utils/utils';
import AppHeader from '../components/header/AppHeader';
import LoadingSpinner from '../components/spinner/LoadingSpinner';
import ShiftsScreen from '../features/shifts/ShiftsScreen';
import PastShiftsScreen from '../features/shifts/PastShiftsScreen';
import ProfessionalsScreen from '../features/professionals/ProfessionalsScreen';
import AccountManagementScreen from '../features/account/AccountManagementScreen';
import SettingsScreen from '../features/settings/SettingsScreen';

import { shouldShowLoadingSpinner } from '../redux/pastShifts';

const PrivateSection = ({ userData }) => {
	const activeFacility = useSelector(selectedFacility) || null;
	const showLoadingSpinner = useSelector(shouldShowLoadingSpinner);

	return (
		<>
			<AppHeader />
			<Route path={PRIVATE_PATH.ACCOUNT}>
				<AccountManagementScreen />
			</Route>
			<Route exact path={PRIVATE_PATH.SHIFTS}>
				<ShiftsScreen />
			</Route>
			<ProtectedRoute
				exact
				path={PRIVATE_PATH.PAST_SHIFTS}
				key={`past-shifts-${activeFacility}`}
				component={PastShiftsScreen}
				pathPermission={PERMISSION.PAST_SHIFTS}
				userPermissions={userData.permissions}
			/>
			<ProtectedRoute
				exact
				path={PRIVATE_PATH.PROFESSIONALS_ON_DEMAND}
				key={`professionals-on-demand-${activeFacility}`}
				component={ProfessionalsScreen}
				pathPermission={PERMISSION.PROFESSIONALS}
				userPermissions={userData.permissions}
				featureCode={FEATURE.PROFESSIONALS}
				features={userData.features}
			/>
			<ProtectedRoute
				exact
				path={PRIVATE_PATH.PROFESSIONALS_INTERNAL_STAFF}
				key={`professionals-internal-staff-${activeFacility}`}
				component={ProfessionalsScreen}
				pathPermission={PERMISSION.INTERNAL_STAFF}
				userPermissions={userData.permissions}
				featureCode={FEATURE.FLEX_PROFESSIONALS}
				features={userData.features}
			/>
			<ProtectedSectionRoute
				exact
				path={PRIVATE_PATH.SETTINGS}
				key={`settings-${activeFacility}`}
				component={SettingsScreen}
				pathPermission={PERMISSION.SETTINGS}
				userPermissions={userData.permissions}
			/>
			{showLoadingSpinner && <LoadingSpinner />}
		</>
	);
};

export default withRouter(PrivateSection);

const ProtectedRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={() =>
			(rest.featureCode ? Utils.isFeatureEnabled(rest.featureCode, rest.features) : true) &&
			Utils.isAuthorizedExact(rest.pathPermission, rest.userPermissions) ? (
				<Component {...rest} />
			) : (
				<Redirect to="/" />
			)
		}
	/>
);

const ProtectedSectionRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={() =>
			Utils.isAuthorizedSection(rest.pathPermission, rest.userPermissions) ? (
				<Component {...rest} />
			) : (
				<Redirect to="/" />
			)
		}
	/>
);
