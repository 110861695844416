import React, { memo } from 'react';
import classNames from 'classnames';
import IsAuthorized from '../IsAuthorized/IsAuthorized';

const AuthorizationInput = (props) => (
	<IsAuthorized
		exactPermission={props.exactPermission}
		operation={props.operations}
		section={props.section}
		readOnlyRender={
			<input
				type={props.type}
				readOnly
				className={classNames(props.className, 'read-only')}
				checked={props.checked}
				disabled={props.disabled}
			/>
		}
	>
		<input
			type={props.type}
			className={props.className}
			onChange={props.onChange}
			checked={props.checked}
			disabled={props.disabled}
		/>
	</IsAuthorized>
);

export default memo(AuthorizationInput);
