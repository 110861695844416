import api from './api';

const facilityContactApi = api.injectEndpoints({
	endpoints: (build) => ({
		updateFacilityContact: build.mutation({
			query: ({ contactId, contact }) => ({
				url: `/facility/contact-information/${contactId}`,
				method: 'PUT',
				body: { contact }
			})
		})
	})
});

export const { useUpdateFacilityContactMutation, unwrapError } = facilityContactApi;

export default facilityContactApi;
