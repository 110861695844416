import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../../redux/notification';
import { setIsEditingFacilityInfo } from '../../../redux/settings';
import InfoIcon from '../../../assets/images/icons8-info.svg';
import AlertInfoIcon from '../../../assets/images/info-icon-black.svg';
import editIcon from '../../../assets/images/purple-edit-pencil.svg';
import './FacilityInfo.scss';
import utils from '../../../utils/utils';
import useFacilityInfo, { Section } from '../../../hooks/useFacilityInfo';
// import FileUploader from '../../../components/FileUploader/FileUploader';
// import config from '../../../config';

function FacilityOverview({ activeFacility, setShowLoadingSpinner, setShouldChangeActiveTab }) {
	const dispatch = useDispatch();
	const [isEditEnabled, setIsEditEnabled] = useState(false);
	const [fileSelected, setFileSelected] = useState({});
	const [persistedFileUploaded, setPersistedFileUploaded] = useState(null);
	const [facilityDoorCode, setFacilityDoorCode] = useState('');
	const [facilityParkingInformation, setFacilityParkingInformation] = useState('');
	const [facilityAdditionalDetails, setFacilityAdditionalDetails] = useState('');
	const [isFileDeleted, setIsFileDeleted] = useState(false);

	const handleisEditing = (isEditing) => {
		setIsEditEnabled(isEditing);
		dispatch(setIsEditingFacilityInfo(isEditing));
	};

	const handleError = (error, message) => {
		const errorMessage = utils.handleNetworkErrorMessage(error, message);
		dispatch(showNotification('error', errorMessage));
	};

	const { latestFacilityInfo, getFacilityInfo, createOrUpdateFacilityInfo } = useFacilityInfo(
		setShowLoadingSpinner,
		handleError
	);

	const addFacilityInformationWithFile = async () => {
		if (fileSelected?.file) {
			const isFileTypeValid = utils.validateFileType(fileSelected.file);

			if (isFileTypeValid) {
				dispatch(showNotification('error', isFileTypeValid));
				return;
			}
		}

		const facilityInfo = [];

		if (latestFacilityInfo[Section.ADDITIONAL_DETAILS].value !== facilityAdditionalDetails) {
			facilityInfo.push({
				...latestFacilityInfo[Section.ADDITIONAL_DETAILS],
				...{ value: facilityAdditionalDetails }
			});
		}

		if (latestFacilityInfo[Section.FACILITY_DOOR_CODE].value !== facilityDoorCode) {
			facilityInfo.push({
				...latestFacilityInfo[Section.FACILITY_DOOR_CODE],
				...{ value: facilityDoorCode }
			});
		}

		if (latestFacilityInfo[Section.PARKING_INFORMATION].value !== facilityParkingInformation) {
			facilityInfo.push({
				...latestFacilityInfo[Section.PARKING_INFORMATION],
				...{ value: facilityParkingInformation }
			});
		}

		const form = new FormData();

		form.append('facilityInfo', JSON.stringify(facilityInfo));
		form.append('fileDeleted', isFileDeleted);

		if (fileSelected?.file) {
			form.append('attachment', fileSelected.file);
		}

		if (fileSelected?.name) {
			form.append('fileName', fileSelected.name);
		}

		createOrUpdateFacilityInfo(form, () => {
			dispatch(showNotification('success', 'Facility Information added successfully.'));
			setPersistedFileUploaded(isFileDeleted ? {} : { name: fileSelected.name, file: fileSelected.file });
			setIsFileDeleted(false);
			handleisEditing(false);
		});
	};

	const setLatestFacilityInfo = () => {
		if (latestFacilityInfo) {
			setFacilityAdditionalDetails(latestFacilityInfo[Section.ADDITIONAL_DETAILS].value);
			setFacilityDoorCode(latestFacilityInfo[Section.FACILITY_DOOR_CODE].value);
			setFacilityParkingInformation(latestFacilityInfo[Section.PARKING_INFORMATION].value);
		}
	};

	const cancelFacilityOverview = () => {
		setLatestFacilityInfo();
		setFileSelected(persistedFileUploaded);
		handleisEditing(false);
	};

	/* 	const handleDownload = () => window.open(`${config.HOST_NAME}${config.API_URL}/facility/facility-policy`, '_blank');

	const handleDelete = () => {
		setFileSelected(null);
		setIsFileDeleted(true);
	}; */

	useEffect(() => {
		setShouldChangeActiveTab(!isEditEnabled);
	}, [isEditEnabled, setShouldChangeActiveTab]);

	useEffect(() => {
		setLatestFacilityInfo();
	}, [latestFacilityInfo]);

	useEffect(() => {
		getFacilityInfo();
	}, [activeFacility]);

	return (
		<div className="facility-overview">
			<div className="facility-overview-header">
				<p>Facility Overview</p>
				<div className="edit" onClick={() => handleisEditing(true)} role="button" aria-hidden>
					<img alt="info-icon" src={editIcon} /> EDIT
				</div>
			</div>
			<p className="info-disclaimer">
				This information will show after a healthcare professional claims a shift at your facility
			</p>

			<div className="overview-info">
				<div className="signature-required-box">
					<p>{Section.PARKING_INFORMATION}</p>
					<input
						disabled={!isEditEnabled}
						value={facilityParkingInformation}
						onChange={(e) => setFacilityParkingInformation(e.target.value)}
						placeholder="Ex: Garage A"
					/>
				</div>
				<div className="signature-required-box">
					<p>
						{Section.FACILITY_DOOR_CODE}
						<OverlayTrigger
							trigger={['hover', 'click']}
							key="door-code-tooltip"
							placement="bottom"
							overlay={
								<Tooltip id="tooltip-signature" show>
									Door code will show 30 minutes before the shift starts.
								</Tooltip>
							}
						>
							<img alt="info-icon" className="door-code" src={InfoIcon} />
						</OverlayTrigger>
					</p>
					<input
						disabled={!isEditEnabled}
						value={facilityDoorCode}
						onChange={(e) => setFacilityDoorCode(e.target.value)}
						placeholder="Ex: Front Door Code 12345"
					/>
				</div>
			</div>
			<div className="additional-details-section">
				<div className="additional-information">
					<img alt="info-icon" className="door-code" src={AlertInfoIcon} />
					<span>
						Any use of discriminatory language based on race, color, national origin, disability, age, gender, gender
						expression, sexual orientation, religion, or political beliefs is prohibited.
					</span>
				</div>
				<p>
					{Section.ADDITIONAL_DETAILS}
					<span>{`${facilityAdditionalDetails?.length ?? 0}/500`}</span>
				</p>
				<textarea
					disabled={!isEditEnabled}
					value={facilityAdditionalDetails}
					onChange={(e) => setFacilityAdditionalDetails(e.target.value)}
					placeholder="Ex: Please enter through front door and ask for Nursing Manager."
				/>
			</div>

			{/* 			<div className="upload-file-section">
				<p>
					<b>Upload Facility Policy and Details</b> (Any files uploaded are for ShiftMed&apos;s product development use
					only, until we develop this functionality in app down the road.)
				</p>
				<FileUploader
					disabled={!isEditEnabled}
					fileSelected={fileSelected}
					onDelete={handleDelete}
					onDownload={handleDownload}
					onSelected={(file) => setFileSelected({ ...fileSelected, ...file })}
				/>
			</div> */}

			<div className="action-section">
				<button onClick={cancelFacilityOverview} className="button-action cancel-button" type="button">
					CANCEL
				</button>
				<button
					onClick={addFacilityInformationWithFile}
					type="button"
					className={classNames('button-action save-changes', { active: isEditEnabled })}
					disabled={!isEditEnabled}
				>
					SUBMIT
				</button>
			</div>
		</div>
	);
}

export default FacilityOverview;
