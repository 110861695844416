import React, { forwardRef, memo } from 'react';
import { useSelector } from 'react-redux';

import DatePicker from 'react-datepicker';
import { user } from '../../../redux/user';

import MultiSelectSearch from '../../../components/MultiSelectSearch/MultiSelectSearch';
import arrow from '../../../assets/images/arrow-right-black.svg';

/**
 * Forwarded ref component for custom date filter input.
 *
 * @component
 * @param {object} props - The component props.
 * @param {Function} props.onClick - Click event handler.
 * @param {string} props.value - The current value for the date filter.
 * @param {React.Ref} ref - Forwarded ref.
 * @returns {JSX.Element} The rendered component.
 */
const DateFilterInput = forwardRef(({ onClick, value }, ref) => (
	<div
		aria-hidden
		ref={ref}
		onClick={onClick}
		suppressContentEditableWarning
		className="date-picker-filter"
		contentEditable
		onKeyPress={(e) => {
			e.preventDefault();
		}}
	>
		<span>
			<span className="date-subtitle">{`Date${value && ': '}`}</span>
			<span className="date">{value}</span>
		</span>
		<img src={arrow} alt="Datepicker" className="datepicker-arrow" />
	</div>
));

/**
 * Component for rendering filter selects.
 *
 * @component
 * @param {object} props - The component props.
 * @param {Date} props.dateFilter - The selected date filter.
 * @param {Function} props.onMultiSelectChange - Function to handle multi-select changes.
 * @param {Function} props.onDateChange - Function to handle date filter changes.
 * @param {object} props.activeOptions - The currently active options.
 * @returns {JSX.Element} The rendered component.
 */
const FilterSelects = ({
	dateFilter,
	onMultiSelectChange = () => {},
	onDateChange = () => {},
	activeOptions,
	availableFilters
}) => {
	const { resourceType, unit, shiftStatus, shiftFeature } = activeOptions;
	const { hasUnitsEnabled, activeFacility } = useSelector(user) || {};
	const { units } = activeFacility;

	const showUnits = hasUnitsEnabled && !!units.length;

	return (
		<div className="filter-selects">
			{availableFilters.date && (
				<DatePicker
					dateFormat="MMM dd, yyyy"
					selected={dateFilter}
					onChange={onDateChange}
					minDate={new Date()}
					customInput={<DateFilterInput />}
				/>
			)}
			{availableFilters.resourceType && (
				<MultiSelectSearch
					label="Shift Type"
					options={resourceType}
					onApply={(options) => onMultiSelectChange(options, 'resourceType')}
				/>
			)}
			{availableFilters.unit && showUnits && (
				<MultiSelectSearch label="Unit" options={unit} onApply={(options) => onMultiSelectChange(options, 'unit')} />
			)}
			{availableFilters.shiftStatus && (
				<MultiSelectSearch
					label="Status"
					options={shiftStatus}
					onApply={(options) => onMultiSelectChange(options, 'shiftStatus')}
				/>
			)}
			{availableFilters.shiftFeature && (
				<MultiSelectSearch
					label="Features"
					options={shiftFeature}
					onApply={(options) => onMultiSelectChange(options, 'shiftFeature')}
				/>
			)}
		</div>
	);
};

export default memo(FilterSelects);
