import React from 'react';

/**
 * A generic component to create an SVG icon.
 * This component should be extended by a specific component that will provide vector path.
 * @param {number} width The width of the icon
 * @param {number} height The height of the icon
 * @param {string} color The color of the icon
 * @param {className} className The class name of the icon
 * @param {path} path The vector path of the icon
 *
 * @returns A customized SVC icon
 */
const Icon = ({ width = 24, height = 25, viewBox = '0 0 24 24', color, className, path, style, children }) => {
	return (
		<svg
			width={width}
			height={height}
			className={className}
			viewBox={viewBox}
			style={style}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			{children || <path fillRule="evenodd" clipRule="evenodd" d={path} fill={color || 'currentColor'} />}
		</svg>
	);
};

export default Icon;
