import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button, InputGroup, Form } from 'react-bootstrap';
import moment from 'moment';
import classNames from 'classnames';
import './EditWageModal.scss';
import { showNotification } from '../../redux/notification';
import guaranteedIcon from '../../assets/images/guaranteed-icon.svg';
import { ArrowLeftIcon, ArrowRightIcon } from '../icons';
import xhr from '../../utils/xhr';
import { user } from '../../redux/user';

const TwoDigitTwoDecimalRegex = /^((?!0)\d{1,3}|0|\.\d{1,2})($|\.$|\.\d{1,2}$)/;

const NewWageField = ({ newCaregiverWage, handleChange, invalidWageMessage }) => {
	return (
		<section className="new-wage-field">
			<Form.Label htmlFor="new-wage">Enter New Wage</Form.Label>
			<InputGroup className={classNames({ invalid: invalidWageMessage.length > 0 })}>
				<InputGroup.Text>$</InputGroup.Text>
				<Form.Control placeholder="0.00" id="new-wage" value={newCaregiverWage} type="text" onChange={handleChange} />
			</InputGroup>
			<p className="error-message">{invalidWageMessage}</p>
		</section>
	);
};

const NewWageConfirm = ({ previousWage, newWage }) => {
	return (
		<section className="new-wage-confirm">
			<div>
				<p>Previous Wage</p>
				<p className="big-number previous">${previousWage}</p>
			</div>
			<div>
				<ArrowRightIcon />
			</div>
			<div>
				<p>New Wage</p>
				<p className="big-number new">${Number(newWage).toFixed(2)}</p>
			</div>
		</section>
	);
};

const EditWageModal = ({ shift, fetchData, onClose, isGuaranteed, openSlots }) => {
	const dispatch = useDispatch();
	const configData = useSelector(user);
	const [showConfirmSection, setShowConfirmSection] = useState(false);
	const [newCaregiverWage, setNewCaregiverWage] = useState(null);
	const [invalidWageMessage, setInvalidWageMessage] = useState('');

	const shiftStartTime = moment.utc(shift.raw_start_time).format('hh:mm A');
	const shiftEndTime = moment.utc(shift.raw_end_time).format('hh:mm A');
	const resourceType = shift.slots[0].resource_type;

	const handleWageChange = (e) => {
		const rawWage = e.target.value;

		const wage = parseFloat(rawWage);

		if (Number.isNaN(wage)) {
			setNewCaregiverWage(null);
			setInvalidWageMessage('');
			return;
		}

		if (!TwoDigitTwoDecimalRegex.test(rawWage)) {
			return;
		}

		setNewCaregiverWage(rawWage);
		if (wage > Number(configData.maxCaregiverWage)) {
			setInvalidWageMessage(`The new wage amount cannot exceed $${Number(configData.maxCaregiverWage)} per hour. `);
			return;
		}

		if (wage < parseFloat(shift.originalFixedMarkupWage)) {
			setInvalidWageMessage(`The new wage amount cannot be lesser than the shift's original wage`);
			return;
		}

		setInvalidWageMessage('');
	};

	const handleContinue = () => {
		if (invalidWageMessage || !newCaregiverWage) return;

		setShowConfirmSection(true);
	};

	const handleConfirm = async () => {
		const shiftInfo = `${resourceType} - ${moment
			.utc(shift.raw_start_time)
			.format('dddd, MMMM D, YYYY')}, ${shiftStartTime} - ${shiftEndTime}.`;
		try {
			await xhr.request('PUT', `/shift/${shift.id}/fixed-markup-wage`, {
				isRecurring: shift.isRecurring,
				shiftDate: moment.utc(shift.raw_start_time).format('YYYY-MM-DD'),
				isGuaranteed,
				newPrice: newCaregiverWage
			});
			fetchData();
			dispatch(showNotification('success', `Successfully updated wage for ${shiftInfo}`));
		} catch (error) {
			const [errorData] = error?.response?.data?.errors || [];
			dispatch(
				showNotification(
					'error',
					errorData && errorData.message ? errorData.message : `Failed to update wage for ${shiftInfo}`
				)
			);
		} finally {
			onClose();
		}
	};

	return (
		<Modal show centered className="edit-wage-modal" onHide={onClose} backdrop="static">
			<Modal.Header closeButton>
				<Modal.Title>Edit Healthcare Professional Hourly Wage</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<section className="current-wage-section bottom-divider">
					{!showConfirmSection && (
						<p>
							Current Wage: <strong>${shift.fixedMarkupWage || shift.originalFixedMarkupWage}</strong>
						</p>
					)}
					{showConfirmSection && (
						<p>
							{' '}
							Please confirm setting the wage to new amount below. The new wage will result in an update to the
							invoiceable amount when the shift is billed.{' '}
						</p>
					)}
				</section>

				<section className="new-wage-section bottom-divider">
					{!showConfirmSection && (
						<NewWageField
							newCaregiverWage={newCaregiverWage || ''}
							handleChange={handleWageChange}
							invalidWageMessage={invalidWageMessage}
						/>
					)}
					{showConfirmSection && (
						<NewWageConfirm
							previousWage={shift.fixedMarkupWage || shift.originalFixedMarkupWage}
							newWage={newCaregiverWage}
						/>
					)}
				</section>

				<section className="shift-info-section">
					<p>
						<strong>Shift Type:</strong> {resourceType}{' '}
					</p>
					<p>
						<strong>Shift Details: </strong>
						<span>{moment.utc(shift.raw_start_time).format('ddd, MMM. D')} </span>
						<span>
							{shiftStartTime} - {shiftEndTime}{' '}
						</span>
						{isGuaranteed && (
							<span className="guaranteed">
								<img src={guaranteedIcon} alt="Guaranteed shift" /> Guaranteed
							</span>
						)}
					</p>
					<p>
						<strong>New wage applies to:</strong> {openSlots} Open Shift(s)
					</p>
				</section>
			</Modal.Body>

			<Modal.Footer className={classNames({ 'confirm-layout': showConfirmSection })}>
				{showConfirmSection && (
					<Button className="go-back-button" onClick={() => setShowConfirmSection(false)} variant="light">
						<ArrowLeftIcon />
						<span>Go Back</span>
					</Button>
				)}
				<div className="buttons">
					<Button variant="light" className="cancel" onClick={onClose}>
						Cancel
					</Button>
					{!showConfirmSection && (
						<Button
							variant="light"
							className={classNames('confirm', { disabled: invalidWageMessage.length > 0 || !newCaregiverWage })}
							onClick={handleContinue}
						>
							Continue
						</Button>
					)}
					{showConfirmSection && (
						<Button variant="light" className="confirm" onClick={handleConfirm}>
							Confirm
						</Button>
					)}
				</div>
			</Modal.Footer>
		</Modal>
	);
};

export default EditWageModal;
