/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	isEditingFacilityInfo,
	setIsEditingFacilityInfo,
	setShowPrompt,
	showFacilityInfoPrompt
} from '../../../redux/settings';
import FacilityOverview from './FacilityOverview';
import RouterPrompt from '../../../components/modals/RouterPrompt/RouterPrompt';
import redFlag from '../../../assets/images/red-flag-icon.svg';
import './FacilityInfo.scss';

/* eslint-disable react/function-component-definition */
const FacilityInfoPage = ({ setShowLoadingSpinner, activeFacility, setShouldChangeActiveTab }) => {
	const dispatch = useDispatch();
	const isEditing = useSelector(isEditingFacilityInfo);
	const showPrompt = useSelector(showFacilityInfoPrompt);

	const handlePromptFinish = (isConfirmed = false) => {
		dispatch(setShowPrompt(false));
		if (isConfirmed) {
			dispatch(setIsEditingFacilityInfo(false));
		}
	};

	const handlePromptToggle = () => dispatch(setShowPrompt(!showPrompt));

	return (
		<div className="facility-info-page">
			<div className="facility-info-warning-box">
				<img alt="flag" src={redFlag} />
				<p>
					<b>Attention</b>: This information will display in the ShiftMed App for the Healthcare Professional. Please
					fill out the required information to ensure the workers want to work at your facility and are set up for
					success for the shift upon being claimed.
				</p>
			</div>
			<FacilityOverview
				setShowLoadingSpinner={setShowLoadingSpinner}
				activeFacility={activeFacility}
				setShouldChangeActiveTab={setShouldChangeActiveTab}
			/>
			<RouterPrompt
				blockRoutePush={isEditing}
				displayPrompt={showPrompt}
				message="Make sure to save changes before leaving the page. Leaving the page will delete any edits you have made"
				title="Done Editing?"
				confirmLabel="LEAVE PAGE"
				cancelLabel="KEEP EDITING"
				onPromptToggle={handlePromptToggle}
				onFinish={handlePromptFinish}
			/>
		</div>
	);
};

export default FacilityInfoPage;
