import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { loadFeed } from '../../../redux/feed';
import { useUpdateUnitMutation, unwrapError } from '../../../redux/units';
import { showNotification } from '../../../redux/notification';

import { UNIT_ASSIGNMENT_MODALS } from '../../../constants/UnitAssignment';
import EditUnitModal from './EditUnitModal';
import UnassignUnitModal from './UnassignUnitModal';
import EditAlreadyArrivedModal from './EditAlreadyArrivedModal';
import UnassignAlreadyArrivedModal from './UnassignAlreadyArrivedModal';

import './UnitAssignment.scss';

const UnitAssignment = ({ availableUnits, setIsLoading, setShowModal, showModal, slot }) => {
	const { resource_rate_id: resourceRateId, slot_id: slotId, unit_id: unitId, unit_name: unitName } = slot;
	const [selectedUnit, setSelectedUnit] = useState(availableUnits.find((_unit) => _unit?.id === unitId));
	const [updateUnit, { isLoading, isError, isSuccess, error }] = useUpdateUnitMutation();
	const dispatch = useDispatch();

	const onEditAssignment = () => {
		setIsLoading(true);
		setShowModal(false);
		updateUnit({ resourceRateId, slotId, unitId: selectedUnit?.id });
	};

	const onCancelEdit = () => {
		setSelectedUnit(availableUnits.filter((_unit) => _unit?.id === unitId)[0]);
		setShowModal(false);
	};

	const reloadFeed = useCallback(async () => {
		await dispatch(loadFeed());
		setIsLoading(false);
	}, [dispatch, setIsLoading]);

	useEffect(() => {
		if (isSuccess) {
			// eslint-disable-next-line camelcase
			const message = selectedUnit?.unit_name
				? `Your unit assignment for ${unitName || ''} for ${moment
						.utc(slot.raw_start_time)
						.format('dddd, MMMM Do, YYYY')},  
		${moment.utc(slot.raw_start_time).format('hh:mm A')} – ${moment.utc(slot.raw_end_time).format('hh:mm A')} 
		${slot.resource_type} to ${selectedUnit.unit_name} was successful.`
				: `Your unit removal for ${unitName || ''} for ${moment
						.utc(slot.raw_start_time)
						.format('dddd, MMMM Do, YYYY')},  
		${moment.utc(slot.raw_start_time).format('hh:mm A')} – ${moment.utc(slot.raw_end_time).format('hh:mm A')} 
		was successful.`;
			dispatch(showNotification('success', message));
		}
		if (isError && error) {
			dispatch(showNotification('error', unwrapError(error) || 'Failed to update unit. Please try again!'));
		}
		if (!isLoading && (isSuccess || isError)) {
			reloadFeed().catch((e) =>
				dispatch(showNotification('error', unwrapError(e) || 'Failed to update unit. Please try again!'))
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading, isError, isSuccess, error, reloadFeed]);

	return (
		<div className="unit-assignment">
			{showModal === UNIT_ASSIGNMENT_MODALS.edit && (
				<EditUnitModal
					slot={slot}
					onCancel={onCancelEdit}
					onEditAssignment={onEditAssignment}
					setShowModal={setShowModal}
					selectedUnit={selectedUnit}
					setSelectedUnit={setSelectedUnit}
					availableUnits={availableUnits}
				/>
			)}
			{showModal === UNIT_ASSIGNMENT_MODALS.unassign && (
				<UnassignUnitModal
					onCancel={() => setShowModal(null)}
					onEditAssignment={onEditAssignment}
					setSelectedUnit={setSelectedUnit}
					selectedUnit={selectedUnit}
					slot={slot}
				/>
			)}
			{showModal === UNIT_ASSIGNMENT_MODALS.editArrived && (
				<EditAlreadyArrivedModal onCancel={() => setShowModal(null)} setShowModal={setShowModal} slot={slot} />
			)}
			{showModal === UNIT_ASSIGNMENT_MODALS.unassignArrived && (
				<UnassignAlreadyArrivedModal
					onCancel={() => setShowModal(null)}
					onEditAssignment={onEditAssignment}
					setSelectedUnit={setSelectedUnit}
					selectedUnit={selectedUnit}
					slot={slot}
				/>
			)}
		</div>
	);
};

export default UnitAssignment;
