import React from 'react';
import Icon from './Icon';

const PencilIcon = ({ width = 24, height = 24, color, className }) => {
	return (
		<Icon
			width={width}
			height={height}
			color={color}
			className={className}
			viewBox="0 0 18 19"
			fill="none"
			path="M14.659 0.5C14.409 0.5 14.149 0.6 13.959 0.79L12.129 2.62L15.879 6.37L17.709 4.54C18.099 4.15 18.099 3.52 17.709 3.13L15.369 0.79C15.169 0.59 14.919 0.5 14.659 0.5ZM11.059 6.52L11.979 7.44L2.91902 16.5H1.99902V15.58L11.059 6.52ZM-0.000976562 14.75L11.059 3.69L14.809 7.44L3.74902 18.5H-0.000976562V14.75Z"
		/>
	);
};

export default PencilIcon;
