/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import IsAuthorized from '../IsAuthorized/IsAuthorized';
import CheckIcon from '../../assets/images/check.svg';
import CloseIcon from '../../assets/images/close-red.svg';
import editIcon from '../../assets/images/edit-icon.svg';

function CustomInput(props) {
	const [value, setValue] = useState('');
	const [edit, setEdit] = useState(false);
	const [hasChanges, setHasChanges] = useState(false);

	const realValue = hasChanges ? value : props.defaultValue || '';

	const enableEdit = () => {
		setEdit(true);
		setHasChanges(false);
	};

	const formatValue = (valueToFormat) => {
		const pointIdx = valueToFormat.indexOf('.');

		if (pointIdx === -1 || pointIdx === valueToFormat.length - 1) {
			return valueToFormat;
		}

		const numArr = valueToFormat.split('.');

		return `${numArr[0]}.${numArr[1].substring(0, 2)}`;
	};

	const handleChange = (e) => {
		const integerRegex = /^\d*\.?\d*$/;

		if (!integerRegex.test(e.target.value)) {
			e.preventDefault();
		} else if (!props.maxLength) {
			setValue(formatValue(e.target.value));
			setHasChanges(true);
		} else if (props.maxLength && e.target.value.length <= props.maxLength) {
			setValue(formatValue(e.target.value));
			setHasChanges(true);
		}
	};

	const isSaveAvailable = () => {
		return hasChanges && props.validValueRegex.test(value);
	};

	const save = () => {
		const _value = value === '' ? 0 : value || props.defaultValue;
		props.onSave(_value, props.label);
		setEdit(false);
		setValue(null);
	};

	const cancel = () => {
		setEdit(false);
		setValue(props.defaultValue);
	};

	const handleBlur = () => {
		if (props.saveOnBlur) {
			save();
		}
	};

	useEffect(() => {
		setValue(props.defaultValue);
	}, [props.defaultValue]);

	return (
		<div className="input-group">
			<div className="input-label">{props.label}</div>
			{edit ? (
				<IsAuthorized exactPermission={props.permission} alternativeRender={<span>{props.defaultValue || '-'}</span>}>
					<div id="custom-input" className="input-mode" onBlur={handleBlur}>
						<input
							className="input"
							autoFocus
							type="text"
							value={realValue}
							onChange={handleChange}
							onBlur={handleBlur}
							onKeyPress={(e) => (e.key === 'Enter' ? save() : '')}
						/>
						{isSaveAvailable() && <img className="save-icon" src={CheckIcon} alt="Save" onClick={save} aria-hidden />}
						<img className="cancel-icon" src={CloseIcon} alt="Cancel" onMouseDown={cancel} aria-hidden />
					</div>
				</IsAuthorized>
			) : (
				<div className={classNames('text-mode', { [props.extraClassName]: props.extraClassName })}>
					<div>
						{props.prependValue && <span>{props.prependValue}</span>}
						<span>{props.defaultValue || '-'}</span>
					</div>
					<IsAuthorized exactPermission={props.permission}>
						{!props.readOnly && <img src={editIcon} alt="Edit" onClick={enableEdit} aria-hidden />}
					</IsAuthorized>
				</div>
			)}
		</div>
	);
}

export default CustomInput;
