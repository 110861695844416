/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import React, { memo, useState, useEffect } from 'react';
import './Checkbox.css';

const Checkbox = (props) => {
	const [checked, setChecked] = useState(false);

	const generateCheckboxId = () => {
		return `checkbox-${Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1)}`;
	};

	const checkboxId = props.id || generateCheckboxId();

	const handleOnChange = (event) => {
		setChecked(event.target.checked);
		props.handleChange(event.target.checked);
	};

	useEffect(() => {
		setChecked(props.checked || false);
	}, [props.checked]);

	return (
		<label
			htmlFor={checkboxId}
			className={classNames(`custom-checkbox`, props.className, { 'custom-checkbox--disabled': props.disabled })}
		>
			<span className="custom-checkbox-container">
				<input
					type="checkbox"
					id={checkboxId}
					onChange={handleOnChange}
					value={checked}
					checked={checked}
					disabled={props.disabled}
				/>

				<span className={classNames({ checked }, 'custom-checkbox-control')}>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" focusable="false">
						<path fill="none" stroke="currentColor" strokeWidth="3" d="M1.73 12.91l6.37 6.37L22.79 4.59" />
					</svg>
				</span>
			</span>
		</label>
	);
};

export default memo(Checkbox);
