import React, { useState } from 'react';
import CaregiverDefaultPicture from '../../../assets/images/caregiver-default-picture.png';

import './DnrDataTable.scss';

import config from '../../../config';

const CgNameDataTable = ({ dnrInfo: { caregiver_name: cgName, caregiver_id: hcpId } }) => {
	const [caregiverPictureError, setCaregiverPictureError] = useState(false);

	return (
		<div className="cg-name-container">
			{caregiverPictureError ? (
				<img
					src={`${config.API_URL}/caregivers/photo/${hcpId}`}
					alt={cgName}
					onError={() => setCaregiverPictureError(true)}
				/>
			) : (
				<img src={CaregiverDefaultPicture} alt={cgName} />
			)}
			<p>{cgName}</p>
		</div>
	);
};

const DeleteButton = ({ onClick }) => {
	return (
		<button onClick={onClick} type="button" className="delete-dnr">
			REMOVE DNR
		</button>
	);
};

export { CgNameDataTable, DeleteButton };
