import React from 'react';
import CaregiverCard from './CaregiverCard';

import { CheckIcon, WarningIcon } from '../icons';
import BaseBadge from '../badges/BaseBadge';

import './CaregiverCard.scss';

/**
 * CaregiverCardInternalStaff component.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.caregiver - The caregiver object.
 * @returns {JSX.Element} - JSX element representing the CaregiverCardInternalStaff.
 */
const CaregiverCardInternalStaff = ({ caregiver }) => {
	return (
		<CaregiverCard
			caregiver={caregiver}
			hideStats
			nameBadge={
				caregiver.is_enabled ? (
					<BaseBadge badgeCopy="Enabled" className="confirmed-badge" icon={<CheckIcon />} />
				) : (
					<BaseBadge badgeCopy="Disabled" className="cancelled-badge" icon={<WarningIcon />} />
				)
			}
		/>
	);
};

export default CaregiverCardInternalStaff;
