import React from 'react';
import Icon from './Icon';

/**
 * SignOutIcon component displays an icon representing a logout action.
 *
 * @component
 * @param {Object} props - The component properties.
 * @param {number} [props.width=24] - Width of the icon.
 * @param {number} [props.height=25] - Height of the icon.
 * @param {string} [props.color='#000000'] - Color of the icon.
 * @param {string} [props.className] - Additional CSS classes for styling.
 * @returns {JSX.Element} SignOutIcon component.
 */
const SignOutIcon = ({ width = 24, height = 24, color = '#121315', className }) => {
	return (
		<Icon
			width={width}
			height={height}
			viewBox="0 0 24 24"
			color={color}
			className={className}
			path="M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.89 21 3 20.1 3 19V15H5V19H19V5H5V9H3V5C3 3.9 3.89 3 5 3ZM11.5 17L10.09 15.59L12.67 13H3V11H12.67L10.09 8.41L11.5 7L16.5 12L11.5 17Z"
		/>
	);
};

export default SignOutIcon;
