import React from 'react';
import Icon from './Icon';

const PastShiftsIcon = ({ width = 24, height = 24, color = '#FFFFFF', className }) => {
	return (
		<Icon
			width={width}
			height={height}
			color={color}
			className={className}
			path="M4.5 12.8822C4.5 7.9122 8.53 3.8822 13.5 3.8822C18.47 3.8822 22.5 7.9122 22.5 12.8822C22.5 17.8522 18.47 21.8822 13.5 21.8822C11.01 21.8822 8.77 20.8722 7.14 19.2422L8.56 17.8222C9.82 19.0922 11.57 19.8822 13.5 19.8822C17.37 19.8822 20.5 16.7522 20.5 12.8822C20.5 9.0122 17.37 5.8822 13.5 5.8822C9.63 5.8822 6.5 9.0122 6.5 12.8822H9.5L5.5 16.8722L1.5 12.8822H4.5ZM12.5 13.8822V8.8822H14V13.0322L17.52 15.1222L16.75 16.4022L12.5 13.8822Z"
		/>
	);
};

export default PastShiftsIcon;
