import React, { memo, useState, useEffect } from 'react';
import './Radio.scss';

const Radio = (props) => {
	const [selectedOption, setSelectedOption] = useState(null);

	const handleOnChange = (event) => {
		setSelectedOption(event.target.value);
		props.handleChange(event.target.value);
	};

	useEffect(() => {
		setSelectedOption(props.selectedOption || null);
	}, [props.selectedOption]);

	return (
		<div className="custom-radio">
			{props.options.map((option) => (
				<div className="custom-radio-option" key={option.id}>
					<input
						type="radio"
						id={option.id}
						name={props.name}
						value={option.value}
						checked={selectedOption === option.value}
						onChange={handleOnChange}
					/>
					<label htmlFor={option.id}>{option.label}</label>
				</div>
			))}
		</div>
	);
};

export default memo(Radio);
