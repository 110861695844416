import React, { forwardRef } from 'react';

import classNames from 'classnames';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Utils from '../../../utils/utils';
import PERMISSION from '../../../constants/Permissions';
import UnitDetails from '../UnitAssignment/UnitDetails';
import SlotArrivalStatus from './SlotArrivalStatus';
import ActionsDropdown from './ActionsDropdown';
import ShiftSlotDisplay from './ShiftSlotDisplay';
import ContractBadge from '../ContractBadge';

import editPencil from '../../../assets/images/edit-pencil.svg';
import CompassIcon from '../../../components/icons/CompassIcon';
import guaranteedIcon from '../../../assets/images/guaranteed-icon.svg';
import BaseBadge from '../../../components/badges/BaseBadge';

const ShiftSlotDesktop = forwardRef(
	(
		{
			slot,
			shift,
			units,
			user,
			isFixedMarkup,
			getRowColor,
			updateSlotArrivalStatus,
			onConfirm,
			onPrepareCancel,
			handleEditBonusModalButton,
			handleAddBonusModalButton,
			preventGuaranteeOrBonusUpdates,
			onGuaranteedCheckboxChange,
			setShowUnitAssignmentModal,
			setUnitAssignmentSlot,
			getUnitName
		},
		ref
	) => {
		return (
			<div
				ref={ref}
				className={classNames(
					`shift-card row with-border with-padding slot ${
						slot.is_cancelled ? 'canceled-row' : getRowColor(slot.resource_type)
					}`
				)}
			>
				<div className="col-sm-2 row no-gutters d-flex d-sm-none">
					<div className="shift-label col-3">Date</div>
					<div className="col shift-day">
						<span className="week-day">{slot.shift_day}</span>
						<span className="month-date">{shift.shift_date}</span>
					</div>
				</div>
				<div className="col-sm-2 row no-gutters d-flex align-items-center d-sm-none">
					<div className="shift-label col-3">Shift</div>
					<div className="col d-flex d-sm-block">
						<div className="shift-block">
							<div className="shift-interval">
								<span>{Utils.formatToSimpleTime(shift.raw_start_time)} -</span>{' '}
								<span className="text-nowrap">{Utils.formatToSimpleTime(shift.raw_end_time)}</span>
							</div>
						</div>
					</div>
				</div>

				<div className="col-sm-2 col-xl-2 row no-gutters d-none d-sm-flex table-separator h-sm-100 align-items-sm-center">
					<div className="col shift-day">
						<div className="month-date-big">
							<b>{moment.utc(slot.raw_start_time).format('MMM Do')}</b>
						</div>
						<div className="week-day-big">{slot.shift_day}</div>
					</div>
					<div className="md-col shift-day">
						<div className="shift-time text-right">
							from <span>{moment.utc(shift.raw_start_time).format('hh:mm A')}</span>
						</div>
						<div className="shift-time text-right">
							to <span>{moment.utc(shift.raw_end_time).format('hh:mm A')}</span>
						</div>
					</div>
				</div>
				<div className="col-sm-2 col-md-2 row no-gutters justify-content-sm-center align-items-center table-separator h-sm-100 padded">
					<div className="shift-block text-sm-center">
						<div className="d-flex justify-content-sm-center ">
							{slot.is_guaranteed && (
								<OverlayTrigger
									placement="right"
									trigger={['hover', 'focus']}
									show
									overlay={
										<Tooltip
											delay={{ show: 150, hide: 400 }}
											className="guaranteed-tooltip"
											style={{ fontSize: '1.4rem' }}
										>
											Guaranteed
										</Tooltip>
									}
								>
									<span className="guaranteed-slot">
										<img className="guaranteed-icon" src={guaranteedIcon} alt="Guaranteed shift" />
									</span>
								</OverlayTrigger>
							)}
							<div className="type font-weight-bold">{slot.resource_type}</div>
						</div>
						<div className="d-flex justify-content-sm-center align-items-md-center flex-column">
							<UnitDetails unitId={slot.unit_id} />
							{slot.contract_id && <ContractBadge shiftsCount={slot.contract_non_cancelled_slots_count} />}
							{shift.is_orientation && (
								<OverlayTrigger
									placement="right"
									trigger={['hover', 'focus']}
									show
									overlay={
										<Tooltip delay={{ show: 150, hide: 400 }} className="orientation-tooltip">
											Orientation
										</Tooltip>
									}
								>
									<BaseBadge badgeCopy="orientation" className="orientation-badge" icon={<CompassIcon />} />
								</OverlayTrigger>
							)}
						</div>
					</div>
				</div>

				<div className="col-sm-4 col-xl-3 row no-gutters table-separator h-sm-100 align-items-sm-center">
					<div className="shift-label col-3 d-flex d-sm-none">Caregiver</div>
					<div className="caregiver col justify-content-center">
						<div className="flex-one d-flex justify-content-sm-center flex-column w-100 ">
							<ShiftSlotDisplay slot={slot} isMobile={false} />
						</div>
					</div>
				</div>

				{!isFixedMarkup && (
					<div className="col-sm-1 col-md-1 row no-gutters justify-content-sm-center align-items-center table-separator h-sm-100">
						<div className="shift-block d-flex align-items-center justify-content-sm-center">
							<b
								className={classNames('bonus-value', {
									'show-mobile-add': !slot.caregiver_name && !shift.facilityPayableBonus && !slot.facility_payable_bonus
								})}
							>
								{slot.facility_payable_bonus ? `$${slot.facility_payable_bonus}` : ''}
							</b>
							{shift.facilityPayableBonus || slot.facility_payable_bonus ? (
								<img
									className={classNames('edit-pencil-icon', {
										show: !slot.caregiver_name
									})}
									role="presentation"
									src={editPencil}
									alt="Edit"
									onKeyDown={handleEditBonusModalButton}
									onClick={handleEditBonusModalButton}
								/>
							) : (
								<img
									className={classNames('edit-pencil-icon', {
										show: !slot.caregiver_name,
										'add-icon': !(shift.facilityPayableBonus || slot.facility_payable_bonus) && !slot.caregiver_name
									})}
									role="presentation"
									src={editPencil}
									alt="Add"
									onKeyDown={handleAddBonusModalButton}
									onClick={handleAddBonusModalButton}
								/>
							)}
						</div>
					</div>
				)}

				{isFixedMarkup && (
					<div className="col-sm-1 col-md-1 row no-gutters justify-content-sm-center align-items-center table-separator h-sm-100">
						<div className="shift-block d-flex align-items-sm-center">
							{!shift.is_flex && Utils.isAuthorizedExact(PERMISSION.EDIT_WAGE, user.permissions) && (
								<b>
									$
									{shift.isRecurring
										? shift.fixedMarkupWage || shift.originalFixedMarkupWage
										: Number(slot.fixed_markup_wage).toFixed(2)}
									/hr
								</b>
							)}
						</div>
					</div>
				)}

				<div className="col-sm-2 row no-gutters table-separator h-sm-100 align-items-sm-center">
					<div className="col d-flex flex-column align-items-md-center slot-arrival-section">
						{!shift.is_flex && (
							<SlotArrivalStatus
								slot={slot}
								key={`slot-arrival-${shift.id}-${shift.start_time}${shift.end_time}slot-${slot.shift_id}-${slot.slot_id}`}
								updateSlotArrivalStatus={updateSlotArrivalStatus}
							/>
						)}
					</div>
				</div>

				<div className="col-sm-2 col-md-2 row no-gutters justify-content-sm-center align-items-center">
					{slot && (
						<ActionsDropdown
							isFlex={shift.is_flex}
							canGuarantee={!shift.is_orientation && !preventGuaranteeOrBonusUpdates}
							getUnitName={getUnitName}
							hasAvailableUnits={units.length > 0}
							hasUnitsEnabled={user.hasUnitsEnabled}
							onCancel={onPrepareCancel}
							onConfirm={onConfirm}
							onGuaranteedCheckboxChange={onGuaranteedCheckboxChange}
							permissions={user.permissions}
							setShowUnitAssignmentModal={setShowUnitAssignmentModal}
							setUnitAssignmentSlot={setUnitAssignmentSlot}
							slot={slot}
						/>
					)}
				</div>
			</div>
		);
	}
);

export default ShiftSlotDesktop;
