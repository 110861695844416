import React, { memo } from 'react';
import classNames from 'classnames';

const FooterSection = ({ isDisabled = false, onApply = () => {}, onCancel = () => {} }) => {
	const handleApply = () => {
		if (isDisabled) return;
		onApply();
	};

	return (
		<>
			<button className="cancel-button" type="button" onClick={onCancel}>
				Cancel
			</button>
			<button
				className={classNames('apply-button', { disabled: isDisabled })}
				disabled={isDisabled}
				type="button"
				onClick={handleApply}
			>
				Apply
			</button>
		</>
	);
};

export default memo(FooterSection);
