import React from 'react';

const DNRModalBodyText = ({ portalUserName, hcpName, upcomingShifts }) => {
	return (
		<div className="dnr-modal">
			<p>{portalUserName}, </p>
			<div className="dnr-warning-body">
				<UpcomingShiftsMessage hcpName={hcpName} upcomingShifts={upcomingShifts} />
			</div>
		</div>
	);
};

const UpcomingShiftsMessage = ({ hcpName, upcomingShifts }) => {
	if (upcomingShifts > 0) {
		return (
			<div>
				<b>{hcpName}</b> has <b>{upcomingShifts}</b> upcoming shift{upcomingShifts > 1 ? 's' : ''} at your facility. If
				you DNR them, you are risking the ability to staff on these and future days. Are you sure you want to submit the
				Do Not Return?
			</div>
		);
	}
	return (
		<div>
			Are you sure you want to submit the Do Not Return for <b>{hcpName}</b>? By DNRing for this individual, you are
			limiting the amount of available Healthcare Professionals who can work at your facility. This results in decreased
			ability to staff future shifts.
		</div>
	);
};

export default DNRModalBodyText;
