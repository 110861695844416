import React, { useState, useMemo } from 'react';
import moment from 'moment-timezone';

import { useProfessionalsQuery } from '../../redux/professionals';
import useFilters from '../../hooks/useFilters';
import { applyFilters } from '../../utils/FilterFunctions';
import CaregiverCardOnDemand from '../../components/cards/CaregiverCardOnDemand';
import CaregiverSearch from '../../components/CaregiverSearch/CaregiverSearch';
import ProfessionalsList from './ProfessionalsList';

const Counter = ({ count, professionalType }) => (
	<span className="professionals-screen__counter">{`${count} ${professionalType}${count !== 1 ? 's' : ''}`}</span>
);

const mapCaregiversData = (data) => {
	return data?.map((caregiver) => {
		const { name, id, resource_type: resourceType, specialties } = caregiver;
		// Assuming resourceType and specialties are strings or arrays of strings
		const resourceAndSpecialtiesString = `${resourceType?.code || ''} ${
			specialties?.map((specialty) => specialty.label).join(', ') || ''
		}`;
		return {
			...caregiver,
			caregiverName: name,
			certificationType: resourceAndSpecialtiesString,
			caregiverId: id
		};
	});
};

const OnDemand = () => {
	const { filters, setFilters } = useFilters();
	const { data, isLoading } = useProfessionalsQuery(undefined, {
		refetchOnMountOrArgChange: true
	});

	const caregivers = useMemo(() => mapCaregiversData(data || []), [data]);

	const filteredCaregivers = useMemo(() => {
		return applyFilters({ items: caregivers, filters }).sort((a, b) =>
			moment.utc(b.last_shift_worked_date).diff(moment.utc(a.last_shift_worked_date))
		);
	}, [caregivers, filters]);

	const [selectedCaregiver, setSelectedCaregiver] = useState();
	const [isCaregiverSelectOpen, setIsCaregiverSelectOpen] = useState(false);

	const onCaregiverSelect = (options) => setFilters({ ...filters, caregiver: options || [] });

	const removeSelectedCaregiver = () => {
		onCaregiverSelect(null);
		setSelectedCaregiver(null);
		setIsCaregiverSelectOpen(false);
	};

	const caregiversCount = filteredCaregivers.length;

	return (
		<div className="professionals-screen">
			<div className="professionals-screen__filters-section">
				<CaregiverSearch
					caregivers={caregivers}
					className="filter-item push-right"
					onCaregiverSelect={onCaregiverSelect}
					selectedCaregiver={selectedCaregiver}
					setSelectedCaregiver={setSelectedCaregiver}
					isOpen={isCaregiverSelectOpen}
					setIsOpen={setIsCaregiverSelectOpen}
					removeSelectedCaregiver={removeSelectedCaregiver}
					emptyListPlaceholder="No Professional Found"
					placeholder="Search for Professional"
				/>
			</div>
			<ProfessionalsList
				showLoadingSpinner={isLoading}
				filteredCaregivers={filteredCaregivers}
				Counter={<Counter count={caregiversCount} professionalType="Professional" />}
				CaregiverCard={CaregiverCardOnDemand}
			/>
		</div>
	);
};

export default OnDemand;
