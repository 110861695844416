import { memo } from 'react';
import { connect } from 'react-redux';

import utils from '../../utils/utils';

const mapStateToProps = (state) => {
	return {
		permissions: state.user.permissions
	};
};

const IsAuthorized = (props) => {
	let isAuthorized;

	if (props.exactPermission) {
		if (Array.isArray(props.exactPermission)) {
			isAuthorized = props.exactPermission.some(permission => 
				utils.isAuthorizedExact(permission, props.permissions)
			);
		} else {
			isAuthorized = utils.isAuthorizedExact(props.exactPermission, props.permissions);
		}
	} else if (props.section && props.operation) {
		isAuthorized = utils.isAuthorized(props.section, props.operation, props.permissions);
	}

	if (isAuthorized) {
		return props.children;
	}
	if (props.readOnlyRender) {
		return typeof props.readOnlyRender === 'function' ? props.readOnlyRender() : props.readOnlyRender;
	}
	return null;
};

export default connect(mapStateToProps, null)(memo(IsAuthorized));
