import React from 'react';
import { TIER } from '../../constants/Tiers';
import platinumBadge from '../../assets/images/caregiver-platinum-badge.svg';
import noAvatar from '../../assets/images/no-avatar.png';
import removeIcon from '../../assets/images/remove-icon-borderless.svg';
import config from '../../config';
import './CaregiverSearch.scss';

/**
 * Component for rendering a selected caregiver with remove option.
 *
 * @component
 * @param {object} props - The component props.
 * @param {object} props.caregiver - The selected caregiver's details.
 * @param {Function} props.onClear - Function to clear te selected caregiver.
 * @returns {JSX.Element} The rendered component.
 */
const SelectedCaregiver = ({ caregiver, onClear = () => {} }) => (
	<div key={`selected-caregiver-${caregiver.caregiver_id}`} className="selected-caregiver-container">
		<li>
			<div className="selected-caregiver-details">
				<img
					className="caregiver-search__avatar logo-mobile"
					src={`${config.API_URL}/caregivers/photo/${caregiver.caregiverId}`}
					alt="caregiver-profile"
					// eslint-disable-next-line no-param-reassign, no-return-assign
					onError={(evt) => (evt.target.src = noAvatar)}
				/>
				<span>
					{caregiver.caregiverName}, {caregiver.certificationType}
				</span>
			</div>
			<div className="remove-button">
				{caregiver.caregiverStats?.tier === TIER.PLATINUM ? (
					<img
						key="tier-badge"
						className="platinum-badge-caregiver logo-mobile"
						alt={caregiver.caregiverName}
						src={platinumBadge}
					/>
				) : null}
				<button type="button" onClick={() => onClear()}>
					<img alt="remove" src={removeIcon} />
				</button>
			</div>
		</li>
	</div>
);

export default SelectedCaregiver;
