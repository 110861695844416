import axios from 'axios';
import { createRef, useEffect } from 'react';
import xhr from '../utils/xhr';

const debounceDelay = 300;

const useSearchDebounce = (
	url,
	searchTerm,
	searchParams = {},
	setSearchTerm = () => {},
	setLoading = () => {},
	setError = () => {},
	handleResults = () => {},
	searchMinChars = 3
) => {
	let currentRequest = null;
	let latestRequestCancelToken = null;

	const lastSearchTimeout = createRef();
	const searchWrapperRef = createRef();
	const searchInputRef = createRef();

	const generateUrl = (_searchTerm) => {
		const urlSearchParams = new URLSearchParams({ name: _searchTerm, ...searchParams });
		return `${url}?${urlSearchParams.toString()}`.replace(/\+/g, '%20');
	};

	const clearSearchResults = (e) => {
		const searchWrapper = searchWrapperRef.current;

		if (e && !e.keyCode && searchWrapper && !searchWrapper.contains(e.target)) {
			const searchInput = searchInputRef.current;
			if (searchInput.value.length > 0) {
				setSearchTerm(searchTerm);
			}
		}
	};

	const searchTermChange = (e) => {
		const _searchTerm = e.target.value;

		setSearchTerm(_searchTerm);
		clearTimeout(lastSearchTimeout.current);

		if (_searchTerm && _searchTerm.length >= searchMinChars) {
			setLoading(true);
			lastSearchTimeout.current = setTimeout(async () => {
				if (latestRequestCancelToken) {
					latestRequestCancelToken.cancel();
					latestRequestCancelToken = null;
				}

				try {
					latestRequestCancelToken = axios.CancelToken.source();
					currentRequest = xhr.request('GET', generateUrl(_searchTerm), {
						cancelToken: latestRequestCancelToken.token
					});

					const response = await currentRequest;
					const _results = response.data;
					handleResults(_results);
					setError(false);
				} catch (error) {
					setLoading(false);
					setError({ error });
				}
			}, debounceDelay);
			setLoading(false);
		} else {
			setLoading(false);
			clearSearchResults();
		}
	};

	useEffect(() => {
		return () => {
			clearTimeout(lastSearchTimeout.current);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		searchTermChange
	};
};

export default useSearchDebounce;
