import React from 'react';
import classNames from 'classnames';
import utils from '../../utils/utils';

import Tooltip from '../Tooltip/Tooltip';

import './Input.scss';
import { InfoIcon } from '../icons';

const Input = ({
	label,
	customClass,
	invalid,
	errorMessage,
	name,
	type = 'text',
	value,
	maxLength = 255,
	readOnly,
	disabled,
	placeholder,
	checked,
	integer,
	float,
	phoneNumber,
	handleChange,
	tooltipPlacement = 'top',
	tooltipText
}) => {
	const showTooltip = !!tooltipText && !readOnly;
	const _handleChange = (e) => {
		let { value: inputValue } = e.target;

		if (inputValue) {
			if (integer) {
				if (!/^\d*$/.test(inputValue)) {
					e.preventDefault();
					return;
				}
			} else if (float) {
				if (Number.isNaN(Number(inputValue))) {
					e.preventDefault();
					return;
				}
			} else if (phoneNumber) {
				inputValue = inputValue.replace(/[^0-9-]/g, '');
				if (inputValue.length > 12) {
					e.preventDefault();
					return;
				}
				inputValue = utils.formatPhoneNumber(inputValue);
			}
		}

		handleChange({ [name]: inputValue });
	};

	const ReadOnlyInput = () => {
		return <div className="input__readonly">{value}</div>;
	};

	return (
		<div className={classNames('input-group', customClass)}>
			{label && (
				<div className="input-label">
					{label}{' '}
					{showTooltip && (
						<Tooltip placement={tooltipPlacement} className="input-label__tooltip" label={tooltipText}>
							<InfoIcon />
						</Tooltip>
					)}
				</div>
			)}

			{readOnly ? (
				<ReadOnlyInput />
			) : (
				<>
					<input
						className={classNames('input', { 'invalid-input': invalid })}
						type={type}
						value={value}
						title={value}
						maxLength={maxLength}
						disabled={disabled}
						onChange={_handleChange}
						placeholder={placeholder}
						checked={checked}
					/>
					{invalid && errorMessage && <i className="error-message">{errorMessage}</i>}
				</>
			)}
		</div>
	);
};

export default Input;
