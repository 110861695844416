import React, { memo } from 'react';
import BaseModal from '../../../components/modals/BaseModal/BaseModal';
import './GuaranteeSlotsModal.scss';

const GuaranteeSlotsModal = ({
	slots = [],
	onSaveChanges = () => {},
	onCancel = () => {},
	resourceType,
	dateAndTime
}) => {
	const isGuaranteed = slots.every((slot) => slot.is_guaranteed);
	const isMultipleSlots = slots.length > 1;
	const shiftText = isMultipleSlots ? 'shifts' : 'shift';
	const guaranteeText = isGuaranteed ? 'remove the guarantee from ' : 'guarantee ';
	const unclaimedText = isGuaranteed ? 'all of the unclaimed ' : 'all open ';

	const title = `Confirmation to ${guaranteeText} ${isMultipleSlots ? 'all open shifts' : 'this shift'}`;

	const guaranteeDetails = !isGuaranteed ? (
		<div className="guarantee-section">
			<p className="guarantee-bold">
				Guaranteed Shifts&trade; are picked up with 20% higher claim rate & have higher show rates.
			</p>
			<p className="guarantee-italic">
				If this shift is claimed, you are committing to pay for the caregiver, even if you choose to cancel them later.
			</p>
		</div>
	) : (
		<div className="guarantee-section">
			<p className="guarantee-bold">
				Guaranteed Shifts&trade; are picked up more often than non-Guaranteed Shifts. Your fulfillment rate may decrease
				significantly.
			</p>
			<p className="guarantee-italic">
				This change will apply only to unclaimed shifts that were previously Guaranteed. If your Guaranteed Shift&trade;
				was already been claimed, you are still required to pay.
			</p>
		</div>
	);

	return (
		<BaseModal
			key="guarantee-slots-modal"
			title={title}
			className="guarantee-slots-modal"
			primaryActionLabel="Confirm"
			onPrimaryAction={() => onSaveChanges(slots, !isGuaranteed)}
			secondaryActionLabel="Cancel"
			onSecondaryAction={onCancel}
			onHide={onCancel}
		>
			<p>
				Are you sure you would like to {guaranteeText}
				<b>
					{!isMultipleSlots ? 'the ' : unclaimedText}
					{resourceType} {dateAndTime}
				</b>{' '}
				{shiftText}?
			</p>
			{guaranteeDetails}
		</BaseModal>
	);
};

export default memo(GuaranteeSlotsModal);
