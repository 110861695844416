import React from 'react';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';

import './BaseModal.scss';

const ConfirmModal = ({
	className,
	title,
	titleIcon,
	primaryActionLabel,
	primaryActionDisabled,
	onPrimaryAction,
	secondaryActionLabel,
	secondaryActionDisabled,
	onSecondaryAction,
	onHide,
	children
}) => {
	return (
		<Modal
			size="md"
			show
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className={classNames('base-modal confirm-modal', className)}
			onHide={onHide}
			backdropClassName="modal-backdrop-no-show"
		>
			<Modal.Header closeButton>
				<div className="header-content">
					{titleIcon}
					<h1>{title}</h1>
				</div>
			</Modal.Header>
			<Modal.Body>{children}</Modal.Body>

			<Modal.Footer>
				<button
					type="button"
					className="footer-buttons secondary-button"
					disabled={secondaryActionDisabled}
					onClick={onSecondaryAction}
				>
					{secondaryActionLabel}
				</button>
				<button
					type="button"
					className={classNames('footer-buttons primary-button', className)}
					disabled={primaryActionDisabled}
					onClick={onPrimaryAction}
				>
					{primaryActionLabel}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default ConfirmModal;
