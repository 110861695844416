/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo } from 'react';

const CheckBoxSection = ({
	selectAllOption = false,
	onSelectAll = () => {},
	checkboxOptions = [],
	onCheckboxChange = () => {},
	search = ''
}) => (
	<>
		{checkboxOptions.length > 1 && (
			<div className="checkbox-item" key="select-all">
				<input
					type="checkbox"
					id="select-all"
					value={selectAllOption}
					checked={selectAllOption}
					onChange={onSelectAll}
				/>
				<label className="checkbox-item-label" htmlFor="select-all">
					Select All {!!search.length && 'Results Below'}
				</label>
			</div>
		)}

		{!checkboxOptions.length && <>No results found, try again.</>}

		{!!checkboxOptions.length &&
			checkboxOptions.map((option) => (
				<div className="checkbox-item" key={option.id}>
					<input
						type="checkbox"
						id={option.label}
						value={option.checked}
						checked={option.checked}
						onChange={() => onCheckboxChange(option.id)}
					/>
					<label className="checkbox-item-label" htmlFor={option.label}>
						{option.label}
					</label>
				</div>
			))}
	</>
);

export default memo(CheckBoxSection);
