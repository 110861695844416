import useEventListener from './useEventListener';

function useOnClickOutside(ref, handler = () => {}, eventName) {
	useEventListener(eventName, (event) => {
		const elements = Array.isArray(ref) ? ref.map((item) => item?.current) : [ref?.current];
		// Do nothing if clicking ref's element or descendent elements
		if (!elements.some((el) => el && el.contains(event.target))) {
			handler(event);
		}
	});
}

export default useOnClickOutside;
