import React from 'react';
import config from '../../config';
import noAvatar from '../../assets/images/no-avatar.png';
import { TIER } from '../../constants/Tiers';
import './InviteCaregiver.css';

const InviteList = ({ listName, inviteList, keyName, isAccepted, resourceType, platinumBadge, pluralize }) => {
	return (
		<section className="selected-professionals-container">
			<div className="selected-professionals-header">
				<h4>
					<span>
						{listName} – {inviteList.length}
					</span>
				</h4>
			</div>
			<div className="selected-professionals-body-list">
				{inviteList.length > 0 ? (
					<ul className="list-selected-caregivers">
						{inviteList.map((caregiver) => {
							const shiftsWorked = `${pluralize(
								`${caregiver.number_of_shift_worked} shift`,
								's',
								caregiver.number_of_shift_worked
							)} worked at this facility`;
							return (
								<div
									key={`${keyName}-${caregiver.caregiver_id}`}
									className={`selected-caregiver-container ${isAccepted ? 'accepted' : ''}`}
								>
									<li>
										<img
											className="avatar logo-mobile"
											src={`${config.API_URL}/caregivers/photo/${caregiver.caregiver_id}`}
											alt="caregiver-profile"
											// eslint-disable-next-line no-param-reassign, no-return-assign
											onError={(evt) => (evt.target.src = noAvatar)}
										/>
										<span>
											<b className="caregiver-name">{caregiver.caregiver_name}</b>,{' '}
											{caregiver.specialty || caregiver.certification_type || resourceType}, <b>{shiftsWorked}</b>
										</span>
										{caregiver.caregiver_stats?.tier === TIER.PLATINUM ? (
											<img
												key="tier-badge"
												className="platinum-badge-caregiver logo-mobile"
												alt="platinum"
												src={platinumBadge}
											/>
										) : null}
									</li>
								</div>
							);
						})}
					</ul>
				) : null}
			</div>
		</section>
	);
};

export default InviteList;
