import React from 'react';
import Icon from './Icon';

const PaperIcon = ({ width = 16, height = 16, color = '#323335', className }) => {
	return (
		<Icon
			width={width}
			height={height}
			color={color}
			className={className}
			viewBox="0 0 14 14"
			path="M3.5 13.3333C3.01389 13.3333 2.60069 13.1632 2.26042 12.8229C1.92014 12.4826 1.75 12.0694 1.75 11.5833V9.83332H3.5V1.66666H12.25V11.5833C12.25 12.0694 12.0799 12.4826 11.7396 12.8229C11.3993 13.1632 10.9861 13.3333 10.5 13.3333H3.5ZM10.5 12.1667C10.6653 12.1667 10.8038 12.1108 10.9156 11.9989C11.0274 11.8871 11.0833 11.7486 11.0833 11.5833V2.83332H4.66667V9.83332H9.91667V11.5833C9.91667 11.7486 9.97257 11.8871 10.0844 11.9989C10.1962 12.1108 10.3347 12.1667 10.5 12.1667ZM5.25 5.74999V4.58332H10.5V5.74999H5.25ZM5.25 7.49999V6.33332H10.5V7.49999H5.25ZM3.5 12.1667H8.75V11H2.91667V11.5833C2.91667 11.7486 2.97257 11.8871 3.08438 11.9989C3.19618 12.1108 3.33472 12.1667 3.5 12.1667ZM3.5 12.1667H2.91667H8.75H3.5Z"
		/>
	);
};

export default PaperIcon;
