/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useCallback, useState } from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';

import config from '../../../config';
import NotificationPopupModal from '../../../components/modals/NotificationPopupModal/NotificationPopupModal';
import { loadShiftStructures, shiftStructures } from '../../../redux/feed';
import { showNotification } from '../../../redux/notification';
import SearchBar from '../../../components/searchbar/SearchBar';
import Select from '../../../components/form/Select';
import xhr from '../../../utils/xhr';
import InfoIcon from '../../../assets/images/icons8-info.svg';
import redFlag from '../../../assets/images/red-flag-icon.svg';
import CaregiverDefaultPicture from '../../../assets/images/caregiver-default-picture.png';
import './DnrPage.scss';
import DNRModalBodyText from './DNRModalBodyText';
import DnrDataTable from './DnrDataTable';

const ERROR_MESSAGES = {
	resourceType: 'Resource Type must be selected first',
	searchHcp: 'Please select an HCP',
	hcpIsDNRed: 'HCP is already DNRed',
	dnrReason: 'Please select a reason'
};
const errorsOrder = Object.keys(ERROR_MESSAGES);

const getUniqueShiftStructures = (feedShiftStructures) => {
	const uniqueShiftStructures = [...new Set(feedShiftStructures.map((item) => item.resource_type))];
	return uniqueShiftStructures.map((st) => ({ value: st, label: st }));
};

const DnrPage = ({ setShowLoadingSpinner, userData }) => {
	const feedShiftStructures = useSelector(shiftStructures);
	const [dnrOptions, setDnrOptions] = useState([]);
	const [dnrData, setDnrData] = useState([]);
	const [dnrOptionSelected, setDnrOptionSelected] = useState(null);
	const [resourceTypeOptionSelected, setResourceTypeOptionSelected] = useState(null);
	const [hcpSelected, setHcpSelected] = useState({});
	const [showSubmitDNRModal, setShowSubmitDNRModal] = useState(false);
	const [showRemoveDNRModal, setShowRemoveDNRModal] = useState(false);
	const [dnrDataToRemove, setDnrDataToRemove] = useState();
	const [caregiverPictureError, setCaregiverPictureError] = useState(false);
	const [shouldRestoreSearchBar, setShouldRestoreSearchBar] = useState(false);
	const [errors, setErrors] = useState({
		searchHcp: null,
		hcpIsDNRed: null,
		resourceType: null,
		dnrReason: null
	});
	const [resourceTypeOptions, setResourceTypeOptions] = useState([]);

	useEffect(() => {
		if (Array.isArray(feedShiftStructures)) {
			setResourceTypeOptions(getUniqueShiftStructures(feedShiftStructures));
		}
	}, [feedShiftStructures]);

	const dispatch = useDispatch();

	const hasErrors = () => {
		if (!dnrOptionSelected || !resourceTypeOptionSelected || !hcpSelected.caregiver_name || hcpSelected.is_dnr) {
			setErrors({
				searchHcp: !hcpSelected.caregiver_name,
				hcpIsDNRed: hcpSelected.is_dnr,
				resourceType: !resourceTypeOptionSelected,
				dnrReason: !dnrOptionSelected
			});
			return true;
		}
		return false;
	};

	const getDNRS = useCallback(async () => {
		setShowLoadingSpinner(true);
		try {
			const { data } = await xhr.request('GET', '/facility/dnrs');
			setDnrData(data);
		} catch (error) {
			dispatch(showNotification('error', `There was an error with the DNR list for this facility. Please try again.`));
		} finally {
			setShowLoadingSpinner(false);
		}
	}, [setShowLoadingSpinner, dispatch]);

	const submitDNR = async () => {
		setShowLoadingSpinner(true);
		setShowSubmitDNRModal(false);
		try {
			await xhr.request('POST', `/facility/dnrs/${hcpSelected.caregiver_id}`, {
				dnrReasonCode: dnrOptionSelected,
				warningConfirmed: true,
				resourceType: resourceTypeOptionSelected
			});
			setHcpSelected({});
			getDNRS();
			dispatch(showNotification('success', `DNR was applied successfully to  ${hcpSelected.caregiver_name}.`));
		} catch (error) {
			dispatch(
				showNotification(
					'error',
					`There was an error filing the DNR for ${hcpSelected.caregiver_name}. Please try again.`
				)
			);
		} finally {
			setHcpSelected({});
			setShouldRestoreSearchBar(true);
			setResourceTypeOptionSelected(null);
			setDnrOptionSelected(null);
			setShowLoadingSpinner(false);
		}
	};

	const applyDNR = () => {
		if (hasErrors()) return;
		setShowSubmitDNRModal(true);
	};

	const getDnrOptions = useCallback(async () => {
		setShowLoadingSpinner(true);
		try {
			const { data } = await xhr.request('GET', `/entity/dnr-reasons`);
			setDnrOptions(data);
		} catch (error) {
			dispatch(showNotification('error', `Failed to get DNR options.`));
		} finally {
			setShowLoadingSpinner(false);
		}
	}, [setShowLoadingSpinner, dispatch]);

	useEffect(() => {
		getDnrOptions();
		getDNRS();
	}, [getDnrOptions, getDNRS]);

	useEffect(() => {
		dispatch(loadShiftStructures());
	}, [dispatch]);

	const renderAutocompleteItem = (item) => (
		<div>
			<span className="search-bar__autocomplete-info">{item.caregiver_name}</span>
			{item.is_dnr && <span className="dnr-hcp">DNR</span>}
		</div>
	);

	const renderSearchBarError = () => {
		let errorMessage = null;
		let display = false;

		if (errors.searchHcp) {
			errorMessage = ERROR_MESSAGES.searchHcp;
			display = true;
		} else if (errors.hcpIsDNRed) {
			errorMessage = ERROR_MESSAGES.hcpIsDNRed;
			display = true;
		}

		return <p className={classNames('warning', { display })}>{errorMessage}</p>;
	};

	const autocompleteListItemClick = (selectedIndex, results) => {
		setErrors({ ...errors, searchHcp: false, hcpIsDNRed: false });
		setCaregiverPictureError(false);
		// eslint-disable-next-line camelcase
		if (results[selectedIndex]?.caregiver_id) {
			setHcpSelected(results[selectedIndex]);
		}
	};

	const isReadyToSubmit = () => {
		return dnrOptionSelected || resourceTypeOptionSelected || hcpSelected.caregiver_name;
	};

	const handleSectionClick = (section) => {
		const values = [resourceTypeOptionSelected, hcpSelected.caregiver_name];
		const sectionIndex = errorsOrder.findIndex((s) => s === section);
		const [prevSectionValue, prevSectionName] = [values[sectionIndex - 1], errorsOrder[sectionIndex - 1]];
		if (!prevSectionValue) {
			setErrors({ ...errors, [prevSectionName]: true });
		}
	};

	const handleDnrOptionSelected = (option) => {
		handleSectionClick('dnrReason');
		setErrors({ ...errors, dnrReason: false });
		setDnrOptionSelected(option.dnrOption);
	};

	const handleResourceTypeOptionSelected = (option) => {
		setErrors({ ...errors, resourceType: false });
		setResourceTypeOptionSelected(option.resourceType);
		setDnrOptionSelected(null);
		setHcpSelected({});
		setShouldRestoreSearchBar(true);
	};

	const handleSearchBarSectionClick = () => {
		setHcpSelected({});
		handleSectionClick('searchHcp');
	};

	const removeDNR = async () => {
		const { rowId, hcpId, hcpName } = dnrDataToRemove;
		setShowLoadingSpinner(true);
		setShowRemoveDNRModal(false);
		try {
			await xhr.request('DELETE', `/facility/dnrs/${hcpId}`);
			const newDnrData = dnrData.filter((dnrRow) => Number(dnrRow.id) !== Number(rowId));
			setDnrData(newDnrData);
			dispatch(showNotification('success', `DNR successfully removed for ${hcpName}.`));
		} catch (error) {
			dispatch(showNotification('error', `There was an error removing the DNR for ${hcpName}. Please try again.`));
		} finally {
			setDnrDataToRemove();
			setShowLoadingSpinner(false);
		}
	};

	const handleDelete = (rowId, hcpId, hcpName) => {
		setDnrDataToRemove({ rowId, hcpId, hcpName });
		setShowRemoveDNRModal(true);
	};

	const handleCloseRemoveDNRModal = () => {
		setDnrDataToRemove();
		setShowRemoveDNRModal(false);
	};

	const formatCaregivers = (results) => {
		return results.map((caregiver) => ({
			id: caregiver.caregiver_id,
			...caregiver
		}));
	};

	const onErrorCaregiverPicture = () => {
		setCaregiverPictureError(true);
	};

	const renderCaregiverPicture = caregiverPictureError ? (
		<div>
			<div className="hcp-picture-not-found">
				<div className="container">
					<img src={CaregiverDefaultPicture} alt="hcp-profile" />
					<p> No Image Available </p>
				</div>
			</div>
			<p className="selected-caregiver-name">
				{hcpSelected.caregiver_name}, {resourceTypeOptionSelected}{' '}
			</p>
		</div>
	) : (
		<div>
			<img
				src={`${config.API_URL}/caregivers/photo/${hcpSelected.caregiver_id}`}
				alt="hcp-profile"
				onError={onErrorCaregiverPicture}
			/>
			<p className="selected-caregiver-name">
				{hcpSelected.caregiver_name}, {resourceTypeOptionSelected}{' '}
			</p>
		</div>
	);

	return (
		<div className="dnr-page">
			<div className="search-section">
				<div className="dnr-warning">
					<img alt="flag" src={redFlag} />
					<p>
						<b>Attention</b>: Filing a <b>Do Not Return</b> limits the available Healthcare Professionals to work at
						your facility.
						<br />
						<span>This decreases your likelihood to fill shifts in the future.</span>
					</p>
				</div>
				<div className="section-title">Search and filter by: </div>
				<div className="copy-info">All fields are required to submit a Do Not Return.</div>
				<div className="selects-section">
					<div className="column">
						<div className="select-title">Resource Type</div>
						<Select
							containerClass="select-dnr"
							handleChange={handleResourceTypeOptionSelected}
							value={resourceTypeOptionSelected}
							options={resourceTypeOptions}
							hasError={errors.resourceType}
							name="resourceType"
						/>
						<p className={classNames('warning', { display: errors.resourceType })}>{ERROR_MESSAGES.resourceType}</p>
						<div className="dnr-reasons">
							<div className="select-title">Reason for DNR</div>
							<Select
								containerClass="select-dnr"
								handleChange={handleDnrOptionSelected}
								value={dnrOptionSelected}
								options={dnrOptions}
								hasError={errors.dnrReason}
								placeholder="Select Reason"
								name="dnrOption"
							/>
						</div>
						<p className={classNames('warning', { display: errors.dnrReason })}>{ERROR_MESSAGES.dnrReason}</p>
					</div>
					<div className="search-bar-div">
						<div className="select-title">
							Healthcare Professional
							<OverlayTrigger
								trigger={['hover', 'click']}
								key="search-hcp-tooltip"
								placement="bottom"
								overlay={
									<Tooltip id="tooltip-hcp-search" show>
										Search by First or Last name. Only HCPs who have claimed or worked a shift are searchable.
									</Tooltip>
								}
							>
								<img alt="info-icon" className="search-hcp-icon" src={InfoIcon} />
							</OverlayTrigger>
						</div>
						<div>
							<SearchBar
								placeholder="Search"
								autocompleteUrl="/facility/dnrs/caregivers"
								queryParamName="caregiverName"
								extraFieldName="resourceType"
								restoreSectionError={() => setErrors({ ...errors, searchHcp: false, hcpIsDNRed: false })}
								extraFieldValue={resourceTypeOptionSelected}
								className="search-bar-dnr"
								errorClass={errors.searchHcp || errors.hcpIsDNRed ? 'error' : ''}
								onSectionClick={handleSearchBarSectionClick}
								renderAutocompleteItem={renderAutocompleteItem}
								onItemSelect={autocompleteListItemClick}
								itemNameColumn="caregiver_name" // Must be provided if the autocomplete results are an array of objects, not strings
								searchMinChars={3}
								formatResponse={formatCaregivers}
								shouldRestoreSearchBar={shouldRestoreSearchBar}
								setShouldRestoreSearchBar={setShouldRestoreSearchBar}
							/>
						</div>
						{renderSearchBarError()}
					</div>
					{hcpSelected && hcpSelected.caregiver_id ? (
						renderCaregiverPicture
					) : (
						<div className="hcp-picture-placeholder" />
					)}
				</div>

				<button
					className={classNames('submit-dnr', { active: isReadyToSubmit() })}
					onClick={() => (isReadyToSubmit() ? applyDNR() : null)}
					type="button"
				>
					SUBMIT DO NOT RETURN
				</button>
			</div>
			<hr />

			{showSubmitDNRModal && (
				<NotificationPopupModal
					title="Are you sure?"
					customChildComponent={
						<DNRModalBodyText
							hcpName={hcpSelected.caregiver_name}
							portalUserName={userData.name}
							upcomingShifts={hcpSelected.upcoming_shifts}
						/>
					}
					confirmLabel="Submit"
					cancelLabel="Cancel"
					confirmReverseColor
					onClose={() => setShowSubmitDNRModal(false)}
					onConfirm={submitDNR}
					key="dnr-notification-popup-modal"
				/>
			)}

			{showRemoveDNRModal && (
				<NotificationPopupModal
					title="Are you sure?"
					bodyText={`<p>Are you sure you want to remove <b>${dnrDataToRemove?.hcpName}</b> from the DNR list?</p>`}
					confirmLabel="Remove"
					cancelLabel="Cancel"
					confirmReverseColor
					onClose={handleCloseRemoveDNRModal}
					onConfirm={() => (dnrDataToRemove ? removeDNR() : null)}
					key="dnr-notification-popup-modal"
				/>
			)}

			<div className="dnr-list-section">
				<div className="section-title">Do Not Return List</div>
				<DnrDataTable dnrData={dnrData} onDelete={handleDelete} />
			</div>
		</div>
	);
};

export default withRouter(DnrPage);
