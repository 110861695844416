/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';
import PullToRefresh from 'rmc-pull-to-refresh';
import moment from 'moment';

import xhr from '../../utils/xhr';
import { showNotification } from '../../redux/notification';
import {
	setPendingNotificationsArr,
	setIsPendingNotificationsFetched,
	isPendingNotificationsFetched,
	setPendingNotificationsFetching,
	pendingNotificationsFetching,
	pendingNotificationsArr,
	removeNotificationById
} from '../../redux/pendingConfirmNotification';
import { feed, shiftStructures, loadFeed } from '../../redux/feed';
import { user, selectedFacility, setCurrentFacility } from '../../redux/user';
import { applyFilters, filterNames } from '../../utils/FilterFunctions';
import LoadingSpinner from '../../components/spinner/LoadingSpinner';
import RequestShiftsModal from '../../components/modals/RequestShiftsModal';
import './Shifts.css';
import NotificationPopupModal from '../../components/modals/NotificationPopupModal/NotificationPopupModal';
import RecurringShiftsModal from '../../components/modals/RecurringShiftsModal/RecurringShiftsModal';
import VendorWarningModal from '../../components/modals/VendorWarningModal';
import UpdatedModal from '../../components/modals/UpdatedModal';
import RecurringShiftsErrorsModal from '../../components/modals/RecurringShiftsModal/RecurringShiftsErrorsModal';
import Utils from '../../utils/utils';
import ConfirmationsPendingModal from '../../components/modals/ConfirmationsPendingModal/ConfirmationsPendingModal';
import Shift from './Shift';
import PageTitle from '../../sections/PageTitle';
import FilterShifts from './FilterShifts/FilterShifts';
import useFilters from '../../hooks/useFilters';
import useShiftSlots from '../../hooks/useShiftSlots';
import { UPCOMING_SHIFTS } from './FilterShifts/AvailableFilters';

const ACCOUNT_RECEIVABLE_STATUS_WITH_DISABLED_BUTTONS = ['SUSPENDED', 'DEACTIVATED'];

const ShiftsScreen = () => {
	const dispatch = useDispatch();
	const userData = useSelector(user) || {};
	const activeFacility = useSelector(selectedFacility) || null;
	const feedShifts = useSelector(feed);
	const [showConfirmationsPendingModal, setShowConfirmationsPendingModal] = useState(0);
	const [showRequestShiftsVendorWarningModal, setShowRequestShiftsVendorWarningModal] = useState(false);
	const [showConfirmationsVendorWarningModel, setShowConfirmationsVendorWarningModal] = useState(false);
	const isPendingNotificationsFetchedVal = useSelector(isPendingNotificationsFetched);
	const pendingNotificationsFetchingVal = useSelector(pendingNotificationsFetching);
	const pendingNotificationsArrVal = useSelector(pendingNotificationsArr);
	const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);
	const [showRequestsShiftModal, setShowRequestsShiftModal] = useState(false);
	const [showFlexRequestsShiftModal, setShowFlexRequestsShiftModal] = useState(false);
	const [showRecurringShiftsModal, setShowRecurringShiftsModal] = useState(false);
	const [showRecurringShiftsConfirmationModal, setShowRecurringShiftsConfirmationModal] = useState(false);
	const [recurringShiftsErrors, setRecurringShiftsErrors] = useState({});
	const [shiftsByDay, setShiftsByDay] = useState([]);
	const [showNotificationPopupModal, setShowNotificationPopupModal] = useState(false);
	const [notificationPopupTitle, setNotificationPopupTitle] = useState('');
	const [notificationPopupBodyText, setNotificationPopupBodyText] = useState('');
	const [notificationPopupConfirmLabel, setNotificationPopupConfirmLabel] = useState('');
	const [notificationPopupOnConfirm, setNotificationPopupOnConfirm] = useState(null);
	const [notificationPopupOnClose, setNotificationPopupOnClose] = useState(null);
	const [notificationPopupLight, setNotificationPopupLight] = useState(false);
	const [notificationPopupBackdrop, setNotificationPopupBackdrop] = useState(true);
	const [notificationPopupWarning, setNotificationPopupWarning] = useState(false);
	const [slotsToConfirm, setSlotsToConfirm] = useState(0);

	const urlParams = new URLSearchParams(window.location.search);
	const urlSlotId = urlParams.get('slotId');
	const urlFacilityId = urlParams.get('facilityId') || false;

	const defaultFilters = filterNames;
	const confirmationsPendingModalOnCloseState = () => {
		dispatch(loadFeed());
		setShowConfirmationsPendingModal(false);
	};
	const [confirmationsPendingModalOnClose, setConfirmationsPendingModalOnClose] = useState(
		() => confirmationsPendingModalOnCloseState
	);

	const { filters, setFilters, filterActive } = useFilters();
	const { getCaregiversNamesByShiftSlots, assignedCaregiversByShiftSlots, feedShiftStructures } = useShiftSlots();

	const touchMoveElementRef = useRef(null);
	const isTopRef = useRef(null);
	const virtuosoRef = useRef(null);

	const countAndSetSlotsToConfirm = (slots) => {
		let toConfirm = 0;
		slots.forEach((slot) => {
			if (slot.can_confirm) {
				toConfirm += 1;
			}
		});
		setSlotsToConfirm(toConfirm);
		setShowLoadingSpinner(false);
	};

	const sortByTimeRange = (days) => {
		days.forEach((day) =>
			day.shifts.sort((a, b) => {
				const startA = moment(a.start_time, 'HH:mm aa');
				const startB = moment(b.start_time, 'HH:mm aa');
				const endA = moment(a.end_time, 'HH:mm aa');
				const endB = moment(b.end_time, 'HH:mm aa');
				if (startA.isAfter(startB)) {
					return 1;
				}
				if (startA.hours() === startB.hours() && startA.minutes() === startB.minutes()) {
					if (endA.isSameOrAfter(endB)) {
						return 1;
					}
					return -1;
				}
				return -1;
			})
		);
		return days;
	};

	const groupShifts = useCallback((shifts) => {
		if (shifts && shifts.length) {
			const days = [];
			let day = { shifts: [] };
			for (let i = 0; i < shifts.length; i++) {
				const {
					shift_id: id,
					shift_date: shiftDate,
					start_time: startTime,
					end_time: endTime,
					shift_guaranteed: shiftGuaranteed,
					shift_structure_id: shiftStructureId,
					raw_start_time: rawStartTime,
					raw_end_time: rawEndTime,
					start_time_at_utc: startTimeAtUtc,
					is_orientation: isOrientation,
					shift_facility_payable_bonus: facilityPayableBonus,
					resource_type: resourceType,
					is_recurring: isRecurring,
					recurring_shift_id: recurringShiftId,
					fixed_markup_wage: fixedMarkupWage,
					original_fixed_markup_wage: originalFixedMarkupWage,
					contract_non_cancelled_slots_count: contractNonCancelledSlotsCount,
					contract_worked_slots_count: contractWorkedSlotsCount,
					contract_id: contractId
				} = shifts[i];

				if (shiftDate !== day.shift_date) {
					day = {
						shift_date: shiftDate,
						shift_day: shifts[i].shift_day,
						raw_calendar_date: moment.utc(shifts[i].raw_start_time, 'YYYY/MM/DD'),
						shifts: [
							{
								is_flex: shifts[i].is_flex,
								id: isRecurring ? recurringShiftId : id,
								shift_date: shiftDate,
								start_time: startTime,
								end_time: endTime,
								raw_start_time: rawStartTime,
								raw_end_time: rawEndTime,
								shift_guaranteed: shiftGuaranteed,
								start_time_at_utc: startTimeAtUtc,
								is_orientation: isOrientation,
								shift_structure_id: shiftStructureId,
								slots: [shifts[i]],
								facilityPayableBonus,
								resourceType,
								isRecurring,
								fixedMarkupWage,
								originalFixedMarkupWage,
								contractNonCancelledSlotsCount,
								contractWorkedSlotsCount,
								contractId
							}
						]
					};
					days.push(day);
				} else {
					// same day
					let pushed = false;
					day.shifts.forEach((each) => {
						if (each.id === id) {
							each.slots.push(shifts[i]);
							pushed = true;
						}
					});
					if (!pushed) {
						day.shifts.push({
							is_flex: shifts[i].is_flex,
							id: isRecurring ? recurringShiftId : id,
							shift_date: shiftDate,
							start_time: startTime,
							end_time: endTime,
							raw_start_time: rawStartTime,
							raw_end_time: rawEndTime,
							shift_guaranteed: shiftGuaranteed,
							start_time_at_utc: startTimeAtUtc,
							is_orientation: isOrientation,
							shift_structure_id: shiftStructureId,
							slots: [shifts[i]],
							facilityPayableBonus,
							resourceType,
							isRecurring,
							fixedMarkupWage,
							originalFixedMarkupWage,
							contractNonCancelledSlotsCount,
							contractWorkedSlotsCount,
							contractId
						});
					}
				}
			}
			days.forEach((currentDay) => {
				currentDay.shifts.forEach((shift) => {
					const currentShift = shift;
					currentShift.has_confirmable_slots = currentShift.slots.some((slot) => slot.can_confirm);
					currentShift.fixedMarkupWage = currentShift.slots.find((s) => {
						return !s.caregiver_id;
					})?.fixed_markup_wage;
				});
			});
			setShiftsByDay(sortByTimeRange(days));
		} else {
			setShiftsByDay([]);
		}
	}, []);

	const updateShiftStatus = (shiftDate, slotId, status) => {
		const _shiftsByDay = [...shiftsByDay];
		for (let i = 0; i < shiftsByDay.length; i++) {
			if (_shiftsByDay[i].shift_date === shiftDate) {
				_shiftsByDay[i] = { ..._shiftsByDay[i] };
				for (let j = 0; j < _shiftsByDay[i].shifts.length; j++) {
					const shift = { ..._shiftsByDay[i].shifts[j] };
					for (let k = 0; k < shift.slots.length; k++) {
						if (shift.slots[k].slot_id === slotId) {
							shift.slots[k] = { ...shift.slots[k], slot_status: status };
							setShiftsByDay(_shiftsByDay);
							return;
						}
					}
				}
			}
		}
	};

	const setPendingNotificationPopup = useCallback(() => {
		if (pendingNotificationsArrVal && pendingNotificationsArrVal.length) {
			const notificationMsgObj = [...pendingNotificationsArrVal].pop();

			setNotificationPopupTitle(notificationMsgObj.title);
			setNotificationPopupBodyText(notificationMsgObj.messageHtml);
			setNotificationPopupConfirmLabel(notificationMsgObj.buttonText);
			setNotificationPopupLight(notificationMsgObj.light);
			setNotificationPopupBackdrop(notificationMsgObj.backdrop);
			setNotificationPopupWarning(notificationMsgObj.warning);
			setShowNotificationPopupModal(true);

			if (notificationMsgObj.id === 'showNotificationPopupModal') {
				setNotificationPopupOnConfirm(() => async () => {
					setShowNotificationPopupModal(false);
					setShowConfirmationsPendingModal(showConfirmationsPendingModal + 1);
				});
				setConfirmationsPendingModalOnClose(() => async () => {
					dispatch(loadFeed());
					setShowConfirmationsPendingModal(false);
					dispatch(removeNotificationById(notificationMsgObj.id));
				});
			} else {
				setNotificationPopupOnConfirm(() => () => {
					setShowNotificationPopupModal(false);
					dispatch(removeNotificationById(notificationMsgObj.id));
				});
			}

			setNotificationPopupOnClose(() => async () => {
				setShowNotificationPopupModal(false);
				dispatch(removeNotificationById(notificationMsgObj.id));
			});
		}
	}, [pendingNotificationsArrVal, dispatch, showConfirmationsPendingModal, activeFacility]);

	const fetchPendingNotifications = useCallback(async () => {
		if (isPendingNotificationsFetchedVal) return;
		try {
			dispatch(setPendingNotificationsFetching(true));
			const pendingNotificationsResponse = await xhr.request('GET', '/facility/pending-notifications');

			if (pendingNotificationsResponse.data.length) {
				dispatch(setPendingNotificationsArr(pendingNotificationsResponse.data));
			}
		} catch (error) {
			const message =
				error.response.data &&
				error.response.data.errors &&
				error.response.data.errors[0] &&
				error.response.data.errors[0].message;
			dispatch(showNotification('error', message || 'Failed to load pending notifications.'));
		} finally {
			dispatch(setPendingNotificationsFetching(false));
			dispatch(setIsPendingNotificationsFetched(true));
		}
	}, [dispatch, isPendingNotificationsFetchedVal, activeFacility]);

	const handleShowRequestOrRecurringModal = () => {
		setShowRequestShiftsVendorWarningModal(false);
		const isMobile = window.matchMedia('only screen and (max-width: 760px)').matches;
		// Even if they have permission, prevent mobile view to access recurring shifts modal
		if (userData.isRecurringScheduleEnabled && !isMobile) {
			setShowRecurringShiftsModal(true);
		} else {
			setShowRequestsShiftModal(true);
		}
	};

	useEffect(() => {
		const filteredShifts = applyFilters({ items: feedShifts, filters });
		getCaregiversNamesByShiftSlots(filteredShifts);
		groupShifts(filteredShifts);
		countAndSetSlotsToConfirm(filteredShifts);
	}, [filters]);

	const isolateTouch = (event) => {
		if (isTopRef.current === false) {
			event.stopPropagation();
		}
	};

	const onScroll = (event) => {
		isTopRef.current = event.target.scrollTop === 0;
	};

	const handleRefresh = () => {
		window.location.reload();
	};

	const handleShowRecurringModalConfirmation = () => {
		setShowLoadingSpinner(true);
		dispatch(loadFeed());
		setShowRecurringShiftsConfirmationModal(true);
	};

	const handleConfirmationModalClick = () => {
		setShowConfirmationsVendorWarningModal(false);
		if (slotsToConfirm > 0 && Utils.isAuthorizedExact('PORTAL.EDIT', userData.permissions)) {
			setShowConfirmationsPendingModal(true);
		}
	};

	const tooltipPlacement = window.innerWidth < 1280 ? 'bottom' : 'right';

	useEffect(() => {
		if (touchMoveElementRef.current) {
			touchMoveElementRef.current.addEventListener('touchmove', isolateTouch, { passive: true });
		}
		return () => touchMoveElementRef.current.removeEventListener('touchmove', isolateTouch);
	}, []);

	useEffect(() => {
		setShowLoadingSpinner(true);
		if (urlFacilityId && urlFacilityId !== activeFacility) {
			dispatch(setCurrentFacility(urlFacilityId));
		} else {
			dispatch(loadFeed());
		}
	}, [window.location.search]);

	useEffect(() => {
		// If both the slot is valid and the facility is enabled, then: filter by slot id; if not, then: clear shiftSlot filter only.
		// this works since validSlot and facilityEnabled are being used only when switching facilities.
		// by only clearing shift slot filter, we will ensure that no other filters get cleared accidentally when updating shift status, etc.
		if (feedShifts) {
			const validSlot = urlSlotId && feedShifts.some((s) => s.slot_id === urlSlotId);
			const facilityEnabled = userData.facilitiesEnabled.some((facility) => facility.id === urlFacilityId);

			if (validSlot && facilityEnabled) {
				setFilters({ ...defaultFilters, shiftSlot: urlSlotId });
			} else {
				setFilters((prevFilters) => ({ ...prevFilters, shiftSlot: [] }));
			}
		}
	}, [feedShifts]);

	useEffect(() => {
		// when the user switches facilities, we want to keep the caregiver filter.
		// this is so that the user can see the same caregiver across facilities.
		setFilters((prevFilters) => ({ ...defaultFilters, caregiver: prevFilters.caregiver }));
	}, [activeFacility]);

	useEffect(() => {
		if (pendingNotificationsFetchingVal) return;
		fetchPendingNotifications();
		setPendingNotificationPopup();
	}, [isPendingNotificationsFetchedVal, pendingNotificationsFetchingVal, pendingNotificationsArrVal, activeFacility]);

	const isVendorEnabled = () => userData.marketplacePartnerIntegrated;

	const delinquentAccountReceivableStatus = useMemo(
		() => ACCOUNT_RECEIVABLE_STATUS_WITH_DISABLED_BUTTONS.includes(userData.activeFacility.account_receivable_status),
		[userData.activeFacility.account_receivable_status]
	);

	return [
		<PullToRefresh
			direction="down"
			key="shifts-page"
			refreshing
			onRefresh={handleRefresh}
			damping={50}
			indicator={{
				activate: '',
				deactivate: '',
				release: '',
				finish: ''
			}}
		>
			<div className="shifts-page-container">
				<PageTitle
					title="Daily Schedule"
					className="daily-schedule"
					delinquentAccountReceivableStatus={delinquentAccountReceivableStatus}
					shiftStructures={shiftStructures}
					slotsToConfirm={slotsToConfirm}
					setShowConfirmationsVendorWarningModal={setShowConfirmationsVendorWarningModal}
					setShowRequestShiftsVendorWarningModal={setShowRequestShiftsVendorWarningModal}
					setShowFlexRequestsShiftModal={setShowFlexRequestsShiftModal}
					showGoBackButton={filters.shiftSlot > 0}
					allowFlexShifts={userData.allowFlexShifts}
					allowOnDemandShifts={userData.allowOnDemandShifts}
				/>
				<div className="shifts-page" key="shifts-page">
					<div className="top-buttons-wrap">
						{!(filters.shiftSlot > 0) && (
							<FilterShifts
								shiftStructures={feedShiftStructures}
								caregivers={assignedCaregiversByShiftSlots}
								filters={filters}
								setFilters={setFilters}
								availableFilters={UPCOMING_SHIFTS}
							/>
						)}
					</div>
					<div className="shifts-grid" ref={touchMoveElementRef}>
						<div className="row shifts-grid-header with-border d-none d-sm-flex">
							<div className="w-100 row border-left">
								<div className="col-sm-2 col-lg-2 col-xl-2 row align-items-center justify-content-center">
									Shift Information
								</div>
								<div className="col-sm-10 col-lg-10 col-xl-10 row text-center">
									<div className="col-sm-2 col-xl-2 first-col justify-content-center">Date & Time</div>
									<div className="col-sm-2 col-md-2">Shift Assignment</div>
									<div className="col-sm-4 col-xl-3">Healthcare Professional</div>
									<div className="col-sm-1 col-md-1">
										{userData.activeFacility.is_fixed_markup_rate ? 'Hourly Wage' : 'Bonus'}
									</div>
									<div className="col-sm-2">Status</div>
									<div className="col-sm-2 col-md-2">Actions</div>
								</div>
							</div>
						</div>
						{shiftsByDay.length === 0 ? (
							<div className="shifts-grid-empty">
								There are currently no shifts to display. Please check your filters or request more shifts.
							</div>
						) : (
							<div className="shifts-grid-body" onScroll={onScroll}>
								<Virtuoso
									ref={virtuosoRef}
									className="virtuoso-container"
									style={{ flex: '1 1' }}
									totalCount={shiftsByDay.length}
									data={shiftsByDay}
									itemContent={(index, day) => [
										<div className="shift-separator" key={`separator-${day.shift_date}`}>
											<div>
												{`${day.shift_day}, `}
												<span className="shift-separator-date">{`${day.shift_date}, ${day.raw_calendar_date.format(
													'YYYY'
												)}`}</span>{' '}
												{day.raw_calendar_date.isSame(moment(), 'date') && (
													<span className="shift-separator-today"> Today</span>
												)}
											</div>
											{index > 0 && <div className="shift-separator-line" key={`shift-${day.shift_date}-separator`} />}
										</div>,

										<div className="row" key={`day-${day.shift_date}`}>
											{day.shifts.map((shift, _index) => [
												<Shift
													key={`shift-${day.shift_date}${shift.start_time}${shift.end_time}${shift.id}${
														shift.is_flex
													}-${shift.isRecurring ? `recurring-${day.shift_date}` : ''}`}
													data={shift}
													isLoading={showLoadingSpinner}
													setIsLoading={setShowLoadingSpinner}
													updateShiftStatus={updateShiftStatus}
													fetchData={() => dispatch(loadFeed())}
													user={userData}
													isVendorEnabled={isVendorEnabled()}
													units={userData.activeFacility.units}
													delinquentAccountReceivableStatus={delinquentAccountReceivableStatus}
													isFilterByCaregiver={filters.caregiver?.[0] && filters.caregiver?.[0] !== ''}
													hideLabel={filterActive}
													isFixedMarkup={userData.activeFacility.is_fixed_markup_rate}
												/>,

												_index !== day.shifts.length - 1 && (
													<div className="shift-day-separator" key={`shift-day-${day.shift_date}-separator`} />
												)
											])}
										</div>
									]}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</PullToRefresh>,

		showRequestsShiftModal && (
			<RequestShiftsModal
				key="request-shifts-modal"
				updateFeed={() => dispatch(loadFeed())}
				shiftStructures={feedShiftStructures}
				shiftsByDay={shiftsByDay}
				setIsLoading={setShowLoadingSpinner}
				setShowRecurringShiftsModal={setShowRecurringShiftsModal}
				user={userData}
				onClose={() => setShowRequestsShiftModal(false)}
			/>
		),

		showFlexRequestsShiftModal && (
			<RequestShiftsModal
				key="request-flex-shifts-modal"
				updateFeed={() => dispatch(loadFeed())}
				shiftStructures={feedShiftStructures}
				shiftsByDay={shiftsByDay}
				setIsLoading={setShowLoadingSpinner}
				setShowRecurringShiftsModal={setShowRecurringShiftsModal}
				user={userData}
				title="Request Internal Flex Shifts"
				isFlex
				onClose={() => setShowFlexRequestsShiftModal(false)}
			/>
		),

		showNotificationPopupModal && (
			<NotificationPopupModal
				title={notificationPopupTitle}
				bodyText={notificationPopupBodyText}
				confirmLabel={notificationPopupConfirmLabel}
				onClose={notificationPopupOnClose}
				onConfirm={notificationPopupOnConfirm}
				light={notificationPopupLight}
				backdrop={notificationPopupBackdrop}
				warning={notificationPopupWarning}
				key="pending-notification-popup-modal"
			/>
		),

		showRecurringShiftsModal && (
			<RecurringShiftsModal
				key="recurring-shifts-modal"
				onConfirm={() => {}}
				setShowRequestsShiftModal={setShowRequestsShiftModal}
				setRecurringShiftsErrors={setRecurringShiftsErrors}
				onClose={() => setShowRecurringShiftsModal(false)}
				confirmModal={handleShowRecurringModalConfirmation}
				shiftStructures={feedShiftStructures}
			/>
		),

		showRecurringShiftsConfirmationModal && (
			<UpdatedModal
				onConfirm={() => setShowRecurringShiftsConfirmationModal(false)}
				title="Recurring Schedule saved successfully"
				shiftStructures={feedShiftStructures}
				tooltipPlacement={tooltipPlacement}
			/>
		),

		Object.keys(recurringShiftsErrors).length > 0 && (
			<RecurringShiftsErrorsModal
				key="recurring-shifts-error-modal"
				onClose={setRecurringShiftsErrors}
				recurringShiftsErrors={recurringShiftsErrors}
				shiftStructures={feedShiftStructures}
				tooltipPlacement={tooltipPlacement}
			/>
		),

		showLoadingSpinner && <LoadingSpinner key="loading-spinner" />,

		showConfirmationsPendingModal && (
			<ConfirmationsPendingModal
				key="request-shifts-modal"
				onClose={confirmationsPendingModalOnClose}
				shiftsByDay={shiftsByDay}
				updateFeed={() => dispatch(loadFeed())}
			/>
		),
		<VendorWarningModal
			key="request-shifts-vendor-warning"
			onConfirm={handleShowRequestOrRecurringModal}
			onCancel={() => setShowRequestShiftsVendorWarningModal(false)}
			isVendorEnabled={isVendorEnabled()}
			showModal={showRequestShiftsVendorWarningModal}
		/>,
		<VendorWarningModal
			key="pending-confirmations-vendor-warning"
			onConfirm={handleConfirmationModalClick}
			onCancel={() => setShowConfirmationsVendorWarningModal(false)}
			isVendorEnabled={isVendorEnabled()}
			showModal={showConfirmationsVendorWarningModel}
		/>
	];
};
export default withRouter(ShiftsScreen);
