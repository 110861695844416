import React, { memo, useEffect } from 'react';
import ConfirmationModal from './ConfirmationModal';

const VendorWarningModal = (props) => {
	const { showModal, isVendorEnabled, onCancel, onConfirm } = props;
	useEffect(() => {
		if (showModal && !isVendorEnabled) {
			// Vendor not enabled, run the confirmation callback immediately and don't show the warning.
			onConfirm();
		}
	}, [showModal, isVendorEnabled, onConfirm]);

	return (
		showModal &&
		isVendorEnabled && (
			<ConfirmationModal
				title="Active Integration"
				cancelLabel="No"
				confirmLabel="Yes"
				onConfirm={onConfirm}
				onCancel={onCancel}
				reverseButtons
			>
				Your facility has an active integration. This change will not be sent to your schedule and will cause your data
				to be out of sync. Continue anyway?
			</ConfirmationModal>
		)
	);
};

export default memo(VendorWarningModal);
