/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import BaseModal from './BaseModal/BaseModal';
import './CancelShiftModal.css';
import Select from '../form/Select';
import Radio from '../form/Radio';
import guaranteed from '../../assets/images/guaranteed-icon.svg';
import warning from '../../assets/images/warning-triangle-yellow.svg';
import { showNotification } from '../../redux/notification';
import xhr from '../../utils/xhr';
import 'react-bootstrap/cjs/divWithClassName';
import Checkbox from '../form/Checkbox';
import { TIER } from '../../constants/Tiers';

const REPLACEMENT_WORKER_OPTIONS = [
	{ label: 'Yes', value: 'yes', id: 'yes' },
	{ label: 'No', value: 'no', id: 'no' }
];

const ACKNOWLEDGMENT_MESSAGES = {
	GUARANTEED_SHIFT_CANCELLATION:
		'I confirm that I am canceling a Guaranteed Shift™. I understand that we will pay in full for this shift if I cancel it.',
	LATE_CANCELLATION: (billableCancelHours) =>
		`I confirm that I am canceling a shift that begins in less than ${billableCancelHours} hours. I agree that we will pay the associated ${billableCancelHours} hour cancelation fee for this shift.`,
	HAS_UBER:
		'I acknowledge I am cancelling this shift knowing that this worker is committed to showing up and has scheduled their Uber ride to my facility.'
};

const CancelShiftModal = (props) => {
	const {
		resourceType,
		caregiverName,
		date,
		day,
		startTime,
		endTime,
		hourlyRate,
		bonus,
		isGuaranteed,
		billableCancellationHours,
		startTimeAtUTC,
		timezone,
		cancelModalErrorMessage,
		checkinUberStatus,
		totalSlotsWorked,
		caregiverTier,
		isReturningWorker,
		isNonClaimedSlot,
		onClose,
		isLoading
	} = props;

	const [cancellationReasons, setCancellationReasons] = useState([]);
	const [selectedReason, setSelectedReason] = useState('');
	const [replacementWorkerNeeded, setReplacementWorkerNeeded] = useState(null);
	const [acknowledgmentAccepted, setAcknowledgmentAccepted] = useState(false);
	const [uberAcknowledgmentAccepted, setUberAcknowledgmentAccepted] = useState(false);
	const [agencyName, setAgencyName] = useState('');
	const [isLateCancelation, setIsLateCancelation] = useState(false);
	const slotsWorked = parseFloat(totalSlotsWorked ?? 0);
	const dispatch = useDispatch();

	useEffect(() => {
		/* To consider the cancel operation a late one, current time should be after the shift start time minus the billable cancellation hours. 
		If shift has already started, consider it too a late cancellation. 
		Also, it is important to set the lateCancellation boolean as a fixed value once the component has been rendered. 
		Props passed to this useEffect are not going to change during life component cycle. */
		if (!isNonClaimedSlot && startTimeAtUTC && timezone && billableCancellationHours) {
			const billableWindowStartTime = moment(startTimeAtUTC).tz(timezone).subtract(billableCancellationHours, 'hours');
			setIsLateCancelation(moment().tz(timezone).isAfter(billableWindowStartTime) && !isGuaranteed);
		}
	}, [startTimeAtUTC, billableCancellationHours, timezone, isGuaranteed, isNonClaimedSlot]);

	const showAcknowledgment = isGuaranteed || isLateCancelation;
	const showUberAcknowledgment = checkinUberStatus === 'REQUESTED';

	const isReadyToSubmit = () => {
		if (!selectedReason) return false;

		if (selectedReason === 'CREDENTIALS_OUT_OF_DATE' && replacementWorkerNeeded === null) return false;

		if (showAcknowledgment && !acknowledgmentAccepted) return false;

		if (cancelModalErrorMessage) return false;

		if (showUberAcknowledgment && !uberAcknowledgmentAccepted) return false;

		return true;
	};

	useEffect(() => {
		const getPortalCancellationReasons = async () => {
			try {
				const { data } = await xhr.request(
					'GET',
					`/entity/slot-cancelation-reasons?filterQuery=${isNonClaimedSlot ? 'NON_CLAIMED_SLOTS' : 'CLAIMED_SLOTS'}`
				);
				setCancellationReasons(data);
			} catch {
				dispatch(showNotification('error', 'Failed to fetch portal cancellation reasons.'));
			}
		};
		getPortalCancellationReasons();
	}, [dispatch, isNonClaimedSlot]);

	const handleCancellationReasonChange = (e) => {
		setSelectedReason(e.cancellationReason);
		setReplacementWorkerNeeded(null);
	};

	const getReplacementWorkerCheckbox = () => {
		return (
			<div className="reason-feedback-checkbox">
				<div className="mb-3">
					<span>Do you need a replacement worker?</span>
				</div>
				<div className="">
					<Radio
						options={REPLACEMENT_WORKER_OPTIONS}
						name="replacement-worker-needed"
						selectedOption={replacementWorkerNeeded}
						handleChange={(e) => setReplacementWorkerNeeded(e)}
					/>
				</div>
			</div>
		);
	};

	const handleCancelButton = () => {
		props.onCancel(
			selectedReason,
			replacementWorkerNeeded === 'yes',
			isLateCancelation,
			acknowledgmentAccepted,
			agencyName,
			isNonClaimedSlot
		);
	};

	const messageCancellation = () => {
		let message;

		if (isReturningWorker && slotsWorked >= 5 && caregiverTier === TIER.PLATINUM) {
			message = (
				<>
					<b>{caregiverName}</b> is a <b>Platinum {resourceType}</b> with a standout show rate who&apos;s worked at your
					facility <b>{slotsWorked} times.</b> Their dependability is hard to match and their extended <b>experience</b>{' '}
					in your facility ensures top-notch care for your patients.
				</>
			);
		} else if (isReturningWorker && slotsWorked < 5 && caregiverTier === TIER.PLATINUM) {
			message = (
				<>
					<b>{caregiverName}</b> is a <b>Platinum {resourceType}</b> with a <b>standout show rate</b>. Their
					dependability is hard to match and their skillset ensures top-notch care for your patients.
				</>
			);
		} else if (isReturningWorker && slotsWorked >= 5 && caregiverTier === TIER.PRO) {
			message = (
				<>
					<b>{caregiverName}</b> is <b>seasoned {resourceType}</b> who&apos;s worked at your facility{' '}
					<b>{slotsWorked} times</b>. Their understanding of your processes, preferences, and patient needs, means they
					will deliver exceptional care to ensure a seamless experience for your facility.
				</>
			);
		} else if (isReturningWorker && slotsWorked < 5 && caregiverTier === TIER.PRO) {
			message = (
				<>
					<b>{caregiverName}</b> is a <b>seasoned {resourceType}</b>. They are familiar with your processes,
					preferences, and needs, ensuring a smoother experience for your facility.
				</>
			);
		} else if (isReturningWorker && slotsWorked >= 5 && caregiverTier === TIER.PURPLE) {
			message = (
				<>
					<b>{caregiverName}</b> is a <b>returning {resourceType}</b> who&apos;s worked at your facility{' '}
					<b>{slotsWorked} times</b>. Their understanding of your processes, preferences, and patient needs, means they
					will deliver exceptional care to ensure a seamless experience for your facility.
				</>
			);
		} else if (isReturningWorker && slotsWorked < 5 && caregiverTier === TIER.PURPLE) {
			message = (
				<>
					<b>{caregiverName}</b> is a <b>returning {resourceType}</b> who&apos;s familiar with your processes,
					preferences, and needs, ensuring a smoother experience for you and your patients.
				</>
			);
		} else if (!isReturningWorker && slotsWorked === 0 && caregiverTier === TIER.PLATINUM) {
			message = (
				<>
					While <b>{caregiverName}</b> hasn&apos;t worked at your facility yet, they are a{' '}
					<b>Platinum {resourceType}</b> with a <b>standout show rate</b>. Cancelling their first shift with you is
					likely to impact their willingness to work with your facility in the future.
				</>
			);
		} else if (!isReturningWorker && slotsWorked === 0 && caregiverTier === TIER.PRO) {
			message = (
				<>
					While <b>{caregiverName}</b> hasn&apos;t worked at your facility yet, they&apos;ve proven they&apos;re a{' '}
					<b>seasoned {resourceType}</b>. Cancelling their first shift with you is likely to impact their willingness to
					work with your facility in the future.
				</>
			);
		} else if (!isReturningWorker && slotsWorked === 0 && caregiverTier === TIER.PURPLE) {
			message = (
				<>
					As a certified {resourceType}, <b>{caregiverName}</b> stands ready to deliver top-quality care. Remember that
					cancelling on a professional impacts their willingness to work at your facility in the future.
				</>
			);
		} else {
			message = (
				<>
					As a certified {resourceType}, <b>{caregiverName}</b> stands ready to deliver top-quality care. Remember that
					cancelling on a professional impacts their willingness to work at your facility in the future.
				</>
			);
		}

		return message;
	};

	return (
		<BaseModal
			title={isNonClaimedSlot ? 'Cancel Shift' : `Are you sure you want to cancel ${caregiverName}?`}
			className="cancel-shift-modal"
			primaryActionLabel="Cancel Shift"
			onPrimaryAction={isReadyToSubmit() ? handleCancelButton : null}
			primaryActionDisabled={!isReadyToSubmit() || isLoading}
			secondaryActionLabel="Go Back"
			onSecondaryAction={onClose}
			secondaryActionDisabled={isLoading}
			onHide={onClose}
		>
			{!isNonClaimedSlot ? (
				<div className="warning-professional">
					<img src={warning} alt="warning" />
					<span>{messageCancellation()}</span>
				</div>
			) : (
				<span>Please select the reason and fill in the necessary details to cancel the following shift</span>
			)}
			{!isNonClaimedSlot && (
				<div className="cancellation-information">
					{!!slotsWorked && <span className="sub-title">Facility shifts worked: {slotsWorked}</span>}
					<div className="shift-details-container">
						<span className="sub-title">Shift details: </span>
						<span className="cancellation-info">{resourceType}</span>
						<span className="cancellation-info">${hourlyRate}/Hr</span>
						<span className="cancellation-info">
							{day} {date}
						</span>
						<span className="cancellation-info">
							{startTime} - {endTime}
						</span>
						{!!bonus && <span className="cancellation-info">${bonus} Bonus</span>}
						{isGuaranteed && (
							<span className="cancellation-info">
								<img src={guaranteed} alt="is guaranteed" />
								&nbsp; <span className="guaranteed-span">Guaranteed</span>
							</span>
						)}
					</div>
				</div>
			)}
			<hr />
			<div className="row no-gutters justify-content-between reason-section mb-4">
				<div className="col-12 col-md-5">
					<div className="mb-3">
						<span>Select a reason for the cancellation</span>
					</div>
					<Select
						name="cancellationReason"
						placeholder="Select one..."
						value={selectedReason}
						options={cancellationReasons}
						handleChange={handleCancellationReasonChange}
						isDisabled={isLoading}
					/>
				</div>
				<div className="reason-feedback col-12 col-md-5">
					{
						{
							SHIFT_FILLED_ANOTHER_AGENCY: (
								<div className="reason-feedback-text">
									<label className="mb-3" htmlFor="agency-filled-shift">
										Which agency filled this shift?
									</label>
									<input
										id="agency-filled-shift"
										type="text"
										placeholder="Agency name..."
										maxLength="50"
										value={agencyName}
										onChange={(e) => setAgencyName(e.target.value)}
										disabled={isLoading}
									/>
								</div>
							),
							CREDENTIALS_OUT_OF_DATE: getReplacementWorkerCheckbox()
						}[selectedReason]
					}
				</div>
			</div>
			{showUberAcknowledgment && !isNonClaimedSlot && (
				<div className="acknowledgment-checkbox">
					<Checkbox
						id="uber-acknowledgment"
						handleChange={(status) => setUberAcknowledgmentAccepted(status)}
						disabled={isLoading}
					/>
					<label htmlFor="uber-acknowledgment">{ACKNOWLEDGMENT_MESSAGES.HAS_UBER}</label>
				</div>
			)}
			{showAcknowledgment && !isNonClaimedSlot && (
				<div className="acknowledgment-checkbox">
					<Checkbox
						id="acknowledgment"
						handleChange={(status) => setAcknowledgmentAccepted(status)}
						disabled={isLoading}
					/>
					<label htmlFor="acknowledgment">
						{isGuaranteed
							? ACKNOWLEDGMENT_MESSAGES.GUARANTEED_SHIFT_CANCELLATION
							: ACKNOWLEDGMENT_MESSAGES.LATE_CANCELLATION(billableCancellationHours)}
					</label>
				</div>
			)}
			{cancelModalErrorMessage && <div className="error-message"> {cancelModalErrorMessage} </div>}
		</BaseModal>
	);
};

export default CancelShiftModal;
