/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */

import { createSlice } from '@reduxjs/toolkit';
import { showNotification } from './notification';
import Utils from '../utils/utils';
import xhr from '../utils/xhr';
import store from './store';
import { loadUser } from './user';

export const slice = createSlice({
	name: 'pastShifts',
	initialState: {
		pastShiftsData: [],
		startDate: '',
		endDate: '',
		shiftsFetched: false,
		showLoadingSpinner: false
	},
	reducers: {
		setPastShifts: (state, action) => {
			state.pastShiftsData = action.payload;
		},
		setShiftsFetched: (state, action) => {
			state.shiftsFetched = action.payload;
		},
		setDate: (state, action) => {
			state.startDate = action.payload.startDate;
			state.endDate = action.payload.endDate;
		},
		setShowLoadingSpinner: (state, action) => {
			state.showLoadingSpinner = action.payload;
		}
	}
});

export const loadPastShifts = (newStartDate, newEndDate) => async (dispatch) => {
	try {
		const { startDate, endDate } = store.getState().pastShifts;
		const _startDate = newStartDate || startDate;
		const _endDate = newEndDate || endDate;
		if (!_startDate || !_endDate) return;
		await dispatch(slice.actions.setShowLoadingSpinner(true));
		const { data: response } = await xhr.request('GET', `/facility/feed/past?start=${_startDate}&end=${_endDate}`);
		await dispatch(slice.actions.setPastShifts(response));
		await dispatch(slice.actions.setShiftsFetched(true));
		await dispatch(slice.actions.setShowLoadingSpinner(false));
	} catch (error) {
		const errorMessage = Utils.handleNetworkErrorMessage(error, 'Failed to load shifts. Please refresh!');
		dispatch(showNotification('error', errorMessage));
		dispatch(slice.actions.setPastShifts([]));
	}
};

export const setDatePastShifts = (newStartDate, newEndDate) => async (dispatch) => {
	const { startDate, endDate } = store.getState().pastShifts;
	await dispatch(slice.actions.setDate({ startDate: newStartDate || startDate, endDate: newEndDate || endDate }));
	await dispatch(loadPastShifts(newStartDate, newEndDate));
};

export const pastShifts = (state) => state.pastShifts.pastShiftsData;

export const shiftsFetched = (state) => state.pastShifts.shiftsFetched;

export const shouldShowLoadingSpinner = (state) => state.pastShifts.showLoadingSpinner;

export const getPastShiftsDates = (state) => {
	return {
		startDate: state.pastShifts.startDate,
		endDate: state.pastShifts.endDate
	};
};

export const setShiftsFetched = (bool) => async (dispatch) => {
	dispatch(slice.actions.setShiftsFetched(bool));
};

export const { setShowLoadingSpinner } = slice.actions;

export const approvePastShiftSlot = (slotId) => async (dispatch) => {
	try {
		await xhr.request('PUT', `/facility/approve-past-shift`, { slotId });
		dispatch(showNotification('success', 'Approved past shift!'));
	} catch (error) {
		const errorMessage = Utils.handleNetworkErrorMessage(error, 'Could not approve past shift, please try again.');
		dispatch(showNotification('error', errorMessage));
	} finally {
		await dispatch(loadPastShifts());
		await dispatch(loadUser());
	}
};

export default slice.reducer;
