/* eslint-disable no-use-before-define */
/* eslint-disable jsx-a11y/label-has-associated-control */
import moment from 'moment-timezone';
import React, { useEffect, useReducer, useState, useMemo, useCallback } from 'react';
import { Button, Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import { showNotification } from '../../../redux/notification';

import Checkbox from '../../form/Checkbox';
import CustomInput from '../../form/CustomInput';
import Select from '../../form/Select';

import guaranteed from '../../../assets/images/guaranteed-icon.svg';
import LateCancellationBadge from '../../badges/LateCancellation';

import './ShiftDisputeModal.css';
import xhr from '../../../utils/xhr';

const TIME_OPTIONS = [];
const BREAK_OPTIONS = [
	{ label: 'Taken', value: 'TAKEN' },
	{ label: 'Not Taken', value: 'NOT_TAKEN' }
];

const DISPUTE_REASONS_WITH_NO_BONUS = [
	'CAREGIVER_REFUSED_TO_WORK',
	'CAREGIVER_SENT_HOME',
	'CAREGIVER_DNR',
	'REQUESTED_SHIFT_CANCELED',
	'CAREGIVER_REFUSED_TO_WORK'
];

const generateTimeOptions = () => {
	const start = moment().startOf('day');
	const end = moment().endOf('day');
	while (start < end) {
		TIME_OPTIONS.push({ label: start.format('h:mm A'), value: start.format('HH:mm') });
		start.add('15', 'minutes');
	}
};

const slotDataReducer = (state, action) => {
	const { value } = action;
	let disputeReasons = state.disputeReasons ? [...state.disputeReasons] : [];
	let disputedFacilityBonus = value.slotBonus ? Number(value.slotBonus) : 0;
	switch (action.type) {
		case `UPDATE`:
			return { ...state, ...value };
		case 'UPDATE_DISPUTE_REASON':
			if (value.status) {
				disputeReasons.push(value.reason.value);
			} else {
				disputeReasons = disputeReasons.filter((reason) => reason !== value.reason.value);
			}

			// eslint-disable-next-line no-case-declarations
			const shouldNotDisplayBonus = disputeReasons.some((dr) => DISPUTE_REASONS_WITH_NO_BONUS.includes(dr));
			disputedFacilityBonus = shouldNotDisplayBonus ? 0 : disputedFacilityBonus;
			return { ...state, disputeReasons, disputedFacilityBonus };
		default:
			return state;
	}
};

generateTimeOptions();

function ShiftDisputeModal(props) {
	const { slot } = props;
	const dispatch = useDispatch();

	const [adjustedStart, setAdjustedStart] = useState(null);
	const [adjustedEnd, setAdjustedEnd] = useState(null);

	const [minDatepickerDate, setMinDatepickerDate] = useState(null);
	const [maxDatepickerDate, setMaxDatepickerDate] = useState(null);

	const formatDateForDatePicker = (date, timezone) => {
		return moment(moment.tz(date, timezone).format('YYYY MMM DD, h:mm A'), 'YYYY MMM DD, h:mm A');
	};

	const setClocks = () => {
		const adjustedStartTime = slot.check_in_time
			? formatDateForDatePicker(slot.check_in_time, slot.facility_timezone)
			: null;

		const adjustedEndTime = slot.check_out_time
			? formatDateForDatePicker(slot.check_out_time, slot.facility_timezone)
			: null;

		// eslint-disable-next-line no-unneeded-ternary
		let minShiftDate = adjustedStartTime
			? adjustedStartTime
			: formatDateForDatePicker(slot.raw_start_time, slot.facility_timezone);

		// eslint-disable-next-line no-unneeded-ternary
		let maxShiftDate = adjustedEndTime
			? adjustedEndTime
			: formatDateForDatePicker(slot.raw_end_time, slot.facility_timezone);

		// max window of 17 hours to adjust the time.
		const diff = 17 * 60 - moment(maxShiftDate).diff(minShiftDate, 'minutes');

		minShiftDate = moment(minShiftDate).subtract(diff, 'minutes');
		setAdjustedStart(adjustedStartTime && adjustedStartTime.toDate());
		maxShiftDate = moment(maxShiftDate).add(diff, 'minutes');
		setAdjustedEnd(adjustedEndTime && adjustedEndTime.toDate());
		setMinDatepickerDate(minShiftDate.toDate());
		setMaxDatepickerDate(maxShiftDate.toDate());
	};

	const changeDisputedTimeHours = useCallback(
		(time) => {
			if (time) {
				const [hours, minutes] = moment(time, ['hh:mm A']).format('HH:mm').split(':');
				return moment
					.tz(slot.raw_start_time, slot.facility_timezone)
					.set('hours', hours)
					.set('minutes', minutes)
					.format();
			}

			return null;
		},
		[slot]
	);

	const calculateTotalWorkedHours = useCallback(
		(checkin, checkout, wasBreakTaken) => {
			const getFormattedDBTimes = () => {
				const formattedCheckinTimeOnDB = slot.check_in_time
					? moment(slot.check_in_time).tz(slot.facility_timezone).format('HH:mm A').split(' ')[0]
					: null;
				const formattedCheckoutTimeonDB = slot.check_out_time
					? moment(slot.check_out_time).tz(slot.facility_timezone).format('HH:mm A').split(' ')[0]
					: null;
				return [formattedCheckinTimeOnDB, formattedCheckoutTimeonDB];
			};

			const breakWasTaken = typeof wasBreakTaken === 'boolean' ? wasBreakTaken : wasBreakTaken === 'TAKEN';
			const [checkinTimeOnDB, checkoutTimeonDB] = getFormattedDBTimes();
			const checkTimesHaveChanged = checkinTimeOnDB !== checkin || checkoutTimeonDB !== checkout;

			if (slot.is_facility_billable_cancellation && !slot.is_guaranteed && !checkTimesHaveChanged) {
				return slot.facility_billable_cancellation_hours;
			}

			if (!checkin || !checkout) {
				return 0;
			}

			let momentStartTime = moment(checkin);
			let momentEndTime = moment(checkout);

			if (checkin.length < 9) {
				// checkin select option
				const checkinOption = moment(checkin, ['hh:mm A']).format('HH:mm');
				const _momentStartTime = moment(slot.raw_start_time);
				const [hours, minutes] = checkinOption.split(':');
				const selectedCheckin = _momentStartTime.set({
					month: _momentStartTime.get('month'),
					day: _momentStartTime.get('day'),
					hours,
					minutes
				});
				momentStartTime = selectedCheckin;
			}

			if (checkout.length < 9) {
				const checkoutOption = moment(checkout, ['hh:mm A']).format('HH:mm');
				const _momentEndTime = moment(slot.raw_start_time);
				const [hours, minutes] = checkoutOption.split(':');
				const selectedCheckout = _momentEndTime.set({
					month: _momentEndTime.get('month'),
					day: _momentEndTime.get('day'),
					hours,
					minutes
				});
				momentEndTime = selectedCheckout;

				if (momentEndTime.isBefore(momentStartTime)) {
					momentEndTime.add(1, 'days');
				}
			}

			const breakTime = breakWasTaken && slot.facility_break_type === 'Unpaid' ? slot.lunch_break_duration : 0;

			const shiftDuration = moment.duration(momentEndTime.diff(momentStartTime)).asHours();
			const hoursWorked = Number(shiftDuration - breakTime / 60);
			return hoursWorked > 0 ? hoursWorked : 0;
		},
		[slot]
	);

	const totalHoursWorked = useMemo(
		() => calculateTotalWorkedHours(slot.check_in_time, slot.check_out_time, slot.took_break),
		[slot.check_in_time, slot.check_out_time, slot.took_break, calculateTotalWorkedHours]
	);

	const formatTime = (time) => {
		const hours = time.split(':')[0];
		const minutes = time.split(':')[1];
		return moment.tz(slot.facility_timezone).set('hours', hours).set('minutes', minutes).format('hh:mm A');
	};

	const [checkinIsRequired, setCheckinIsRequired] = useState(false);
	const [checkoutIsRequired, setCheckoutIsRequired] = useState(false);
	const [modalHasErrors, setModalHasErrors] = useState(false);
	const [reasons, setReasons] = useState([]);
	const [slotData, dispatchUpdate] = useReducer(slotDataReducer, {
		slotId: slot.slot_id,
		facilityBonus: Number(slot.facility_payable_bonus),
		disputedFacilityBonus: Number(slot.facility_payable_bonus),
		checkinTime: slot.check_in_time,
		checkinTimeFormatted: slot.check_in_time
			? moment.tz(slot.check_in_time, slot.facility_timezone).format('hh:mm A')
			: 'No Info',
		disputedCheckinTime: slot.check_in_time
			? moment(slot.check_in_time).tz(slot.facility_timezone).format('HH:mm')
			: null,
		checkoutTime: slot.check_out_time,
		checkoutTimeFormatted: slot.check_out_time
			? moment.tz(slot.check_out_time, slot.facility_timezone).format('hh:mm A')
			: 'No Info',
		disputedCheckoutTime: slot.check_out_time
			? moment(slot.check_out_time).tz(slot.facility_timezone).format('HH:mm')
			: null,
		wasBreakTaken: slot.took_break,
		disputedWasBreakTaken: slot.took_break,
		hoursWorked: slot.total_hours,
		disputedHoursWorked: totalHoursWorked || slot.total_hours,
		facilityRate: Number(slot.facility_price),
		disputedFacilityRate: Number(slot.facility_price),
		slotRevenue: slot.total_rate,
		disputedSlotRevenue: Number(slot.total_rate),
		isBillableCancellation: slot.is_facility_billable_cancellation,
		disputeReasons: [],
		additionalInfo: ''
	});

	const formatDate = (date) => moment.tz(date, slot.facility_timezone).format('dddd, MMMM Do YYYY');

	const isValidDispute = () => {
		if (!slot.check_in_time || !slot.check_out_time) {
			const hasRelatedReasons =
				slotData.disputeReasons.includes('NO_CALL_NO_SHOW') ||
				slotData.disputeReasons.includes('CAREGIVER_REFUSED_TO_WORK') ||
				slotData.disputeReasons.includes('REQUESTED_SHIFT_CANCELED') ||
				slotData.disputeReasons.includes('MISSED_PUNCH') ||
				slotData.disputeReasons.includes('CAREGIVER_LEFT_EARLY');

			const hasLateCancellation = slot.is_facility_billable_cancellation && !slot.is_guaranteed;
			const hasUpdatedCheckIn = slot.check_in_time || slotData.disputedCheckinTime;
			const hasUpdatedCheckOut = slot.check_out_time || slotData.disputedCheckoutTime;

			if (!hasRelatedReasons && !hasLateCancellation && (!hasUpdatedCheckIn || !hasUpdatedCheckOut)) {
				setCheckinIsRequired(!hasUpdatedCheckIn);
				setCheckoutIsRequired(!hasUpdatedCheckOut);
				dispatch(
					showNotification('error', 'Please modify at least one of the highlighted fields to submit the dispute.')
				);
				return false;
			}
		}

		if (
			Number(slotData.disputedFacilityBonus) !== Number(slot.facility_payable_bonus) &&
			slotData.disputedFacilityBonus % 5 !== 0
		) {
			dispatch(showNotification('error', 'Bonus field must be a whole increment of $5.'));
			return false;
		}

		return true;
	};

	const save = async () => {
		const isDisputeValidated = isValidDispute();

		if (!isDisputeValidated) return;
		try {
			const payload = {
				...slotData,
				disputedCheckinTime: changeDisputedTimeHours(slotData.disputedCheckinTime),
				disputedCheckoutTime: changeDisputedTimeHours(slotData.disputedCheckoutTime),
				disputedWasBreakTaken:
					typeof slotData.disputedWasBreakTaken === 'string'
						? slotData.disputedWasBreakTaken === 'TAKEN'
						: slot.took_break
			};

			await xhr.request('POST', '/disputes', payload);

			dispatch(showNotification('success', 'Dispute created successfully.'));

			if (props.confirmModal) {
				props.confirmModal();
			}

			if (props.onSave) {
				props.onSave();
			}

			if (props.onClose) {
				props.onClose();
			}
		} catch (error) {
			if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.length) {
				dispatch(showNotification('error', error.response.data.errors[0].message));
				if (
					error.response.data.errors[0].message ===
					'A dispute reason or edit of the information is required to submit a dispute.'
				) {
					setModalHasErrors(true);
				}
			} else {
				dispatch(showNotification('error', 'Failed to create dispute.'));
			}
		}
	};

	useEffect(() => {
		dispatchUpdate({
			type: 'UPDATE',
			value: {
				disputedHoursWorked: calculateTotalWorkedHours(
					slotData.disputedCheckinTime || slot.check_in_time,
					slotData.disputedCheckoutTime || slot.check_out_time,
					slotData.disputedWasBreakTaken
				)
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slotData.disputedCheckinTime, slotData.disputedCheckoutTime, slotData.disputedWasBreakTaken]);

	useEffect(() => {
		const getDisputeReasons = async () => {
			try {
				const { data } = await xhr.request('GET', `/entity/dispute-reasons-types`);
				setReasons(data);
			} catch {
				dispatch(showNotification('error', 'Failed to fetch dispute reasons.'));
			}
		};
		getDisputeReasons();
	}, [dispatch]);

	useEffect(() => {
		setModalHasErrors(false);
		const payload = { type: 'UPDATE', value: { disputedSlotRevenue: 0 } };
		const breakTime =
			slotData.disputedWasBreakTaken && slot.facility_break_type === 'Unpaid' ? slot.lunch_break_duration : 0;
		if (!slotData.disputedHoursWorked && !slotData.hoursWorked) {
			payload.value.disputedSlotRevenue = 0;
		} else if (slot.took_break === false && slotData.disputedWasBreakTaken === 'TAKEN') {
			payload.value.disputedSlotRevenue =
				slotData.disputedFacilityRate * (slotData.disputedHoursWorked - breakTime / 60) +
				slotData.disputedFacilityBonus;
		} else {
			payload.value.disputedSlotRevenue =
				slotData.disputedFacilityRate * slotData.disputedHoursWorked + slotData.disputedFacilityBonus;
		}

		dispatchUpdate(payload);
	}, [
		slot.took_break,
		slot.facility_break_type,
		slotData.disputedFacilityBonus,
		slotData.disputedFacilityRate,
		slotData.disputedHoursWorked,
		slotData.disputedWasBreakTaken,
		slotData.hoursWorked,
		slot.lunch_break_duration
	]);

	useEffect(() => {
		setClocks();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClockChange = (newValue, isCheckin) => {
		const newDate = moment(newValue).format('HH:mm');
		setCheckinIsRequired(false);
		setModalHasErrors(false);

		if (isCheckin) {
			setAdjustedStart(newValue);
			dispatchUpdate({ type: 'UPDATE', value: { disputedCheckinTime: formatTime(newDate) } });
		} else {
			setAdjustedEnd(newValue);
			dispatchUpdate({ type: 'UPDATE', value: { disputedCheckoutTime: formatTime(newDate) } });
		}
	};

	const handleUpdateAdditionalInfo = (e) => {
		setModalHasErrors(false);
		dispatchUpdate({ type: 'UPDATE', value: { additionalInfo: e.target.value } });
	};

	return (
		<Modal
			size={props.size || 'xl'}
			show
			aria-labelledby="contained-modal-title-vcenter"
			centered
			scrollable={!!props.scrollable}
			className="disputes-modal"
			onHide={props.onClose}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					<h2>File a Shift Dispute</h2>
					<span className="file-dispute-text">
						To file a dispute, please update incorrect worked information and then select a dispute reason.
					</span>
					<div className="hcp-name-type">
						<div>
							<span className="sub-title">Name</span>
							<b>{slot.caregiver_name}</b>
						</div>
						<div>
							<span className="sub-title">Shift Type</span>
							<b>{slot.resource_type}</b>
						</div>
					</div>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<hr className="separation" />
				<div className="disputes-modal-shift-information">
					<h2 className="section-title">WORKED INFORMATION</h2>
					<div className="disputes-modal-si-date with-prefix">
						Date <span>{formatDate(slot.start_time_at_utc)}</span>
					</div>
					<div className="disputes-modal-si-time with-prefix">
						Time
						<span>
							{slot.start_time} - {slot.end_time}
						</span>
					</div>
					<div className="disputes-modal-si-break with-prefix">
						Break <span>{slot.facility_break_type} Break</span>
					</div>
					<div className="disputes-modal-si-hours with-prefix">
						Rate <span>${Number(slot.facility_price)}</span>
					</div>
					<div className="disputes-modal-si-hours with-prefix">
						Bonus <span>${Number(slot.facility_payable_bonus)}</span>
					</div>
				</div>
				<div className="disputes-modal-shift-information extra">
					<div className="disputes-modal-si-hours with-prefix">
						Clocked In <span>{slotData.checkinTimeFormatted} </span>
					</div>
					<div className="disputes-modal-si-hours with-prefix">
						Clocked Out <span>{slotData.checkoutTimeFormatted}</span>
					</div>
					<div className="disputes-modal-si-hours with-prefix">
						Break <span>{slot.lunch_break_duration ? `Taken ${Number(slot.lunch_break_duration)} Min` : ''}</span>
					</div>
					<div className="disputes-modal-si-hours with-prefix">
						Total Hrs <span>{Number(slot.total_hours)} Hours</span>
					</div>
					<div className="disputes-modal-si-hours with-prefix">
						Total Pay <span>${Number(slot.total_rate)}</span>
					</div>
				</div>
				<hr className="separation" />
				<h2 className="section-title">ADJUSTED SHIFT INFORMATION</h2>
				<span className="file-dispute-text">Update shift information and select a dispute reason.</span>
				<div className="disputes-modal-worked-information">
					<div className="d-flex justify-content-between">
						<div className="disputes-modal-wi-datepicker with-prefix">
							<p className="field-title">Clocked In</p>
							<div>
								<DatePicker
									className={classNames('time-select', {
										error: checkinIsRequired || modalHasErrors
									})}
									name="clocked_in"
									selected={adjustedStart}
									minDate={minDatepickerDate}
									maxDate={maxDatepickerDate}
									onChange={(newValue) => handleClockChange(newValue, true)}
									dateFormat="h:mm aa"
									showTimeInput
									timeCaption="Time: "
								/>
								{slot.is_facility_billable_cancellation && !slot.is_guaranteed && (
									<div>
										{' '}
										<LateCancellationBadge />{' '}
									</div>
								)}
								<div
									className={classNames('required', {
										show: checkinIsRequired
									})}
								>
									Required
								</div>
							</div>
						</div>

						<div className="disputes-modal-wi-datepicker with-prefix">
							<p className="field-title">Clocked Out</p>
							<div>
								<DatePicker
									className={classNames('time-select', {
										error: checkoutIsRequired || modalHasErrors
									})}
									name="clocked_out"
									selected={adjustedEnd}
									minDate={minDatepickerDate}
									maxDate={maxDatepickerDate}
									onChange={(newValue) => handleClockChange(newValue, false)}
									dateFormat="h:mm aa"
									showTimeInput
									timeCaption="Time: "
								/>
								{slot.is_facility_billable_cancellation && !slot.is_guaranteed && (
									<div>
										{' '}
										<LateCancellationBadge />{' '}
									</div>
								)}
								<div
									className={classNames('required', {
										show: checkoutIsRequired
									})}
								>
									Required
								</div>
							</div>
						</div>

						<div className="disputes-modal-wi-break break with-prefix">
							<p className="field-title">Break</p>
							<div>
								<Select
									placeholder={slotData.disputedWasBreakTaken ? 'Taken' : 'Not Taken'}
									className={classNames('break-select', { error: modalHasErrors })}
									name="break"
									isSearchable={false}
									handleChange={(newValue) =>
										dispatchUpdate({ type: 'UPDATE', value: { disputedWasBreakTaken: newValue.break } })
									}
									options={BREAK_OPTIONS}
									value={BREAK_OPTIONS.filter(({ value }) => slotData.disputedWasBreakTaken === value)}
								/>
								{slot.is_facility_billable_cancellation && !slot.is_guaranteed && <LateCancellationBadge />}
								<div className="required">Required</div>
							</div>
						</div>

						<div className="disputes-modal-wi-rate with-prefix">
							<p className="field-title">Rate</p>
							<div>
								<CustomInput
									permission="PORTAL.EDIT"
									validValueRegex={/\d+/g}
									prependValue="$"
									defaultValue={slotData.disputedFacilityRate || '0'}
									saveOnBlur
									extraClassName={modalHasErrors ? 'error' : null}
									onSave={(newValue) =>
										dispatchUpdate({ type: 'UPDATE', value: { disputedFacilityRate: Number(newValue) } })
									}
									maxLength={6}
								/>
							</div>
						</div>

						<div className="disputes-modal-wi-bonus with-prefix">
							<p className="field-title">Bonus</p>
							<div>
								<CustomInput
									permission="PORTAL.EDIT"
									validValueRegex={/\d+/g}
									prependValue="$"
									defaultValue={slotData.disputedFacilityBonus || '0'}
									saveOnBlur
									extraClassName={modalHasErrors ? 'error' : null}
									onSave={(newValue) =>
										dispatchUpdate({ type: 'UPDATE', value: { disputedFacilityBonus: Number(newValue) } })
									}
									maxLength={5}
								/>
							</div>
						</div>
					</div>
					<div className="d-flex">
						<div className="disputes-modal-wi-hours with-prefix worked-hours">
							<p className="field-title">Hrs Worked</p>
							<span className="total">
								{parseFloat(slotData.disputedHoursWorked).toFixed(2) || Number(slot.total_hours)} Hours
							</span>
						</div>
						<div
							className={classNames('disputes-modal-wi-hours with-prefix worked-hours', {
								'is-guaranteed': slot.is_guaranteed
							})}
						>
							<p className="field-title">
								Total Pay{' '}
								{slot.is_guaranteed && <img className="guaranteed-badge" src={guaranteed} alt="is guaranteed" />}
							</p>
							<span className="total">
								${parseFloat(slotData.disputedSlotRevenue).toFixed(2) || slotData.slotRevenue}
							</span>
						</div>
					</div>
				</div>
				<div className="disputes-modal-reason">
					<h2 className="section-title reasons">REASONS FOR DISPUTE</h2>
					<div className={classNames('disputes-modal-reason-checkboxes', { error: modalHasErrors })}>
						{reasons.map((reason) => {
							return (
								<div className="disputes-modal-checkbox" key={reason.value.replace(/\s/g, '_')}>
									<Checkbox
										handleChange={(status) => {
											setModalHasErrors(false);
											dispatchUpdate({
												type: 'UPDATE_DISPUTE_REASON',
												value: {
													reason,
													status,
													slotBonus: slotData.disputedFacilityBonus
												}
											});
										}}
										id={reason.value.replace(/\s/g, '_')}
									/>
									<label htmlFor={reason.value.replace(/\s/g, '_')}>{reason.label}</label>
								</div>
							);
						})}
					</div>

					<div className="disputes-modal-additional-info">
						<label className="description" htmlFor="additionalInfo">
							Additional Info
						</label>
						<input
							id="additionalInfo"
							type="text"
							maxLength="280"
							className={classNames('disputes-additional-info-input', { error: modalHasErrors })}
							onChange={handleUpdateAdditionalInfo}
						/>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={save} variant="primary" className="disputes-modal-send_button">
					SEND DISPUTE
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default ShiftDisputeModal;
