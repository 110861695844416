import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { user } from '../redux/user';
import './PageTitle.scss';
import InfoIcon from '../assets/images/icons8-info.svg';
import DailyScheduleActions from '../features/shifts/DailyScheduleActions';

const TIMEZONE = 'America/New_York';

const PageTitle = ({
	title,
	className,
	hasDisputesPermission,
	delinquentAccountReceivableStatus,
	shiftStructures,
	slotsToConfirm,
	setShowConfirmationsVendorWarningModal = () => {},
	setShowRequestShiftsVendorWarningModal = () => {},
	setShowFlexRequestsShiftModal = () => {},
	showGoBackButton = false,
	allowFlexShifts,
	allowOnDemandShifts
}) => {
	const userData = useSelector(user);
	const today = moment().tz(TIMEZONE);
	const hours = moment(today, 'HH:mm').hours();
	const TuesdayPast8PM = (today.weekday() === 2 && hours > 20) || today.weekday() > 2;
	const fridayRelated = TuesdayPast8PM ? 5 : -2;
	const saturdayRelated = TuesdayPast8PM ? -1 : -8;
	const disputeWeekUpperLimit = moment().day(fridayRelated).format('MM/DD');
	const disputeWeekLowerLimit = moment().day(saturdayRelated).format('MM/DD');
	const nextAutoApproveDate = moment()
		.day(TuesdayPast8PM ? 9 : 2)
		.format('MM/DD');
	const showAccountReceivableBanner = delinquentAccountReceivableStatus && title === 'Daily Schedule';

	return (
		<>
			<div className={classNames('top-selected-menu-bar', className)}>
				{!showGoBackButton && <div className="top-selected-menu-bar-label font-style">{title}</div>}
				{/* Won't show on purpose. We'll make this text visible after turning on auto approves cronscript on production */}
				<div className={classNames({ 'right-content': !showGoBackButton })}>
					{title === 'Past Shifts' && hasDisputesPermission && (
						<div className="top-selected-menu-bar-right font-style">{`Shifts from ${disputeWeekLowerLimit} - ${disputeWeekUpperLimit} that haven't been disputed will Auto-Approve on Tuesday, ${nextAutoApproveDate} at 8 PM EST`}</div>
					)}
					{title === 'Daily Schedule' && (
						<>
							{userData.shiftsToReview > 0 && (
								<div className="top-selected-menu-bar-right font-style">
									{userData.shiftsToReview} Past {userData.shiftsToReview === 1 ? 'Shift' : 'Shifts'} to Approve!{' '}
								</div>
							)}
							<DailyScheduleActions
								delinquentAccountReceivableStatus={delinquentAccountReceivableStatus}
								shiftStructures={shiftStructures}
								slotsToConfirm={slotsToConfirm}
								setShowConfirmationsVendorWarningModal={setShowConfirmationsVendorWarningModal}
								setShowRequestShiftsVendorWarningModal={setShowRequestShiftsVendorWarningModal}
								setShowFlexRequestsShiftModal={setShowFlexRequestsShiftModal}
								showGoBackButton={showGoBackButton}
								allowFlexShifts={allowFlexShifts}
								allowOnDemandShifts={allowOnDemandShifts}
							/>
						</>
					)}
				</div>
			</div>
			{showAccountReceivableBanner && (
				<div className="facility-receivable-status-banner">
					<img src={InfoIcon} alt="Info" />
					<div className="label">{userData.activeFacility.account_receivable_status_message}</div>
				</div>
			)}
		</>
	);
};

export default PageTitle;
