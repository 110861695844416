/* eslint-disable no-param-reassign */
/* eslint-disable import/no-cycle */

import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { showNotification } from './notification';
import Utils from '../utils/utils';
import xhr from '../utils/xhr';

export const slice = createSlice({
	name: 'feed',
	initialState: {
		shiftsFeed: [],
		shiftStructures: []
	},
	reducers: {
		setShiftsFeed: (state, action) => {
			state.shiftsFeed = action.payload;
		},
		setShiftStructures: (state, action) => {
			state.shiftStructures = action.payload;
		}
	}
});

export const loadFeed = () => async (dispatch) => {
	try {
		const { data: feedResponse } = await xhr.request('GET', '/facility/feed/upcoming');
		dispatch(slice.actions.setShiftsFeed(feedResponse));
	} catch (error) {
		const errorMessage = Utils.handleNetworkErrorMessage(error, 'Failed to load shifts. Please refresh!');
		dispatch(showNotification('error', errorMessage));
		dispatch(slice.actions.setShiftsFeed([]));
	}
};

export const loadShiftStructures = () => async (dispatch) => {
	try {
		const { data: fetchedShiftStructuresAvailable } = await xhr.request('GET', '/facility/shift-structures');
		// sort the shift structures by resource type and start time
		fetchedShiftStructuresAvailable.sort((a, b) => {
			if (a.resource_type === b.resource_type) {
				const firstStart = moment(a.from_time, 'HH:mm:ss');
				const secondStart = moment(b.from_time, 'HH:mm:ss');
				if (firstStart.isAfter(secondStart)) {
					return 1;
				}
				if (firstStart.isBefore(secondStart)) {
					return -1;
				}
				return 0;
			}
			return 0;
		});
		dispatch(slice.actions.setShiftStructures(fetchedShiftStructuresAvailable));
	} catch (error) {
		const errorMessage = Utils.handleNetworkErrorMessage(error, 'Failed to fetch shifts structures');
		dispatch(showNotification('error', errorMessage));
		dispatch(slice.actions.setShiftStructures([]));
	}
};

export const feed = (state) => state.feed.shiftsFeed;

export const shiftStructures = (state) => state.feed.shiftStructures;

export default slice.reducer;
