import { useState } from 'react';
import { useSelector } from 'react-redux';
import { user } from '../redux/user';
import xhr from '../utils/xhr';

const useInviteCaregiver = (onLoading = () => {}, sendNotification = () => {}) => {
	const [inviteCaregiver, setInviteCaregiver] = useState(null);
	const [facilityInvites, setFacilityInvites] = useState(null);
	const userData = useSelector(user);
	const {
		activeFacility: { id: facilityId }
	} = userData;
	const getInviteCaregiver = async (inviteId) => {
		try {
			const { data } = await xhr.request('GET', `/caregivers-invite/${inviteId}`);
			setInviteCaregiver(data);
		} catch (error) {
			sendNotification('success', 'Failed to fetch invite data');
		}
	};

	const getInvitesByFacilityId = async () => {
		try {
			const { data } = await xhr.request('GET', `/caregivers-invite/facility`);
			setFacilityInvites(data);
		} catch (error) {
			sendNotification('error', 'Failed to fetch invite data');
		}
	};

	/**
	 * @param {'POST' | 'PUT'} method
	 * @param {object} payload
	 * @param {string} successMessage
	 * @param {strings} errorMessage
	 */
	const createOrUpdateInviteCaregiver = async (method, payload, successMessage, errorMessage) => {
		onLoading(true);
		try {
			await xhr.request(method, '/caregivers-invite', payload);
			sendNotification('success', successMessage);
		} catch (error) {
			sendNotification('error', errorMessage);
		} finally {
			onLoading(false);
		}
	};
	/**
	 *
	 * @param {object[]} selectedCaregivers
	 * @param {string} shiftId
	 * @param {boolean} isRecurring
	 * @param {string} successMessage
	 * @param {string} errorMessage
	 */
	const bulkCreateInvite = async (
		selectedCaregivers,
		shiftId,
		isRecurring,
		shiftDate,
		successMessage,
		errorMessage
	) => {
		let bulkResponse;
		try {
			onLoading(true);

			const inviteCaregivers = selectedCaregivers.map((caregiver) => {
				return {
					facilityId,
					shiftId,
					status: 'PENDING',
					caregiverId: caregiver.caregiver_id
				};
			});
			const payload = {
				inviteCaregivers,
				isRecurring,
				shiftDate
			};
			const { data } = await xhr.request('POST', '/caregivers-invite/bulk-create', payload);
			sendNotification('success', successMessage);
			bulkResponse = data;
		} catch (error) {
			const type = error.response.data[0]?.type;
			sendNotification(type || 'error', error.response?.data[0]?.message || errorMessage);
		} finally {
			onLoading(false);
		}
		return bulkResponse;
	};

	return {
		inviteCaregiver,
		facilityInvites,
		getInviteCaregiver,
		getInvitesByFacilityId,
		createOrUpdateInviteCaregiver,
		bulkCreateInvite
	};
};

export default useInviteCaregiver;
