import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { showNotification } from '../../../redux/notification';
import xhr from '../../../utils/xhr';
import './ConfirmationsPendingModal.css';
import ConfirmationsTable from './table/ConfirmationsTable';
import Checkbox from '../../form/Checkbox';
import LoadingSpinner from '../../spinner/LoadingSpinner';

const ConfirmationsPendingModal = (props) => {
	const dispatch = useDispatch();

	const [selectedSlots, setSelectedSlots] = useState([]);
	const [checkedConfirmation, setCheckedConfirmation] = useState(false);
	const [errors, setErrors] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const handleSave = async () => {
		try {
			if (errors.length > 0) {
				props.onClose();
				return;
			}
			setIsLoading(true);
			await xhr.request('PUT', `/slots/update-batch`, { slots: selectedSlots, code: 'CONFIRMED_SHIFT' });
			await props.updateFeed();
			dispatch(showNotification('success', 'Your shifts were confirmed successfully'));
			props.onClose();
		} catch (e) {
			if (e.response && e.response.data && e.response.data.errors) {
				if (e.response.status === 403) {
					setErrors(selectedSlots);
				} else {
					setErrors(e.response.data.errors.map((err) => err.id));
				}
			}
		} finally {
			setIsLoading(false);
		}
	};

	const orderByCert = (slots) => {
		const slotsByCert = {
			CNA: [],
			GNA: [],
			STNA: [],
			LPN: [],
			RN: [],
			RNS: [],
			CC: [],
			LNA: []
		};

		for (let i = 0; i < slots.length; i++) {
			const slot = slots[i];

			// add not specified resource types
			if (!slotsByCert[slot.resource_type]) {
				slotsByCert[slot.resource_type] = [];
			}

			slotsByCert[slot.resource_type].push(slot);
		}

		const { CNA, CC, LNA, GNA, STNA, LPN, RN, RNS, ...otherCerts } = slotsByCert;
		return [
			...CNA,
			...CC,
			...LNA,
			...GNA,
			...STNA,
			...LPN,
			...RN,
			...RNS,
			...Object.keys(otherCerts).reduce((acc, key) => {
				return [...acc, ...otherCerts[key]];
			}, [])
		];
	};

	const daysWithConfirmableSlots = props.shiftsByDay
		.map((day) => {
			const dayCopy = { ...day };
			dayCopy.shifts = day.shifts.filter((shift) => shift.has_confirmable_slots);

			return dayCopy;
		})
		.filter((day) => day.shifts.length > 0);

	const shiftsWithConfirmableSlots = daysWithConfirmableSlots.map((day) => {
		const currentDay = day;

		currentDay.slots = [];

		currentDay.shifts.forEach((shift) => {
			currentDay.slots.push(...shift.slots.filter((slot) => slot.can_confirm));
		});

		currentDay.slots = orderByCert(currentDay.slots);

		return currentDay;
	});

	return [
		<Modal
			size={props.size || 'xl'}
			key="confirmations-pending"
			show
			aria-labelledby="contained-modal-title-vcenter"
			centered
			scrollable={!!props.scrollable}
			className="confirmations-pending-modal"
			onHide={props.onClose}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					<h2>Confirm shifts</h2>
					<p className="confirmation-subtitle">
						{errors.length > 0
							? 'We found errors while confirming the shifts.'
							: 'Please confirm the following nurses and aides! They are excited to work for you! Need to cancel shifts? Please go to the specific shift on your Daily Schedule to cancel it.'}
					</p>
					<hr />
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="confirmations-pending-modal-body">
					<ConfirmationsTable
						key="confirmations-table"
						days={shiftsWithConfirmableSlots}
						onChecked={setSelectedSlots}
						hideCheckbox={errors.length > 0}
						hasErrors={errors}
					/>
					<div className="confirmations-pending-modal-footer mt-4">
						{!errors.length > 0 && (
							<div className="confirmations-pending-modal-terms">
								<Checkbox handleChange={setCheckedConfirmation} />
								<p>I acknowledge that I am confirming all selected shifts.</p>
							</div>
						)}
						{errors.length > 0 && (
							<div className="confirmations-pending-modal-hasErrors">
								<p>All shifts confirmed excluding the nurses and/or aides listed.</p>
								<p>To confirm these shifts, please go to the shift on the portal feed.</p>
							</div>
						)}
						<div className="buttons">
							<Button
								className="confirm-button"
								onClick={errors.length > 0 ? props.onClose : handleSave}
								variant="primary"
								disabled={(errors.length === 0 && (selectedSlots.length < 1 || !checkedConfirmation)) || isLoading}
							>
								{errors.length > 0 ? 'OK' : 'CONFIRM'}
							</Button>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>,
		isLoading && <LoadingSpinner key="loading-spinner" />
	];
};

export default ConfirmationsPendingModal;
