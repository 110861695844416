import React, { useState, useRef, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';

import xhr from '../../utils/xhr';
import utils from '../../utils/utils';
import { showNotification } from '../../redux/notification';
import { loadUser } from '../../redux/user';
import LoadingSpinner from '../../components/spinner/LoadingSpinner';
import backgroundImage from '../../assets/images/login-background.png';
import logoIcon from '../../assets/images/portal-logo.svg';
import userIcon from '../../assets/images/login-user.svg';
import passwordIcon from '../../assets/images/lock.svg';
import config from '../../config';

import './Login.css';

const Login = (props) => {
	const recaptchaRef = useRef(null);

	const dispatch = useDispatch();

	const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [needsRecaptcha, setNeedsRecaptcha] = useState(false);
	const [recaptchaResponse, setRecaptchaResponse] = useState(null);
	const [recaptchaSiteKey, setRecaptchaSiteKey] = useState(null);
	const [passwordInputType, setPasswordInputType] = useState('password');

	const resetRecaptcha = () => {
		if (recaptchaRef.current) {
			recaptchaRef.current.reset();
		}
	};

	const togglePasswordInputType = () => {
		setPasswordInputType(passwordInputType === 'password' ? 'text' : 'password');
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const slotId = urlParams.get('slotId');
		const facilityId = urlParams.get('facilityId');
		if (slotId) {
			window.localStorage.setItem('slotId', slotId);
		}
		if (facilityId) {
			window.localStorage.setItem('activeFacility', facilityId);
		}
	}, []);

	const signIn = async (e) => {
		e.preventDefault();
		if (!email || !utils.emailRegex.test(email.trim())) {
			dispatch(showNotification('error', 'Please enter a valid email address'));
		} else if (!password) {
			dispatch(showNotification('error', 'Please enter a password'));
		} else if (needsRecaptcha && !recaptchaResponse) {
			dispatch(showNotification('error', "Please confirm you're not a robot"));
		} else {
			try {
				setShowLoadingSpinner(true);
				await xhr.request('POST', `/login`, { email: email.trim(), password, recaptchaResponse });
				dispatch(loadUser());
				const queryParams = utils.getURLQueryParams();
				if (queryParams.url) {
					window.location.href = queryParams.url;
				} else {
					props.history.replace('/');
				}
			} catch (error) {
				setShowLoadingSpinner(false);
				if (error.response.data && error.response.data.errors && error.response.data.errors.length) {
					dispatch(showNotification('error', error.response.data.errors[0].message));
					if (error.response.data.errors[0].needRecaptcha) {
						setNeedsRecaptcha(true);
						setRecaptchaResponse(null);
						setRecaptchaSiteKey(error.response.data.errors[0].recaptchaSiteKey);
						resetRecaptcha();
					}
				}
			}
		}
	};

	return (
		<div className="login-screen">
			<img src={backgroundImage} className="login-background" alt="" />
			<div className="login-form-container">
				<form className="login-form" onSubmit={signIn}>
					<img src={logoIcon} alt="" className="logo" aria-hidden="true" onClick={() => props.history.replace('/')} />
					<h1 className="header">Sign in!</h1>

					<div className="input-container">
						<div className="input-icon">
							<img src={userIcon} alt="" />
						</div>
						<input
							type="email"
							maxLength={128}
							className="flex-one"
							placeholder="Email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</div>
					<div className="input-container">
						<div className="input-icon">
							<img src={passwordIcon} alt="" />
						</div>
						<div className="input-password-wrapper">
							<input
								type={passwordInputType}
								maxLength={128}
								className="flex-one"
								placeholder="Password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<span onClick={togglePasswordInputType} aria-hidden="true" className="password-show-hide-toggle">
								{passwordInputType === 'password' ? 'Show' : 'Hide'}
							</span>
						</div>
					</div>

					<Link to="/forgot-password" className="text-purple">
						Forgot password?
					</Link>

					{needsRecaptcha && (
						<ReCAPTCHA
							className="g-recaptcha"
							ref={recaptchaRef}
							sitekey={recaptchaSiteKey}
							size="normal"
							onChange={(e) => setRecaptchaResponse(e)}
						/>
					)}

					<Button size="lg" block type="submit">
						SIGN IN
					</Button>
				</form>
			</div>
			{config.IS_IE && <OldBrowserDetected />}
			{showLoadingSpinner && <LoadingSpinner />}
		</div>
	);
};

export default withRouter(Login);

const OldBrowserDetected = () => {
	return (
		<div className="old-browser-alert">
			<h1>Upgrade Your Browser</h1>
			<p>
				Please upgrade your browser for an optimal experience & improved security. We recommend{' '}
				<a href="https://www.google.com/chrome/">Google Chrome</a>.
			</p>
		</div>
	);
};
