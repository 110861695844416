import xhr from './xhr';

const getInvitesByShiftId = async (shiftId) => {
	try {
		const { data } = await xhr.request('GET', `/caregivers-invite/shift/${shiftId}`);
		return data;
	} catch (error) {
		return null;
	}
};

const InviteCaregiverUtils = {
	getInvitesByShiftId
};

export default InviteCaregiverUtils;
