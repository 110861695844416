import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { showNotification } from '../../redux/notification';
import { user, loadUser } from '../../redux/user';
import { navigate } from '../../redux/navigate';

import { useDropdownsQuery } from '../../redux/dropdowns';
import { useUpdateFacilityContactMutation, unwrapError } from '../../redux/facilityContacts';
import { PRIVATE_PATH } from '../../constants';
import { PencilIcon } from '../../components/icons';

import RouterPrompt from '../../components/modals/BaseModal/RouterPrompt';
import SidebarWrapper from '../../sections/SidebarWrapper';
import ContactInformationSection from './ContactInformationSection';
import NotificationsSection from './NotificationsSection';

const AccountManagementScreen = () => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const history = useHistory();
	const userDataSelector = useSelector(user) || {};
	const navigateSelector = useSelector(navigate) || {};
	const [isLoading, setIsLoading] = useState(false);
	const { isLoading: isContactMethodsLoading, data: contactMethodOptions } = useDropdownsQuery('contact-methods');
	const { isLoading: isPhoneTypesLoading, data: phoneTypeOptions } = useDropdownsQuery('phone-types');
	const [updateFacilityContact, { isLoading: isSaving }] = useUpdateFacilityContactMutation();

	useEffect(() => {
		if (pathname === PRIVATE_PATH.ACCOUNT) {
			history.replace(PRIVATE_PATH.CONTACT_INFORMATION);
		}
	}, [pathname, history]);

	/* eslint-disable camelcase */
	const destructuredUserData = useMemo(
		() => ({
			facilityId: userDataSelector.activeFacility?.id,
			contactId: userDataSelector.activeFacility?.facility_contact_id,
			email: userDataSelector.email,
			contactMethodType: userDataSelector.facilityContactMethodCode,
			facilitiesEnabled: userDataSelector.facilitiesEnabled,
			contactName: userDataSelector.name,
			phoneExtensionNumber: userDataSelector.phone?.extension,
			phoneNumber: userDataSelector.phone?.number,
			contactPhoneType: userDataSelector.phone?.type_code,
			smsEnabled: userDataSelector.phone?.sms_enabled,
			smsOptIn: userDataSelector.phone?.sms_opt_in,
			sourceNumberFacilityContacts: userDataSelector.phone?.sourceNumberFacilityContacts
		}),
		[userDataSelector]
	);
	/* eslint-enable camelcase */

	const [defaultUserData, setDefaultUserData] = useState(destructuredUserData);
	const [userData, setUserData] = useState(destructuredUserData);
	const [showLoader, setShowLoader] = useState(false);
	const [allowEditContactInformation, setAllowEditContactInformation] = useState(false);
	const facilityOptions = (userData.facilitiesEnabled || []).map(({ label, id }) => ({ label, value: id }));

	useEffect(() => {
		setDefaultUserData(destructuredUserData);
		setUserData(destructuredUserData);
		setAllowEditContactInformation(false);
		setIsLoading(false);
	}, [userDataSelector, destructuredUserData]);

	useEffect(() => {
		setShowLoader(isSaving || isContactMethodsLoading || isPhoneTypesLoading || isLoading);
	}, [isSaving, isContactMethodsLoading, isPhoneTypesLoading, isLoading]);

	const sidebarItems = [
		{
			label: 'Contact Information',
			path: PRIVATE_PATH.CONTACT_INFORMATION,
			additionalAction: !allowEditContactInformation && (
				<span
					tabIndex={0}
					role="button"
					onKeyDown={() => setAllowEditContactInformation(true)}
					onClick={() => setAllowEditContactInformation(true)}
					className="edit-contact"
				>
					<PencilIcon />
					Edit
				</span>
			)
		},
		{ label: 'Notifications', path: PRIVATE_PATH.NOTIFICATIONS }
	];

	const saveChanges = useCallback(async () => {
		setIsLoading(true);
		try {
			await updateFacilityContact({
				contactId: userData.contactId,
				contact: {
					contactMethodType: userData.contactMethodType,
					contactName: userData.contactName,
					contactPhoneType: userData.contactPhoneType,
					phoneExtensionNumber: userData.phoneExtensionNumber,
					phoneNumber: userData.phoneNumber,
					smsEnabled: userData.smsEnabled
				}
			}).unwrap();
			await dispatch(loadUser());
			dispatch(showNotification('success', 'Your contact information was saved successfully.'));
		} catch (error) {
			dispatch(showNotification('error', unwrapError(error)));
		} finally {
			setIsLoading(false);
		}
	}, [updateFacilityContact, userData, dispatch]);

	return (
		<SidebarWrapper
			sidebarTitle="Manage Account"
			sidebarItems={sidebarItems}
			className="account-management"
			isLoading={showLoader}
		>
			<Switch>
				<Route path={PRIVATE_PATH.NOTIFICATIONS}>
					<NotificationsSection
						facilityOptions={facilityOptions}
						saveChanges={saveChanges}
						setAllowEditContactInformation={setAllowEditContactInformation}
						setUserData={setUserData}
						userData={userData}
					/>
				</Route>
				<Route path="/*">
					<ContactInformationSection
						allowEdit={allowEditContactInformation}
						contactMethodOptions={contactMethodOptions}
						defaultUserData={defaultUserData}
						dispatch={dispatch}
						facilityOptions={facilityOptions}
						phoneTypeOptions={phoneTypeOptions}
						saveChanges={saveChanges}
						setAllowEdit={setAllowEditContactInformation}
						setUserData={setUserData}
						userData={userData}
						facilityId={destructuredUserData.facilityId}
					/>
				</Route>
			</Switch>
			<RouterPrompt when={navigateSelector.preventNavigation} />
		</SidebarWrapper>
	);
};

export default AccountManagementScreen;
