import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
	name: 'error',
	initialState: {
		type: ''
	},
	reducers: {
		setError: (state, action) => {
			// eslint-disable-next-line no-param-reassign
			state.type = action.payload;
		}
	}
});

export const { setError } = slice.actions;

export const error = (state) => state.error;

export default slice.reducer;
