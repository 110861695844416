import React from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import ReactTooltip from 'react-bootstrap/Tooltip';

import './Tooltip.scss';

const renderTooltip = (label, props) => (
	<ReactTooltip
		bsClass="tooltip-component--overlay"
		className="tooltip-component--overlay"
		id="tooltip-component--overlay"
		// eslint-disable-next-line react/jsx-props-no-spreading
		{...props}
	>
		{label}
	</ReactTooltip>
);

const Tooltip = ({ children, className, label, placement = 'auto' }) => {
	return (
		<OverlayTrigger
			className="tooltip-component"
			placement={placement}
			delay={{ show: 100, hide: 400 }}
			overlay={(props) => renderTooltip(label, props)}
		>
			<div className={className}>{children}</div>
		</OverlayTrigger>
	);
};

export default Tooltip;
