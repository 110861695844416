import React from 'react';
import moment from 'moment-timezone';
import './ModalShiftDetails.css';

const ModalShiftDetails = ({ dateTime, resourceType, startTime, endTime, openShift }) => {
	const day = moment(dateTime).format('dddd');
	const date = moment(dateTime).format('MMM D, YYYY');
	return (
		<section className="shift-details-container modal-actions">
			<div className="shift-details-header">
				<h4>
					<span>Shift Details</span>
				</h4>
			</div>
			<div className="shift-details-body">
				<div className="shift-details-row">
					<p>
						Date: <span>{day}, </span>
						<b>{date}</b>
					</p>
					<p>
						Shift Type: <b>{resourceType}</b>
					</p>
				</div>
				<p>
					Time: <b>{`${startTime} - ${endTime}`}</b>
				</p>
				<p>
					Open Shifts: <b>{openShift}</b>
				</p>
			</div>
		</section>
	);
};

export default ModalShiftDetails;
