import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import LoadingSpinner from '../spinner/LoadingSpinner';
import checked from '../../assets/images/checked.svg';
import './UpdatedModal.css';

const UpdatedModal = (props) => {
	return (
		<Modal
			size={props.size || 'ms'}
			show
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className="confirmation-modal"
			onHide={props.onCancel}
		>
			<Modal.Body className={props.customModalBodyClass}>
				{!props.showLoadingSpinner ? (
					<div className="information">
						<div className="title">{props.title}</div>
						<img src={checked} alt="done" />
					</div>
				) : (
					<div className="spinner-wrapper">
						<LoadingSpinner key="loading-spinner" />
					</div>
				)}
			</Modal.Body>
			<Modal.Footer className="d-flex justify-content-center">
				<Button className="ok-button" onClick={props.onConfirm} variant="primary" disabled={props.showLoadingSpinner}>
					OK
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default UpdatedModal;
