import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import useMediaQuery, { MOBILE_WIDTH_QUERY } from '../hooks/useMediaQuery';
import { PlatinumIcon } from '../icons';
import { TIER } from '../../constants/Tiers';

import './TierBadge.scss';

const TierBadge = ({ caregiver, caregiverName }) => {
	const isMobile = useMediaQuery(MOBILE_WIDTH_QUERY);

	const { caregiverStats = null } = caregiver;
	let alt;
	let text;

	if (caregiverStats && caregiverStats.tier === TIER.PLATINUM) {
		alt = 'platinum tier badge';
		text = `
			 With a proven track record of high show rates and exceptional work performance,
			our Platinum Tier nurses deliver best-in-class care.
		`;
	}

	return alt && text ? (
		<>
			{caregiverName && (
				<OverlayTrigger
					placement={isMobile ? 'bottom' : 'right'}
					overlay={<Tooltip style={{ fontSize: '1.4rem' }}>{text}</Tooltip>}
				>
					<div className="mx-2">
						<PlatinumIcon />
					</div>
				</OverlayTrigger>
			)}
		</>
	) : null;
};

export default TierBadge;
