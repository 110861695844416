import React, { forwardRef, memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Highlighter, Typeahead } from 'react-bootstrap-typeahead';
import { error, setError } from '../../redux/error';
import { showNotification } from '../../redux/notification';
import { setCurrentFacility, user } from '../../redux/user';
import { setPreventNavigation, navigate } from '../../redux/navigate';
import useConditionalNavigation from '../hooks/useConditionalNavigation';
import { ERROR_CODES, FEATURE, PERMISSION, PRIVATE_PATH } from '../../constants';
import Utils from '../../utils/utils';
import xhr from '../../utils/xhr';
import ConfirmationModal from '../modals/ConfirmationModal';
import ViewOnComputerModal from '../modals/ViewOnComputerModal';
import { AccountManagementIcon, CaretIcon, SignOutIcon } from '../icons';
import RouterPrompt from '../modals/BaseModal/RouterPrompt';
import useMediaQuery, { MEDIUM_SCREEN_WIDTH_QUERY } from '../hooks/useMediaQuery';
import useOnClickOutside from '../hooks/useOnClickOutside';
import AppHeaderMobile from './AppHeaderMobile';
import AppHeaderDesktop from './AppHeaderDesktop';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import './UserSection.scss';

const AppHeader = ({ history }) => {
	const dispatch = useDispatch();
	const err = useSelector(error);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const userData = useSelector(user) || {};
	const navigateSelector = useSelector(navigate) || {};
	const dropdownRef = useRef(null);
	const dropdownMenuRef = useRef(null);
	const typeaheadRef = useRef(null);
	const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
	const [showMobileMenu, setShowMobileMenu] = useState(false);
	const [showViewOnComputerModal, setShowViewOnComputerModal] = useState(false);
	const activeFacility = userData.activeFacility || null;
	const [selectedFacility, setSelectedFacility] = useState(activeFacility);
	const [dropdownClick, setDropdownClick] = useState(false);
	const [showPrompt, setShowPrompt] = useState(false);
	const [tempFacility, setTempFacility] = useState(activeFacility);
	const navigateByRemovingSearchParams = useConditionalNavigation();
	const isMobile = useMediaQuery(MEDIUM_SCREEN_WIDTH_QUERY);
	const containerDesktopRef = useRef(null);
	const containerMobileRef = useRef(null);
	const toggleMenuRef = useRef(null);
	const [open, setOpen] = useState(false);

	const handleAccountManagementClick = useCallback(() => {
		setOpen(false);
		setShowMobileMenu(false);
		history.push(PRIVATE_PATH.ACCOUNT);
	}, [history]);

	const onLogOut = () => setShowLogoutConfirmation(true);

	const menuItems = [
		{ label: 'Manage Account', onClick: handleAccountManagementClick, icon: AccountManagementIcon },
		{ label: 'Sign Out', onClick: onLogOut, icon: SignOutIcon }
	];
	useOnClickOutside(containerDesktopRef, () => setOpen(false), 'mousedown');
	useOnClickOutside([containerMobileRef, toggleMenuRef], () => setShowMobileMenu(false), 'mousedown');

	const { shiftsToReview } = userData;
	const showFacility =
		activeFacility &&
		activeFacility.value &&
		activeFacility.label &&
		userData.facilitiesEnabled &&
		userData.facilitiesEnabled.length;

	const hideFacilitySearch = () => {
		if (dropdownRef.current && dropdownMenuRef.current) {
			dropdownRef.current.className = 'dropdown';
			dropdownMenuRef.current.className = 'dropdown-menu';
		}
	};

	useEffect(() => {
		hideFacilitySearch();
		setSelectedFacility(activeFacility);
	}, [activeFacility, userData]);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const urlFacilityId = urlParams.get('facilityId') || false;

		if (err.type === ERROR_CODES.FACILITY_NOT_PERMITTED && urlFacilityId) {
			navigateByRemovingSearchParams(['slotId', 'facilityId'], true);
			dispatch(setError(false));
		}
	}, [err.type, navigateByRemovingSearchParams, dispatch]);

	const switchFacility = (facility) => {
		navigateByRemovingSearchParams(['slotId', 'facilityId']);
		hideFacilitySearch();
		setSelectedFacility(facility);
		dispatch(setCurrentFacility(facility.id));
	};

	const handleFacilityChange = (facility) => {
		if (selectedFacility?.id !== facility.id) {
			if (!navigateSelector.preventNavigation) {
				switchFacility(facility);
			} else {
				setTempFacility(facility);
				setShowPrompt(true);
			}
			if (showMobileMenu) {
				setShowMobileMenu(false);
			}
		}
		setDropdownClick(!dropdownClick);
	};

	const onCancelNavigation = () => {
		setShowPrompt(false);
	};

	const onConfirmNavigation = () => {
		dispatch(setPreventNavigation(false));
		setShowPrompt(false);
		switchFacility(tempFacility);
	};

	const logout = async (errorType = null) => {
		try {
			if (errorType === '403' || !errorType) {
				await xhr.request('POST', '/logout');
			}
			window.location.href = '/login';
		} catch (e) {
			setShowLogoutConfirmation(false);
			dispatch(showNotification('error', 'Failed to logout. Please refresh the page!'));
		}
	};

	const handleNavBarNotOnMobileClick = (e) => {
		if (showMobileMenu) {
			e.preventDefault();
			setShowViewOnComputerModal(true);
		}
	};

	const handleCloseViewOnComputerModal = () => {
		setShowViewOnComputerModal(false);
		setShowMobileMenu(false);
		history.push('/private/shifts');
	};

	const getSortedFacilitiesEnabled = useCallback((facilities) => {
		const groups = facilities.reduce(
			(acc, curr) => {
				if (curr.pendingConfirmations) {
					acc.pending.push(curr);
				} else {
					acc.standard.push(curr);
				}

				return acc;
			},
			{ pending: [], standard: [] }
		);

		const sortByAlpha = (a, b) => a.label.localeCompare(b.label);

		return [...groups.pending.sort(sortByAlpha), ...groups.standard.sort(sortByAlpha)];
	}, []);
	const isPastShiftsAllowed = Utils.isAuthorizedExact(PERMISSION.PAST_SHIFTS, userData.permissions);
	const isDisputesAllowed = Utils.isAuthorizedExact(PERMISSION.DISPUTES, userData.permissions);
	const hasProfessionalsFeature =
		(Utils.isFeatureEnabled(FEATURE.PROFESSIONALS, userData.features) &&
			Utils.isAuthorizedExact(PERMISSION.PROFESSIONALS, userData.permissions)) ||
		(Utils.isFeatureEnabled(FEATURE.FLEX_PROFESSIONALS, userData.features) &&
			Utils.isAuthorizedExact(PERMISSION.INTERNAL_STAFF, userData.permissions));
	const hasSettingsAccess = Utils.isAuthorizedSection(PERMISSION.SETTINGS, userData.permissions);

	const CustomToggle = React.forwardRef(({ children, onClick, 'aria-expanded': isOpen }, ref) => (
		<button
			type="button"
			className="toggle"
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			<span className="toggle-content">{children}</span>
			<CaretIcon pointTo={isOpen ? 'up' : 'down'} className="toggle__caret" />
		</button>
	));

	const CustomMenu = forwardRef(({ style, className, 'aria-labelledby': labeledBy, onOptionChange }, ref) => {
		return (
			<div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
				<Typeahead
					id="facility-enabled-search-typeahead"
					ref={typeaheadRef}
					clearButton
					defaultOpen
					placeholder="Search"
					options={getSortedFacilitiesEnabled(userData.facilitiesEnabled)}
					onChange={onOptionChange}
					renderMenuItemChildren={(option, props) => (
						<div className={classNames('text', { selected: selectedFacility?.id === option.id })}>
							<span>
								<Highlighter search={props.text}>{option.label}</Highlighter>
							</span>

							{!!option.pendingConfirmations && (
								<div className="badge badge-pill badge-danger pending-confirmation">{option.pendingConfirmations}</div>
							)}
						</div>
					)}
				/>
			</div>
		);
	});
	const firstUpdate = useRef(true);
	useLayoutEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}
		const hiddenClick = document.querySelector('.toggle');
		hiddenClick.click();
		hideFacilitySearch();
	}, [dropdownClick]);

	const showPastShiftsCircle =
		isDisputesAllowed &&
		isPastShiftsAllowed &&
		shiftsToReview > 0 &&
		!history.location.pathname.includes(PRIVATE_PATH.PAST_SHIFTS);

	const permissionsToPaths = [
		{
			permission: PERMISSION.PROFESSIONALS,
			path: PRIVATE_PATH.PROFESSIONALS_ON_DEMAND,
			feature: FEATURE.PROFESSIONALS
		},
		{
			permission: PERMISSION.INTERNAL_STAFF,
			path: PRIVATE_PATH.PROFESSIONALS_INTERNAL_STAFF,
			feature: FEATURE.FLEX_PROFESSIONALS
		}
	];
	const professionalsPath =
		permissionsToPaths.find((path) => {
			return (
				Utils.isAuthorizedExact(path.permission, userData.permissions) &&
				Utils.isFeatureEnabled(path.feature, userData.features)
			);
		})?.path || '';

	const mainMenuContent = () => {
		if (isMobile) {
			// mobile view
			return (
				<AppHeaderMobile
					key="app-header-mobile"
					showFacility={showFacility}
					userData={userData}
					dropdownRef={dropdownRef}
					typeaheadRef={typeaheadRef}
					CustomToggle={CustomToggle}
					selectedFacility={selectedFacility}
					dropdownMenuRef={dropdownMenuRef}
					CustomMenu={CustomMenu}
					hideFacilitySearch={hideFacilitySearch}
					handleFacilityChange={handleFacilityChange}
					activeFacility={activeFacility}
					isPastShiftsAllowed={isPastShiftsAllowed}
					setShowMobileMenu={setShowMobileMenu}
					handleNavBarNotOnMobileClick={handleNavBarNotOnMobileClick}
					showPastShiftsCircle={showPastShiftsCircle}
					shiftsToReview={shiftsToReview}
					hasProfessionalsFeature={hasProfessionalsFeature}
					hasSettingsAccess={hasSettingsAccess}
					showMobileMenu={showMobileMenu}
					containerMobileRef={containerMobileRef}
					handleAccountManagementClick={handleAccountManagementClick}
					onLogOut={onLogOut}
					toggleMenuRef={toggleMenuRef}
					professionalsPath={professionalsPath}
				/>
			);
		}

		// desktop
		return (
			<AppHeaderDesktop
				key="app-header-desktop"
				showFacility={showFacility}
				userData={userData}
				dropdownRef={dropdownRef}
				typeaheadRef={typeaheadRef}
				CustomToggle={CustomToggle}
				selectedFacility={selectedFacility}
				dropdownMenuRef={dropdownMenuRef}
				CustomMenu={CustomMenu}
				hideFacilitySearch={hideFacilitySearch}
				handleFacilityChange={handleFacilityChange}
				activeFacility={activeFacility}
				isPastShiftsAllowed={isPastShiftsAllowed}
				setShowMobileMenu={setShowMobileMenu}
				handleNavBarNotOnMobileClick={handleNavBarNotOnMobileClick}
				showPastShiftsCircle={showPastShiftsCircle}
				shiftsToReview={shiftsToReview}
				hasProfessionalsFeature={hasProfessionalsFeature}
				hasSettingsAccess={hasSettingsAccess}
				containerDesktopRef={containerDesktopRef}
				open={open}
				setOpen={setOpen}
				menuItems={menuItems}
				professionalsPath={professionalsPath}
			/>
		);
	};

	return [
		mainMenuContent(),

		showMobileMenu && (
			<div className="sidebar-backdrop" key="show-mobile-menu" onClick={() => setShowMobileMenu(false)} aria-hidden />
		),

		showViewOnComputerModal && <ViewOnComputerModal onCancel={handleCloseViewOnComputerModal} key="view-on-pc" />,

		showLogoutConfirmation && (
			<ConfirmationModal
				key="logout-confirmation"
				onConfirm={() => logout()}
				onCancel={() => setShowLogoutConfirmation(false)}
			>
				Are you sure you want to log out?
			</ConfirmationModal>
		),

		(err.type === '403' || err.type === 'Error') && (
			<ConfirmationModal
				key="reload-confirmation"
				title="Session Expired"
				confirmLabel="GO TO LOGIN"
				showCancel={false}
				onConfirm={() => logout(err.type)}
				onCancel={() => logout(err.type)}
			>
				Your session has expired. Please log in again to continue working.
			</ConfirmationModal>
		),
		showPrompt && (
			<RouterPrompt.BasePrompt
				key="facility-switch-prompt"
				showPrompt={showPrompt}
				handleCancel={onCancelNavigation}
				handleOK={onConfirmNavigation}
			/>
		)
	];
};

export default withRouter(memo(AppHeader));
