import moment from 'moment-timezone';
import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ArrowLeft from '../../assets/images/arrow-left-black.svg';
import ArrowRight from '../../assets/images/arrow-right-black.svg';
import DatepickerIcon from '../../assets/images/datepicker.svg';
import dropdownArrow from '../../assets/images/dropdown-arrow-new-purple.svg';
import alertIconImg from '../../assets/images/alert-icon.svg';

import exportFile from '../../assets/images/file-download.svg';
import guaranteed from '../../assets/images/guaranteed-icon.svg';
import utils from '../../utils/utils';
import { CAREGIVER_ROLE } from '../../constants';

import './PastShiftsScreen.scss';
import '../../components/DatePicker/DatePicker.scss';

const ERROR_MESSAGES = {
	longerThanSixteenHours: 'Shift is over 16 hours',
	shorterThanTwoHours: 'Shift is under 2 hours',
	checkOutTime: 'Clock out does not exist',
	checkInOut: 'Clock in & out do not exist',
	checkInTime: 'Clock in does not exist',
	checkoutOverAMile: 'Please review the clock out time',
	canceledGuaranteedShift: 'Canceled Guaranteed Shift'
};

const PastShiftsName = (props) => {
	const { name, slotId, slotClaimRole, attachmentId } = props;
	return (
		<div className="d-flex align-items-center">
			<div className="resource-name">
				{name}
				{slotClaimRole?.code === CAREGIVER_ROLE.EXTERNAL && (
					<div className="worker-badge-wrapper" key="caregiver-external-worker">
						<div className="external-worker-badge">EXTERNAL WORKER</div>
					</div>
				)}
			</div>
			{attachmentId && (
				<OverlayTrigger
					placement="top"
					trigger={['hover', 'focus']}
					overlay={
						<Tooltip delay={{ show: 150, hide: 400 }} id="credential-packet-tooltip" className="packet-tooltip">
							Download Credential packet
						</Tooltip>
					}
				>
					<img
						src={exportFile}
						alt="download file"
						className="download-file"
						onClick={() => utils.openAttachment(slotId)}
						aria-hidden
					/>
				</OverlayTrigger>
			)}
		</div>
	);
};

const PastShiftsClock = (props) => {
	const {
		checkIn,
		checkOut,
		facilityTimezone,
		checkoutOverAMile,
		shorterThanTwoHours,
		longerThanSixteenHours,
		slotError,
		startTime,
		endTime,
		isApproved,
		showTextAsError
	} = props;

	let checkInTime = null;
	let checkOutTime = null;

	checkInTime = isApproved && !checkIn ? startTime : checkIn;
	checkOutTime = isApproved && !checkOut ? endTime : checkOut;

	const start = `${checkInTime ? moment.tz(checkInTime, facilityTimezone).format('hh:mm A') : '______ AM '}`;
	const end = `${checkOutTime ? moment.tz(checkOutTime, facilityTimezone).format('hh:mm A') : '______ PM '}`;
	return (
		<>
			<span
				className={classNames({
					'error-slot': showTextAsError && slotError && (!checkIn || shorterThanTwoHours || longerThanSixteenHours)
				})}
			>
				{start}
			</span>
			<span
				className={classNames({
					'error-slot':
						showTextAsError && slotError && ((!checkIn && !checkOut) || shorterThanTwoHours || longerThanSixteenHours)
				})}
			>
				{' '}
				-{' '}
			</span>
			<span
				className={classNames({
					'error-slot':
						showTextAsError &&
						(!checkOut || checkoutOverAMile || shorterThanTwoHours || longerThanSixteenHours || slotError)
				})}
			>
				{end}
			</span>
		</>
	);
};

const PastShiftsTotal = (props) => {
	const {
		totalWage,
		isGuaranteed,
		slotError,
		showTextAsError,
		isFacilityBillableCancellation,
		hideTotalAmount
	} = props;
	const _totalWage = hideTotalAmount ? '-' : `$${Number(totalWage).toFixed(2)}`;
	return (
		<>
			<span className={classNames({ 'error-slot': showTextAsError && (isFacilityBillableCancellation || slotError) })}>
				{showTextAsError ? `$ ____` : _totalWage}
			</span>
			{isGuaranteed && <img className="guaranteed-badge" src={guaranteed} alt="is guaranteed" />}
		</>
	);
};

const PastShiftDatePicker = (props) => {
	const {
		periodStart,
		periodEnd,
		onPreviousPeriod,
		onNextPeriod,
		periodIndex,
		dayFilter,
		datepickerRef,
		onSelectPeriod,
		maxDate,
		minDate,
		disablePast
	} = props;

	const DatepickerInput = forwardRef((forwardProps, ref) => (
		<div aria-hidden className="datepicker-input" ref={ref} onClick={forwardProps.onClick}>
			<img src={DatepickerIcon} alt="Previous period" className="datepicker-icon" />
			<input disabled value={forwardProps.selectedDate.format('MM/DD/YYYY')} />
			<img src={dropdownArrow} alt="Datepicker" className="datepicker-arrow" />
		</div>
	));

	return (
		<div className="shifts-period">
			<img
				aria-hidden
				src={ArrowLeft}
				alt="Previous period"
				className={classNames('shifts-period-nav', { disabled: disablePast })}
				onClick={onPreviousPeriod}
			/>
			<div className="nav-text">
				<img src={DatepickerIcon} alt="Previous period" className="nav-calendar" />
				<span>{periodStart.format('MM/DD/YYYY')}</span>
				<span className="dates-separate">&nbsp;&nbsp;to&nbsp;&nbsp;</span>
				<DatePicker
					customInput={<DatepickerInput selectedDate={periodEnd} ref={datepickerRef} />}
					dateFormat="MMMM dd yyyy"
					selected={moment(periodEnd).subtract(1, 'days').toDate()}
					maxDate={maxDate || new Date()}
					minDate={minDate}
					filterDate={dayFilter}
					onChange={onSelectPeriod}
				/>
			</div>
			<img
				aria-hidden
				src={ArrowRight}
				alt="Next period"
				className={classNames({ disabled: periodIndex === 2 }, 'shifts-period-nav')}
				onClick={onNextPeriod}
			/>
		</div>
	);
};

const TooltipDescription = ({ name, date, message }) => {
	return (
		<div className="slot-tooltip-info">
			{name && <p>{name === 'Admin ' ? 'Auto Approved' : `Submitted by: ${name}`}</p>}
			{date && <p>Date: {date}</p>}
			{message && <p>{message}</p>}
		</div>
	);
};

const PastShiftsActions = (props) => {
	const {
		confirmAction,
		disputeAction,
		isApproved,
		approvedDate,
		approvedBy,
		isDisputed,
		disputeCreatedDate,
		disputeCreatedBy,
		effectiveDateApprovalsDisputes,
		isApprovalDisputeAllowed
	} = props;

	const getApprovalTooltip = (_isApproved, _isApprovalDisputeAllowed) => {
		let approvalTooltip = <TooltipDescription message="Approve shift" />;
		if (_isApproved) {
			approvalTooltip = <TooltipDescription date={approvedDate} name={approvedBy} />;
		}
		if (!_isApprovalDisputeAllowed) {
			approvalTooltip = (
				<TooltipDescription
					message={`Approvals can not be submitted on shifts that started before ${effectiveDateApprovalsDisputes} (EST)`}
				/>
			);
		}

		return approvalTooltip;
	};

	const getDisputeTooltip = (_isApproved, _isApprovalDisputeAllowed, _isDisputed) => {
		let disputeTooltip = '';
		if (_isApprovalDisputeAllowed) {
			if (!_isApproved && _isDisputed) {
				disputeTooltip = <TooltipDescription date={disputeCreatedDate} name={disputeCreatedBy} />;
			} else {
				disputeTooltip = <TooltipDescription message="Dispute shift" />;
			}
		} else {
			disputeTooltip = (
				<TooltipDescription
					message={`Disputes can not be submitted on shifts that started before ${effectiveDateApprovalsDisputes} (EST)`}
				/>
			);
		}

		return disputeTooltip;
	};

	return (
		<div className="dispute-actions">
			<div>
				<OverlayTrigger
					placement="top"
					trigger={['hover', 'focus']}
					overlay={
						<Tooltip
							id="dispute-tooltip"
							delay={{ show: 150, hide: 400 }}
							aria-haspopup="true"
							className="dispute-tooltip"
						>
							{getApprovalTooltip(isApproved, isApprovalDisputeAllowed)}
						</Tooltip>
					}
				>
					<svg
						className={classNames(`past-shift-action past-shift-approve`, {
							active: isApproved,
							disabled: !isApprovalDisputeAllowed || isApproved || isDisputed
						})}
						width="39"
						height="39"
						viewBox="0 0 39 39"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						onClick={!isApprovalDisputeAllowed || isApproved || isDisputed ? () => {} : confirmAction}
					>
						<circle cx="19.0283" cy="19.6838" r="18.5" fill="white" stroke="#54BE7A" />
						<rect width="21.28" height="21.28" transform="translate(8.38843 9.04395)" />
						<path
							d="M16.1867 23.1197L12.4893 19.4223L11.2302 20.6725L16.1867 25.629L26.8267 14.989L25.5765 13.7388L16.1867 23.1197Z"
							fill="#54BE7A"
						/>
					</svg>
				</OverlayTrigger>
				<OverlayTrigger
					placement="top"
					trigger={['hover', 'focus']}
					overlay={
						<Tooltip id="dispute-tooltip" delay={{ show: 150, hide: 400 }} className="dispute-tooltip">
							{getDisputeTooltip(isApproved, isApprovalDisputeAllowed, isDisputed)}
						</Tooltip>
					}
				>
					<svg
						className={classNames(`past-shift-action past-shift-dispute`, {
							active: isDisputed,
							disabled: !isApprovalDisputeAllowed || isApproved || isDisputed
						})}
						width="39"
						height="39"
						viewBox="0 0 39 39"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						alt=""
						aria-hidden
						onClick={!isApprovalDisputeAllowed || isDisputed || isApproved ? () => {} : disputeAction}
					>
						<circle cx="19.9771" cy="19.0374" r="18.5" fill="white" stroke="#B32929" />
						<path
							fill="#B32929"
							d="M26.1838 14.081L24.9336 12.8308L19.9772 17.7873L15.0207 12.8308L13.7705 14.081L18.727 19.0375L13.7705 23.9939L15.0207 25.2441L19.9772 20.2877L24.9336 25.2441L26.1838 23.9939L21.2274 19.0375L26.1838 14.081Z"
						/>
					</svg>
				</OverlayTrigger>
			</div>
		</div>
	);
};

const ResolvedDisputeTooltip = ({
	disputeCreatedBy,
	disputeCreatedDate,
	disputeResolvedBy,
	disputeResolvedDate,
	disputeResolution,
	isInvoiceable
}) => {
	return (
		<div className="dispute-actions">
			<OverlayTrigger
				placement="top"
				overlay={
					<Tooltip className="dispute-tooltip" id="dispute-tooltip">
						<div className="slot-tooltip-info">
							<p>Dispute submitted by: {disputeCreatedBy} </p>
							<p>Date of dispute: {disputeCreatedDate}</p>
							<p>Resolved by: {disputeResolvedBy}</p>
							<p>Resolved date: {disputeResolvedDate}</p>
							<p>Resolution: {disputeResolution}</p>
							<p>Invoiceable: {isInvoiceable ? 'Yes' : 'No'}</p>
						</div>
					</Tooltip>
				}
			>
				<span className="resolved-dispute">Resolved</span>
			</OverlayTrigger>
		</div>
	);
};

const AlertIcon = (props) => {
	const {
		hasDisputesPermission,
		slotError,
		checkoutOverAMile,
		longerThanSixteenHours,
		shorterThanTwoHours,
		checkInTime,
		checkOutTime,
		isApproved,
		isDisputed,
		isGuaranteed,
		isCancelled
	} = props;

	const errorMessageString =
		(isGuaranteed && isCancelled && 'canceledGuaranteedShift') ||
		(checkoutOverAMile && 'checkoutOverAMile') ||
		(longerThanSixteenHours && 'longerThanSixteenHours') ||
		(shorterThanTwoHours && 'shorterThanTwoHours') ||
		(!checkInTime && !checkOutTime && 'checkInOut') ||
		(!checkInTime && 'checkInTime') ||
		(!checkOutTime && 'checkOutTime');

	const overlayMessage = ERROR_MESSAGES[errorMessageString];
	return (
		(!isApproved && !isDisputed && hasDisputesPermission && slotError && overlayMessage && (
			<OverlayTrigger
				placement="top"
				trigger={['hover', 'focus']}
				overlay={
					<Tooltip
						id="dispute-tooltip"
						delay={{ show: 150, hide: 400 }}
						aria-haspopup="true"
						className="dispute-tooltip"
					>
						{overlayMessage}
					</Tooltip>
				}
			>
				<img src={alertIconImg} alt="slot with error" />
			</OverlayTrigger>
		)) ||
		null
	);
};

export {
	PastShiftsName,
	PastShiftsClock,
	PastShiftsTotal,
	PastShiftDatePicker,
	PastShiftsActions,
	AlertIcon,
	ResolvedDisputeTooltip
};
