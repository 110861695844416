import React from 'react';
import { Modal } from 'react-bootstrap';
import warningImg from '../../assets/images/warning-image.png';
import Button from '../button/Button';

import './PastShiftWarningModal.scss';

const PastShiftWarningModal = (props) => {
	return (
		<Modal
			size={props.size || 'sm'}
			key="warning-past-shift-modal"
			show
			aria-labelledby="contained-modal-title-vcenter"
			centered
			scrollable={!!props.scrollable}
			className="warning-past-shift-modal"
			onHide={props.onClose}
		>
			<Modal.Header closeButton />
			<Modal.Body>
				<div className="container">
					<img src={warningImg} alt="warning" />
					<div className="text-info-past">
						<h2>Still Want to Approve?</h2>
						<p>Are you sure you want to approve this shift with missing data?</p>
						<p>(Any missing data will be auto-populated to the shift’s start and end time)</p>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button exactPermission="PORTAL.EDIT" className="button-approve" onClick={props.onConfirm} title="APPROVE" />
				<Button exactPermission="PORTAL.EDIT" className="button-cancel" onClick={props.onClose} title="CANCEL" />
			</Modal.Footer>
		</Modal>
	);
};

export default PastShiftWarningModal;
