import React from 'react';
import close from '../../assets/images/close-black.svg';

const BadgesSection = ({ optionsSelected = [], onCheckboxChange = () => {}, onClear = () => {} }) => (
	<>
		<hr />
		<div className="badges-section">
			{optionsSelected.map((option) => (
				<div className="badge" key={`badge-${option.id}`}>
					<span>{option.label}</span>
					<img aria-hidden src={close} alt="close badge" onClick={() => onCheckboxChange(option.id)} />
				</div>
			))}
		</div>
		<span aria-hidden onClick={onClear} className="clear-badges">
			Clear All
		</span>
	</>
);

export default BadgesSection;
