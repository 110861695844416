import React from 'react';

const ProfessionalsList = ({ showLoadingSpinner, filteredCaregivers, Counter, CaregiverCard }) => {
	return (
		!showLoadingSpinner &&
		filteredCaregivers && (
			<>
				{Counter}
				{filteredCaregivers.map((caregiver) => (
					<CaregiverCard key={`caregiver-card-${caregiver.id}`} caregiver={caregiver} />
				))}
			</>
		)
	);
};

export default ProfessionalsList;
