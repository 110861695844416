/* eslint-disable react/self-closing-comp */
/* eslint-disable camelcase */
/* eslint-disable func-names */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import InformationIcon from '../../assets/images/bonus-info-icon.svg';
import PageTitle from '../../sections/PageTitle';
import {
	PastShiftsName,
	PastShiftsClock,
	PastShiftsTotal,
	PastShiftDatePicker,
	PastShiftsActions,
	ResolvedDisputeTooltip,
	AlertIcon
} from './PastShiftsCellComponents';
import { showNotification } from '../../redux/notification';
import Utils from '../../utils/utils';
import {
	pastShifts,
	setDatePastShifts,
	loadPastShifts,
	approvePastShiftSlot,
	getPastShiftsDates,
	shouldShowLoadingSpinner
} from '../../redux/pastShifts';
import { applyFilters } from '../../utils/FilterFunctions';
import LateCancellationBadge from '../../components/badges/LateCancellation';
import FacilityCancelledBadge from '../../components/badges/FacilityCancelled';
import { user, loadUser } from '../../redux/user';
import './Shifts.css';
import './PastShiftsScreen.scss';
import '../../components/DatePicker/DatePicker.scss';
import ShiftDisputeModal from '../../components/modals/ShiftDisputeModal/ShiftDisputeModal';
import ConfirmationModal from '../../components/modals/ConfirmationModal';
import PastShiftWarningModal from '../../components/modals/PastShiftWarningModal';
import LoadingSpinner from '../../components/spinner/LoadingSpinner';
import PastShiftBadge from '../../components/badges/PastShiftBadge';
import WithOnlyOnDesktop from '../../components/onlyOnDesktop/withOnlyOnDesktop';
import FilterShifts from './FilterShifts/FilterShifts';
import useFilters from '../../hooks/useFilters';
import useShiftSlots from '../../hooks/useShiftSlots';
import { PAST_SHIFTS } from './FilterShifts/AvailableFilters';

import TooltipComponent from '../../components/Tooltip/Tooltip';
import UnitDetails from './UnitAssignment/UnitDetails';
import ContractBadge from './ContractBadge';

const HIDDEN_CELL_INFO = ' - ';

function PastShiftsScreen() {
	const userData = useSelector(user);
	const showLoadingSpinner = useSelector(shouldShowLoadingSpinner);
	const pastShiftsDatesStore = useSelector(getPastShiftsDates);
	const dispatch = useDispatch();

	const datepickerRef = React.createRef();
	const weekAutoApproved = useRef(false);
	const [previousPeriodIndex, setPreviousPeriodIndex] = useState(1);
	const [showDisputesModal, setShowDisputesModal] = useState(false);
	const [warningModal, setWarningModal] = useState(false);
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [slotIdToApprove, setSlotIdToApprove] = useState(null);
	const [activeDisputedShift, setActiveDisputedShift] = useState(null);
	const [hasDisputesPermission, setHasDisputesPermission] = useState(
		Utils.isAuthorizedExact('PORTAL.DISPUTES', userData.permissions)
	);
	const [periodEnd, setPeriodEnd] = useState(moment.tz(userData.pastShiftsWeek.end, userData.activeFacility.timezone));
	const [periodStart, setPeriodStart] = useState(
		moment.tz(userData.pastShiftsWeek.start, userData.activeFacility.timezone)
	);
	const { filters, setFilters } = useFilters();

	const { getCaregiversNamesByShiftSlots, assignedCaregiversByShiftSlots, feedShiftStructures } = useShiftSlots();

	const _periodStart = (_periodIndex) =>
		moment.tz(userData.pastShiftsWeek.start, userData.activeFacility.timezone).day(7 * _periodIndex - 1);
	const _periodEnd = (_periodIndex) =>
		moment
			.tz(userData.pastShiftsWeek.start, userData.activeFacility.timezone)
			.day(7 * _periodIndex + 5)
			.endOf('day');

	const pastShiftsData = useSelector(pastShifts);

	const [pastShiftFeed, setPastShiftFeed] = useState(pastShiftsData);

	const indexToPutSeparator = useMemo(
		() => pastShiftFeed.findIndex((shift) => shift.is_disputed || shift.is_approved),
		[pastShiftFeed]
	);

	const dispatchNewDates = (_previousPeriodIndex, _startDate, _endDate) => {
		const storedStartDate = pastShiftsDatesStore.startDate;
		const storedEndDate = pastShiftsDatesStore.endDate;
		const newStartDate = _startDate || _periodStart(_previousPeriodIndex);
		const newEndDate = _endDate || _periodEnd(_previousPeriodIndex);
		if (storedStartDate === newStartDate && storedEndDate === newEndDate) {
			return;
		}
		setPeriodStart(newStartDate);
		setPeriodEnd(newEndDate);
		dispatch(setDatePastShifts(newStartDate.format('YYYY-MM-DD'), newEndDate.format('YYYY-MM-DD')));
	};

	const reloadFeed = () => {
		dispatch(loadPastShifts());
	};

	const onPreviousPeriod = () => {
		// If index (which expresses weeks) is greater than the months range allowed, then prevent going back.
		// Doing * 4 since config value is in months.
		if (Math.abs(previousPeriodIndex) + 1 > userData.pastShiftsMonthsRange * 4) {
			dispatch(showNotification('error', `Past shifts range limit is ${userData.pastShiftsMonthsRange} months`));
			return;
		}
		setPreviousPeriodIndex(previousPeriodIndex - 1);
		dispatchNewDates(previousPeriodIndex - 1);
	};

	const onNextPeriod = () => {
		if (previousPeriodIndex < 1) {
			setPreviousPeriodIndex(previousPeriodIndex + 1);
			dispatchNewDates(previousPeriodIndex + 1);
		}
	};

	const onSelectPeriod = (value) => {
		let delta = moment.utc(value).startOf('day').diff(periodEnd.startOf('day'), 'days') / 7;
		if (delta < 0) {
			delta = Math.floor(delta);
		} else {
			delta = Math.ceil(delta);
		}
		setPreviousPeriodIndex(previousPeriodIndex + delta);
		dispatchNewDates(previousPeriodIndex + delta);
	};

	const isFriday = (date) => date.getDay() === 5;
	const minDate = moment.utc().subtract(userData.pastShiftsMonthsRange, 'months').day(12).toDate(); // .day(12) goes to next friday

	useEffect(() => {
		setHasDisputesPermission(Utils.isAuthorizedExact('PORTAL.DISPUTES', userData.permissions));
	}, [userData]);

	const approveModal = (slotError, slotId) => {
		if (slotError) {
			setWarningModal(true);
		} else {
			setConfirmationModal(true);
		}
		setSlotIdToApprove(slotId);
	};

	const reviewsNeeded = pastShiftsData.reduce((total, { is_approved, is_disputed }) => {
		if (!is_disputed && !is_approved) {
			return total + 1;
		}
		return total;
	}, 0);

	const approveOnConfirm = async (slotId, type) => {
		// if the user approved then checkForAutoApprove should not work
		weekAutoApproved.current = true;
		dispatch(approvePastShiftSlot(slotId));
		if (type === 'confirmationModal') {
			setConfirmationModal(false);
		} else {
			setWarningModal(false);
		}
		reloadFeed();
	};

	const disputeShift = (shift) => {
		// if the user disputes then checkForAutoApprove should not work
		weekAutoApproved.current = true;
		setActiveDisputedShift(shift);
		setShowDisputesModal(true);
	};

	useEffect(() => {
		if (Utils.isAuthorizedExact('PORTAL.SHIFTS.PAST', userData.permissions)) {
			// Prevent triggering new requests if facility has changed and permission to see past shifts isn't there anymore
			dispatch(loadUser());
			if (!weekAutoApproved.current) {
				dispatchNewDates(previousPeriodIndex, periodStart, periodEnd);
			}
		}
		return () => {
			weekAutoApproved.current = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // no need to declare dependency

	const closeDisputeModalAndReload = () => {
		setShowDisputesModal(false);
		dispatch(loadUser());
	};

	const datePickerMaxDate = Utils.getPayrollFromDate(moment.utc()).toDate();

	useEffect(() => {
		if (pastShiftsData) {
			const filteredShifts = applyFilters({ items: pastShiftsData, filters });
			getCaregiversNamesByShiftSlots(filteredShifts);
			setPastShiftFeed(filteredShifts);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pastShiftsData, filters]);

	return [
		showLoadingSpinner && (
			<div className="spinner-wrapper" key="spinner-wrapper-key">
				<LoadingSpinner key="loading-spinner" />
			</div>
		),
		<PageTitle title="Past Shifts" key="page-title-past-shifts" hasDisputesPermission={hasDisputesPermission} />,
		<WithOnlyOnDesktop>
			<div className="past-shifts-page" key="past-shift-data-table-container">
				<div className="past-shift-data-table">
					<div className="picker-and-message">
						<PastShiftDatePicker
							periodStart={periodStart}
							periodEnd={periodEnd}
							onSelectPeriod={onSelectPeriod}
							onPreviousPeriod={onPreviousPeriod}
							onNextPeriod={onNextPeriod}
							periodIndex={previousPeriodIndex}
							datepickerRef={datepickerRef}
							dayFilter={isFriday}
							minDate={minDate}
							maxDate={datePickerMaxDate}
							disablePast={Math.abs(previousPeriodIndex) + 1 > userData.pastShiftsMonthsRange * 4}
						/>
						{hasDisputesPermission ? (
							<div className="reviews">{`${reviewsNeeded} Review${reviewsNeeded !== 1 ? 's' : ''} Needed`}</div>
						) : (
							<>
								<div className="message">
									If something is missing or incorrect, please <br />
									email us at shiftmedstaffing@shiftmed.com
								</div>
								<OverlayTrigger
									placement="bottom"
									overlay={
										<Tooltip id="pastShiftsInformation" className="past-shifts-tooltip">
											To help us better address the issue, please include in your email:
											<ul>
												<li>Health care provider’s name</li>
												<li>Shift Date & Time</li>
												<li>Any information to update </li>
											</ul>
										</Tooltip>
									}
								>
									<img src={InformationIcon} className="information-icon" alt="Information icon" />
								</OverlayTrigger>
							</>
						)}
					</div>

					<FilterShifts
						caregivers={assignedCaregiversByShiftSlots}
						filters={filters}
						setFilters={setFilters}
						shiftStructures={feedShiftStructures}
						availableFilters={PAST_SHIFTS}
					/>
					<table>
						<thead className="past-shift-separator header-separator">
							<tr className="past-shift-table-header justify-content-between">
								{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
								<th className="table-col-icon"></th>
								<th className="h4 header-title table-col">NAME</th>
								<th className="h4 header-title table-col-small">SHIFT ASSIGNMENT</th>
								<th className="h4 header-title table-col-big">DATE AND TIME</th>
								<th className="h4 header-title table-col">CLOCK IN/OUT</th>
								{hasDisputesPermission && <th className="h4 header-title table-col-small">RATE</th>}
								<th className="h4 header-title table-col-small">BONUS</th>
								<th className="h4 header-title table-col-small">TOTAL HRS</th>
								{hasDisputesPermission && <th className="h4 header-title table-col-small total-pay">TOTAL</th>}
								{hasDisputesPermission && <th className="h4 header-title table-col-small actions">ACTIONS</th>}
							</tr>
						</thead>
						<tbody>
							{pastShiftFeed.map((shift, index) => {
								const {
									caregiver_name,
									start_time,
									start_time_at_utc,
									end_time_at_utc,
									end_time,
									facility_payable_bonus,
									slot_id,
									slot_claim_role,
									facility_price,
									total_rate,
									is_guaranteed,
									total_hours,
									check_in_time,
									check_out_time,
									attachment_id,
									facility_timezone,
									is_disputed,
									dispute_created_date,
									dispute_resolved_by,
									dispute_created_by,
									slot_error,
									slot_approved_by,
									slot_approved_date,
									is_facility_billable_cancellation,
									is_approved,
									checkout_over_a_mile,
									longer_than_sixteen_hours,
									shorter_than_two_hours,
									finance_checkin_time,
									finance_checkout_time,
									is_dispute_resolved,
									is_approval_allowed,
									dispute_resolution_date,
									resolution_in_ops,
									is_invoiceable,
									is_cancelled,
									outcome_code,
									resource_type,
									contract_id,
									contract_non_cancelled_slots_count
								} = shift;

								let totalHours = total_hours;
								let totalRate = total_rate;
								let showTextAsError = false;
								let facilityPayableBonus = facility_payable_bonus;
								let facilityPrice = Utils.roundWithPrecision(facility_price, 2);
								let hideTotalAmount = false;

								const startDate = moment.tz(start_time_at_utc, facility_timezone).format('ddd, MMM D');

								if ((!check_in_time || !check_out_time) && !is_facility_billable_cancellation && !is_guaranteed) {
									showTextAsError = true;
								}
								if (is_approved || is_dispute_resolved || is_disputed) {
									showTextAsError = false;
								}

								if (is_approved && (!check_in_time || !check_out_time)) {
									totalHours = total_hours;
									totalRate = total_rate;
								}

								let clockComponent = (
									<PastShiftsClock
										slotError={slot_error}
										startTime={start_time_at_utc}
										endTime={end_time_at_utc}
										isApproved={is_approved}
										checkIn={is_approved || is_dispute_resolved ? finance_checkin_time : check_in_time}
										checkOut={is_approved || is_dispute_resolved ? finance_checkout_time : check_out_time}
										facilityTimezone={facility_timezone}
										checkoutOverAMile={checkout_over_a_mile}
										shorterThanTwoHours={shorter_than_two_hours}
										longerThanSixteenHours={longer_than_sixteen_hours}
										showTextAsError={showTextAsError}
									/>
								);

								if (is_facility_billable_cancellation && !is_guaranteed) clockComponent = <LateCancellationBadge />;

								if (is_facility_billable_cancellation && is_guaranteed) clockComponent = <FacilityCancelledBadge />;

								if (
									(resolution_in_ops === 'Late Cancelation' || resolution_in_ops === 'Facility Cancelation') &&
									is_guaranteed
								) {
									clockComponent = <PastShiftBadge title="GUARANTEED CANCEL" />;
									facilityPayableBonus = HIDDEN_CELL_INFO;
								}

								if (resolution_in_ops === 'Late Cancelation' && !is_guaranteed && is_invoiceable) {
									clockComponent = <LateCancellationBadge />;
									facilityPayableBonus = HIDDEN_CELL_INFO;
								}

								if (
									resolution_in_ops === 'No Call / No Show' ||
									resolution_in_ops === 'CG Cancelation' ||
									(resolution_in_ops === 'Facility Cancelation' && !is_guaranteed) ||
									(!is_invoiceable && (resolution_in_ops || is_approved))
								) {
									clockComponent = <PastShiftBadge title="NOT INVOICEABLE" />;
									totalHours = HIDDEN_CELL_INFO;
									facilityPrice = HIDDEN_CELL_INFO;
									facilityPayableBonus = HIDDEN_CELL_INFO;
									hideTotalAmount = true;
								}

								if (
									resolution_in_ops === 'Walk out' ||
									outcome_code === 'WALK_OUT' ||
									is_facility_billable_cancellation
								) {
									facilityPayableBonus = HIDDEN_CELL_INFO;
								}

								return (
									<tr
										key={`past-row-${slot_id}`}
										className={classNames('table-body-row justify-content-between', {
											'table-separator': pastShiftFeed.length !== index + 1,
											'disputed-separator': index === indexToPutSeparator - 1
										})}
									>
										<td className="table-col-icon">
											{!is_facility_billable_cancellation && (
												<AlertIcon
													isApproved={is_approved}
													isDisputed={is_disputed}
													hasDisputesPermission={hasDisputesPermission}
													slotError={slot_error}
													checkoutOverAMile={checkout_over_a_mile}
													longerThanSixteenHours={longer_than_sixteen_hours}
													shorterThanTwoHours={shorter_than_two_hours}
													checkInTime={check_in_time}
													checkOutTime={check_out_time}
													isGuaranteed={is_guaranteed}
													isCancelled={is_cancelled}
												/>
											)}
										</td>
										<td className="table-col">
											<PastShiftsName
												name={caregiver_name}
												attachmentId={attachment_id}
												slotId={slot_id}
												slotError={slot_error}
												slotClaimRole={slot_claim_role}
												hasDisputesPermission={hasDisputesPermission}
											/>
										</td>
										<td className="table-col">
											<TooltipComponent placement="top" label="Shift Type">
												{resource_type}
											</TooltipComponent>
											<UnitDetails unitId={shift.unit_id} />
											{contract_id && <ContractBadge shiftsCount={contract_non_cancelled_slots_count} />}
										</td>
										<td className="table-col">
											{startDate} <br /> {`${start_time} - ${end_time}`}
										</td>
										<td className="table-col">{clockComponent}</td>
										{hasDisputesPermission && <td className=" table-col-small">{`$${facilityPrice}`}</td>}
										<td className="table-col-small">{`$${facilityPayableBonus || 0}`}</td>
										<td
											className={classNames('table-col-small', {
												'error-slot':
													showTextAsError &&
													(slot_error ||
														!check_out_time ||
														shorter_than_two_hours ||
														longer_than_sixteen_hours ||
														(!check_in_time && !check_out_time))
											})}
										>
											{showTextAsError ? '-' : totalHours}
										</td>
										<td className="table-col-small total-pay">
											{hasDisputesPermission && (
												<PastShiftsTotal
													slotError={slot_error}
													isFacilityBillableCancellation={is_facility_billable_cancellation}
													showTextAsError={showTextAsError}
													totalWage={totalRate}
													isGuaranteed={is_guaranteed}
													hideTotalAmount={hideTotalAmount}
												/>
											)}
										</td>
										<td className="table-col-small total-pay">
											{hasDisputesPermission && !dispute_resolved_by && (
												<PastShiftsActions
													isApproved={is_approved}
													isDisputed={is_disputed}
													approvedBy={slot_approved_by}
													approvedDate={slot_approved_date}
													checkoutOverAMile={checkout_over_a_mile}
													disputeCreatedBy={dispute_created_by}
													disputeCreatedDate={dispute_created_date}
													effectiveDateApprovalsDisputes={userData.effectiveDateApprovalsDisputes}
													isApprovalDisputeAllowed={is_approval_allowed}
													confirmAction={() => approveModal(slot_error, slot_id)}
													disputeAction={() => disputeShift(shift)}
												/>
											)}
											{hasDisputesPermission && dispute_resolved_by && (
												<ResolvedDisputeTooltip
													disputeCreatedBy={dispute_created_by}
													disputeCreatedDate={dispute_created_date}
													disputeResolvedBy={dispute_resolved_by}
													disputeResolvedDate={dispute_resolution_date}
													disputeResolution={resolution_in_ops}
													isInvoiceable={is_invoiceable}
												/>
											)}
										</td>
									</tr>
								);
							})}
							{pastShiftFeed.length === 0 && (
								<tr key="past-shift-row" className="no-shifts-available table-body-row ">
									<td colSpan="4">
										There are no past shifts that exist this week. Please navigate to a different week to see past
										shifts.
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		</WithOnlyOnDesktop>,
		showDisputesModal && (
			<ShiftDisputeModal slot={activeDisputedShift} onSave={reloadFeed} onClose={closeDisputeModalAndReload} />
		),
		confirmationModal && (
			<ConfirmationModal
				onConfirm={() => approveOnConfirm(slotIdToApprove, 'confirmationModal')}
				onCancel={() => setConfirmationModal(false)}
				confirmLabel="APPROVE"
				cancelLabel="CANCEL"
				key="confirmation-past-shift"
			>
				Are you sure you want to Approve the shift?
			</ConfirmationModal>
		),
		warningModal && (
			<PastShiftWarningModal
				onConfirm={() => approveOnConfirm(slotIdToApprove, 'warningModal')}
				onClose={() => setWarningModal(false)}
				key="approve-past-shift"
			/>
		)
	];
}

export default PastShiftsScreen;
