import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';

import xhr from '../../utils/xhr';
import utils from '../../utils/utils';
import { showNotification } from '../../redux/notification';
import LoadingSpinner from '../../components/spinner/LoadingSpinner';
import backgroundImage from '../../assets/images/login-background.png';
import logoIcon from '../../assets/images/portal-logo.svg';
import userIcon from '../../assets/images/login-user.svg';

import './Login.css';

const ForgotPassword = (props) => {
	const dispatch = useDispatch();

	const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
	const [email, setEmail] = useState('');
	const [requestSent, setRequestSent] = useState(false);

	const reset = async () => {
		if (!email || !utils.emailRegex.test(email.trim())) {
			dispatch(showNotification('error', 'Please enter a valid email address'));
		} else {
			setShowLoadingSpinner(true);
			try {
				await xhr.request('POST', `/user/forgot-password`, { email: email.trim() });
				setRequestSent(true);
			} catch (error) {
				const message =
					error.response.data &&
					error.response.data.errors &&
					error.response.data.errors[0] &&
					error.response.data.errors[0].message;
				dispatch(showNotification('error', message || 'Error. Please try again'));
			} finally {
				setShowLoadingSpinner(false);
			}
		}
	};

	return (
		<div className="login-screen">
			<img src={backgroundImage} className="login-background d-none d-md-block" alt="" />
			<div className="login-form-container flex-one">
				<div className="login-form">
					{/* TODO fix eslint - transform to button */}
					{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
					<img src={logoIcon} alt="" className="logo" onClick={() => props.history.replace('/')} />
					{!requestSent ? (
						<div>
							<div className="subtitle">Forgot password?</div>
							<div className="text">
								Please insert your email address associated with your account and we will send the reset instructions.
							</div>
							<div className="input-container">
								<div className="input-icon">
									<img src={userIcon} alt="" />
								</div>
								<input
									type="email"
									maxLength={128}
									className="flex-one"
									placeholder="Email"
									value={email}
									onKeyUp={(e) => e.keyCode === 13 && reset()}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
						</div>
					) : (
						<div>
							<div className="subtitle">Check your email</div>
							<div className="text">
								If the provided email address matches that account&apos;s verified email address, you&apos;ll receive an
								email with the reset link shortly.
							</div>
							<div className="text-purple">Didn&apos;t receive anything?</div>
						</div>
					)}

					<Button size="lg" block onClick={reset}>
						{requestSent ? 'SEND AGAIN' : 'RESET PASSWORD'}
					</Button>

					{requestSent && (
						<Button variant="link" className="text-purple link" onClick={() => props.history.replace('/')}>
							Go Back
						</Button>
					)}
				</div>
			</div>

			{showLoadingSpinner && <LoadingSpinner />}
		</div>
	);
};

export default withRouter(ForgotPassword);
