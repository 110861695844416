import React from 'react';
import { withRouter } from 'react-router';
import classNames from 'classnames';

import '../../sections/SidebarWrapper.scss';

const SettingsSideBar = ({ availableTabs, activeTab, setActiveTab }) => {
	return (
		<div className="portal-sidebar">
			<h1>Settings</h1>
			{availableTabs.map((tab) => {
				return (
					<div
						role="button"
						tabIndex="0"
						key={tab}
						onClick={() => (activeTab !== tab ? setActiveTab(tab) : null)}
						className={classNames('portal-sidebar__link', { active: activeTab === tab })}
						onKeyPress={() => setActiveTab(tab)}
					>
						{tab === 'Signature Approval' ? 'Signature Rule' : tab}
					</div>
				);
			})}
		</div>
	);
};

export default withRouter(SettingsSideBar);
