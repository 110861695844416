import React from 'react';
import Icon from './Icon';

/**
 * AccessTimeIcon component displays an icon representing check mark.
 *
 * @component
 * @param {Object} props - The component properties.
 * @param {number} [props.width=16] - Width of the icon.
 * @param {number} [props.height=16] - Height of the icon.
 * @param {string} [props.color='#000000'] - Color of the icon.
 * @param {string} [props.className] - Additional CSS classes for styling.
 * @returns {JSX.Element} AccountManagementIcon component.
 */
const AccessTimeIcon = React.memo(({ width = 16, height = 16, color = '#000', className }) => {
	return (
		<Icon width={width} height={height} viewBox={`0 0 ${width} ${height}`} color={color} className={className}>
			<path
				fillRule="evenodd"
				d="M8.49325 1.83333C4.81325 1.83333 1.83325 4.81999 1.83325 8.49999C1.83325 12.18 4.81325 15.1667 8.49325 15.1667C12.1799 15.1667 15.1666 12.18 15.1666 8.49999C15.1666 4.81999 12.1799 1.83333 8.49325 1.83333ZM8.49992 13.8333C5.55325 13.8333 3.16659 11.4467 3.16659 8.49999C3.16659 5.55333 5.55325 3.16666 8.49992 3.16666C11.4466 3.16666 13.8333 5.55333 13.8333 8.49999C13.8333 11.4467 11.4466 13.8333 8.49992 13.8333Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				d="M8.83325 5.16667H7.83325V9.16667L11.3333 11.2667L11.8333 10.4467L8.83325 8.66667V5.16667Z"
				fill={color}
			/>
		</Icon>
	);
});

export default AccessTimeIcon;
