/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import classNames from 'classnames';

import xhr from '../../utils/xhr';
import './SearchBar.scss';

const UP_KEY = 38;
const DOWN_KEY = 40;
const ESC_KEY = 27;
const ENTER_KEY = 13;

const searchBlurDelay = 150;
const debounceDelay = 300;

const SearchBar = (props) => {
	let currentRequest = null;
	let latestRequestCancelToken = null;

	const { shouldRestoreSearchBar, setShouldRestoreSearchBar } = props;

	const searchWrapperRef = React.createRef();
	const searchInputRef = React.createRef();
	const resultsListRef = React.createRef();
	const navigateTimeout = React.createRef();
	const lastSearchTimeout = React.createRef();
	const searchBlurTimeout = React.createRef();

	const [searchTerm, setSearchTerm] = useState('');
	const [results, setResults] = useState([]);
	const [selectedItemSearchTerm, setSelectedItemSearchTerm] = useState('');
	const [showAutocomplete, setShowAutocomplete] = useState(false);
	const [selectedItem, setSelectedItem] = useState(-1);

	const restoreSearchBar = useCallback(() => {
		setSearchTerm('');
		setResults([]);
		setSelectedItemSearchTerm('');
		setSelectedItem(-1);
		setShouldRestoreSearchBar(false);
	}, [setShouldRestoreSearchBar]);

	useEffect(() => {
		return () => {
			clearTimeout(navigateTimeout.current);
			clearTimeout(lastSearchTimeout.current);
			clearTimeout(searchBlurTimeout.current);
		};
	}, []);

	useEffect(() => {
		if (shouldRestoreSearchBar) {
			restoreSearchBar();
		}
	}, [shouldRestoreSearchBar, restoreSearchBar]);

	const clearSearchResults = (e) => {
		const searchWrapper = searchWrapperRef.current;

		if (e && !e.keyCode && searchWrapper && !searchWrapper.contains(e.target)) {
			// A blur event happened
			const searchInput = searchInputRef.current;

			if (searchInput.value.length > 0) {
				setShowAutocomplete(true);
				setSelectedItemSearchTerm(''); // Treat the case when the user focuses the input and edits the value AFTER a search item was selected
				setSearchTerm(searchTerm);
				setSelectedItem(-1);
			}
		} else if (!e || e.keyCode === ESC_KEY) {
			// Clear the search input when a blur didn't happen: search term modification, "ESC" key press
			setShowAutocomplete(false);
			setSelectedItemSearchTerm(''); // Treat the case when the user focuses the input and edits the value AFTER a search item was selected
			setResults(e && e.keyCode === ESC_KEY ? results : []);
			setSelectedItem(-1);

			if (e && e.keyCode === ESC_KEY) {
				searchInputRef.current.blur();
			}
		}
	};

	const generateURL = (_searchTerm) => {
		let url = props.autocompleteUrl;
		url += (props.queryParamName ? `?${props.queryParamName}=` : '/') + encodeURIComponent(_searchTerm || searchTerm); // Append query param
		url += (props.extraFieldName ? `&${props.extraFieldName}=` : '/') + encodeURIComponent(props.extraFieldValue); // Append extraField param
		return url;
	};

	const searchTermChange = (e) => {
		if (props.restoreSectionError) {
			props.restoreSectionError();
		}

		const _searchTerm = e.target.value;
		setSearchTerm(_searchTerm);
		clearTimeout(lastSearchTimeout.current);

		const searchMinChars = props.searchMinChars || 1;
		if (_searchTerm && _searchTerm.length >= searchMinChars) {
			lastSearchTimeout.current = setTimeout(async () => {
				if (latestRequestCancelToken) {
					latestRequestCancelToken.cancel();
					latestRequestCancelToken = null;
				}

				try {
					const url = generateURL(_searchTerm);

					latestRequestCancelToken = axios.CancelToken.source();

					currentRequest = xhr.request('GET', url, {
						cancelToken: latestRequestCancelToken.token
					});

					const response = await currentRequest;
					let _results = response.data;

					if (props.formatResponse) {
						_results = props.formatResponse(_results);
					}

					setShowAutocomplete(true);
					setSelectedItemSearchTerm(''); // Treat the case when the user focuses the input and edits the value AFTER a search item was selected
					setResults(_results);
				} catch (error) {
					console.error(error); // eslint-disable-line
				}
			}, debounceDelay);
		} else {
			clearSearchResults();
		}
	};

	const search = async () => {
		if (!props.onInputEnterPress) {
			return;
		}

		const searchMinChars = props.searchMinChars || 1;
		if (searchTerm && searchTerm.length >= searchMinChars) {
			if (currentRequest) {
				try {
					await currentRequest;
				} catch (error) {
					console.error(error); // eslint-disable-line
				}
				currentRequest = null;
			}
			props.onInputEnterPress(searchTerm, results);
			setShowAutocomplete(false);
		} else {
			setResults([]);
			setShowAutocomplete(false);
			props.onInputEnterPress(searchTerm, []);
		}
		searchInputRef.current.blur();
	};

	const searchSelectedItem = (e, index) => {
		const selectedItemIndex = parseInt(e ? e.currentTarget.dataset.index : index, 10);
		const _selectedItem = results[selectedItemIndex];
		const term = props.itemNameColumn ? _selectedItem[props.itemNameColumn] : _selectedItem;

		searchInputRef.current.blur();

		setShowAutocomplete(false);
		setSearchTerm(typeof term === 'string' ? term : '');
		setSelectedItemSearchTerm('');
		setSelectedItem(-1);

		if (props.onItemSelect) {
			props.onItemSelect(selectedItemIndex, results);
		}
	};

	const moveCaretAtEndOfInput = () => {
		const searchInput = searchInputRef.current;
		const searchTermLength = searchInput.value.length;
		if (searchTermLength > 0) {
			searchInput.setSelectionRange(searchTermLength, searchTermLength);
		}
	};

	const restoreSearchResults = (e) => {
		props.onSectionClick();
		moveCaretAtEndOfInput(); // Sometimes the caret is set at the beginning
		if (e.target.value.length >= props.searchMinChars && results.length > 0 && !selectedItemSearchTerm) {
			setShowAutocomplete(true);
		}
	};

	const navigateResults = (e) => {
		const acceptedKeyCodes = [ENTER_KEY, ESC_KEY, UP_KEY, DOWN_KEY];
		const resultsListItems = resultsListRef.current && resultsListRef.current.querySelectorAll('li');

		if (acceptedKeyCodes.indexOf(e.keyCode) === -1) {
			return;
		}

		if (e.keyCode === ENTER_KEY) {
			// If a user searches using "Enter" key press
			if (selectedItem === -1) {
				// If it's the search bar
				navigateTimeout.current = setTimeout(search, debounceDelay); // The delay is for being sure that the search request started
			} else if (resultsListItems && resultsListItems[selectedItem]) {
				searchSelectedItem(null, selectedItem);
			}
			return;
		}

		if (e.keyCode === ESC_KEY) {
			// Clear search results if "ESC" is pressed
			searchInputRef.current.focus();
			clearSearchResults(e);
			return;
		}

		if (!resultsListItems) {
			return;
		}

		let newSelectedItem;
		if (e.keyCode === UP_KEY) {
			newSelectedItem = selectedItem === -1 ? resultsListItems.length - 1 : selectedItem - 1;

			resultsListItems.forEach((item) => item.classList.remove('focus'));
			if (newSelectedItem === -1) {
				searchInputRef.current.focus();
			} else {
				resultsListItems[newSelectedItem].classList.toggle('focus');
			}
		} else if (e.keyCode === DOWN_KEY) {
			newSelectedItem = selectedItem === -1 ? 0 : selectedItem + 1;
			newSelectedItem = newSelectedItem > resultsListItems.length - 1 ? -1 : newSelectedItem;

			resultsListItems.forEach((item) => item.classList.remove('focus'));
			if (newSelectedItem === -1) {
				searchInputRef.current.focus();
			} else {
				resultsListItems[newSelectedItem].classList.toggle('focus');
			}
		}

		// Get the selected search results item, or, if the search input is selected, assign an empty object
		const selectedResultsItem = results[newSelectedItem] || {};

		// In case the selected item is an object, we need to specify which property to use for determining what to display in the search bar
		const term = props.itemNameColumn ? selectedResultsItem[props.itemNameColumn] : selectedResultsItem;

		setSelectedItemSearchTerm(typeof term === 'string' ? term : '');
		setSelectedItem((prevItem) => (Number.isNaN(newSelectedItem) ? prevItem : newSelectedItem));
	};

	const handleBlur = () => {
		clearTimeout(searchBlurTimeout.current);
		searchBlurTimeout.current = setTimeout(() => {
			setShowAutocomplete(false);
		}, searchBlurDelay);
	};

	return (
		<div
			ref={searchWrapperRef}
			className={`search-bar${props.className ? ` ${props.className}` : ' '}`}
			onKeyUp={navigateResults}
		>
			<input
				ref={searchInputRef}
				type="text"
				className={classNames('input', { [props.errorClass]: props.errorClass })}
				maxLength={props.maxLength || 70}
				placeholder={props.placeholder || ''}
				value={selectedItemSearchTerm || searchTerm}
				onBlur={handleBlur}
				onChange={searchTermChange}
				onFocus={restoreSearchResults}
			/>
			{props.renderAutocompleteItem && showAutocomplete && results.length === 0 && (
				<ul ref={resultsListRef} className="search-bar__results-list">
					<li key="no-results-found" className="search-bar__autocomplete-item no-results">
						No results found
					</li>
				</ul>
			)}
			{props.renderAutocompleteItem && showAutocomplete && results.length > 0 && (
				<ul ref={resultsListRef} className="search-bar__results-list">
					{results.map((item, index) => (
						<li
							key={`autocomplete-item-${item.id}`}
							data-index={index}
							className="search-bar__autocomplete-item"
							onClick={searchSelectedItem}
						>
							{props.renderAutocompleteItem(item, index)}
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default SearchBar;
