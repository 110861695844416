import React, { useState } from 'react';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { user } from '../../redux/user';

import Card from './BaseCard';
import { PhoneIcon, PlatinumPillIcon } from '../icons';
import CaregiverAvatar from '../Avatar/CaregiverAvatar';
import useMediaQuery, { MEDIUM_SCREEN_WIDTH_QUERY } from '../hooks/useMediaQuery';
import utils from '../../utils/utils';
import { TIER } from '../../constants/Tiers';

import './CaregiverCard.scss';

/**
 * Phone number component for the CaregiverCard.
 *
 * @param {Object} props - Component props.
 * @param {string} props.label - The phone number label.
 * @returns {JSX.Element} - JSX element representing the phone number.
 */
const PhoneNumber = ({ label }) => (
	<span className="caregiver-card__phone-number">
		<PhoneIcon className="icon" />
		<a href={`tel:${utils.formatPhoneNumber(label)}`}>{utils.formatPhoneNumber(label)}</a>
	</span>
);

/**
 * Stats component for the CaregiverCard.
 *
 * @param {Object} props - Component props.
 * @param {string} props.label - The stat label.
 * @param {string} props.value - The stat value.
 * @returns {JSX.Element} - JSX element representing the stats.
 */
const Stats = ({ label, value }) => (
	<span className="caregiver-card__stats">
		{label} <b>{value}</b>
	</span>
);

/**
 * Caregiver details component for the CaregiverCard.
 *
 * @param {Object} props - Component props.
 * @param {string} props.specialty - The caregiver's specialty.
 * @param {string} props.resource - The caregiver's resource type.
 * @param {boolean} props.platinum - Indicates if the caregiver is platinum tier.
 * @returns {JSX.Element} - JSX element representing the caregiver details.
 */
const CaregiverDetails = ({ specialty, resource, platinum }) => {
	const [collapsed, setCollapsed] = useState(true);

	const handleKeyDown = (ev) => {
		// will toggle the accordion if the element is focused and the user hits the enter key.
		// this behavior is implemented in order to comply with accessibility patterns.
		if (ev.keyCode === 13) {
			setCollapsed(!collapsed);
		}
	};

	return (
		<>
			<div
				role="button"
				tabIndex={0}
				className={classNames('caregiver-card__specialties', { collapsed })}
				onKeyDown={handleKeyDown}
				onClick={() => setCollapsed(!collapsed)}
			>
				<span className="resource">{resource}</span>
				{resource && specialty ? ' - ' : ''}
				{specialty}
			</div>

			{platinum && <PlatinumPillIcon className="tier" />}
		</>
	);
};

/**
 * Additional details component for the CaregiverCard.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.caregiver - The caregiver object.
 * @returns {JSX.Element} - JSX element representing additional details.
 */
const AdditionalDetails = ({ caregiver, facilityTimezone, hideStats }) => {
	return (
		<div className="caregiver-card__additional-details">
			<Card.PhoneNumber label={caregiver.phone_number} />
			{!hideStats && (
				<div className="caregiver-card__stats-container">
					{caregiver.last_shift_worked_date && (
						<Card.Stats
							label="Last worked shift:"
							value={moment.tz(caregiver.last_shift_worked_date, facilityTimezone).format('ddd, MMM D, YYYY')}
						/>
					)}
					{caregiver.upcoming_shift_date && (
						<Card.Stats
							label="Upcoming shift:"
							value={moment.tz(caregiver.upcoming_shift_date, facilityTimezone).format('ddd, MMM D, YYYY')}
						/>
					)}
					{caregiver.total_worked_at_facility && (
						<Card.Stats label="Shifts worked:" value={caregiver.total_worked_at_facility} />
					)}
				</div>
			)}
		</div>
	);
};

/**
 * CaregiverCard component.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.caregiver - The caregiver object.
 * @param {boolean} props.hideStats - Indicates if the stats should be hidden.
 * @returns {JSX.Element} - JSX element representing the CaregiverCard.
 */
const CaregiverCard = ({ caregiver, nameBadge, hideStats }) => {
	const { resource_type: resourceType, specialties, id, name, tier } = caregiver;
	const isSmallScreen = useMediaQuery(MEDIUM_SCREEN_WIDTH_QUERY);
	const { timezone } = useSelector(user);

	const resourceCode = resourceType?.code || '';
	const hasSpecialties = specialties && specialties.length > 0;

	const specialtyString = `${hasSpecialties ? specialties.map(({ label }) => label).join(', ') : ''}`;

	return (
		<Card className="caregiver-card">
			<Card.Body className="caregiver-card__body">
				<div className="caregiver-card__details">
					<CaregiverAvatar large caregiverId={id} caregiverName={name} className="caregiver-card__avatar" />
					<div className="caregiver-card__main-profile">
						<Card.Title className="caregiver-name">
							{nameBadge} {name}
						</Card.Title>
						<Card.CaregiverDetails
							specialty={specialtyString}
							resource={resourceCode}
							platinum={tier === TIER.PLATINUM}
						/>
						{!isSmallScreen && (
							<Card.AdditionalDetails caregiver={caregiver} facilityTimezone={timezone} hideStats={hideStats} />
						)}
					</div>
				</div>
				{isSmallScreen && (
					<Card.AdditionalDetails caregiver={caregiver} facilityTimezone={timezone} hideStats={hideStats} />
				)}
			</Card.Body>
		</Card>
	);
};

Card.PhoneNumber = PhoneNumber;
Card.Stats = Stats;
Card.CaregiverDetails = CaregiverDetails;
Card.AdditionalDetails = AdditionalDetails;

export default CaregiverCard;
