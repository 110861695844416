import React from 'react';
import close from '../../assets/images/close-black.svg';
import searchIcon from '../../assets/images/search-icon.svg';

const SearchSection = ({ search = '', setSearch = () => {} }) => (
	<>
		<input type="text" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
		{!!search.length && (
			<img src={close} alt="delete" className="delete-icon" aria-hidden onClick={() => setSearch('')} />
		)}
		<img src={searchIcon} alt="search" className="search-icon" />
	</>
);

export default SearchSection;
