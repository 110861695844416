import React from 'react';
import Icon from './Icon';

/**
 * CheckIcon component displays an icon representing check mark.
 *
 * @component
 * @param {Object} props - The component properties.
 * @param {number} [props.width=16] - Width of the icon.
 * @param {number} [props.height=16] - Height of the icon.
 * @param {string} [props.color='#000000'] - Color of the icon.
 * @param {string} [props.className] - Additional CSS classes for styling.
 * @returns {JSX.Element} AccountManagementIcon component.
 */
const CheckIcon = React.memo(({ width = 16, height = 16, color = '#000', className }) => {
	return (
		<Icon
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			color={color}
			className={className}
			path="M6.5001 11.28L3.7201 8.5L2.77344 9.44L6.5001 13.1667L14.5001 5.16667L13.5601 4.22667L6.5001 11.28Z"
		/>
	);
});

export default CheckIcon;
