import api from './api';

const dropdownsAPI = api.injectEndpoints({
	endpoints: (build) => ({
		dropdowns: build.query({
			query: (entity) => `entity/${entity}`
		})
	})
});

export const { useDropdownsQuery } = dropdownsAPI;

export default dropdownsAPI;
