import { useState } from 'react';
import { filterNames } from '../utils/FilterFunctions';

/**
 * Custom React hook for managing and applying filters to a list of shifts.
 * @returns {Object} An object containing state and functions for managing and applying filters.
 * @property {Object} filters - The current filter state containing arrays for each filter type.
 * @property {function} setFilters - Function to update the filter state.
 * @property {boolean} filterActive - A boolean indicating whether any filter is active.
 */
const useFilters = () => {
	const [filterState, setFilterState] = useState(filterNames);

	const filterActive =
		filterState?.unit?.length || filterState?.shiftStatus?.length || filterState?.shiftFeature?.length;

	return {
		filters: filterState,
		setFilters: setFilterState,
		filterActive
	};
};

export default useFilters;
