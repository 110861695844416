import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { setPreventNavigation } from '../../../redux/navigate';
import ConfirmModal from './ConfirmModal';

import './BaseModal.scss';

const Prompt = ({
	children = 'Are you sure you want to leave the page? Your changes will not be saved.',
	handleOK,
	handleCancel,
	title = 'Unsaved Changes',
	okText = 'Leave Without Saving',
	cancelText = 'Keep Editing'
}) => (
	<ConfirmModal
		title={title}
		onSecondaryAction={handleOK}
		secondaryActionLabel={okText}
		onPrimaryAction={handleCancel}
		primaryActionLabel={cancelText}
		onHide={handleCancel}
		className="router-prompt-modal"
	>
		{children}
	</ConfirmModal>
);

const RouterPrompt = ({ when }) => {
	const history = useHistory();

	const [showPrompt, setShowPrompt] = useState(false);
	const [currentPath, setCurrentPath] = useState('');
	const unblockRef = useRef();
	const dispatch = useDispatch();

	const handleShowModal = () => {
		setShowPrompt(true);
	};

	useEffect(() => {
		unblockRef.current = history.block((location) => {
			if (when) {
				setCurrentPath(location.pathname);
				handleShowModal();
				return false;
			}
			return true;
		});
		return () => {
			if (unblockRef.current) {
				unblockRef.current();
			}
		};
	}, [when]);

	const handleOK = () => {
		if (unblockRef) {
			unblockRef.current();
		}
		setShowPrompt(false);
		dispatch(setPreventNavigation(false));
		history.push(currentPath);
	};

	const handleCancel = () => {
		setShowPrompt(false);
	};

	return showPrompt ? <Prompt handleOK={handleOK} handleCancel={handleCancel} /> : null;
};

RouterPrompt.BasePrompt = Prompt;

export default RouterPrompt;
