import React, { memo, useEffect, useState } from 'react';
import moment from 'moment';
import guaranteedIcon from '../../assets/images/guaranteed-icon.svg';
import orientationIcon from '../../assets/images/compass.svg';
import Select from '../../components/form/Select';

const SLOT_LIMIT = 20;
const SLOT_OPTIONS = Array.from(Array(SLOT_LIMIT), (o, i) => ({ label: i + 1, value: i + 1 }));

const AddSlots = (props) => {
	const { shift } = props;
	const [duration, setDuration] = useState(null);
	const [shiftDate, setShiftDate] = useState(null);

	useEffect(() => {
		// raw_start_time is shift.start_time AT TIME ZONE facility.timezone
		// on the server node-postgres calls new Date() on the date string returned by the postgres server
		// Because the local time zone of the server is UTC, the date string returned by postgres is then converted
		// to UTC at the hour returned by postgres. Timezone info is lost
		// calling just moment(...) here applies the local browser time zone
		// this means that depending on the time zone, you can either end up with
		// - a date on the previous day when local browser timezone is GMT -x
		// - a date on the next day when local timezone is GMT +x
		// using moment.utc prevents additional time conversions and fixes the issue
		const end = moment.utc(shift.raw_end_time);
		const start = moment.utc(shift.raw_start_time);
		setDuration(end.diff(start, 'hours', true));
		setShiftDate(start.format('MM/DD'));
	}, [shift]);

	return [
		<div key="slots-header">
			<p className="title-slots">Would you like to add a new {shift.slots[0].resource_type} to this shift?</p>
			<div className="header-confirmation-shifts slots d-none d-sm-flex">
				<div className="head-text resource-type">{shift.slots[0].resource_type}(s) needed</div>
				<div className="head-text">Day</div>
				<div className="head-text">Date</div>
				<div className="head-text">Start Time</div>
				<div className="head-text">End Time</div>
				<div className="head-text">Hours</div>
				<div className="head-text" />
			</div>
		</div>,

		<div className="row-confirmation-shifts slots" key={`add-slot-details-${shift.id}`}>
			<div className="row-shift add resource-type">
				<Select
					options={SLOT_OPTIONS}
					value={props.numberOfSlots}
					name="numberOfSlots"
					handleChange={(e) => props.setNumberOfSlots(e.numberOfSlots)}
				/>
			</div>
			<div className="row-shift">
				<div>{shift.slots[0].shift_day.substr(0, 3)}</div>
			</div>
			<div className="row-shift">
				<div>{shiftDate}</div>
			</div>
			<div className="row-shift">
				<div>{shift.start_time}</div>
			</div>
			<div className="row-shift">
				<div>{shift.end_time}</div>
			</div>
			<div className="row-shift">
				<div>{duration}</div>
			</div>
			<div className="row-shift flex-wrap">
				{shift.shift_guaranteed && (
					<span className="guaranteed-badge-span slot mb-2">
						<img className="guaranteed-icon" src={guaranteedIcon} alt="Guaranteed shift" />
						Guaranteed
					</span>
				)}
				{shift.is_orientation && (
					<span className="guaranteed-badge-span slot orientation">
						<img className="orientation-icon" src={orientationIcon} alt="Orientation shift" />
						Orientation
					</span>
				)}
			</div>
		</div>
	];
};

export default memo(AddSlots);
