// these constants are used for the SMS validation flow; the values here are the ones that will allow us to send SMS to the users.
const VALID_SMS_CONTACT_METHODS = {
	ANY: 'Any',
	CALL_TEXT: 'Call & Text',
	EMAIL_TEXT: 'Email & Text',
	TEXT: 'Text'
};
const VALID_SMS_PHONE_TYPES = {
	MOBILE_PERSONAL: 'Mobile (Personal)',
	MOBILE_WORK: 'Mobile (Work)'
};

export { VALID_SMS_CONTACT_METHODS, VALID_SMS_PHONE_TYPES };
