import React, { memo } from 'react';
import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import IsAuthorized from '../IsAuthorized/IsAuthorized';

const Button = (props) => {
	const disableButton = (
		<>
			<OverlayTrigger
				trigger={['hover', 'focus']}
				key="clock-out-tooltip"
				placement="top"
				overlay={
					<Tooltip show className="invited-tooltip">
						You do not have permission to perform this action. Please contact your Customer Success Rep for details.
					</Tooltip>
				}
			>
				<button type="button" className={classNames(props.className, 'button-read-only')}>
					{props.title}
				</button>
			</OverlayTrigger>
		</>
	);

	return (
		<IsAuthorized
			exactPermission={props.exactPermission}
			operation={props.operations}
			section={props.section}
			readOnlyRender={disableButton}
		>
			<button
				type="button"
				className={classNames(props.className, { 'read-only': props.disabled })}
				onClick={props.onClick}
				disabled={props.disabled}
			>
				{props.title}
			</button>
		</IsAuthorized>
	);
};

export default memo(Button);
