import React, { memo, useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { showNotification } from '../../redux/notification';
import guaranteedIcon from '../../assets/images/guaranteed-icon.svg';
import orientationIcon from '../../assets/images/compass.svg';
import xhr from '../../utils/xhr';
import utils from '../../utils/utils';
import LoadingSpinner from '../../components/spinner/LoadingSpinner';

const ShiftsConfirmation = (props) => {
	const dispatch = useDispatch();
	const [hasGuaranteedSlot, setHasGuaranteedSlot] = useState(false);
	const [similarShifts, setSimilarShifts] = useState({});
	const [showLoadingSpinner, setShowLoadingSpinner] = useState(true);
	const getOpenSlotsByShift = (slots) => slots.filter((slot) => !slot.caregiver_id);

	// Checks open slots for those shifts that share same shift structure id and same date with current shift requested.
	const getSameDayAndStructureShifts = (shiftDate, shiftStructure) => {
		const openShifts = [];
		props.shiftsByDay.forEach((day) => {
			const dayDate = moment(day.raw_calendar_date).format('MMMM DD');
			if (dayDate === shiftDate) {
				day.shifts.forEach((shift) => {
					if (shift.shift_structure_id === shiftStructure.id && !shift.isRecurring) {
						openShifts.push(shift);
					}
				});
			}
		});

		return openShifts;
	};

	const getOpenShiftsSlotsBeforePosting = (shiftDate, shiftStructure) => {
		const openShiftSlots = [];
		const sameShifts = getSameDayAndStructureShifts(shiftDate, shiftStructure);

		for (let i = 0; i < sameShifts.length; i++) {
			const shift = sameShifts[i];
			openShiftSlots.push(...getOpenSlotsByShift(shift.slots));
		}

		return openShiftSlots;
	};

	const checkAnySlotIsGuaranteed = () => {
		let isGuaranteed = false;
		props.shiftsGroups.forEach((group) => {
			const _hasGuaranteedSlot = group.shifts.some((shift) => !!shift.is_guaranteed);
			if (_hasGuaranteedSlot) {
				isGuaranteed = true;
			}
		});
		return isGuaranteed;
	};

	useEffect(() => {
		setHasGuaranteedSlot(checkAnySlotIsGuaranteed());
	}, [props.shiftGroups]); // eslint-disable-line react-hooks/exhaustive-deps

	const getSimilarShifts = useCallback(async () => {
		setShowLoadingSpinner(true);
		const shiftStructuresByDateParams = new URLSearchParams();

		props.shiftsGroups.map((group) =>
			group.shifts.map((shift) =>
				shiftStructuresByDateParams.append(moment(group.date).format('YYYY-MM-DD'), shift.shiftStructureId)
			)
		);

		try {
			const response = await xhr.request('GET', `/shifts/similar?${shiftStructuresByDateParams.toString()}`);
			setSimilarShifts(response.data);
			setShowLoadingSpinner(false);
		} catch (e) {
			const errorMessage = utils.handleNetworkErrorMessage(e, 'Failed getting similar shifts. Please try again.');
			dispatch(showNotification('error', errorMessage));
		}
	}, [dispatch, props.shiftsGroups]);

	useEffect(() => {
		getSimilarShifts();
	}, [getSimilarShifts]);

	return showLoadingSpinner ? (
		<LoadingSpinner />
	) : (
		<>
			<div key="confirmation-header" className="header-confirmation-shifts d-none d-sm-flex">
				<div className="head-text resource-type">Shift Type</div>
				<div className="head-text">Date</div>
				<div className="head-text start-end">Start - End</div>
				<div className="head-text wrap">Shifts Requested</div>
				<div className="head-text wrap">Open Shifts Before Posting</div>
				<div className="head-text wrap">Total Open Shifts After Posting</div>
			</div>

			{props.shiftsGroups &&
				props.shiftsGroups.map((shiftsGroup, groupIndex) =>
					shiftsGroup.shifts.map((shift, shiftIndex) => {
						const shiftStructure = props.shiftStructures.find((structure) => structure.id === shift.shiftStructureId);
						const end = moment.utc(shiftStructure.to_time, 'HH:mm:ss');
						const start = moment.utc(shiftStructure.from_time, 'HH:mm:ss');

						if (end.isBefore(start)) {
							end.add(1, 'days');
						}

						const monthAndDay = moment(shiftsGroup.date).format('MM/DD');
						const dateToCheck = moment(shiftsGroup.date).format('MMMM DD');
						const openShiftsSlotsBeforePosting = getOpenShiftsSlotsBeforePosting(dateToCheck, shiftStructure);
						const [prevShiftsGuaranteed, prevShiftsNotGuaranteed] = openShiftsSlotsBeforePosting.reduce(
							([guaranteed, notGuaranteed], slot) => {
								if (slot.is_guaranteed) {
									return [guaranteed + 1, notGuaranteed];
								}
								return [guaranteed, notGuaranteed + 1];
							},
							[0, 0] // Initial values for guaranteed and not guaranteed counts
						);

						const [newShiftsGuaranteed, newShiftsNotGuaranteed] = shift.isGuaranteed
							? [shift.numberOfProviders, 0]
							: [0, shift.numberOfProviders];

						const totalOpenGuaranteed = prevShiftsGuaranteed + newShiftsGuaranteed;
						const totalOpenNotGuaranteed = prevShiftsNotGuaranteed + newShiftsNotGuaranteed;

						const startFormatted = moment(shiftsGroup.date).format('YYYY-MM-DD');
						const similarShiftFromBE =
							similarShifts && similarShifts[startFormatted] && similarShifts[startFormatted][shift.shiftStructureId]
								? similarShifts[startFormatted][shift.shiftStructureId]
								: false;

						console.log('test - similarShiftFromBE: ', similarShiftFromBE);
						return (
							<div
								className="row-confirmation-shifts request d-block d-sm-flex"
								/* eslint-disable-next-line react/no-array-index-key */
								key={`confirmation-details-${groupIndex}-${shiftIndex}`}
							>
								<div className="row no-gutters d-flex">
									<div className="col-6 d-sm-none d-flex align-items-center">Type</div>
									<div className="row-shift resource-type">
										<div>{shiftStructure.resource_type}</div>
									</div>
								</div>

								<div className="row no-gutters d-flex">
									<div className="col-6 d-sm-none d-flex align-items-center">Date</div>
									<div className="row-shift">
										<div>
											{monthAndDay} - {moment(shiftsGroup.date).format('ddd')}
										</div>
									</div>
								</div>

								<div className="row no-gutters d-sm-none d-flex">
									<div className="col-6 d-flex align-items-center">Start</div>
									<div className="row-shift">
										<div>{shiftStructure && start.format('h:mm A')}</div>
									</div>
								</div>
								<div className="row no-gutters d-sm-none d-flex">
									<div className="col-6 d-flex align-items-center">End</div>
									<div className="row-shift">
										<div>{shiftStructure && end.format('h:mm A')}</div>
									</div>
								</div>
								<div className="row-shift start-end d-none d-sm-flex">
									<div>
										{shiftStructure && start.format('h:mm A')} - {shiftStructure && end.format('h:mm A')}
									</div>
								</div>

								<div className="row no-gutters d-flex providers">
									<div className="col-6 d-sm-none d-flex align-items-center">Shifts Requested</div>
									<div className={classNames('row-shift', { 'has-guaranteed': hasGuaranteedSlot })}>
										{shiftStructure.is_orientation && (
											<div className="guaranteed-badge-span orientation">
												<img className="guaranteed-icon" src={orientationIcon} alt="Guaranteed shift" />
												Orientation
											</div>
										)}
										{newShiftsGuaranteed > 0 && (
											<div className="shift-count-details">
												<span className="guaranteed-badge-span">
													<img className="guaranteed-icon" src={guaranteedIcon} alt="Guaranteed shift" />
													Guaranteed:
												</span>{' '}
												<span className="highlighted">{newShiftsGuaranteed}</span>
											</div>
										)}
										{newShiftsNotGuaranteed > 0 && (
											<div className="shift-count-details">
												<span>Not Guaranteed: </span>
												<span className="highlighted">{newShiftsNotGuaranteed}</span>
											</div>
										)}
									</div>
								</div>

								<div className="row no-gutters d-flex open-before">
									<div className="col-6 d-sm-none d-flex align-items-center">Current Open Shifts</div>
									<div className="row-shift">
										{prevShiftsGuaranteed > 0 && (
											<div className="shift-count-details">
												<span className="guaranteed-badge-span">
													<img className="guaranteed-icon" src={guaranteedIcon} alt="Guaranteed shift" />
													Guaranteed:
												</span>
												<span>{prevShiftsGuaranteed}</span>
											</div>
										)}
										{prevShiftsNotGuaranteed > 0 && (
											<div className="shift-count-details">
												<span>Not Guaranteed: </span>
												<span>{prevShiftsNotGuaranteed}</span>
											</div>
										)}
									</div>
								</div>

								<div className="row no-gutters d-flex new-shifts">
									<div className="col-6 d-sm-none d-flex align-items-center">Updated Open Shifts</div>
									<div className="row-shift">
										{totalOpenGuaranteed > 0 && (
											<div className="shift-count-details">
												<span className="guaranteed-badge-span">
													<img className="guaranteed-icon" src={guaranteedIcon} alt="Guaranteed shift" />
													Guaranteed:
												</span>
												<span>
													{totalOpenGuaranteed !== prevShiftsGuaranteed ? (
														<span className="highlighted">{totalOpenGuaranteed}</span>
													) : (
														totalOpenGuaranteed
													)}
												</span>
											</div>
										)}
										{totalOpenNotGuaranteed > 0 && (
											<div className="shift-count-details">
												<span>Not Guaranteed: </span>
												<span>
													{totalOpenNotGuaranteed !== prevShiftsNotGuaranteed ? (
														<span className="highlighted">{totalOpenNotGuaranteed}</span>
													) : (
														totalOpenNotGuaranteed
													)}
												</span>
											</div>
										)}
									</div>
								</div>
							</div>
						);
					})
				)}
		</>
	);
};

export default memo(ShiftsConfirmation);
