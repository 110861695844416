import React from 'react';
import { Link } from 'react-router-dom';
import AppHeader from '../../components/header/AppHeader';

import './NotFound.css';

const NotFound = (props) => {
	return [
		props.userData && props.userData.name && <AppHeader key="app-header" userData={props.userData} />,

		<div className="not-found-screen" key="nf">
			<div className="not-found-background d-none d-md-block" />
			<div className="not-found-container flex-one">
				<div className="title">404</div>
				<div className="subtitle">Sorry, this page isn&apos;t available</div>
				<div className="text">
					The link you followed may be broken or the page may have been removed.
					<br />
					<Link to="/" className="go-back">
						GO BACK TO THE HOME PAGE
					</Link>
				</div>
			</div>
		</div>
	];
};

export default NotFound;
