import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { PERMISSION, PRIVATE_PATH } from '../../constants';
import utils from '../../utils/utils';
import SidebarWrapper from '../../sections/SidebarWrapper';
import InternalStaff from './InternalStaff';
import OnDemand from './OnDemand';
import { FEATURE } from '../../constants/Features';

import './ProfessionalsScreen.scss';

const ProfessionalsScreen = () => {
	const permissions = useSelector((state) => state.user.permissions);
	const features = useSelector((state) => state.user.features);
	const sidebarItems = [
		{
			label: 'Professionals List',
			path: PRIVATE_PATH.PROFESSIONALS_ON_DEMAND,
			permission: PERMISSION.PROFESSIONALS,
			feature: FEATURE.PROFESSIONALS,
			isFlexOnly: false
		},
		{
			label: 'Internal Staff',
			path: PRIVATE_PATH.PROFESSIONALS_INTERNAL_STAFF,
			permission: PERMISSION.INTERNAL_STAFF,
			feature: FEATURE.FLEX_PROFESSIONALS,
			isFlexOnly: false
		}
	]
		.filter((item) => utils.isFeatureEnabled(item.feature, features))
		.filter((item) => utils.isAuthorizedExact(item.permission, permissions));

	const isPathInSidebarItems = (path) => sidebarItems.some((item) => item.path === path);
	const defaultRedirectPath = sidebarItems.length > 0 ? sidebarItems[0].path : PRIVATE_PATH.SHIFTS;

	return (
		<SidebarWrapper
			title="Professionals List"
			sidebarTitle="Professionals"
			sidebarItems={sidebarItems}
			className="professionals-container"
		>
			<div className="professionals-screen">
				<Switch>
					<Route
						path={PRIVATE_PATH.PROFESSIONALS_ON_DEMAND}
						render={(props) =>
							// eslint-disable-next-line react/jsx-props-no-spreading
							isPathInSidebarItems(props.match.path) ? <OnDemand /> : <Redirect to={defaultRedirectPath} />
						}
					/>
					<Route
						path={PRIVATE_PATH.PROFESSIONALS_INTERNAL_STAFF}
						render={(props) =>
							// eslint-disable-next-line react/jsx-props-no-spreading
							isPathInSidebarItems(props.match.path) ? <InternalStaff /> : <Redirect to={defaultRedirectPath} />
						}
					/>
				</Switch>
			</div>
		</SidebarWrapper>
	);
};

export default ProfessionalsScreen;
