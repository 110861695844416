import React, { forwardRef } from 'react';

import classNames from 'classnames';
import moment from 'moment';

import UnitDetails from '../UnitAssignment/UnitDetails';
import SlotArrivalStatus from './SlotArrivalStatus';
import ActionsDropdown from './ActionsDropdown';
import ShiftSlotDisplay from './ShiftSlotDisplay';

import editPencil from '../../../assets/images/edit-pencil.svg';
import Badge from '../../../components/badges/BaseBadge';
import CompassIcon from '../../../components/icons/CompassIcon';
import GuaranteedIcon from '../../../components/icons/GuaranteedIcon';
import ContractBadge from '../ContractBadge';

const ShiftSlotMobile = forwardRef(
	(
		{
			slot,
			shift,
			units,
			user,
			getRowColor,
			updateSlotArrivalStatus,
			onConfirm,
			onPrepareCancel,
			handleEditBonusModalButton,
			handleAddBonusModalButton,
			preventGuaranteeOrBonusUpdates,
			onGuaranteedCheckboxChange,
			setShowUnitAssignmentModal,
			setUnitAssignmentSlot,
			getUnitName
		},
		ref
	) => {
		const MobileRow = ({ label, children, column, className }) => {
			return (
				<div className="row">
					<div className="shift-label col-3">{label}</div>
					<div
						className={classNames('col-9', className, {
							'd-flex flex-wrap': !column
						})}
					>
						{children}
					</div>
				</div>
			);
		};

		return (
			<div
				ref={ref}
				className={classNames(
					`shift-card row with-border with-padding slot ${
						slot.is_cancelled ? 'canceled-row' : getRowColor(slot.resource_type)
					}`
				)}
			>
				<MobileRow label="Assignment">
					<span className="font-weight-bold">{slot.resource_type} &nbsp;&nbsp;</span>{' '}
					<UnitDetails unitId={slot.unit_id} />
				</MobileRow>

				<MobileRow label="Date">
					<span className="week-day">{slot.shift_day},&nbsp;</span>
					<span className="month-date">{shift.shift_date}</span>
				</MobileRow>

				<MobileRow label="Time">
					{moment.utc(shift.raw_start_time).format('hh:mm A')} - {moment.utc(shift.raw_end_time).format('hh:mm A')}
				</MobileRow>

				<MobileRow label="Professional" className="caregiver" column>
					<ShiftSlotDisplay slot={slot} isMobile />
				</MobileRow>

				<MobileRow label="Details">
					{slot.is_guaranteed && (
						<Badge
							badgeCopy="guaranteed"
							icon={<GuaranteedIcon width={16} height={16} fillColor="#00b4c3" color="#FFFFFF" />}
							className="guaranteed-badge"
						/>
					)}
					{slot.contract_id && <ContractBadge shiftsCount={slot.contract_non_cancelled_slots_count} />}
					{shift.is_orientation && (
						<Badge badgeCopy="orientation" className="orientation-badge" icon={<CompassIcon />} />
					)}
				</MobileRow>

				<MobileRow label="Bonus">
					<b
						className={classNames('bonus-value', {
							'show-mobile-add': !slot.caregiver_name && !shift.facilityPayableBonus && !slot.facility_payable_bonus
						})}
					>
						{slot.facility_payable_bonus ? `$${slot.facility_payable_bonus}` : ''}
					</b>
					{shift.facilityPayableBonus || slot.facility_payable_bonus ? (
						<img
							className={classNames('edit-pencil-icon', {
								show: !slot.caregiver_name
							})}
							role="presentation"
							src={editPencil}
							alt="Edit"
							onKeyDown={handleEditBonusModalButton}
							onClick={handleEditBonusModalButton}
						/>
					) : (
						<img
							className={classNames('edit-pencil-icon', {
								show: !slot.caregiver_name,
								'add-icon': !(shift.facilityPayableBonus || slot.facility_payable_bonus) && !slot.caregiver_name
							})}
							role="presentation"
							src={editPencil}
							alt="Add"
							onKeyDown={handleAddBonusModalButton}
							onClick={handleAddBonusModalButton}
						/>
					)}
				</MobileRow>

				<MobileRow label="Status" column>
					<SlotArrivalStatus
						slot={slot}
						key={`slot-arrival-${shift.id}-${shift.start_time}${shift.end_time}slot-${slot.shift_id}-${slot.slot_id}`}
						updateSlotArrivalStatus={updateSlotArrivalStatus}
					/>
				</MobileRow>

				<MobileRow label="Actions">
					<ActionsDropdown
						isFlex={shift.is_flex}
						canGuarantee={!shift.is_orientation && !preventGuaranteeOrBonusUpdates}
						getUnitName={getUnitName}
						hasAvailableUnits={units.length > 0}
						hasUnitsEnabled={user.hasUnitsEnabled}
						onCancel={onPrepareCancel}
						onConfirm={onConfirm}
						onGuaranteedCheckboxChange={onGuaranteedCheckboxChange}
						permissions={user.permissions}
						setShowUnitAssignmentModal={setShowUnitAssignmentModal}
						setUnitAssignmentSlot={setUnitAssignmentSlot}
						slot={slot}
					/>
				</MobileRow>
			</div>
		);
	}
);

export default ShiftSlotMobile;
