import React, { useMemo } from 'react';

import { useSelector, shallowEqual } from 'react-redux';
import { user } from '../../../redux/user';
import Tooltip from '../../../components/Tooltip/Tooltip';

import './UnitDetails.scss';

const UnitDetails = React.memo(
	({ unitId }) => {
		const userData = useSelector(user, shallowEqual);

		const unitName = useMemo(() => {
			// eslint-disable-next-line camelcase
			return userData.activeFacility.units.find((u) => u.id === unitId)?.unit_name;
		}, [unitId, userData.activeFacility.units]);

		return (
			<div className="unit-details">
				{unitName && (
					<Tooltip label="Unit Assignment" placement="bottom">
						Unit: <span className="bold">{unitName}</span>
					</Tooltip>
				)}
			</div>
		);
	},
	(prevProps, nextProps) => {
		// Memoize based on the unitId prop
		return prevProps.unitId === nextProps.unitId;
	}
);

export default UnitDetails;
