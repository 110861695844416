import React from 'react';
import Icon from './Icon';

/**
 * InfoIcon component displays an icon representing a logout action.
 *
 * @component
 * @param {Object} props - The component properties.
 * @param {number} [props.width=24] - Width of the icon.
 * @param {number} [props.height=24] - Height of the icon.
 * @param {string} [props.color='#A11010'] - Color of the icon.
 * @param {string} [props.className] - Additional CSS classes for styling.
 * @returns {JSX.Element} InfoIcon component.
 */
const InfoIcon = ({ width = 24, height = 24, color, className }) => {
	return (
		<Icon
			width={width}
			height={height}
			viewBox="0 0 24 24"
			color={color}
			className={className}
			path="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11 7V9H13V7H11ZM11 11V17H13V11H11ZM4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12Z"
		/>
	);
};

export default InfoIcon;
