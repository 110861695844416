import React, { useMemo, useCallback } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import useMediaQuery, { MOBILE_WIDTH_QUERY } from '../hooks/useMediaQuery';

const IconWithTooltip = ({ tooltipText, icon, onClick = () => {} }) => {
	const isMobile = useMediaQuery(MOBILE_WIDTH_QUERY);

	const handleKeyDown = useCallback(
		(e) => {
			if (e.key === 'Enter' || e.key === ' ') {
				onClick(e);
			}
		},
		[onClick]
	);

	const buttonElement = useMemo(
		() => (
			<div className="mx-2" onClick={onClick} onKeyDown={handleKeyDown} role="button" tabIndex={0}>
				{icon}
			</div>
		),
		[icon, onClick, handleKeyDown] // Added handleKeyDown to the dependency array
	);

	const tooltipOverlay = useMemo(() => <Tooltip style={{ fontSize: '1.4rem' }}>{tooltipText}</Tooltip>, [tooltipText]);

	return tooltipText ? (
		<OverlayTrigger placement={isMobile ? 'bottom' : 'right'} overlay={tooltipOverlay}>
			{buttonElement}
		</OverlayTrigger>
	) : (
		buttonElement
	);
};

export default IconWithTooltip;
