import React, { useEffect } from 'react';
import { withRouter } from 'react-router';

import config from '../../config';

/**
 * Dummy component that takes a route and redirects straight to the API in order to download a file
 * This needs to be here otherwise when the user is logged out and they try to download a file they are not
 * presented with the login page
 */
const Form530Download = (props) => {
	const { facilityId, fileId } = props.match.params;
	useEffect(() => {
		window.location.href = `${config.HOST_NAME}${config.API_URL}/facilities/${facilityId}/form530/pdf/${fileId}`;
	}, [facilityId, fileId]);
	return <div>Your file will load in a few seconds</div>;
};

export default withRouter(Form530Download);
