import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import classNames from 'classnames';
import './InviteCaregiver.css';
import platinumBadge from '../../assets/images/caregiver-platinum-badge.svg';
import InviteList from './InviteList';
import InviteCaregiverUtils from '../../utils/InviteCaregiverUtils';
import useSearchDebounce from '../../hooks/useSearchDebounce';
import LoadingSpinner from '../../components/spinner/LoadingSpinner';
import ModalShiftDetails from '../../components/modals/CaregiverShiftActionModals/ModalShiftDetails';
import Utils from '../../utils/utils';
import CaregiverSearchControl from '../../components/modals/CaregiverShiftActionModals/CaregiverSearchControl';
/**
 *
 * @param {object} props
 * @param {string} props.resourceType
 * @param {string} props.specialtyId
 * @param {string} props.startTime
 * @param {string} props.endTime
 * @param {string} props.dateTime
 * @param {string} props.openShift
 * @param {function} props.removeCaregiver
 * @param {function} props.addCaregiver
 * @param {object[]} props.selectedCaregivers
 * @returns
 *
 */
const InviteCaregiver = ({
	specialtyId,
	resourceType,
	startTime,
	endTime,
	dateTime,
	shiftDate,
	openShift,
	removeCaregiver,
	addCaregiver,
	selectedCaregivers,
	shiftId,
	showLoadingSpinner
}) => {
	const minSearchChars = 3;
	const SECTIONS_VALUE = {
		INVITE_PROFESSIONAL: 1,
		SENT_INVITES_SECTION: 2
	};
	const SECTIONS = [
		{
			name: 'Invite Professionals',
			value: SECTIONS_VALUE.INVITE_PROFESSIONAL
		},
		{
			name: 'Sent Invite(s)- ${}',
			value: SECTIONS_VALUE.SENT_INVITES_SECTION
		}
	];

	const [sectionValue, setSectionValue] = useState(SECTIONS_VALUE.INVITE_PROFESSIONAL);
	const [loading, setLoading] = useState(false);
	const [acceptedInvites, setAcceptedInvites] = useState([]);
	const [pendingInvites, setPendingInvites] = useState([]);
	const [errorSearch, setErrorSearch] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [searchedCaregivers, setSearchCaregivers] = useState([]);

	const handleResults = (results = []) => {
		const caregiversHaystack = new Set(selectedCaregivers.map((c) => c.caregiver_id));
		const filterResults = results.filter((c) => !caregiversHaystack.has(c.caregiver_id));
		setSearchCaregivers(filterResults);
	};

	let newResourceType;
	const searchParams = {
		resourceType,
		shiftId
	};

	if (resourceType.includes('-')) {
		const aux = resourceType.split('-');
		newResourceType = aux[0].trim();
	}

	if (specialtyId && newResourceType) {
		searchParams.specialty = specialtyId;
		searchParams.resourceType = newResourceType;
	}

	if (shiftDate) {
		searchParams.shiftDate = shiftDate;
	}

	const searchURL = `/caregivers-invite/search-caregiver`;
	const { searchTermChange } = useSearchDebounce(
		searchURL,
		searchValue,
		searchParams,
		setSearchValue,
		setLoading,
		setErrorSearch,
		handleResults,
		minSearchChars
	);

	const { getInvitesByShiftId } = InviteCaregiverUtils;
	SECTIONS[1].name = SECTIONS[1].name.replace('${}', pendingInvites.length + acceptedInvites.length);

	const fetchInvites = async () => {
		const response = await getInvitesByShiftId(shiftId);
		if (response) {
			const acceptedStatus = response.filter((cg) => cg.status === 'ACCEPTED');
			const pendingStatus = response.filter((cg) => cg.status === 'PENDING');
			setAcceptedInvites(acceptedStatus);
			setPendingInvites(pendingStatus);
		}
	};

	useEffect(() => {
		fetchInvites();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const addSelectedCaregiver = (caregiver) => {
		setSearchValue('');
		setSearchCaregivers([]);
		addCaregiver(caregiver);
	};
	/**
	 *
	 * @param {Event} evt
	 * @param {object} caregiver
	 */
	const removeSelectedProfessional = (evt, caregiver) => {
		evt.preventDefault();
		removeCaregiver(caregiver);
	};
	return (
		<div className="invite-caregiver-container">
			<ModalShiftDetails
				dateTime={dateTime}
				resourceType={resourceType}
				startTime={startTime}
				endTime={endTime}
				openShift={openShift}
			/>

			<ButtonGroup>
				{SECTIONS.map((section) => {
					return (
						<Button
							key={`invite-caregiver-section-${section.value}`}
							id={`invite-caregiver-section-section-${section.value}`}
							className={
								section.value === sectionValue
									? classNames('section-buttons', 'selected')
									: classNames('section-buttons')
							}
							onClick={() => setSectionValue(section.value)}
						>
							{section.name}
						</Button>
					);
				})}
			</ButtonGroup>
			<br />
			<br />
			{sectionValue === SECTIONS_VALUE.INVITE_PROFESSIONAL ? (
				<CaregiverSearchControl
					addSelectedCaregiver={addSelectedCaregiver}
					errorSearch={errorSearch}
					searchValue={searchValue}
					searchTermChange={searchTermChange}
					selectedCaregivers={selectedCaregivers}
					removeSelectedProfessional={removeSelectedProfessional}
					loading={loading}
					searchedCaregivers={searchedCaregivers}
				/>
			) : (
				<>
					<InviteList
						listName="Accepted Invite(s)"
						inviteList={acceptedInvites}
						keyName="accepted-caregiver"
						isAccepted
						resourceType={resourceType}
						platinumBadge={platinumBadge}
						pluralize={Utils.pluralize}
					/>
					<InviteList
						listName="Pending Invite(s)"
						inviteList={pendingInvites}
						keyName="pending-caregiver"
						resourceType={resourceType}
						platinumBadge={platinumBadge}
						pluralize={Utils.pluralize}
					/>
				</>
			)}
			{showLoadingSpinner && <LoadingSpinner key="loading-invites" />}
		</div>
	);
};

export default InviteCaregiver;
