import React, { useCallback, useEffect, useState } from 'react';
import { setPreventNavigation } from '../../redux/navigate';
import { VALIDATION_PATTERN, VALID_SMS_CONTACT_METHODS, VALID_SMS_PHONE_TYPES } from '../../constants';

import ConfirmModal from '../../components/modals/BaseModal/ConfirmModal';
import Input from '../../components/input/Input';
import Select from '../../components/form/Select';

import './AccountManagement.scss';

const emptyPhoneTypeOption = { label: '- Select a phone type -', value: null };
const validContactMethods = new Set(Object.keys(VALID_SMS_CONTACT_METHODS));
const validPhoneTypes = new Set(Object.keys(VALID_SMS_PHONE_TYPES));

const ContactInformationSection = ({
	allowEdit,
	contactMethodOptions,
	defaultUserData,
	dispatch,
	facilityId,
	facilityOptions,
	phoneTypeOptions,
	saveChanges,
	setAllowEdit,
	setUserData,
	userData
}) => {
	const [allowSave, setAllowSave] = useState(false);
	const [isInvalidName, setIsInvalidName] = useState(false);
	const [isInvalidPhone, setIsInvalidPhone] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [latestFieldUpdated, setLatestFieldUpdated] = useState(null);

	const resetAndDiscardChanges = useCallback(() => {
		setAllowSave(false);
		setIsInvalidName(false);
		setIsInvalidPhone(false);
		dispatch(setPreventNavigation(false));
		setUserData(defaultUserData);
		setAllowEdit(false);
	}, [dispatch, setUserData, defaultUserData, setAllowEdit]);

	useEffect(() => {
		resetAndDiscardChanges();
	}, [facilityId, resetAndDiscardChanges]);

	useEffect(() => {
		const _isInvalidName =
			!userData.contactName?.length ||
			userData.contactName?.length < 3 ||
			userData.contactName?.length > 64 ||
			!userData.contactName?.match(VALIDATION_PATTERN.FULL_NAME);

		const _isInvalidPhone =
			!!userData.phoneNumber?.length &&
			(userData.phoneNumber?.length !== 12 || !userData.phoneNumber?.match(VALIDATION_PATTERN.PHONE));

		setIsInvalidName(_isInvalidName);
		setIsInvalidPhone(_isInvalidPhone);
		const someValueIsDifferent = Object.keys(userData).some((key) => userData[key] !== defaultUserData[key]);
		dispatch(setPreventNavigation(someValueIsDifferent));
		setAllowSave(!_isInvalidPhone && !_isInvalidName && someValueIsDifferent);
		if (
			someValueIsDifferent &&
			userData.smsEnabled &&
			((_isInvalidPhone && userData.phoneNumber?.length === 0) ||
				(userData.phoneNumber !== defaultUserData.phoneNumber && !_isInvalidPhone) ||
				!validPhoneTypes.has(userData.contactPhoneType) ||
				!validContactMethods.has(userData.contactMethodType))
		) {
			setShowModal(true);
		}
	}, [defaultUserData, dispatch, userData]);

	const disableNotifications = useCallback(() => {
		setUserData((prevUserData) => ({ ...prevUserData, smsEnabled: false }));
		setShowModal(false);
	}, [setUserData]);

	const revertChanges = useCallback(() => {
		setUserData((prevUserData) => ({ ...prevUserData, [latestFieldUpdated]: defaultUserData[latestFieldUpdated] }));
		setShowModal(false);
	}, [setShowModal, defaultUserData, setUserData, latestFieldUpdated]);

	const handleChange = useCallback(
		(selected) => {
			const [key, value] = Object.entries(selected)[0];
			setUserData((prevUserData) => ({ ...prevUserData, [key]: value }));
			setLatestFieldUpdated(key);
		},
		[setUserData]
	);

	return (
		<>
			<div className="account-management__form-wrapper">
				<Select
					label={
						<>
							Facility{' '}
							{allowEdit && (
								<span className="input-label__comment">(Changes will only apply to the chosen facility.)</span>
							)}
						</>
					}
					name="facilityId"
					className="select"
					value={userData.facilityId}
					options={facilityOptions}
					readOnly={!allowEdit}
					disabled
				/>

				<Input
					label="Name"
					name="contactName"
					type="text"
					value={userData.contactName}
					readOnly={!allowEdit}
					handleChange={handleChange}
					maxLength={64}
					invalid={isInvalidName}
					errorMessage="Name must be less than 65 characters and can only contain letters, spaces, hyphens, commas, periods, and apostrophes."
				/>
				<Select
					label="Contact Method"
					placeholder="- select a contact method -"
					className="select"
					name="contactMethodType"
					value={userData.contactMethodType}
					handleChange={handleChange}
					options={contactMethodOptions || []}
					readOnly={!allowEdit}
					disabled={!allowEdit}
				/>
				{allowEdit && (
					<div className="account-management__input-description">
						You must select an option that includes ‘Text’, or the ‘Any’ option, before you can enable SMS
						notifications.
					</div>
				)}
				<Input
					label="Email"
					name="email"
					value={userData.email}
					readOnly={!allowEdit}
					disabled
					tooltipPlacement="top-start"
					tooltipText="Email addresses cannot be edited. Please contact ShiftMed to update this information."
				/>

				<div className="account-management__phone-wrapper">
					<Input
						label={
							<>
								Phone <span className="input-label__comment">(Optional)</span>
							</>
						}
						name="phoneNumber"
						type="text"
						value={userData.phoneNumber || ''}
						readOnly={!allowEdit}
						disabled={!allowEdit}
						handleChange={handleChange}
						invalid={isInvalidPhone}
						maxLength={12}
						phoneNumber
					/>

					<Input
						label={
							<>
								Ext. <span className="input-label__comment">(Optional)</span>
							</>
						}
						name="phoneExtensionNumber"
						value={userData.phoneExtensionNumber || ''}
						readOnly={!allowEdit}
						disabled={!allowEdit}
						handleChange={handleChange}
						maxLength={4}
					/>
				</div>
				<Select
					label={
						<>
							Phone Type <span className="input-label__comment">(Optional)</span>
						</>
					}
					placeholder="- Select an option -"
					className="select"
					name="contactPhoneType"
					value={userData.contactPhoneType}
					handleChange={handleChange}
					options={[emptyPhoneTypeOption, ...(phoneTypeOptions || [])]}
					readOnly={!allowEdit}
					disabled={!allowEdit}
				/>
			</div>
			{allowEdit && (
				<div className="account-management__input-description">
					You must enter a mobile phone type before you can enable SMS Notifications.
				</div>
			)}
			{allowEdit && (
				<div className="account-management__buttons">
					<button
						type="button"
						className="account-management__button secondary-button"
						onClick={resetAndDiscardChanges}
					>
						Cancel
					</button>
					<button
						type="button"
						className="account-management__button primary-button"
						onClick={saveChanges}
						disabled={!allowSave}
					>
						Save Changes
					</button>
				</div>
			)}

			{showModal && (
				<ConfirmModal
					className="notifications-section--disable-modal"
					onHide={revertChanges}
					onPrimaryAction={disableNotifications}
					onSecondaryAction={revertChanges}
					primaryActionLabel="Yes, Continue"
					secondaryActionLabel="Go back"
					title="Disable SMS notifications?"
				>
					By updating this value, SMS notifications will be disabled for this facility. You will continue to receive
					text messages if they are enabled at another facility.
				</ConfirmModal>
			)}
		</>
	);
};

export default ContactInformationSection;
