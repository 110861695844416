import React, { memo, forwardRef } from 'react';
import moment from 'moment';

// eslint-disable-next-line no-unused-vars
const Input = forwardRef(({ placeholder, value, onClick }, ref) => {
	const dayString = moment(value, 'MM/DD/YYYY').format('dddd').substr(0, 3);
	return (
		/* TODO fix eslint - transform to button */
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
		<div onClick={onClick} className="date-input-container">
			<span className="day-string-date-picker">{dayString}</span>
			<input className="input-shift-date-picker" placeholder={placeholder} readOnly value={value} />
			<p className="arrow-wrapper">
				<i className="arrow-down" />
			</p>
		</div>
	);
});

export default memo(Input);
