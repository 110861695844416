import React from 'react';
import { Modal } from 'react-bootstrap';
import WarningImage from '../../assets/images/warning-image.png';
import './ViewOnComputerModal.css';

const ViewOnComputerModal = ({ onCancel, size, customModalBodyClass }) => {
	return (
		<Modal
			size={size || 'ms'}
			show
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className="confirmation-modal"
			onHide={onCancel}
		>
			<Modal.Body className={customModalBodyClass}>
				<div className="information">
					<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onCancel}>
						<span aria-hidden="true">&times;</span>
					</button>
					<img src={WarningImage} alt="View on computer please" />
					<div className="title">View on Computer</div>
					<p className="subtitle">
						This feature doesn’t exist for mobile. To view, please use a computer or desktop screen.
					</p>
				</div>
			</Modal.Body>
			<Modal.Footer className="d-flex justify-content-center">
				<button type="button" className="go-home" onClick={onCancel}>
					<span>GO HOME</span>
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default ViewOnComputerModal;
