const PERMISSION = {
	BONUS: 'PORTAL.BONUS',
	CANCEL: 'PORTAL.CANCEL',
	CDPH530: 'PORTAL.CDPH530.SEND_EMAIL',
	DISPUTES: 'PORTAL.DISPUTES',
	DNR: 'PORTAL.SETTINGS.DNR',
	EDIT: 'PORTAL.EDIT',
	EDIT_ASSIGNMENT: 'PORTAL.EDIT.ASSIGNMENT',
	EDIT_WAGE: 'PORTAL.EDIT.WAGE',
	FACILITY_INFO: 'PORTAL.SETTINGS.FACILITY_INFO',
	GUARANTEE: 'PORTAL.GUARANTEE',
	INVITE: 'PORTAL.SETTINGS.CAREGIVER_INVITE',
	PAST_SHIFTS: 'PORTAL.SHIFTS.PAST',
	PROFESSIONALS: 'PORTAL.FEATURES.PROFESSIONALS',
	INTERNAL_STAFF: 'PORTAL.FLEX.INTERNAL_STAFF',
	SIGNATURE_RULE: 'PORTAL.SETTINGS.SIGNATURE_RULE',
	SETTINGS: 'PORTAL.SETTINGS',
	FLEX_ADD_CAREGIVER: 'PORTAL.FLEX.ADD_CAREGIVER',
	FLEX_ADD_EDIT_SHIFT: 'PORTAL.FLEX.ADD_EDIT_SHIFT',
	FLEX_CANCEL: 'PORTAL.FLEX.CANCEL'
};

export default PERMISSION;
