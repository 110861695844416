import React, { memo } from 'react';

// import { EditButton, DeleteButton } from '../Buttons/Buttons';

// eslint-disable-next-line no-unused-vars
const Actions = ({ edit, remove, rowId, customComponent }) => {
	return (
		<td>
			<div className="flex">
				{/* {edit && <EditButton customClass="action-buttons" handleClick={() => edit(rowId)} />}
				{remove && <DeleteButton customClass="action-buttons" handleClick={() => remove(rowId)} />} */}
			</div>
		</td>
	);
};

export default memo(Actions);
