import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import arrow from '../../assets/images/arrow-right-black.svg';
import useOnClickOutside from '../hooks/useOnClickOutside';
import BadgesSection from './BadgesSection';
import CheckBoxSection from './CheckBoxSection';
import SearchSection from './SearchSection';
import FooterSection from './FooterSection';
import './MultiSelectSearch.scss';

/**
 * MultiSelectSearch
 *
 * @param {Object} props
 * @param {String} props.label - Label name for the button
 * @param {Object[]} props.options - Array of objects with id, label and value
 * @param {String} props.options.label - Label name for the checkbox
 * @param {String} props.options.value - Value for the checkbox
 * @param {String} props.options.checked - Checked for the checkbox selection
 * @param {String} props.options.id - Id for the checkbox
 * @param {Function} props.onApply - Callback function to apply the selected options
 *
 * @returns {JSX.Element}
 */

const MultiSelectSearch = ({ label = '', options = [], onApply = () => {} }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [filterOptions, setFilterOptions] = useState(options);
	const [persistOptions, setPersistOptions] = useState(options);
	const [selectAllOption, setSelectAllOption] = useState(false);
	const [search, setSearch] = useState('');

	const containerRef = useRef(null);

	const syncOptions = (_options, _filterOptions) =>
		_options.map((option) => {
			const index = _filterOptions.findIndex((filterOption) => filterOption.id === option.id);
			if (index !== -1) {
				return { ...option, checked: _filterOptions[index].checked };
			}
			return option;
		});

	const compareOptions = (_options, _filterOptions) =>
		Object.keys(_options).length === Object.keys(_filterOptions).length &&
		Object.keys(_options).every((p) => _options[p] === _filterOptions[p]);

	const handleCheckboxChange = (id) => {
		const index = filterOptions.findIndex((option) => option.id === id);
		const newFilterOption = { ...filterOptions[index], checked: !filterOptions[index].checked };
		const newFilterOptions = [...filterOptions];
		newFilterOptions[index] = newFilterOption;
		setFilterOptions(newFilterOptions);
		setPersistOptions(syncOptions(persistOptions, newFilterOptions));
	};

	const handleBadgeDelete = (id) => {
		const index = persistOptions.findIndex((option) => option.id === id);
		const newFilterOption = { ...persistOptions[index], checked: !persistOptions[index].checked };
		const newPersistOptions = [...persistOptions];
		newPersistOptions[index] = newFilterOption;
		setPersistOptions(newPersistOptions);
	};

	const handleSelectAll = () => {
		const newFilterOption = filterOptions.map((option) => ({ ...option, checked: !selectAllOption }));
		setPersistOptions(syncOptions(persistOptions, newFilterOption));
		setFilterOptions(newFilterOption);
		setSelectAllOption(!selectAllOption);
	};

	const handleClear = () => {
		const newOptions = persistOptions.map((option) => ({ ...option, checked: false }));
		setPersistOptions(newOptions);
		setFilterOptions(newOptions);
		setSelectAllOption(false);
	};

	const handleSearch = useCallback(
		(_search) => {
			const newFilterOptions = persistOptions.filter((option) =>
				option.label.toLowerCase().includes(_search.toLowerCase())
			);
			setFilterOptions(newFilterOptions);
		},
		[persistOptions]
	);

	const handleApply = () => {
		setSearch('');
		setIsOpen(false);
		onApply(persistOptions);
	};

	useEffect(() => {
		if (filterOptions) {
			const allChecked = filterOptions.every((option) => option.checked);
			setSelectAllOption(allChecked);
		}
	}, [filterOptions]);

	useEffect(() => {
		if (
			filterOptions.length !== persistOptions.length ||
			!filterOptions.every((element, index) => element === persistOptions[index])
		) {
			setFilterOptions(persistOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [persistOptions]);

	useEffect(() => {
		if (handleSearch) {
			handleSearch(search);
		}
	}, [search, handleSearch]);

	useEffect(() => {
		if (
			options.length !== persistOptions.length ||
			!persistOptions.every((element, index) => element === options[index])
		) {
			setPersistOptions(options);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options]);

	const handleCancel = () => {
		setIsOpen(false);
		setSearch('');
		setPersistOptions(options);
	};

	useOnClickOutside(containerRef, handleCancel, 'mousedown');

	const isDisabled =
		persistOptions.every((option) => option.checked === false) || compareOptions(persistOptions, options);

	const optionsSelected = options.filter((option) => option.checked);
	const persistOptionsSelected = persistOptions.filter((option) => option.checked);

	return (
		<div className="multi-select-container" ref={containerRef}>
			<button className={classNames('button', { active: isOpen })} type="button" onClick={() => setIsOpen(!isOpen)}>
				<span>
					{label}
					{optionsSelected.length < options.length && (
						<>
							{': '}
							<b>{`${optionsSelected.length} selected`}</b>
						</>
					)}
				</span>
				<img src={arrow} alt="Datepicker" className="datepicker-arrow" />
			</button>
			{isOpen && (
				<div className="content">
					<div className="search-section">
						<SearchSection search={search} setSearch={setSearch} />
					</div>
					<div className="checkbox-section">
						<CheckBoxSection
							selectAllOption={selectAllOption}
							onSelectAll={handleSelectAll}
							checkboxOptions={filterOptions}
							onCheckboxChange={handleCheckboxChange}
							search={search}
						/>
					</div>
					{!!persistOptionsSelected.length && (
						<BadgesSection
							optionsSelected={persistOptionsSelected}
							onCheckboxChange={handleBadgeDelete}
							onClear={handleClear}
						/>
					)}
					<hr />
					<div className="footer-section">
						<FooterSection onCancel={handleCancel} onApply={handleApply} isDisabled={isDisabled} />
					</div>
				</div>
			)}
		</div>
	);
};

export default MultiSelectSearch;
