/* eslint-disable no-unused-expressions */
import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import NotificationPopupModal from '../NotificationPopupModal/NotificationPopupModal';

const RouterPrompt = (props) => {
	const {
		blockRoutePush,
		title,
		message,
		cancelLabel,
		confirmLabel,
		displayPrompt,
		onFinish = () => {},
		onPromptToggle = () => {}
	} = props;

	const history = useHistory();
	const [currentPath, setCurrentPath] = useState(null);
	const unblockRef = useRef();

	useEffect(() => {
		unblockRef.current = history.block((location) => {
			if (blockRoutePush) {
				setCurrentPath(location.pathname);
				onPromptToggle();
				return false;
			}
			return true;
		});
		return () => {
			unblockRef.current && unblockRef.current();
		};
	}, [blockRoutePush, history, onPromptToggle]);

	const handleConfirm = () => {
		if (unblockRef) {
			unblockRef.current();
		}

		if (displayPrompt) {
			currentPath ? history.push(currentPath) : history.go(0);
		}
		onFinish(true);
	};

	return (
		displayPrompt && (
			<NotificationPopupModal
				title={title}
				customChildComponent={<div>{message}</div>}
				cancelLabel={cancelLabel}
				confirmLabel={confirmLabel}
				onConfirm={handleConfirm}
				onClose={() => onFinish(false)}
				key="router-prompt--notification-popup-modal"
			/>
		)
	);
};

export default RouterPrompt;
