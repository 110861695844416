import React, { useState } from 'react';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import ConfirmationModal from '../modals/ConfirmationModal';

import './Select.scss';

const Select = (props) => {
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [selected, setSelected] = useState(false);

	const getValue = () => {
		if (props.value && Array.isArray(props.options)) {
			if (props.isMulti) {
				return props.value.map((element) => ({ label: element, value: element }));
			}
			if (typeof props.value === 'object') {
				return props.options.find((option) => {
					return JSON.stringify(option.value) === JSON.stringify(props.value);
				});
			}
			return props.options.find((option) => {
				return option.value === props.value;
			});
		}
		return null;
	};

	const onSelect = (selectedOptions) => {
		let findSelected;
		if (props.isMulti) {
			findSelected = selectedOptions.map((option) => option.value);
		} else {
			findSelected = selectedOptions.value;
		}
		if (props.withConfirmation) {
			setSelected(findSelected);
			setShowConfirmation(true);
		} else {
			props.handleChange({ [props.name]: findSelected }, props.additionalData);
		}
	};

	const ReadOnlySelect = () => {
		return <div className="select__readonly">{getValue()?.label}</div>;
	};

	return (
		<div className={classNames('select-container', props.containerClass)}>
			{props.label && <div className="select-label">{props.label}</div>}
			{props.readOnly ? (
				<ReadOnlySelect />
			) : (
				<ReactSelect
					placeholder={props.placeholder}
					className={classNames(
						{ 'read-only': props.disabled, 'invalid-input': props.invalid, 'has-error': props.hasError },
						props.className
					)}
					classNamePrefix="sm"
					menuPlacement="auto"
					menuShouldScrollIntoView={false}
					isMulti={props.isMulti}
					isClearable={false}
					isSearchable={false}
					value={getValue()}
					onChange={onSelect}
					options={props.options}
					components={props.customComponents}
					openMenuOnClick={props.openMenuOnClick}
					controlShouldRenderValue={props.controlShouldRenderValue}
					blurInputOnSelect
					isDisabled={props.isDisabled}
				/>
			)}

			{showConfirmation && (
				<ConfirmationModal
					onConfirm={() => {
						setShowConfirmation(false);
						props.handleChange({ [props.name]: selected }, props.additionalData);
					}}
					onCancel={() => setShowConfirmation(false)}
				>
					{props.confirmationMessage}
				</ConfirmationModal>
			)}
		</div>
	);
};

export default Select;
