import { useState } from 'react';
import xhr from '../utils/xhr';

export const Section = {
	ADDITIONAL_DETAILS: 'Additional Details',
	FACILITY_DOOR_CODE: 'Facility Door Code',
	PARKING_INFORMATION: 'Parking Information'
};

const useFacilityInfo = (onLoading = () => {}, onError = () => {}) => {
	const [facilityInfo, setFacilityInfo] = useState();
	const [latestFacilityInfo, setLatestFacilityInfo] = useState();

	const groupBySection = (data) => {
		return data.reduce((group, info) => {
			const { section_name: sectionName } = info;
			// eslint-disable-next-line no-param-reassign
			group[sectionName] = group[sectionName] ?? [];
			group[sectionName].push(info);
			return group;
		}, {});
	};

	// eslint-disable-next-line consistent-return,camelcase
	const getLatest = (section_name, values) => {
		if (values[section_name] && Array.isArray(values[section_name]) && values[section_name].length) {
			const orderByLatest = (a, b) => (parseInt(a.id, 10) < parseInt(b.id, 10) ? 1 : -1);
			return values[section_name].sort(orderByLatest)[0];
		}

		return { section_name, value: '' };
	};

	const getFacilityInfo = async () => {
		onLoading(true);
		try {
			const { data } = await xhr.request('GET', '/facility/facility-info');
			const grouped = groupBySection(data);

			setFacilityInfo(grouped);
			setLatestFacilityInfo({
				[Section.ADDITIONAL_DETAILS]: getLatest(Section.ADDITIONAL_DETAILS, grouped),
				[Section.FACILITY_DOOR_CODE]: getLatest(Section.FACILITY_DOOR_CODE, grouped),
				[Section.PARKING_INFORMATION]: getLatest(Section.PARKING_INFORMATION, grouped)
			});
		} catch (error) {
			onError(error, 'Failed to get facility information');
		} finally {
			onLoading(false);
		}
	};

	const createOrUpdateFacilityInfo = async (form, onSuccess = () => {}) => {
		onLoading(true);
		try {
			await xhr.request('PUT', '/facility/facility-info', form);
			await getFacilityInfo();
			onSuccess();
		} catch (error) {
			onError(error, 'Failed to update facility information');
		} finally {
			onLoading(false);
		}
	};

	return {
		facilityInfo,
		latestFacilityInfo,
		getFacilityInfo,
		createOrUpdateFacilityInfo
	};
};

export default useFacilityInfo;
