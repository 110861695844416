/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
	name: 'navigate',
	initialState: {
		preventNavigation: false,
		showPrompt: false
	},
	reducers: {
		setPreventNavigation: (state, action) => {
			state.preventNavigation = action.payload;
		},
		setShowPrompt: (state, action) => {
			state.showPrompt = action.payload;
		}
	}
});

export const setPreventNavigation = (preventNavigation) => async (dispatch) => {
	dispatch(slice.actions.setPreventNavigation(preventNavigation));
};

export const setShowPrompt = (showPrompt) => async (dispatch) => {
	dispatch(slice.actions.setShowPrompt(showPrompt));
};

export const navigate = (state) => state.navigate;

export const preventNavigation = (state) => state.navigate.preventNavigation;

export const showPrompt = (state) => state.navigate.showPrompt;

export default slice.reducer;
