import React from 'react';
import Icon from './Icon';

const DailyScheduleIcon = ({ width = 24, height = 24, color = '#FFFFFF', className }) => {
	return (
		<Icon
			width={width}
			height={height}
			color={color}
			className={className}
			path="M3.01605 3.8822H11.0161V13.8822H3.01605V3.8822ZM21.0161 3.8822H13.0161V9.8822H21.0161V3.8822ZM9.01605 11.8822V5.8822H5.01605V11.8822H9.01605ZM19.0161 7.8822V5.8822H15.0161V7.8822H19.0161ZM19.0161 13.8822V19.8822H15.0161V13.8822H19.0161ZM9.01605 19.8822V17.8822H5.01605V19.8822H9.01605ZM21.0161 11.8822H13.0161V21.8822H21.0161V11.8822ZM3.01605 15.8822H11.0161V21.8822H3.01605V15.8822Z"
		/>
	);
};

export default DailyScheduleIcon;
