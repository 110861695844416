import React, { memo } from 'react';
import DataTable from '../../../components/DataTable/DataTable';
import { CgNameDataTable, DeleteButton } from './DnrDataTableCellComponents';
import './DnrDataTable.scss';

const DnrDataTable = ({ dnrData, onDelete }) => {
	const formatSubmittedBy = (dnr) =>
		dnr.facility_contact_name === null ? 'Requested by Facility' : dnr.facility_contact_name;
	const formatDateOfDNr = (dnr) => (!dnr.date ? '-' : dnr.date);

	const headers = [
		{
			title: 'Healthcare Professional',
			field: 'caregiver_name',
			format: (row) => <CgNameDataTable dnrInfo={row} key={`cg-name-${row.id}`} />
		},
		{ title: 'Date Of DNR', field: 'date', format: (row) => formatDateOfDNr(row) },
		{ title: 'Type', field: 'resource_type', size: 'small-title' },
		{ title: 'Reason', field: 'dnr_reason_name' },
		{ title: 'Submitted By', field: 'submitted_by', format: (row) => formatSubmittedBy(row) },
		{
			title: 'Remove',
			field: 'remove',
			size: 'normal-title',
			extraClassName: 'remove-dnr',
			format: (row) => (
				<DeleteButton
					onClick={() => onDelete(row.id, row.caregiver_id, row.caregiver_name)}
					key={`delete-button-${row.id}`}
				/>
			)
		}
	];
	return <DataTable headers={headers} data={dnrData} PageSize={10} />;
};

export default memo(DnrDataTable);
