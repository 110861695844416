import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../config';

// Creating a getCookie function to be reused across the system
const getCookie = () => {
	const cookie = document.cookie
		.split(';')
		.map((_cookie) => _cookie.trim().split('='))
		.find(([name]) => name === 'csrfToken');

	return cookie ? cookie[1] : null;
};

// initialize an empty api service that we'll inject endpoints into later as needed
const api = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: config.API_URL,
		prepareHeaders: (headers) => {
			const token = getCookie();
			if (token) {
				headers.set('x-csrf-token', token);
			}
			return headers;
		},
		headers: {
			'X-Requested-With': 'XMLHttpRequest',
			'Content-Type': 'application/json',
			Accept: 'application/json'
		},
		credentials: 'include'
	}),
	endpoints: () => ({})
});

// utility to unwrap the error if there's one coming from the API's response
api.unwrapError = (_error) => {
	if (
		!_error ||
		!_error.data ||
		!_error.data.validationError ||
		!_error.data.errors ||
		!Array.isArray(_error.data.errors) ||
		!_error.data.errors.length
	) {
		// If _error or its nested properties are missing or empty, return an Unexpected Error
		return 'An unexpected error occurred. Please try again or contact ShiftMed if this issue persists.';
	}
	const {
		data: {
			validationError,
			errors: [firstError]
		}
	} = _error;
	if (validationError) {
		return firstError.message;
	}
	return null;
};

export default api;
