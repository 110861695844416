import React from 'react';
import Icon from './Icon';

const ArrowLeftIcon = ({ width = 24, height = 25, color, className }) => {
	return (
		<Icon
			width={width}
			height={height}
			color={color}
			className={className}
			path="M10.3731 1.69314L8.84708 0.21814L0.368225 8.46814L8.85566 16.7181L10.3731 15.2431L3.40313 8.46814L10.3731 1.69314Z"
		/>
	);
};

export default ArrowLeftIcon;
