/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

/**
 * pendingNotificationsArr:
 * [
 * 	{
 * 	  title,
 * 	  messageHtml,
 * 	  buttonText,
 * 	  id,
 * 	  eventCode
 * 	},...
 * ]
 */
export const slice = createSlice({
	name: 'pendingConfirmNotification',
	initialState: {
		pendingNotificationsArr: [],
		isFetched: false,
		fetching: false
	},
	reducers: {
		setPendingNotificationsArr: (state, action) => {
			state.pendingNotificationsArr = action.payload;
		},
		removeNotificationById: (state, action) => {
			state.pendingNotificationsArr = state.pendingNotificationsArr.filter(({ id }) => id !== action.payload);
		},
		setIsPendingNotificationsFetched: (state, action) => {
			state.isFetched = action.payload;
		},
		setPendingNotificationsFetching: (state, action) => {
			state.fetching = action.payload;
		}
	}
});

export const {
	setPendingNotificationsArr,
	removeNotificationById,
	setIsPendingNotificationsFetched,
	setPendingNotificationsFetching
} = slice.actions;

export const isPendingNotificationsFetched = (state) => state.pendingConfirmNotification.isFetched;
export const pendingNotificationsFetching = (state) => state.pendingConfirmNotification.fetching;
export const pendingNotificationsArr = (state) => state.pendingConfirmNotification.pendingNotificationsArr;

export default slice.reducer;
