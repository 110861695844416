import { useEffect, useLayoutEffect, useRef } from 'react';

const useEventListener = (eventName, handler = () => {}, element, options) => {
	const savedHandler = useRef(handler);
	useLayoutEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(() => {
		if (!eventName) return;

		const targetElement = element?.current ?? window;

		if (!(targetElement && targetElement.addEventListener)) return;

		const listener = (event) => savedHandler.current(event);

		targetElement.addEventListener(eventName, listener, options);

		// eslint-disable-next-line consistent-return
		return () => {
			targetElement.removeEventListener(eventName, listener, options);
		};
	}, [eventName, element, options]);
};

export default useEventListener;
