import axios from 'axios';

import config from '../config';
import { setError } from '../redux/error';
// eslint-disable-next-line import/no-cycle
import store from '../redux/store';
import { showNotification } from '../redux/notification';

const getCookie = () => {
	const cookieArray = document.cookie.split(';');
	for (let i = 0; i < cookieArray.length; i++) {
		const cookie = cookieArray[i].split('=');
		let name = cookie[0];
		name = name.charAt(0) === ' ' ? name.substring(1) : name;
		if (name === 'csrfToken') {
			return cookie[1];
		}
	}
	return null;
};

const xhr = {
	request: async (method, url, data, options = {}) => {
		try {
			return await axios.request({
				url: config.API_URL + url,
				method: method.toLowerCase(),
				data,
				headers: {
					Accept: 'application/json',
					'x-csrf-token': getCookie()
				},
				...options
			});
		} catch (error) {
			return xhr.handleGenericErrors(error);
		}
	},

	handleGenericErrors(error) {
		const status = error.response && error.response.status;
		const data = error.response && error.response.data;
		const errors = data && error.response.data.errors ? error.response.data.errors : false;

		if (
			status === 401 &&
			!error.response.data.validationError &&
			(window.location.href.includes('/private/') || window.location.href.includes('/form-530/'))
		) {
			window.location.href = `/login?url=${encodeURIComponent(window.location.href)}`;
			throw error;
		} else if (status === 403) {
			if (errors) {
				store.dispatch(showNotification('error', error.response.data.errors[0].message));
				throw error;
			} else {
				const _error = error.response.data === 'Forbidden' ? '403' : 'Error';
				store.dispatch(setError(_error));
				throw error;
			}
		} else if (status === 404) {
			window.location.href = '/404';
			throw error;
		} else {
			throw error;
		}
	}
};

export default xhr;
