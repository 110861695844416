import React, { useState } from 'react';
import classNames from 'classnames';
import config from '../../config';
import noAvatar from '../../assets/images/no-avatar.png';
import './Avatar.scss';

/**
 * @description HCP round Image will load a default image if the image is not found
 * @param {object} props
 * @param {string} props.caregiverId
 * @param {string} props.className
 * @returns {JSX.Element}
 *
 */
const CaregiverAvatar = ({ caregiverId, className, large, caregiverName }) => {
	const src = (_caregiverId) => `${config.API_URL}/caregivers/photo/${_caregiverId}`;
	const [imgSrc, setImgSrc] = useState(src(caregiverId));

	const onError = () => {
		if (imgSrc !== noAvatar) {
			setImgSrc(noAvatar);
		}
	};
	return (
		<img
			src={imgSrc}
			className={classNames(className, 'avatar', { avatar__large: large })}
			onError={onError}
			alt={caregiverName || 'caregiver profile'}
		/>
	);
};

export default CaregiverAvatar;
