import React from 'react';

const FilterBurgerIcon = ({ width = 24, height = 25, color, className }) => {
	return (
		<svg
			width={width}
			height={height}
			className={className}
			viewBox={`0 0 ${width} ${height}`}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3 6.35294L3 8.35294L21 8.35294V6.35294L3 6.35294ZM10 18.3529H14V16.3529H10V18.3529ZM18 13.3529L6 13.3529L6 11.3529L18 11.3529V13.3529Z"
				fill={'currentColor' || color}
			/>
		</svg>
	);
};

export default FilterBurgerIcon;
