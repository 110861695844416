import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import Select from '../../../components/form/Select';
import ConfirmationModal from '../../../components/modals/ConfirmationModal';
import CheckIcon from '../../../components/icons/CheckIcon';
import AccessTimeIcon from '../../../components/icons/AccessTimeIcon';
import { SHIFT_STATUS } from '../../../constants';
import BaseBadge from '../../../components/badges/BaseBadge';

const ARRIVAL_STATUS_OPTIONS = [
	{ label: 'Arrived', value: 'ARRIVED' },
	{ label: 'No Call / No Show', value: 'NO_SHOW' }
];

const STATUS_DETAILS = {
	confirmed: { className: 'confirmed', icon: <CheckIcon /> },
	pending: { className: 'pending', icon: <AccessTimeIcon /> },
	cancelled: { className: 'cancelled', icon: null },
	expired: { className: 'expired', icon: null }
};

const ARRIVAL_STATUS_COLORS = {
	CHECKED_IN: 'teal',
	ON_TIME: 'purple',
	RUNNING_LATE: 'pink'
};

const StatusBadge = ({ status }) => {
	const { className, icon } = STATUS_DETAILS[status] || { className: 'pending', icon: null };

	return <BaseBadge badgeCopy={status} icon={icon} className={`${className}-badge`} />;
};

const StatusIndicator = ({ bulletPointColorStyle, label, detail }) => {
	return (
		<div className="status-indicator">
			<div className={classNames('status-indicator-label', bulletPointColorStyle)}>{label}</div>

			{detail && <div className="status-indicator-detail">{detail}</div>}
		</div>
	);
};

const SlotArrivalStatus = (props) => {
	const { slot, updateSlotArrivalStatus } = props;

	const [showUpdateSlotStatusModal, setShowUpdateSlotStatusModal] = useState(false);
	const [arrivalOptionSelected, setArrivalOptionSelected] = useState(null);

	const handleUpdateSlotArrivalStatus = (option) => {
		setArrivalOptionSelected(option);
		if (option.arrivalStatus === 'NO_SHOW') {
			setShowUpdateSlotStatusModal(true);
		} else {
			updateSlotArrivalStatus(slot.slot_id, option.arrivalStatus);
		}
	};

	const handleCancel = () => {
		setShowUpdateSlotStatusModal(false);
		setArrivalOptionSelected(slot.outcome_code);
	};

	const handleConfirm = async () => {
		await updateSlotArrivalStatus(slot.slot_id, arrivalOptionSelected.arrivalStatus);
		setShowUpdateSlotStatusModal(false);
	};

	useEffect(() => {
		setArrivalOptionSelected(slot.outcome_code);
	}, [slot.outcome_code]);

	const uberStatus = slot.checkin_uber_ride_status === 'REQUESTED' ? 'Scheduled' : 'In Progress';
	const uberLegend =
		slot.checkin_uber_ride_status === 'REQUESTED'
			? `pickup ${slot.uber_ride_pickup_time}`
			: `arrival ${slot.uber_arrival_time}`;

	let slotStatus = null;

	if (slot.caregiver_id) {
		slotStatus = slot.slot_status === SHIFT_STATUS.CONFIRMED ? 'confirmed' : 'pending';
	} else if ((!!slot.replaced_caregiver_name || !!slot.contract_id) && slot.slot_status === SHIFT_STATUS.CONFIRMED) {
		slotStatus = 'confirmed';
	} else if (!slot.is_checked_in && !slot.arrival_status_code && slot.is_cancelled) {
		slotStatus = 'cancelled';
	} else if (slot.integrated_shift_expired) {
		slotStatus = 'expired';
	}

	const arrivalStatusBulletColor = ARRIVAL_STATUS_COLORS[slot.arrival_status_code] || 'teal';

	return [
		slot.caregiver_name && slot.can_set_arrival_status && (
			<Select
				key={`${slot.slot_id}-arrival-select`}
				placeholder="Set Arrival Status"
				options={ARRIVAL_STATUS_OPTIONS}
				name="arrivalStatus"
				value={arrivalOptionSelected}
				handleChange={(option) => handleUpdateSlotArrivalStatus(option)}
				className="status-dropdown"
			/>
		),
		slotStatus && <StatusBadge status={slotStatus} />,

		slot.is_checked_in && !slot.can_set_arrival_status && (
			<StatusIndicator bulletPointColorStyle="purple" label="Checked In" detail={slot.checkin_time} />
		),

		!slot.is_checked_in &&
			slot.has_uber_ride &&
			(slot.checkin_uber_ride_status === 'REQUESTED' || slot.checkin_uber_ride_status === 'IN_PROGRESS') && (
				<StatusIndicator bulletPointColorStyle="teal" label={uberStatus} detail={uberLegend} />
			),

		!slot.is_checked_in && slot.arrival_status_code && (
			<StatusIndicator bulletPointColorStyle={arrivalStatusBulletColor} label={slot.arrival_status_name} />
		),

		showUpdateSlotStatusModal && (
			<ConfirmationModal
				key="confirmation-modal-request"
				size="m"
				title="Confirmation"
				scrollable
				onConfirm={handleConfirm}
				onCancel={handleCancel}
			>
				Do you confirm this caregiver didn&apos;t show up to work?
			</ConfirmationModal>
		)
	];
};

export default SlotArrivalStatus;
