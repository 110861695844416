import React from 'react';
import { Modal } from 'react-bootstrap';
import classNames from 'classnames';

import './BaseModal.scss';

import ConfirmModal from './ConfirmModal';

const BaseModal = ({
	className,
	confirmModal,
	title,
	titleIcon,
	primaryActionLabel,
	primaryActionDisabled,
	onPrimaryAction,
	secondaryActionLabel,
	secondaryActionDisabled,
	onSecondaryAction,
	onHide,
	children
}) => {
	if (confirmModal === true) {
		return (
			<ConfirmModal
				className={className}
				title={title}
				titleIcon={titleIcon}
				primaryActionLabel={primaryActionLabel}
				primaryActionDisabled={primaryActionDisabled}
				onPrimaryAction={onPrimaryAction}
				secondaryActionLabel={secondaryActionLabel}
				secondaryActionDisabled={secondaryActionDisabled}
				onSecondaryAction={onSecondaryAction}
				onHide={onHide}
			>
				{children}
			</ConfirmModal>
		);
	}

	return (
		<Modal
			size="lg"
			show
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className={classNames('base-modal', className)}
			onHide={onHide}
			backdropClassName="modal-backdrop-no-show"
		>
			<Modal.Header closeButton>
				<div className="header-content">
					{titleIcon}
					<h1>{title}</h1>
				</div>
				<div className="header-close-spacer" />
			</Modal.Header>
			<Modal.Body>{children}</Modal.Body>

			<Modal.Footer>
				<button
					type="button"
					className="footer-buttons secondary-button"
					disabled={secondaryActionDisabled}
					onClick={onSecondaryAction}
				>
					{secondaryActionLabel}
				</button>
				<button
					type="button"
					className="footer-buttons primary-button"
					disabled={primaryActionDisabled}
					onClick={onPrimaryAction}
				>
					{primaryActionLabel}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default BaseModal;
