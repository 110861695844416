import React from 'react';
import classNames from 'classnames';

import { TIER } from '../../constants/Tiers';
import config from '../../config';

import platinumBadge from '../../assets/images/caregiver-platinum-badge.svg';
import noAvatar from '../../assets/images/no-avatar.png';
import './CaregiverSearch.scss';

/**
 * Displays a list of caregivers based on search criteria.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.searchValue - The search value.
 * @param {Array} props.caregivers - The array of caregivers to display.
 * @param {boolean} props.isOpen - Indicates whether the list is open.
 * @param {function} props.onSelect - Callback function when a caregiver is selected.
 * @returns {JSX.Element} - The rendered component.
 */
const CaregiversList = ({ searchValue, caregivers, isOpen, onSelect, placeholder }) => (
	<div className={classNames('searched-caregivers', { hide: !isOpen })}>
		<ul className="list-search-caregivers">
			{searchValue.length >= 3 &&
				caregivers &&
				caregivers.length > 0 &&
				caregivers.map((caregiver) => (
					<li
						key={`caregiver-${caregiver.caregiverId}`}
						onClick={() => onSelect(caregiver)}
						onKeyPress={() => onSelect(caregiver)}
						role="presentation"
					>
						<img
							key="avatar"
							className="caregiver-search__avatar logo-mobile"
							alt={caregiver.caregiverName}
							src={`${config.API_URL}/caregivers/photo/${caregiver.caregiverId}`}
							// eslint-disable-next-line no-param-reassign, no-return-assign
							onError={(evt) => (evt.target.src = noAvatar)}
						/>
						<span>
							{caregiver.caregiverName}, {caregiver.certificationType}
						</span>
						{caregiver.caregiverStats?.tier === TIER.PLATINUM && (
							<img
								key="tier-badge"
								className="platinum-badge-caregiver logo-mobile"
								alt="tier-badge"
								src={platinumBadge}
							/>
						)}
					</li>
				))}

			{searchValue.length > 0 && caregivers.length === 0 && (
				<li>
					<span>{placeholder}</span>
				</li>
			)}
		</ul>
	</div>
);

export default CaregiversList;
