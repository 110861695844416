import React, { memo } from 'react';
import PaperIcon from '../../components/icons/PaperIcon';
import BaseBadge from '../../components/badges/BaseBadge';

const ContractBadge = ({ shiftsCount }) => {
	const tooltipHtml = (
		<>
			This shift is a part <br />
			of a {shiftsCount}-shift contract.
		</>
	);

	return <BaseBadge tooltipHtml={tooltipHtml} badgeCopy="CONTRACT" className="contract-badge" icon={<PaperIcon />} />;
};

export default memo(ContractBadge);
