import React from 'react';
import classNames from 'classnames';
import AuthorizationInput from '../../../components/input/AuthorizationInput';
import PERMISSION from '../../../constants/Permissions';
import GuaranteedBadge from './GuaranteedBadge';

const GuaranteedCheckbox = ({
	shift,
	onGuaranteedCheckboxChange,
	isGuaranteed,
	className,
	preventGuaranteeOrBonusUpdates,
	isAllowedToToggle
}) => (
	<div className={classNames(className)}>
		{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
		<label className={classNames('guaranteed-checkbox-label')}>
			<AuthorizationInput
				type="checkbox"
				disabled={!isAllowedToToggle || shift.is_orientation || preventGuaranteeOrBonusUpdates}
				checked={isGuaranteed}
				onChange={() => onGuaranteedCheckboxChange(shift.slots)}
				exactPermission={PERMISSION.EDIT}
				id={`guaranteed-toggle-${shift.id}`}
			/>

			<GuaranteedBadge isGuaranteed={isGuaranteed} />
		</label>
	</div>
);

export default GuaranteedCheckbox;
