import React from 'react';

import { NavLink } from 'react-router-dom';

import './Sidebar.scss';

/**
 * Sidebar component for the portal.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {ReactNode} props.children - The content of the sidebar.
 * @returns {JSX.Element} - JSX element representing the Sidebar.
 */
const Sidebar = ({ children }) => {
	return <div className="portal-sidebar">{children}</div>;
};

/**
 * Sidebar link component for navigation within the portal.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {string} props.label - The label of the sidebar link.
 * @param {string} props.to - The target URL for the link.
 * @returns {JSX.Element} - JSX element representing the Sidebar link.
 */
Sidebar.Link = ({ label, to }) => (
	<NavLink to={to} className="portal-sidebar__link" activeClassName="active">
		{label}
	</NavLink>
);

export default Sidebar;
