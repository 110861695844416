import React, { memo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import classNames from 'classnames';

import CustomInput from './CustomDatePickerInput';
import ShiftRequired from './ShiftRequired';

const ShiftsRequiredByDate = (props) => {
	const minDate = new Date();
	const maxDate = new Date(minDate.getFullYear() + 2, minDate.getMonth(), minDate.getDate());
	const { shiftsGroup, shiftGroupIndex, updateShiftGroupDate, shiftStructures } = props;

	const onDateChange = (date) => {
		updateShiftGroupDate(shiftGroupIndex, date);
	};

	const addShift = () => {
		props.addEmptyShift(shiftGroupIndex);
	};

	const getAvailableShiftStructures = (currentShift) => {
		const availableShiftStructures = [];

		shiftStructures.forEach((shiftStructure) => {
			let found = false;
			// eslint-disable-next-line no-restricted-syntax
			for (const shift of shiftsGroup.shifts) {
				// make the current shift structure available for the current shits
				// this happens when we have a shift structure selected for this shift
				if (shift === currentShift) {
					// eslint-disable-next-line no-continue
					continue;
				}
				if (shift.shiftStructureId === shiftStructure.id) {
					found = true;
					break;
				}
			}
			if (!found) {
				availableShiftStructures.push(shiftStructure);
			}
		});
		return availableShiftStructures;
	};

	return (
		<>
			<div className={classNames('date-row', { 'shift-row-grey-background': props.isOddIndex })}>
				<div className="date-section">
					<p className="text-head">Date</p>
					<div className="by-date-picker-wrapper">
						<DatePicker
							selected={new Date(shiftsGroup.date)}
							minDate={minDate}
							maxDate={maxDate}
							onChange={onDateChange}
							customInput={<CustomInput />}
						/>

						{props.showAddButton && (
							<button type="button" className="add-date-button" onClick={props.addAnotherDate}>
								+ ADD ANOTHER DATE
							</button>
						)}
					</div>
				</div>

				<div className={classNames('shifts-section', { last: props.showAddButton })}>
					{shiftsGroup &&
						shiftsGroup.shifts.map((shift, index) => {
							return (
								<ShiftRequired
									/* eslint-disable-next-line react/no-array-index-key */
									key={`shift${index}`}
									availableShiftStructures={getAvailableShiftStructures(shift)}
									allowRemove={props.allowRemove}
									addEmptyShift={addShift}
									shiftGroupIndex={shiftGroupIndex}
									updateShift={props.updateShift}
									shiftIndex={index}
									errors={props.errors[shift.id] || {}}
									removeShift={props.removeShift}
									shift={shift}
									date={shiftsGroup.date}
									isFlex={props.isFlex}
								/>
							);
						})}
					{shiftStructures.length !== shiftsGroup.shifts.length && (
						<button type="button" key="add-shift-button" className="add-shift-button" onClick={addShift}>
							+ ADD ANOTHER SHIFT
						</button>
					)}
				</div>
			</div>
		</>
	);
};
export default memo(ShiftsRequiredByDate);
