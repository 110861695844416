import React, { Fragment, useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import tooltipIcon from '../../../assets/images/tooltip-icon.svg';
import checkShield from '../../../assets/images/checkShield.svg';
import tradeMarkIcon from '../../../assets/images/trade-mark.svg';
import './RecurringShiftsTable.css';

const RecurringShiftsErrorsModal = (props) => {
	const { onClose, recurringShiftsErrors, shiftStructures, tooltipPlacement } = props;
	const [errorsByDay, setErrorsByDay] = useState([]);

	const getErrorsByDay = useCallback(() => {
		return Object.keys(recurringShiftsErrors).reduce((allDays, day) => {
			if (!Array.isArray(recurringShiftsErrors[day])) return allDays;
			const dayErrors = recurringShiftsErrors[day].map(({ id }) => shiftStructures.find((shift) => shift.id === id));
			allDays.push({ day, dayErrors });
			return allDays;
		}, []);
	}, [recurringShiftsErrors, shiftStructures]);

	useEffect(() => {
		setErrorsByDay(getErrorsByDay());
	}, [getErrorsByDay, recurringShiftsErrors]);

	const DayInformation = (day, dayErrors) => {
		return dayErrors.map((shiftObj, ind) => (
			<Fragment key={`fragment-${shiftObj.resource_type}-${shiftObj.from_time}-${shiftObj.to_time}`}>
				{ind === 0 && <hr />}
				<div className="w-100 row" key={`${shiftObj.resource_type}-${shiftObj.from_time}-${shiftObj.to_time}`}>
					<div className="col-2 reccuring-shifts-grid-font common-font centered-v-h-position">{ind === 0 && day}</div>
					<div className="col-2 reccuring-shifts-grid-font common-font centered-v-h-position">
						{shiftObj.resource_type}
					</div>
					<div className="col-3 centered-v-h-position common-font time-font">
						{`${moment.utc(shiftObj.from_time, 'HH:mm:ss').format('h:mm A')} - ${moment
							.utc(shiftObj.to_time, 'HH:mm:ss')
							.format('h:mm A')}`}
					</div>
					<div className="col-3 centered-v-h-position">
						{shiftObj.is_orientation && <img src={checkShield} alt="trade-mark-icon" className="trade-mark-icon" />}
					</div>
					<div className="col-2 centered-v-h-position">
						<span className="error-badge">Did not save</span>
					</div>
				</div>
			</Fragment>
		));
	};

	return (
		<Modal
			size="xl"
			show
			aria-labelledby="contained-modal-title-vcenter"
			centered
			className="confirmation-modal"
			onHide={() => onClose({})}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">Recurring Shifts Errors</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="recurring-shifts-modal-body">
					<div className="reccuring-shifts-grid error-container">
						<div className="w-100 row reccuring-shifts-grid-head">
							<div className="col-2 title-font reccuring-shifts-grid-font centered-v-h-position">Day</div>
							<div className="col-2 title-font reccuring-shifts-grid-font centered-v-h-position">Type</div>
							<div className="col-3 title-font reccuring-shifts-grid-font centered-v-h-position">Time</div>
							<div className="col-3 centered-v-h-position">
								<div className="title-font centered-v-h-position reccuring-shifts-grid-font">Guaranteed Shifts</div>
								<div className="table-head-centered-icons">
									<div className="right-top-corner margin-right-4">
										<img src={tradeMarkIcon} alt="trade-mark-icon" className="trade-mark-icon" />
									</div>
									<div className="right-top-corner">
										<OverlayTrigger
											placement={tooltipPlacement}
											trigger={['hover', 'click']}
											overlay={
												<Tooltip className="aditionalInformation info-tooltip">
													<p>
														Guaranteed Shifts are picked up with 20% higher claim rates &amp; have higher show rates.
													</p>
													<p>
														NOTE: If this shift is claimed, you are committing to pay for the professional, even if you
														choose to cancel them later.
													</p>
												</Tooltip>
											}
										>
											<img src={tooltipIcon} alt="tooltip-icon" className="tooltip-icon-small" />
										</OverlayTrigger>
									</div>
								</div>
							</div>
							<div className="col-3 centered-v-h-position" />
							<div className="col-2" />
						</div>
						<div className="reccuring-shifts-grid-body">
							{errorsByDay.map(({ day, dayErrors }) => DayInformation(day, dayErrors))}
						</div>
						<div className="reccuring-shifts-grid-font error-text">
							These shifts were not able to be updated. Please go back to your recurring shifts to update the shifts
							above. If you still face issues please reach out to your account manager.
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer className="error-btn">
				<Button size="lg" onClick={() => onClose({})} variant="primary">
					CLOSE
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default RecurringShiftsErrorsModal;
