import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import Checkbox from '../../../form/Checkbox';
import SlotView from './SlotView';

import './ConfirmationsTable.css';

const ConfirmationsTable = (props) => {
	const { onChecked } = props;

	const [daysToShow, setDaysToShow] = useState([]);
	const [checkedSlots, setCheckedSlots] = useState([]);
	const [slotsAmount, setSlotsAmount] = useState(0);

	useEffect(() => {
		onChecked(checkedSlots);
	}, [checkedSlots, onChecked]);

	const formatDate = (date) => {
		return moment.utc(date).format('dddd, MMM D, YYYY');
	};

	useEffect(() => {
		let slotsCounter = 0;

		props.days.forEach((day) => {
			slotsCounter += day.slots.length;
		});

		setSlotsAmount(slotsCounter);
	}, [props.days]);

	const checkSlot = (slotId) => {
		if (!checkedSlots.includes(slotId)) {
			setCheckedSlots([...checkedSlots, slotId]);
		}
	};

	const checkMultipleSlots = useCallback(
		(slotIds) => {
			setCheckedSlots([...checkedSlots, ...slotIds]);
		},
		[checkedSlots]
	);

	const uncheckSlot = (slotId) => {
		setCheckedSlots(checkedSlots.filter((currentSlotId) => currentSlotId !== slotId));
	};

	const toggleSlotCheck = (slotId) => {
		if (checkedSlots.includes(slotId)) {
			uncheckSlot(slotId);
		} else {
			checkSlot(slotId);
		}
	};

	const handleToggleAll = () => {
		if (checkedSlots.length === slotsAmount) {
			setCheckedSlots([]);
		} else {
			const slotsToCheck = [];

			props.days.forEach((day) => {
				day.slots.forEach((slot) => {
					if (!checkedSlots.includes(slot.slot_id)) {
						slotsToCheck.push(slot.slot_id);
					}
				});
			});

			checkMultipleSlots(slotsToCheck);
		}
	};

	useEffect(() => {
		const defaultCheckedSlots = [];
		props.days.forEach((day) => {
			day.slots.forEach((slot) => {
				if (slot.is_guaranteed) {
					defaultCheckedSlots.push(slot.slot_id);
				}
			});
		});
		checkMultipleSlots(defaultCheckedSlots);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (props.hasErrors.length > 0) {
			setDaysToShow(props.days.filter((day) => day.slots.some((slot) => props.hasErrors.includes(slot.slot_id))));
		} else {
			setDaysToShow(props.days);
		}
	}, [props.days, props.hasErrors]);

	return (
		<div className="confirmations-table-body">
			<div className="confirmations-table-header d-flex justify-content-between">
				<div className="table-title col-1">
					{!props.hideCheckbox && (
						<Checkbox
							checked={checkedSlots.length === slotsAmount}
							className="slot-checkbox"
							handleChange={handleToggleAll}
						/>
					)}
				</div>
				<div className="table-title font-weight-bold h3 col-1">Type</div>
				<div className="table-title font-weight-bold h3 col-2">Name</div>
				<div className="table-title font-weight-bold h3 col-1 col-lg-2">Time</div>
				<div className="table-title font-weight-bold h3 col-2">Credentials</div>
				<div className="table-title font-weight-bold h3 col-1">Bonus</div>
				<div className="table-title font-weight-bold h3 col-1 text-center">Guarantee</div>
				<div className="table-title col-2" />
			</div>
			<div className="shift-day-separator header-separator" />
			<div className="confirmations-table-rows" key="confirmation-shifts-table">
				{daysToShow.map((day, index) => [
					<h2 className="shift-day-header-date" key={`shift-day-${day.shifts[0].id}`}>
						{formatDate(day.shifts[0].raw_start_time)}
					</h2>,
					<SlotView
						day={day}
						dayIndex={index}
						slots={day.slots}
						toggleSlotCheck={toggleSlotCheck}
						checkedSlots={checkedSlots}
						hideCheckbox={props.hideCheckbox}
						className="confirmations-table-row"
						hasErrors={props.hasErrors}
						key={`shift-day-${day.shifts[0].id}-slot`}
					/>,
					<div className="shift-day-separator" key={`shift-day-${day.shifts[0].id}-separator`} />
				])}
			</div>
		</div>
	);
};

export default ConfirmationsTable;
