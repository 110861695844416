import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import ProTip from '../proTip/ProTip';

import './GenericPermissionsModal.css';

const GenericPermissionsModal = (props) => {
	return (
		<Modal
			size={props.size || 'ms'}
			show
			aria-labelledby="contained-modal-title-vcenter"
			centered
			scrollable={!!props.scrollable}
			className="bonus-permissions-modal"
			onHide={props.onClose}
		>
			<Modal.Body className="text-center">
				{props.showProTip && <ProTip />}
				<div className="modal-title">Action Not Allowed… Yet!</div>
				<p className="modal-text">
					Please contact your account manager to receive the permission to
					{props.permissionAction}.
				</p>
			</Modal.Body>
			<Modal.Footer className="d-flex justify-content-center">
				<Button onClick={props.onConfirm} variant="light" className="confirm">
					{props.confirmLabel || 'OK'}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default GenericPermissionsModal;
