import React from 'react';
import Card from 'react-bootstrap/Card';

import './BaseCard.scss';

const Actions = ({ children }) => {
	return <div className="card-actions">{children}</div>;
};

const ActionButton = ({ label, icon, onClick }) => {
	return (
		<div className="card-actions__button" role="button" aria-hidden="true" tabIndex={0} onClick={onClick}>
			{icon}
			{label}
		</div>
	);
};

Card.Actions = Actions;
Card.ActionButton = ActionButton;

export default Card;
