import React, { memo } from 'react';
import classNames from 'classnames';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { PERMISSION, PRIVATE_PATH } from '../../constants';
import { CaretIcon } from '../icons';
import IsAuthorized from '../IsAuthorized/IsAuthorized';
import DailyScheduleIcon from '../icons/DailyScheduleIcon';
import MenuItemIconLabel from './MenuItemIconLabel';
import PastShiftsIcon from '../icons/PastShiftsIcon';
import SettingsIcon from '../icons/SettingsIcon';
import ProfessionalsIcon from '../icons/ProfessionalsIcon';
import UserAvatar from '../Avatar/UserAvatar';
import DropdownMenu from '../form/Dropdown';

import './AppHeaderDesktop.scss';

const AppHeaderDesktop = ({
	showFacility,
	userData,
	dropdownRef,
	typeaheadRef,
	CustomToggle,
	selectedFacility,
	dropdownMenuRef,
	CustomMenu,
	hideFacilitySearch,
	handleFacilityChange,
	activeFacility,
	isPastShiftsAllowed,
	setShowMobileMenu,
	handleNavBarNotOnMobileClick,
	showPastShiftsCircle,
	shiftsToReview,
	hasProfessionalsFeature,
	hasSettingsAccess,
	containerDesktopRef,
	open,
	setOpen,
	menuItems,
	professionalsPath
}) => {
	const location = useLocation();

	return (
		<header className="app-header-desktop" key="app-header-desktop">
			<Link to="/" className="logo" />
			<div className="facility-and-menu">
				<div className="facility-label-dropdown">
					<div className="vertical-separator" />
					{showFacility && userData.facilitiesEnabled.length > 1 ? (
						<Dropdown
							ref={dropdownRef}
							onToggle={(isOpen) => {
								if (isOpen) {
									setTimeout(() => {
										typeaheadRef.current.focus();
										typeaheadRef.current.clear();
									}, 10);
								}
							}}
						>
							<Dropdown.Toggle id="facility-enabled-search-dropdown" as={CustomToggle}>
								{selectedFacility?.label}
							</Dropdown.Toggle>
							<Dropdown.Menu
								ref={dropdownMenuRef}
								style={{ margin: 0 }}
								as={CustomMenu}
								onOptionChange={(selected) => {
									hideFacilitySearch();
									if (selected[0]) {
										handleFacilityChange(selected[0]);
									}
								}}
							/>
						</Dropdown>
					) : (
						<div>{activeFacility.label}</div>
					)}
					<div className="flex-one" />
				</div>

				<div className={classNames('right-menu ', { 'flex-one': isPastShiftsAllowed })}>
					<NavLink
						to={PRIVATE_PATH.SHIFTS}
						className="shift-menu-item"
						onClick={() => setShowMobileMenu(false)}
						activeClassName="active"
					>
						<MenuItemIconLabel icon={<DailyScheduleIcon />} label="Daily&nbsp;Schedule" />
					</NavLink>
					{isPastShiftsAllowed && (
						<NavLink
							to={PRIVATE_PATH.PAST_SHIFTS}
							className="shift-menu-item"
							onClick={handleNavBarNotOnMobileClick}
							activeClassName="active"
						>
							<MenuItemIconLabel
								icon={<PastShiftsIcon />}
								label="Past&nbsp;shifts"
								showRedCircle={showPastShiftsCircle}
								redNumber={shiftsToReview}
							/>
						</NavLink>
					)}

					{hasProfessionalsFeature && (
						<IsAuthorized
							exactPermission={[PERMISSION.PROFESSIONALS, PERMISSION.INTERNAL_STAFF]}
							readOnlyRender={
								<OverlayTrigger
									trigger={['hover', 'focus']}
									key="professionals-tooltip"
									placement="bottom"
									overlay={
										<Tooltip className="professionals-menu-item-tooltip" placement="bottom" show>
											You do not have permission to perform this action. Please contact your Customer Success Rep for
											details.
										</Tooltip>
									}
								>
									<MenuItemIconLabel icon={<ProfessionalsIcon />} label="Professionals" />
								</OverlayTrigger>
							}
						>
							<NavLink
								to={professionalsPath}
								className="shift-menu-item"
								onClick={() => setShowMobileMenu(false)}
								activeClassName="active"
								isActive={() => {
									return [PRIVATE_PATH.PROFESSIONALS_ON_DEMAND, PRIVATE_PATH.PROFESSIONALS_INTERNAL_STAFF].includes(
										location.pathname
									);
								}}
							>
								<MenuItemIconLabel icon={<ProfessionalsIcon />} label="Professionals" />
							</NavLink>
						</IsAuthorized>
					)}
					{hasSettingsAccess && (
						<NavLink
							to={{ pathname: PRIVATE_PATH.SETTINGS }}
							className="shift-menu-item"
							onClick={handleNavBarNotOnMobileClick}
							activeClassName="active"
						>
							<MenuItemIconLabel icon={<SettingsIcon />} label="Settings" />
						</NavLink>
					)}
				</div>
				<div className={classNames('user-section', { active: open })} ref={containerDesktopRef}>
					{(userData.name || userData.email) && (
						<>
							<div
								className="user-section__avatar"
								key="user-avatar"
								onClick={() => setOpen(!open)}
								role="button"
								aria-hidden="true"
								tabIndex={0}
							>
								<UserAvatar initials={userData.initials} />
								<button type="button" onClick={() => setOpen(!open)} aria-label="Toggle user menu">
									<CaretIcon pointTo={open ? 'up' : 'down'} className="user-section__carat" />
								</button>
							</div>
							<DropdownMenu
								className="user-section__dropdown"
								open={open}
								header={
									<ul>
										<li className="bold">{userData.name}</li>
										<li>{userData.email}</li>
									</ul>
								}
								menuItems={menuItems}
							/>
						</>
					)}
				</div>
			</div>
		</header>
	);
};

export default memo(AppHeaderDesktop);
